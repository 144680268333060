import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const ReportsListSidebarORX = (props) => {
  return (
    <>
    {/* <noPrint> */}
      <Container  fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px",paddingRight:"20px" }  }>
        <div className="sidenav" style={{paddingLeft: "0px",paddingRight: "20px",marginRight: "10px"}}>

      

        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/ORX/SCstanza">
Stanza Summary
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/ORX/pokeListSMA">
        Southwest Medical Poke List
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/ORX/pokeListUPMC">
        UPMC Poke List
        </Link>
        </div>
        </div>
      </Container>
  
    </>
  );
};

export default ReportsListSidebarORX; 
