import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import CmReportTableVS1NoCols from "./CmReportTableVS1NoCols"; 
import GraphTemplateVSA from "./GraphTemplateVSA";
import CmReportTableVS1noIndSpc from "./CmReportTableVS1noIndSpc";
import CmReportTableVS1noIndBT from "./CmReportTableVS1noIndBT";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCappointmentsAB = (props) => {
 
    const [updated, setUpdated] = useState([]);
    const [ptitleAp, setPtitleAp] = useState([]);
    const [completed_intake, setCompleted_intake] = useState([]);
    const [sched_next, setSched_next] = useState([]);
    const [stanza_rx, setStanza_rx] = useState([]);
    const [active_in_stanza, setActive_in_stanza] = useState([]);
    const [ever_active,setEver_active]=useState([]);
    const [intake_explA, setIntake_explA] = useState([]);
    const [intake_explB, setIntake_explB] = useState([]);

const [STRc,setSTRc]=useState([]);
const [STRe,setSTRe]=useState([]);
const [_em,setEm]=useState([]);

    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("appointments");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }
       const getAbbrvs = async (abbrv,setter) => { 
        const dashes = firestore.collection("analytics").doc("preVS").collection("appt_abbrv_key").doc(abbrv);
        const doc = await dashes.get();
        const docData=doc.data()
        // const abbrv = docData['abbrv'];
        const appt = docData['appt'];
            console.log(abbrv)
            console.log(appt)
            setter(appt);
       }
       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
        getTitleCs('ptitleAp',setPtitleAp)
		getTitleCs('completed_intake',setCompleted_intake)
		getTitleCs('sched_next',setSched_next)
		getTitleCs('stanza_rx',setStanza_rx)
		getTitleCs('active_in_stanza',setActive_in_stanza)
        getTitleCs('ever_active',setEver_active)
        getTitleCs('intake_explA',setIntake_explA)
        getTitleCs('intake_explB',setIntake_explB)

        getAbbrvs('STRc',setSTRc)
        getAbbrvs('STRe',setSTRe)
        getAbbrvs('_em',setEm)
    }, [props]);
return (<>
 <Helmet>
        <title>Appointments SCA</title>
        <meta name="Appointments SCA" content="Appointments SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebarSCA/>
        


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
             
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#active_patients">•active patients</HashLink>
                <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#appts_stat">•appointments, by status</HashLink>  
             <br/>
        
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#per_completed">•appointments, proportion completed</HashLink>  
             <br/>
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#appts_month">•completed appointments, by month</HashLink>  
             <br/>
            
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#appt_epros">•epro completion</HashLink>
               <br/>
          

             
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true}>
                   <div className="article" style={{paddingLeft: "0px", paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{ptitleAp} <span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
</div>

                   </Container>

      
<Container fluid={true} >
    <Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>
        <Row>
            <Col>
                <HashLink id="active_patients" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Active Patients</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "18px",lineHeight: "1"}}>{completed_intake} <span style={{fontSize: "15px", paddingRight: "4px",fontWeight: "450"}}>patients have completed intake appointments.</span></div>
            <div className="TitleC" style={{paddingLeft: "20px",paddingTop: "2px",paddingBottom: "5px",fontSize: "17px",lineHeight: "1"}}>{ever_active} <span style={{fontSize: "15px", paddingRight: "4px",fontWeight: "450"}}>patients have ever been active.</span></div>

            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1",fontWeight: "750"}}>{sched_next} <span className="TitleC" style={{fontSize: "16px", paddingRight: "4px",fontWeight: "450"}} >patients have completed intake appointments and have an upcoming appointment scheduled.</span></div>
            <Row>
            <Col>

            </Col>
           </Row>
            <div className="TitleC" style={{paddingLeft: "20px",paddingTop: "0px",paddingBottom: "3px",fontSize: "18px",lineHeight: "1",fontWeight: "750"}}>{stanza_rx} <span className="TitleC" style={{fontSize: "16px", paddingRight: "4px",fontWeight: "450"}} > of those <strong>{sched_next}</strong> patients have a Stanza Rx.</span></div>
            <div className="TitleC" style={{paddingLeft: "40px",paddingTop: "0px",paddingBottom: "3px",fontSize: "17px",lineHeight: "1",fontWeight: "750"}}>{active_in_stanza} <span className="TitleC" style={{fontSize: "16px", paddingRight: "4px",fontWeight: "450"}} > of those <strong>{stanza_rx}</strong> patients are actively using Stanza.</span><span className="TitleC" style={{paddingLeft: "50px",fontSize: "10px",lineHeight: "1",fontWeight: "300"}}>*have completed a session in the last 3 days</span> </div>
           

           
           
            </div> 
            <div className="TitleC" style={{paddingLeft: "220px",paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",lineHeight: "1"}}>*active → ever active & completed intake ≤ month ≤ final month</div>

            <div style={{paddingTop: "0px",paddingLeft: "60px", maxWidth: "500px"}} >
         
<Row style={{overflowY: "scroll",  height: "auto",maxHeight: "400px"}}>
<Col>
<CmReportTableVS1stickyH style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="11px" tdFontSize="10px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#DA36DB" tableKey={"intake_activesA"} />
</Col>
</Row>
</div>
</div> 
</Row>
</Container>



<Container fluid={true} >
<Row>
<div className="articleVS" style={{paddingTop: "45px", paddingBottom: "20px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "15px"}}>
        <Row>
            <Col>
                <HashLink id="appts_stat" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Appointments</HashLink>
                
                <div style={{ borderTop: "1px solid #941751",paddingTop: "0px", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "8px",paddingBottom: "0px",fontSize: "15px",color: "#000000"}}>Appointment Abbreviations</div>

<CmReportTableVS1NoCols style={{padding: "0px"}} fColor="#000000"  tdFontSize="12px"  tableKey={"appt_abbrv_key"} />


</div> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "20px"}}>Counts by Status</div>
           <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "4px",fontSize: "18px"}}>by category</div>
           <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "1px",fontSize: "10px", lineHeight: "1"}}>{intake_explA} </div>
           <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "10px", lineHeight: "1"}}>{intake_explB}</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1noIndBT style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="0px"  thPaddingLeft="2px" bgColor="#9FC5D4" tableKey={"appt_counts11"} />

</Col>
</Row>


</div>
</Row>
</Container>

<Container fluid={true} >
<Row>   
<div className="articleVS" style={{paddingTop: "45px", paddingBottom: "20px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "15px"}}>
        <Row>
            <Col>
                <HashLink id="per_completed" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Appointments</HashLink>
                
                <div style={{ borderTop: "1px solid #941751",paddingTop: "0px", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Proportion Completed</div>

            </div> 
            <div  className="TitleC" style={{ fontSize: "13px",lineHeight: "1",paddingLeft: "10px",paddingBottom: "4px"}} ><span style={{color: "#945200"}}>Total3</span> == <span style={{color: "#FF0000"}}>No-Show</span> + <span style={{color: "#FFC000"}}>Cancelled</span> + <span style={{color: "#003EC0"}}>Occurred</span> <span style={{paddingLeft: "35px"}} ><span style={{color: "#7001A0"}}>Total2</span> == <span style={{color: "#FF0000"}}>No-Show</span> + <span style={{color: "#003EC0"}}>Occurred</span></span> </div>
<div  className="TitleC" style={{ fontSize: "13px",lineHeight: "1",paddingLeft: "10px"}} ><span style={{color: "#945200"}}>Proportion_Occurred3</span> == <span style={{color: "#003EC0"}}>Occurred</span>/ <span style={{color: "#945200"}}>Total3</span> <span style={{paddingLeft: "35px"}} ><span style={{color: "#7001A0"}}>Proportion_Occurred2</span> == <span style={{color: "#003EC0"}}>Occurred</span>/ <span style={{color: "#7001A0"}}>Total2</span></span> </div>
{/* <span className="TitleC" style={{paddingLeft: "60px",fontSize: "12px"}} >Intake + Intake_OTCC == total Intakes</span> */}
            <div className="TitleC" style={{paddingTop: "18px",paddingBottom: "4px",fontSize: "18px"}}>by category</div>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "1px",fontSize: "10px", lineHeight: "1"}}>{intake_explA}</div>
           <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "10px", lineHeight: "1"}}>{intake_explB}</div>

            <Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1noIndSpc style={{padding: "0px"}}  fColor="#FFF9F3"  thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" tableKey={"appt_occurred1"} />

</Col>
</Row>
{/* <div className="TitleC" style={{paddingTop: "18px",paddingBottom: "5px",fontSize: "18px"}}>by type, package</div> */}
<Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px"}}>
<Col>
{/* <CmReportTableVS1noIndSpc style={{padding: "0px"}}  fColor="#FFF9F3"  thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" tableKey={"appt_occurred"} /> */}
</Col>
</Row>

</div>
</Row>
</Container>


<Container fluid={true}  >
<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingTop: "0px",paddingBottom: "5px"}}>
        <Row>
            <Col>
                <HashLink id="appts_month" className="TitleC" style={{ paddingTop: "0px",paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Appointments</HashLink>
                
                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Completed Appointments by Month</div>

            </div> 
{/* <span className="TitleC" style={{paddingLeft: "60px",fontSize: "12px"}} >Intake + Intake_OTCC == total Intakes</span> */}
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "4px",fontSize: "18px", lineHeight: "1"}}>by category</div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "1px",fontSize: "10px", lineHeight: "1"}}>{intake_explA} </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "10px", lineHeight: "1"}}>{intake_explB} </div>

            <Row >

    <Col>
   
 <GraphTemplateVSA style={{padding: "0px"}} report={"appt_counts21"} />
</Col>
</Row>

</div>
</Row>
</Container>


<Container fluid={true}  >
<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "30px", maxWidth: "90%" }}> 
<div style={{paddingTop: "40px",paddingBottom: "0px"}}>
        <Row>
            <Col>
                {/* <HashLink id="appts_month" className="TitleC" style={{ paddingTop: "0px",paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Appointments</HashLink> */}
                
                {/* <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div> */}


            </Col>
            </Row>
            {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Completed Appointments by Month</div> */}

            </div> 
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "18px", lineHeight: "1"}}>by type, package</div> */}
            <Row >

    <Col>
   
 {/* <GraphTemplateVSA style={{padding: "0px"}} report={"appt_counts2"} /> */}
</Col>
</Row>

</div>
</Row>
</Container>

<Container fluid={true}  >
<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingTop: "0px",paddingBottom: "15px"}}>
        <Row>
            <Col>
                <HashLink id="appt_epros" className="TitleC" style={{ paddingTop: "0px",paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">EPRO Completion</HashLink>
                
                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>

            </div> 
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "18px"}}>overall</div>
            <Row >

    <Col>
   
<CmReportTableVS1 style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#6D7722" tableKey={"appt_epros_overall"} />

</Col>
</Row>
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "0px",fontSize: "18px"}}>EPRO Completion by Appointment<span style={{paddingLeft: "230px",fontSize: "10px",lineHeight: "1",fontWeight: "300"}}>*of completed epros, percent completed in advance</span></div>
<Row >
    <Col>
    <CmReportTableVS1noInd style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"appt_epros1B"} />
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "0px",fontSize: "18px"}}>EPRO Completion by Patient<span style={{paddingLeft: "265px",fontSize: "10px",lineHeight: "1",fontWeight: "300"}}>*of completed epros, percent completed in advance</span></div>
<Row >
    <Col>
    <CmReportTableVS1 style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#7A0078" tableKey={"patient_epros1"} />
</Col>
</Row>
</div>
</Row>
</Container>





</>);




};

export default SCappointmentsAB;