import React, {useState} from "react";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../utils/screens/LoadingScreen";
import "../surveyAdherence.css";
import {firestore} from "../../utils/firebase";
import AdminNavbar from "../../components/AdminNavbar";
import {useHistory} from "react-router-dom";
import AreportsListSidebar from "./AreportsListSidebar";
import {Col, Container, Jumbotron, Row, Table} from "react-bootstrap";

const FablesOfTables = (props) => {
  const [filter, setFilter] = useState("");
  const query = firestore
    .collection("surveyAdherence")
    .orderBy("activeProgram", "desc");
  const columns = [
    "adherence",
    "activeProgram",
    "subjectID",
    "w2020_06_22",
    "w2020_06_29",
    "w2020_07_06",
    "w2020_07_13",
    "w2020_07_20",
    "w2020_07_27",
    "w2020_08_03",
    "w2020_08_10",
    "w2020_08_17",
    "w2020_08_24",
    "w2020_08_31",
    "w2020_09_07",
    "w2020_09_14",
    "w2020_09_21",
    "w2020_09_28",
    "w2020_10_05",
    "w2020_10_12",
    "w2020_10_19",
    "w2020_10_26",
    "w2020_11_02",
    "w2020_11_09",
    "w2020_11_16",
    "w2020-11-30",
    "w2020-12-07"
  ];
  let history = useHistory();
  const [rows, rowsLoading, rowsError] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const summaryQuery = firestore.collection("analytics").doc("FIQRweek");
  const [summaryRow, rowsLoading2, rowsError2] = useDocument(summaryQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const getTableRow = (progRef) => {
    // if (!journalType.matchesFilter(filter)) {
    //     return <></>;
    // }
    let data = progRef.data();
    let numerator = 0;
    let denominator = 0;

    let cols = columns.map((c) => {
      numerator = data[c] === 1 ? numerator + 1 : numerator;
      denominator =
        data[c] === 1 || data[c] === 0 ? denominator + 1 : denominator;
      return (
        <td
          className={
            data[c] === 1
              ? "table-success"
              : data[c] === 0
              ? "table-danger"
              : data[c]
              ? ""
              : "table-active"
          }
        >
          {data[c]}
        </td>
      );
    });

    return (
      <tr key={progRef.id} onClick={() => {}}>
        {/* <td>{denominator !== 0 && numerator / denominator}</td> */}
        {cols}
      </tr>
    );
  };

  return (
    <>
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>FIQR Adherence</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <AreportsListSidebar />

      <Container
        fluid={true}
        style={{ backgroundColor: "white", width: "95%" }}
      >
        <div className="articleB">
          <p class="TitleC" style={{ paddingLeft: "20px" }}>
            By Week & Participant
          </p>
          {/* <Row>
                    <Col>
                        <p class="TitleA">By Participant</p>
                    </Col>
                </Row> */}
          <Row>
            {rowsError && (
              <>
                <strong>Error: {JSON.stringify(rowsError)}</strong>
                <br></br>
              </>
            )}
            {rowsLoading && (
              <>
                <strong>Reports Loading: {JSON.stringify(rowsError)}</strong>
                <br></br>
                <LoadingScreen></LoadingScreen>
              </>
            )}
            {rows && (
              <Col className={"reports_table"} xs={12}>
                <Table>
                  <thead key="programs">
                    <tr>
                      {/* <th>adherence</th> */}
                      {columns.map((c) => {
                        return <th>{c}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.docs.map((u) => getTableRow(u))}
                    {summaryRow && getTableRow(summaryRow)}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default FablesOfTables;
