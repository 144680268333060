import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref"; 
import CmReportTable003AxLref from "./CmReportTable003AxLref";
import CmReportTable003AxL from "./CmReportTable003AxL";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";

import CmReportTable003Ax3 from "./CmReportTable003Ax3";
import CmReportTable003Ax3Ind from  "./CmReportTable003Ax3Ind" ;
// import GAD7explanation from   "../GAD7explanation";





const A003Mauq = (props) => {
    

 return (<>
 <Helmet>
        <title>MAUQ 003A</title>
        <meta name="MAUQ 003A" content="MAUQ 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1 style={{paddingBottom: "9px"}}>MAUQ 003A</h1>
                 <h2 className="TitleDB" style={{paddingBottom: "25px", fontSize: "28px"}}>mHealth App Usability Questionnaire</h2>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
             
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#subscales">•skip to subscale scores</HashLink>
{/* <br/>
<HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_done">•skip to individual scores, done</HashLink>
<br/>
 <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_therapy">•skip to individual scores, therapy</HashLink>
<br/>
 <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_early_terms">•skip to individual scores, early terms</HashLink> */}

     
      
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>

       <Container fluid={true} style={{paddingLeft: "20px"}}> 

</Container>
<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{fontSize: "40px" ,paddingBottom: "0px"}}>MAUQ Subscales</div>
            <table className={"twoArm_tableB"}  style={{padding: "0px"}}>
<tr>
<th style={{backgroundColor: "#FFF9F3", fontSize: "17px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px",fontWeight: "450",color:"#636161"}}>name</th>
<th style={{backgroundColor: "#FFF9F3", fontSize: "17px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px",fontWeight: "450",color:"#636161"}}>subscale</th>
<th style={{ backgroundColor: "#FFF9F3",fontSize: "17px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px",fontWeight: "450",color:"#636161"}}>questions</th>
<th style={{backgroundColor: "#FFF9F3", fontSize: "17px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px",fontWeight: "450",color:"#636161"}}>score</th>
</tr>
<tr>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>mauqI</td>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>interface</td>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>7</td>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>mean</td>
</tr>
<tr>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>mauqE</td>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>ease of use</td>
<td style={{ fontSize: "15px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>5</td>
<td style={{ fontSize: "16px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "6px"}}>mean</td>
</tr>
</table>

            {/* <div className="TitleC" style={{fontSize: "40px" ,paddingBottom: "5px"}}>MAUQ Scoring</div> */}
            <div className="TitleC" style={{paddingBottom: "3px",paddingTop: "7px"}}>All Questions, Both Subscales</div>
<table style={{padding: "0px"}}>
<tr>
</tr>
<tr>
<td style={{backgroundColor: "#B4CF16", color: "#FFFFFF", borderRight: "double white",borderBottom: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>1</td>
<td style={{backgroundColor: "#D6D6D6", color: "#FFFFFF", borderRight: "double white",  paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>→</td>

<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderBottom: "double white",  paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>7</td>
</tr>
<tr>
<td style={{backgroundColor: "#B4CF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>strongly agree</td>
<td style={{backgroundColor: "#D6D6D6", color: "#FFFFFF", borderRight: "double white",  paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>→</td>

<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", paddingRight:"5px",paddingLeft: "4px", fontSize: "16px"}}>strongly disagree</td>
</tr>
</table>
</div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{fontSize: "40px" ,paddingBottom: "0px"}}>Results by Question</div>

            <div className="TitleC" style={{paddingBottom: "5px"}}>Mean Scores, by Question and Week
            
            <span style={{paddingLeft: "40px", fontSize: "16px"}} >All participants</span>
            </div>
            <CmReportTable003Ax3 bgColor="#F55C48" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"mauqIDmeans003A"} />

        </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>

            <div className="TitleC" style={{paddingBottom: "5px"}}>Mean Scores, by Question and Week
            
            <span style={{paddingLeft: "40px", fontSize: "16px"}} >Participants with week 6 and at least 1 subsequent score</span>
            </div>
            <CmReportTable003Ax3 bgColor="#17BAFF" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"mauqIDmeans003A_has_comp"} />

        </div>
        </Container>
    
        <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
<div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
        <Row>
            <Col>
                <HashLink id="subscales" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
               
                
            </Col>
            </Row>
            </div>
            </Container>
            <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{fontSize: "40px" ,paddingBottom: "0px"}}>Results by Subscale</div>

            <div className="TitleC" style={{paddingBottom: "5px"}}>Subscale Scores by participant
            
            {/* <span style={{paddingLeft: "40px", fontSize: "16px"}} >Participants with week 6 and at least 1 subsequent score</span> */}
            </div>
            <CmReportTable003Ax3Ind bgColor="#662D91" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"mauqScores003A"} />

        </div>
        </Container>
</>);

};

export default A003Mauq;
