import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";
import { HashLink } from "react-router-hash-link";

import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";

import CmReportTable003Ax from "./CmReportTable003Ax";
import {Helmet} from "react-helmet";

import GraphTemplate003A from "./GraphTemplate003A"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref";

const A003chapterResponses = (props) => {
  return (<>
   <Helmet>
     <title>Chapter Responses 003A</title>
     <meta name="Chapter Responses 003A" content="Chapter Responses 003A" />
   </Helmet>
         <AdminNavbar/>
         <Jumbotron>
             <Container fluid={true} style={{paddingBottom: "30px"}}>
                 <Row>
                     <Col>
                         <h1>Chapter Evaluation Responses, Cohort 003A</h1>
 
                     </Col>
                 </Row>
             </Container>
         </Jumbotron>
         <AnotherReportsListSidebar003A/>
         <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#comments">•skip to comments</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#plots">•skip to plots</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "10px",paddingBottom: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "0px",paddingTop: "0px"}} >Score Summary</div>
              <CmReportTable003Ax2 bgColor="#F55C48" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_summary003A"} />
            </Col>
          </Row>
          </Container>
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "0px",paddingTop: "0px"}} >Score Counts</div>
              <CmReportTable003Ax2 bgColor="#17BECF" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_counts003A"} />
            </Col>
          </Row>
          </Container>
     


          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop: "10px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
        <Row>
            <Col>
               <HashLink id="plots" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
               <br/>
               <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#comments">skip to comments</HashLink>

            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "0px",paddingTop: "0px"}} >Chapter Relevance Scores</div>
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"chapterResponses003A_relevant"} />
            </Col>
          </Row>
          </Container>
    

          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "0px",paddingTop: "25px"}} >Chapter Worth Scores</div>
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"chapterResponses003A_worthwhile"} />
            </Col>
          </Row>
          </Container>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop: "40px"}}>

<Container fluid={true} style={{ paddingBottom: "10px", paddingTop: "0px" }}>
        <Row>
            <Col>

                <HashLink id="comments" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
            </Container>
           
          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "0px", paddingRight: "0px",paddingTop: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "20px",paddingBottom: "0px",paddingTop: "0px"}} >Comments</div>
              <CmReportTable003Ax2 bgColor="#005276" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_comments003A"} />
            </Col>
          </Row>
          </Container>
 </div>
       
 
 
 

    


</>);

};

export default A003chapterResponses;




