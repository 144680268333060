import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
import GraphTemplateIndivs004BST from "./GraphTemplateIndivs004BST";

import {Col, Container, Jumbotron, Row} from "react-bootstrap";

import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

const Graphs004BST = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 ST Cohort 2</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar004B/>
      <Container>
        <div className="mt-4">
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s10_010"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s10_014"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s11_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s12_021"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s13_011"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s14_010"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s14_014"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s15_001"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s15_008"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s15_010"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s15_014"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s16_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s16_006"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004BST report={"s16_011"} />
            </Col>
          </Row>










        </div>
      </Container>


    </>
  );
};

export default Graphs004BST;
