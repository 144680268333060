import React, { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
import AdminNavbar from "../components/AdminNavbar";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import PokeTable from "./PokeListTable";
import PROkeyTable from "../reports/PROkeyTable";
import PROkeyTableXS from "../reports/PROkeyTableXS";
import "../reports/surveyAdherence.css";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import PokeList004 from "./PokeList004";
import PokeList002 from "./PokeList002";
import PokeList003 from "./PokeList003";
import PokeList005 from "./PokeList005";
import ScrollUpButton from "react-scroll-up-button";
import {Link} from "react-router-dom";

const PokeList = () => {

  return (
    <>
      <Helmet>
        <title>Poke List</title>
        <meta name="Poke List" content="Poke List" />
      </Helmet>
      <AdminNavbar />
      <Jumbotron>
      <ScrollUpButton />

        <h1 className="pokelist-header">Poke List</h1>


        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "20px", paddingTop: "0px", lineHeight: "1" }}>
 {/* <div className="article" style={{paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px"}}> */}
           <Row>
               <Col>
               {/* <HashLink id="top" className="TitleDB" style={{  fontSize: "14px" }} to="#NotificationChecks">• jump to Notification Check</HashLink> */}
            <br/>
 
        <HashLink id="top" className="TitleDB" style={{  fontSize: "14px" }} to="#003">• jump to 003</HashLink>

       
  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "0px", paddingRight: "30px", maxWidth:"20%"  }}></div>
                   <div className="TitleDB" style={{  fontSize: "14px" }}><Link style={{color: "#000000"}}  to="/ORX/pokeListSMA" target="_blank">• Southwest Medical Poke List</Link> </div>
<br/>
<div className="TitleDB" style={{  fontSize: "14px" }}><Link style={{color: "#000000"}}  to="/ORX/pokeListUPMC" target="_blank">• UPMC Poke List</Link> </div>

               </Col>
           </Row>
       {/* </div> */}
       </Container>





      </Jumbotron>
                  {/* Table for 005 user */}

      <Container fluid={true} style={{ backgroundColor: "white", marginBottom: "3rem" }}>
        <Col xs={8}>
            <div style={{paddingBottom: "40px"}}></div>
            {/* <PokeList005 />
            <div style={{paddingBottom: "40px"}}></div> */}
            {/* <Container fluid={true} style={{ paddingBottom: "5px",lineHeight: "1.05" }}>
        <Row>
          <Col>

            <HashLink id="003" className="TitleDB" style={{  fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            <br/>
            <HashLink id="003" className="TitleDB" style={{  fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to notification check</HashLink>

          
 

          </Col>
        </Row>
      </Container> */}
               {/* Table for 003 users */}
     

          <PokeList003 />
          <div style={{paddingBottom: "40px"}}></div>
          {/* Table for 004 users */}
         
          {/* <PokeList004 /> */}
          {/* <div style={{paddingBottom: "20px"}}></div> */}
          {/* Table for 002 users */}
          {/* <PokeList002 /> */}
       
        
        </Col>
      </Container>

      <Container fluid={true} style={{ paddingBottom: "20px" }}>
        <Row>
          <Col>

            <HashLink id="jump" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "14px", paddingLeft: "15px" }} to="#top">jump to top</HashLink>
          </Col>
        </Row>
      </Container>

      {/* <Container fluid={true} style={{ backgroundColor: "white", width: "100%" }}>
        <div style={{ paddingBottom: "40px" }}>

          <div className="TitleDB" id="NotificationChecks" style={{ fontSize: "34px", paddingBottom: "18px", paddingLeft: "10px" }}>Notification Checks</div>
          <h4 className="pokelist-header" style={{ paddingBottom: "8px", paddingLeft: "10px" }}>Please check that these participants can receive notifications</h4>

          <PROkeyTableXS style={{ fontSize: "14px" }} tableKey={"check_notifications004Bext"}></PROkeyTableXS>
          <h4 className="pokelist-header" style={{ paddingLeft: "10px" }}>003 Cohort A</h4>

<PROkeyTableXS style={{ fontSize: "14px" }} tableKey={"check_notifications003A"}></PROkeyTableXS>
        </div>
      </Container> */}
     
    
    
    
    </>
  );
};

export { PokeList };
