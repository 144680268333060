import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003by_subjectID = (props) => {
    return (<>
 
 <Helmet>
        <title>Scores_by_Participant 003A</title>
        <meta name="Scores_by_Participant 003A" content="Scores_by_Participant 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Scores by Participant 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

 <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#done">•skip to done or Ext</HashLink>
            <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#all_scores">•skip to all scores</HashLink>

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>

 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "80px"}}>
<div className="TitleC" style={{fontSize: "20px"}}  >Scores by Participant: workflowStatus==therapy</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#17BAFF" tableKey={"by_subjectID_D"}></CmReportTable003Ax>
</div>
</Container>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}> 
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
        <Row>
            <Col>
                <HashLink id="done" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
       <br/>
       <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#all_scores">jump to all_scores</HashLink>
      
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "80px"}}>
<div className="TitleC" style={{fontSize: "20px"}}  >Scores by Participant: done or in Ext</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#F55C48" tableKey={"by_subjectID_E"}></CmReportTable003Ax>
</div>
</Container>


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}> 
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
        <Row>
            <Col>
                <HashLink id="all_scores" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
       <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#done">jump to done</HashLink>
      
             
            </Col>
            </Row>
            </div>
            </Container>


<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "80px"}}>
<div className="TitleC" style={{fontSize: "20px"}}  >Scores by Participant</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#DE784E" tableKey={"by_subjectID003A"}></CmReportTable003Ax>
</div>
</Container>

      
</>);

};

export default A003by_subjectID;




