import React, {useEffect, useState} from "react";
import Plot from "react-plotly.js";
import {firestore} from "../../utils/firebase";

const GraphTemplate003A = (props) => {
  const [lines, setLines] = useState([]);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState("");

  let { report } = props;

  const getLines = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const query = await firestore
      .collection("analytics")
      .doc("reports")
      .collection("reportGraphs003");

    const lineQuery = await query.doc(`${report}`).collection("lines").get();

   

    const titleQuery = await query.doc(`${report}`).get();
    const layoutQuery = await query.doc(`${report}`).get();

    const layoutdata = layoutQuery.data();
    const layout = layoutdata.layout  ? layoutdata.layout : "";
    setLayout(layoutdata.layout);


    const data = titleQuery.data();
    const title = data.title  ? data.title : "";
    setTitle(data.title);

    const lineArray = [];
    lineQuery.docs.map((r) => {
      lineArray.push(r.data());
    });
    setLines(lineArray);
  };

  useEffect(() => {
    getLines();
  }, [lines]);

  
  let graph = (
    <Plot
      scrollZoom={true}
      data={lines}
      className="col"
      useResizeHandler={true}
      layout={layout}
    
    />
  );

  return graph;
};

export default GraphTemplate003A;
