import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A22F from "./CmReportTable005A22F";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const A005Dash = (props) => {

    const [data_retrieved, setData_retrieved] = useState([]);
    const [screeningWeek_opens_updated, setScreeningWeek_opens_updated] = useState([]);
    const [screened, setScreened] = useState([]);
    const [screening, setScreening] = useState([]);
    const [enrolled, setEnrolled] = useState([]);
    const [screenfailed, setScreenfailed] = useState([]);
    const [terminatedearly, setTerminatedearly] = useState([]);
    const [ACT, setACT] = useState([]);
    const [ST, setST] = useState([]);
    const [ACT_finished, setACT_finished] = useState([]);
    const [ST_finished, setST_finished] = useState([]);
    const [ACT_early_term, setACT_early_term] = useState([]);
    const [ST_early_term, setST_early_term] = useState([]);
    
    const [ACT_extension, setACT_extension] = useState([]);
    const [ACT_no_ext, setACT_no_ext] = useState([]);
    const [ACT_not_qual_ext, setACT_not_qual_ext] = useState([]);
    const [ACT_decl_ext, setACT_decl_ext] = useState([]);

    

    const getStatusesData = async (status,setter) => { 
        const statuses = firestore.collection("analytics").doc("A005").collection("data005").doc("statuses005A");
        const doc = await statuses.get();
        const docData=doc.data()
        const statusCounts = docData[status];
        console.log(statusCounts)
        setter(statusCounts);
   }

  const screeningWeek_opens_updateD = async (setter) => { 
    const sco_doc = firestore.collection("analytics").doc("A005").collection("screeningWeek_opens_updated").doc("screeningWeek_opens_updated");
    const doc = await sco_doc.get();
    const docData=doc.data()
    const screeningWeek_opens_updated = docData['screeningWeek_opens_updated'];
    console.log(screeningWeek_opens_updated)
    setter(screeningWeek_opens_updated);
  } 
 

  useEffect(() => {
    getStatusesData('data_retrieved', setData_retrieved)
    getStatusesData('screened',setScreened)
    getStatusesData('screening',setScreening)
    getStatusesData('enrolled',setEnrolled)
    getStatusesData('terminatedearly',setTerminatedearly)
    getStatusesData('screenfailed',setScreenfailed)
    getStatusesData('ACT',setACT)
    getStatusesData('ST',setST)
    getStatusesData('ACT_finished',setACT_finished)
    getStatusesData('ST_finished',setST_finished)
    getStatusesData('ACT_early_term',setACT_early_term)
    getStatusesData('ST_early_term',setST_early_term)
    screeningWeek_opens_updateD(setScreeningWeek_opens_updated)
    getStatusesData('ACT_extension',setACT_extension)
    getStatusesData('ACT_no_ext',setACT_no_ext)
    getStatusesData('ACT_not_qual_ext',setACT_not_qual_ext)
    getStatusesData('ACT_decl_ext',setACT_decl_ext)

}, [props]);


 return (<>
 <Helmet>
        <title>Summary 005A</title>
        <meta name="Summary 005A" content="Status Summary 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Summary 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#visitsEar">•visit counts</HashLink>
            <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#finScreeningWeek">•finished screening week</HashLink>


  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>
<Container fluid={true}>

<div className="article" style={{paddingTop: "20px", paddingBottom: "60px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "45px"}}>Consort <span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {data_retrieved}</span></div>




<table style={{width: "900px"}} >

                <tr >
                <td colSpan={4} style={{width: "680px", height:"25px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "35px",textAlign: "center"}} >screened: {screened}</td>
                <td colSpan={1} style={{ width: "200px", paddingLeft: "10px",height:"25px", backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "25px",textAlign: "center"}} >screening: {screening}</td>

                </tr> 
                <tr style={{height: "20px"}}></tr>
              

                <tr >
               
                <td colSpan={2}  style={{width: "340px",height:"25px", backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >screenfailed: {screenfailed}</td>


                <td colSpan={2}  style={{width: "340px",height:"25px", backgroundColor:"#0F8793",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >enrolled: {enrolled}</td>

                <td colSpan={1}  style={{width: "200px",backgroundColor:"#FFFFFF"}}></td>
             
                             </tr> 
                             
                <tr style={{height: "20px"}}></tr>
                <tr >
               
                <td colSpan={2}  style={{width: "340px",height:"25px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >screenfailed: {screenfailed}</td>


                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#4491F6",color: "#FFFFFF",fontSize: "18px",textAlign: "center"}} >ACT: {ACT}</td>
                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#FF4D00",color: "#FFFFFF",fontSize: "18px",textAlign: "center"}} >ST: {ST}</td>

                <td colSpan={1}  style={{width: "200px",backgroundColor:"#FFFFFF"}}></td>
             
                             </tr> 
                             <tr style={{height: "20px"}}></tr>
                <tr >
               
                <td colSpan={1}  style={{width: "100px",height:"25px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "15px",textAlign: "center"}} >________________</td>

                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "15px",textAlign: "center"}} >ACT_early_term: {ACT_early_term}</td>

                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#0034C0",color: "#FFFFFF",fontSize: "15px",textAlign: "center"}} >ACT_finished: {ACT_finished}</td>
                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "15px",textAlign: "center"}} >ST_finished: {ST_finished}</td>
                <td colSpan={1}  style={{width: "170px",height:"25px", backgroundColor:"#B00000",color: "#FFFFFF",fontSize: "15px",textAlign: "center"}} >ST_early_term: {ST_early_term}</td>

                <td colSpan={1}  style={{width: "100px",backgroundColor:"#FFFFFF"}}></td>
             
                             </tr> 
                             
            <tr style={{height: "5px"}}></tr>
               
            <tr >
               <td colSpan={1}  style={{width: "100px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
                    <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#172F8D",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >ACT_extension: {ACT_extension}</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "100px",backgroundColor:"#FFFFFF"}}></td>
            </tr> 

            <tr >
               <td colSpan={1}  style={{width: "100px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
                    <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#393F92",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >ACT_declined_extension: {ACT_decl_ext}</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "100px",backgroundColor:"#FFFFFF"}}></td>
            </tr> 
            <tr >
               <td colSpan={1}  style={{width: "100px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
                    <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#596990",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >ACT_not_qualified_ext: {ACT_not_qual_ext}</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "170px",height:"20px", backgroundColor:"#FFFFFF",color: "#FFFFFF",fontSize: "12px",textAlign: "center"}} >________________</td>
               <td colSpan={1}  style={{width: "100px",backgroundColor:"#FFFFFF"}}></td>
            </tr>


               

                    </table> 

</div>
</Container>


{/* <Container fluid={true}>
<Row>
                    <Col>



                    <div className="article">
<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>🚨 Needs to Download<span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {screeningWeek_opens_updated}</span></div>
</div>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "500px"}}> 
<CmReportTable005A2 style={{padding: "0px"}} thPaddingRight="5px" thPaddingLeft="6px" thFontSize="14px" tdFontSize="14px" bgColor="#DF270D" tableKey={"needs_activeProgramA"} />

</div>
</Col>
                </Row>
</Container>
<Container fluid={true} >
<Row style={{paddingBottom: "20px"}}>
                    <Col>
                   
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px", width: "auto", lineHeight: "1"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px"}}>App Opens <span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {screeningWeek_opens_updated}</span></div>
<CmReportTable005A22F  thPaddingLeft="6px" thPaddingRight="0px" thFontSize="13px" tdFontSize="12px" bgColor="#2475C7" tableKey={"screeningWeek_opensA"} />
</div>

                    </Col>
                </Row>
</Container> */}










{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="visitsEar" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                        <br/>
                        <HashLink id="visitsEar" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#finScreeningWeek">jump to finished screening week</HashLink>                
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 



<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Initial Visit Counts</div>

<GraphTemplate005A style={{padding: "0px"}} report={"startVisits005A"} />

</div>
</Container> */}


{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "25px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingTop: "45px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="finScreeningWeek" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                        <br/>
                        <HashLink id="finScreeningWeek" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#visitsEar">jump to visit counts</HashLink>                
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true} >
<Row style={{paddingBottom: "20px"}}>
                    <Col>
                   
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px", width: "auto", lineHeight: "1"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px"}}>Finished Screening Week App Opens</div>
<CmReportTable005A22F style={{padding: "0px"}} thFontSize="14px" tdFontSize="14px" thPaddingRight="2px" bgColor="#FF6E2C" tableKey={"finScreeningWeekA"} />
</div>

                    </Col>
                </Row>
</Container> */}

</>);

};

export default A005Dash;


{/* <Container fluid={true}>
<Row >
                    <Col >
                   

<div className="article" style={{paddingTop: "10px", paddingBottom: "30px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>App Opens <span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {screeningWeek_opens_updated}</span></div>
<CmReportTable005A22F  style={{padding: "0px" }}  thPaddingRight="5px" thPaddingLeft="6px" thFontSize="14px" tdFontSize="14px" bgColor="#2475C7" tableKey={"screeningWeek_opensA"} />
</div>

                    </Col>
                </Row>
</Container> */}