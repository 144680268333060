
import React,{ useState, useEffect }  from "react";
import {firestore} from "../utils/firebase";

import "./surveyAdherence.css";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import PROkeyTableXSBB from "./PROkeyTableXSBB";
import PROkeyTableXSBBwh7 from "./PROkeyTableXSBBwh7";


const whoqA6explanation = (props) => {
return(<>

            <PROkeyTableXSBBwh7  style={{paddingBottom:"0px",paddingTop: "20px", paddingRight: "0px", paddingLeft: "0px",fontSize: "12px"}} tableKey={"whoqA6_question_key"} />

    </>);

};
export default whoqA6explanation;

