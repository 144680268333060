
import "../surveyAdherence.css";

import {firestore} from "../../utils/firebase";
import React,{ useState, useEffect } from "react";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax2smaller from "./CmReportTable003Ax2smaller";
// import CmReportTable004BextST from "./CmReportTable004BextST"; 
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003AxL2 from "./CmReportTable003AxL2";
import CmReportTable003AxL3 from "./CmReportTable003AxL3";
// import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003EXTDash = (props) => {
    const [NN, setNN] = useState([]);
    const [no_key_epros, setNo_key_epros] = useState([]);
    const [done_participants, setDone_participants] = useState([]);
    const [loading, setLoading] = useState(false);



    const getNoExtData = async (docName, setter) => {
        const query = await firestore.collection("analytics").doc("PROs003A").collection("noExt_PROsummsNs_003A").doc(docName).get();
        const data = query.data();
        setter(data[docName]);
        console.log(data[docName])
        return data[docName];
        }

    useEffect(() => {
        getNoExtData('n', setNN);
        getNoExtData('no_key_epros', setNo_key_epros);
        getNoExtData('done_participants', setDone_participants);
    }, [props]);

 return (<>
 <Helmet>
        <title>EXT Summary 003A</title>
        <meta name="EXT Summary 003A" content="EXT Status Summary 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>EXT Summary 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indivEXT">•skip to EXT results, by participant</HashLink>
               <br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#NoEXT">•skip to no-EXT results</HashLink>

<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indivNoEXT">•skip to no-EXT results, by participant</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>       {/* , paddingLeft: "0px", paddingRight: "0px" */}
            <Row>
                <Col>
            <div className="TitleC" style={{paddingBottom: "0px"}}>Current Workflow Status Counts</div>
            


        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"ext_statuses_003A"} />
        </Col>
       
            </Row>
        </div>
        </Container>
        {/* <td style={{paddingLeft:"40px",width: "500px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "11px",textAlign: "center"}} key={no_key_epros}>{no_key_epros}</td> */}

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

{/* CmReportTable003AxL2 */}
            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >EXT PRO Result Summary, means</div>
            <CmReportTable003AxL3 bgColor="#17BAFF" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"preExt_PROsumms_003AEXT"}></CmReportTable003AxL3>

            {/* <CmReportTable003Ax bgColor="#17BAFF" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"preExt_PROsumms_003AEXT"}></CmReportTable003Ax> */}
          </div>
        </Container>
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="NoEXT" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
             <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indivEXT">skip to EXT results, by participant</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indivNoEXT">skip to no-EXT results, by participant</HashLink>

            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>



            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >no-EXT, PRO Result Summary, means</div>
            <div className="TitleC" style={{fontSize: "15px",paddingLeft: "10px",paddingBottom: "1px"}} >
            <span  key={done_participants}><strong>{done_participants}</strong> participants are done and not currently in the extension</span><br/>
            <span key={no_key_epros}><strong>{no_key_epros}</strong> of these participants did not complete their week12 epros</span><br/>
            <span  key={NN}><strong>n = {NN}</strong> participants' epro data ➔</span>
            </div>

            {/* <CmReportTable003Ax bgColor="#73ACE6" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"noExt_PROsummsNs2_003A"}></CmReportTable003Ax> */}

            <CmReportTable003Ax bgColor="#0092D0" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"noExt_PROsumms_003A"}></CmReportTable003Ax>
          </div>
        </Container>
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="indivEXT" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indivNoEXT">skip to no-EXT results, by participant</HashLink>

             {/* <br/> */}
                {/* <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#swing_internal">jump to swing_internal</HashLink> */}
            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >EXT PRO Results, by Participant</div>
            <CmReportTable003AxL2 bgColor="#C49CE0" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"preExt_PROs_003AEXT"}></CmReportTable003AxL2>
            {/* <CmReportTable003Ax bgColor="#C49CE0" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"preExt_PROs_003AEXT"}></CmReportTable003Ax> */}

          </div>
        </Container>
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="indivNoEXT" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
             <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indivEXT">jump to pre-EXT results, by participant</HashLink>
            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >no-EXT PRO Results, by Participant</div>
            <CmReportTable003Ax bgColor="#E0CDEF" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"noExt_PROs_003A"}></CmReportTable003Ax>
          </div>
        </Container>
        {/* preExt_PROsumms_003AEXT */}
</>);

};

export default A003EXTDash;
