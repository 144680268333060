import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A22F from "./CmReportTable005A22F";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A22 from "./CmReportTable005A22";

import CmReportTable005A4 from "./CmReportTable005A4";
import CmReportTable005A4f from  "./CmReportTable005A4f";
import CmReportTable005A2Ind from "./CmReportTable005A2Ind";
import CmReportTable005A3Ind from "./CmReportTable005A3Ind";

import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A2S from "./CmReportTable005A2S";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";

const A005ChapterEvals = (props) => {




 return (<>
 <Helmet>
        <title>Chapter Evals 005A</title>
        <meta name="Chapter Evals 005A" content="Chapter Evals 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Chapter Evaluation Responses 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#plots">•plots</HashLink>
           
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#comments">•comments</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#by_site">•site means</HashLink>


                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>




<Container fluid={true}>
<Col>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", width: "auto"}}> 
{/* <Row> */}


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Score Summary, Relevance</div>

<div style={{width: "350px"}}>
<Row>
<CmReportTable005A2 style={{padding: "2px"}} thPaddingRight="5px" thPaddingLeft="6px" thFontSize="16px" tdFontSize="15px" bgColor="#FF6E2C" tableKey={"chapter_eval_summary005A_relevance"} />
</Row>
</div>

{/* </Row> */}
</div>
</Col>
</Container>

<Container fluid={true}>
    <Col>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Score Summary, Worthwhile</div>
<div style={{width: "350px"}}>
<Row>
<CmReportTable005A2 style={{padding: "0px"}} thPaddingRight="5px" thPaddingLeft="6px" thFontSize="16px" tdFontSize="15px" bgColor="#0092FE" tableKey={"chapter_eval_summary005A_worthwhile"} />
</Row>
</div>


</div>
</Col>
</Container>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "25px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingTop: "25px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="plots" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                   
            </Col>
            </Row>
            </div>
            </Container>
       <Container fluid={true} style={{ paddingBottom: "20px", paddingTop: "45px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", width: "auto"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Chapter Relevance Scores</div>

<GraphTemplate005A style={{padding: "0px"}} report={"chapterResponses005A_relevant"} />

</div>
</Container>

<Container fluid={true} style={{ paddingBottom: "20px", paddingTop: "45px" }}>
<div className="article" style={{paddingTop: "30px", paddingBottom: "20px", width: "auto"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Chapter Worthwhile Scores</div>

<GraphTemplate005A style={{padding: "0px"}} report={"chapterResponses005A_worthwhile"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingBottom: "0px", paddingTop: "25px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="comments" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                   
            </Col>
            </Row>
            </div>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px"}}> 
<Row>


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Comments</div>

<CmReportTable005A4f  thPaddingRight="7px" thPaddingLeft="6px" thFontSize="16px" tdFontSize="15px" bgColor="#662D91" tableKey={"chapter_eval_comments005A"} />


</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingBottom: "0px", paddingTop: "25px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="by_site" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                   
            </Col>
            </Row>
            </div>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", maxWidth: "400px"}}> 
<Row>


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Mean Score by Site</div>

<CmReportTable005A22  style={{maxWidth: "400px"}}  thPaddingRight="8px" thPaddingLeft="8px" thFontSize="16px" tdFontSize="15px" bgColor="#00618A" tableKey={"chapter_eval_bySite005A"} />


</Row>
</div>
</Container>

</>);

};

export default A005ChapterEvals;

