import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import BReportTable004A from "./BReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

const A004Visits = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 Participants</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar style={{ paddingRight: "50px"}}/>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "50px"}}>
            <div className="article">
            <div className="TitleC">Visits</div>
            <BReportTable004A tableKey={"visits004"}></BReportTable004A>
                </div>
        </Container>


</>);

};

export default A004Visits;

