import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import BReportTable from "./BReportTable";
import CmReportTable004B from "./CmReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004Pros = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
        <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Participants Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B />
        
       


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px" }}>
            <div className="TitleC"  >PROs by Visit</div>
            {/* style={{ paddingLeft: "10px"}}  */}
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pros004B"}></CmReportTable004B>
                </div>
        </Container>
       
</>);

};

export default B004Pros;

{/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "50px", paddingRight: "10px"}}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
<div className="TitleC">PROs by Therapy Week
</div>
<div className="TitleDB" style={{fontSize: "13px",paddingBottom: "15px",paddingTop: "5px"}} > weeks since app start, where week 0 averages the two scores preceding app use.</div>

<CmReportTable004B  tableKey={"otherPros004B"}></CmReportTable004B>
    </div>
</Container> */}