import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import BReportTable004A from "./BReportTable004A";
import BReportTable004ACT from "./BReportTable004ACT";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const A004FIQRbyAppProgress = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores, by App Progress</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>by completed sessions/ entries; app-content weeks</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>

            <div className="sidenav">
            <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/sites">
                Sites</Link></p>
               <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/pros">
                PROs</Link></p>

            </div>
             </Container> */}
        <Container fluid={true} style={{backgroundColor: "white",paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Mean FIQR Scores
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} >*excludes s10_008</span>
            </div>

            <BReportTable004A  tableKey={"fiqrAP_004A_means"}></BReportTable004A>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">ACT FIQR Scores<span className="TitleDB" style={{fontSize: "15px"}} >, by count of completed sessions</span></div>
            <BReportTable004ACT tableKey={"fiqrAP_004A_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">ST FIQR Scores<span className="TitleDB" style={{fontSize: "15px"}}>, by count of completed entries</span></div>
            <BReportTable004A tableKey={"fiqrAP_004A_ST"}></BReportTable004A>
                </div>
        </Container>


</>);

};

export default A004FIQRbyAppProgress;

