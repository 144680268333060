import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

// import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableSMW1sticky from "./CmReportTableSMW1sticky";
import CmReportTableUPMC1sticky from "./CmReportTableUPMC1sticky";
import CmReportTableVS1stickyHRfmlSMW from "./CmReportTableVS1stickyHRfmlSMW";
import CmReportTableVS1stickyHRfmlUPMC from "./CmReportTableVS1stickyHRfmlUPMC";
import CmReportTableVS1stickyHRfmlreportTablespUPMC_SM  from "./CmReportTableVS1stickyHRfmlreportTablespUPMC_SM";
import ReportsListSidebarORX from "./ReportsListSidebarORX";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const NicPage = (props) => {
    const [titleC1, setTitleC1] = useState([]);
    const [updatedP, setUpdatedP] = useState([]);
    const [stanzaRX, setStanzaRX] = useState([]);

    const [titleCU, setTitleCU] = useState([]);
    const [updatedP_upmc, setUpdatedP_upmc] = useState([]);
    const [stanzaRX_upmc, setStanzaRX_upmc] = useState([]);
  

    const getTitleBCs = async (titleC,dC,setter) => { 
        const dashes = firestore.collection("analytics").doc(dC).collection("pages").doc("dash");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleBCs('titleC1',"swA",setTitleC1)
        getTitleBCs('updatedP',"swA",setUpdatedP)
        getTitleBCs('stanzaRX',"swA",setStanzaRX)
        getTitleBCs('titleCU',"upmcA",setTitleCU)
        getTitleBCs('updatedP',"upmcA",setUpdatedP_upmc)
        getTitleBCs('stanzaRX',"upmcA",setStanzaRX_upmc)

    }, [props]);
return (<>
 <Helmet>
        <title>Nic's Pilot Dash</title>
        <meta name="Nic's Pilot Dash" content="Nic's Pilot Dash" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Prescribing Clinics</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarORX/> 
        


<Container fluid={true} style={{ paddingBottom: "50px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{lineHeight: "1", paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px"}}>
           <Row style={{lineHeight: "1" }}>
               <Col>
               <HashLink id="top" className="TitleDB" style={{lineHeight: "1", paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#yellow">•Priority Patients</HashLink> 
   <br/>
   <HashLink id="top" className="TitleDB" style={{lineHeight: "1", paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#neverloggedin">•Never Logged-In Patients</HashLink> 
   <br/>
   <HashLink id="top" className="TitleDB" style={{lineHeight: "1", paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#stanza">•Stanza Patients</HashLink> 
   <br/>
   <HashLink id="top" className="TitleDB" style={{lineHeight: "1", paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nostanza">•Patients Not in Stanza</HashLink> 
   <br/>
{/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#SWM">•Southwest Medical</HashLink> */}
{/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#UPMC">•UPMC</HashLink> */}

                   <div style={{ borderTop: "1px solid #941751",paddingTop: "40px", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>


                   <Container fluid={true}  >
                   <div className="article" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>Pilot Patients<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updatedP}</span></div>
</div>
                   </Container>



                
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="yellow" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Priority Patients</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "0px",fontSize: "22px"}}>{titleC1}</div>

</div>
</Container>
       
 <Container fluid={true}>

<div className="article" style={{paddingTop: "8px", paddingBottom: "0px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "2px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlSMW  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FDCF16" tableKey={"nic1AY"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>

<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "5px",fontSize: "22px"}}>{titleCU}</div>

</div>
</Container>

<Container fluid={true}>

<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "10px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlUPMC  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FDCF16" tableKey={"nic1AY"} />
</Col>
</Row>
</div>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="neverloggedin" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Never Logged-In Patients</HashLink>
</Col>
</Row>
{/* <div className="TitleC" style={{paddingTop: "12px",paddingBottom: "0px",fontSize: "22px"}}>{titleC1}</div> */}

</div>
</Container>
       
<Container fluid={true}>

<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "10px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlreportTablespUPMC_SM  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF6E16" tableKey={"nic1Astrays"} />
</Col>
</Row>
</div>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="stanza" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Stanza Patients</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "0px",fontSize: "22px"}}>{titleC1}</div>

</div>
</Container>
       
 <Container fluid={true}>

<div className="article" style={{paddingTop: "8px", paddingBottom: "0px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "2px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlSMW  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"nic1A"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>

<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "5px",fontSize: "22px"}}>{titleCU}</div>

</div>
</Container>

<Container fluid={true}>

<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "10px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlUPMC  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"nic1A"} />
</Col>
</Row>
</div>
</div>
</Container>







<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nostanza" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Not-in-Stanza Patients</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "0px",fontSize: "22px"}}>{titleC1}</div>

</div>
</Container>
       
 <Container fluid={true}>

<div className="article" style={{paddingTop: "8px", paddingBottom: "0px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "2px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlSMW  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#5AA2FF" tableKey={"nic1AW"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>

<div className="TitleC" style={{paddingTop: "12px",paddingBottom: "5px",fontSize: "22px"}}>{titleCU}</div>

</div>
</Container>

<Container fluid={true}>

<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "0px"}}>
<Row > 
<Col>

</Col>
</Row>
</div>
<div style={{paddingBottom: "10px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%",maxHeight: "250px"}}>
    <Col>
<CmReportTableVS1stickyHRfmlUPMC  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#5AA2FF" tableKey={"nic1AW"} />
</Col>
</Row>
</div>
</div>
</Container>



</>);

};

export default NicPage;