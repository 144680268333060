import React from "react";
import "./surveyAdherence.css";
import AdminNavbar from "../components/AdminNavbar";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import PROkeyTable from "./PROkeyTable";
import PROkeyTableXL from "./PROkeyTableXL";

import PROkeyTableXLB from "./PROkeyTableXLB";
import {Helmet} from "react-helmet";

const Insightskey = (props) => {
  return (
    <>
     <Helmet>
		<title>Insights & ST key</title>
		<meta name="Insights & ST key" content="Insights & ST key" />
	</Helmet>

      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Insights & Symptom Tracker Reference</h1>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container fluid={true} style={{ backgroundColor: "white",width:"100%" }}>
        <div  style={{paddingBottom: "40px"}}>
          <div className="TitleC"  style={{paddingBottom: "8px" }}>004 Cohort 2 questions</div>
          <PROkeyTableXLB style={{fontSize: "14px"}}  tableKey={"insights_tracker_key_asOf_004B"}></PROkeyTableXLB>
        </div>
      </Container>
  


    </>
  );
};

export default Insightskey;
