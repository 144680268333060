import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003AxSmaller from "./CmReportTable003AxSmaller";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";
import CmReportTable003Ax2sp from "./CmReportTable003Ax2sp";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003inProgress = (props) => {
    return (<>
 
 <Helmet>
        <title>In Progress 003A</title>
        <meta name="In Progress 003A" content="In Progress 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Open Screens & Baselines 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 


        
 

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "45px", paddingRight: "0px",paddingBottom: "40px"}}>
            <div className="article" style={{ paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC">In Progress Screenings</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#17BAFF" tableKey={"mid_screens003A"}></CmReportTable003Ax>



</div>
        </Container>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "45px", paddingRight: "0px",paddingBottom: "40px"}}>
        <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC" style={{paddingBottom: "5px",lineHeight: "1"}} >In Progress Baselines</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#9E005D" tableKey={"mid_base003A"}></CmReportTable003Ax>
</div>
        </Container>
      
      
  
    
     
   
         
</>);

};

export default A003inProgress;




