import "../surveyAdherence.css";

import {Col, Container, Jumbotron, Row,Table} from "react-bootstrap";


const ApplicantGroups003A  = (props) => {
  return (
    <>
    {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "40px",paddingTop: "0px"}}> */}
        {/* <div style={{paddingTop: "0px",paddingBottom: "10px", paddingLeft: "40px", paddingRight: "30px"}}> */}
        {/* <Row  style={{paddingBottom:"8px"}}> */}
        
        <Col>
        
        <table style={{padding: "0px"}}>
<tr>
<th style={{backgroundColor: "#000000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px"}}>applicant_group</th>
<th style={{backgroundColor: "#000000", color: "#FFFFFF", paddingRight:"5px",paddingLeft: "4px"}}>criteria</th>

</tr>

<tr>
<td style={{backgroundColor: "#17BAFF", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px"}}>groupA</td>
<td style={{backgroundColor: "#17BAFF", color: "#FFFFFF",paddingLeft: "4px",paddingRight: "4px", fontSize: "13px"}}>scrn01</td>
</tr>
<tr>
<td style={{backgroundColor: "#0092D0", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px"}}>groupB</td>
<td style={{backgroundColor: "#0092D0", color: "#FFFFFF",paddingLeft: "4px",paddingRight: "4px", fontSize: "13px"}}>scrn02 & expanded_criteria_only==False</td>
</tr>
<tr>
<td style={{backgroundColor: "#00618A", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px"}}>groupC</td>
<td style={{backgroundColor: "#00618A", color: "#FFFFFF",paddingLeft: "4px",paddingRight: "4px", fontSize: "13px"}}>reapplied/scrn02/scrn03 & expanded_criteria_only==True</td>
</tr>
<tr>
<td style={{backgroundColor: "#73ACE6", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px"}}>groupE</td>
<td style={{backgroundColor: "#73ACE6", color: "#FFFFFF",paddingLeft: "4px",paddingRight: "4px", fontSize: "13px"}}>scrn03 & expanded_criteria_only==False</td>
</tr>
<tr>
<td colSpan={2} style={{backgroundColor: "#636161", color: "#FFFFFF", paddingRight:"5px",paddingLeft: "4px",paddingTop: "2px",paddingBottom: "2px",  fontSize: "13px"}}>expanded_criteria_only: has ≥1 previously disqualifying comoribidities</td>
</tr>

</table>
</Col>
{/* </Row> */}

{/* // </div> */}
{/* </Container> */}
</>
);
};
export default ApplicantGroups003A;
