import React from "react";
import "../surveyAdherence.css";
// import { usePageName } from 'react-page-name';
    
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import { Link } from "react-router-dom";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import { HashLink } from "react-router-hash-link";

 
import {Helmet} from "react-helmet";





const Bext004Pros = (props) => {
    // usePageName('PROs-Visit 004-2X');
 return (<>
 <Helmet>
		<title>PROs-Visit 004-2X</title>
		<meta name="PROs-Visit 004-2X" content="PROs-Visit 004-2X" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
        <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other PRO Scores, Cohort 2 Extension</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext />
        
        {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "175px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container> */}

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px" }}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC"  >PROs by Visit, ACT</div>
            <CmReportTable004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pros004BextACT"}></CmReportTable004Bext>
                </div>
        </Container>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px" }}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="ST" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC"  >PROs by Visit, ST</div>
            <CmReportTable004BextST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pros004BextST"}></CmReportTable004BextST>
                </div>
        </Container>
</>);

};

export default Bext004Pros;

