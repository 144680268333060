import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";
import {auth} from "../firebase";
import SignIn from "../screens/SignIn";
import LoadingScreen from "../screens/LoadingScreen";

const ProtectedRoute = ({component: Component, ...rest}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoadingAuth(false);
    });
  }, []);
  return loadingAuth ? (
  <LoadingScreen></LoadingScreen>
  ) : (
         <Route
         {...rest}
         render={(props) => authenticated ? <Component {...props} /> : <SignIn></SignIn>}
         />
         );
};
export default ProtectedRoute;
