import React, {useEffect, useState} from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import {Col, Container, Jumbotron, Row,Table} from "react-bootstrap";






const EproBase005 = (props) => {
    const {eproKey} = props;
    const [data_retrieved, setData_retrieved] = useState([]);
    const [EproName, setEproName] = useState([]);
    const [arm_data_retrieved, setArm_data_retrieved] = useState([]);
    const [ACTarm, setACTarm] = useState([]);
    const [ACTCount, setACTCount] = useState([]);
    const [ACTMax, setACTMax] = useState([]);
    const [ACTMean, setACTMean] = useState([]);
    const [ACTMin, setACTMin] = useState([]);
    const [ACTp25th, setACTp25th] = useState([]);
    const [ACTp50th, setACTp50th] = useState([]);
	const [ACTp75th, setACTp75th] = useState([]);
	const [STarm, setSTarm] = useState([]);
	const [STCount, setSTCount] = useState([]);
	const [STMax, setSTMax] = useState([]);
	const [STMean, setSTMean] = useState([]);
	const [STMin, setSTMin] = useState([]);
	const [STp25th, setSTp25th] = useState([]);
	const [STp50th, setSTp50th] = useState([]);
	const [STp75th, setSTp75th] = useState([]);
	const [Screenfailedarm, setScreenfailedarm] = useState([]);
	const [ScreenfailedCount, setScreenfailedCount] = useState([]);
	const [ScreenfailedMax, setScreenfailedMax] = useState([]);
	const [ScreenfailedMean, setScreenfailedMean] = useState([]);
	const [ScreenfailedMin, setScreenfailedMin] = useState([]);
	const [Screenfailedp25th, setScreenfailedp25th] = useState([]);
	const [Screenfailedp50th, setScreenfailedp50th] = useState([]);
	const [Screenfailedp75th, setScreenfailedp75th] = useState([]); 

    // const feproName =  firestore.collection("analytics").doc("A005").collection("baselineEprosA").doc(eproKey);
    // const eproDoc = feproName.get();
    // const eproName = eproDoc.data()['name'];
    // const eproName =  firestore.collection("analytics").doc("A005").collection("baselineEprosA").doc(eproKey).get()['name'];
    const FeproName = async (eproKey,setter) => { 
        const res = firestore.collection("analytics").doc("A005").collection("baselineEprosA").doc(eproKey);
        const doc = await res.get();
        const docData=doc.data()
        const resres = docData['name'];
        setter(resres);
        }

    // const res = firestore.collection("analytics").doc("A005").collection("baselineEprosA").doc(eproKey);
    // const doc = await res.get();
    // const docData=doc.data()

    const ArmData = async (eproKey,arm,met,setter) => { 
        const res = firestore.collection("analytics").doc("A005").collection("baselineEprosA").doc(eproKey);
        const doc = await res.get();
        const docData=doc.data()
        const resres = docData[arm][met];
        setter(resres);
        }

    useEffect(() => {
        ArmData(eproKey,'ACT','p50th',setACTp50th)
		ArmData(eproKey,'ACT','Count',setACTCount)
  		ArmData(eproKey,'ACT','Max',setACTMax)
  		ArmData(eproKey,'ACT','Mean',setACTMean)
  		ArmData(eproKey,'ACT','p25th',setACTp25th)
  		ArmData(eproKey,'ACT','Min',setACTMin)
  		ArmData(eproKey,'ACT','p75th',setACTp75th)
		ArmData(eproKey,'ST','Count',setSTCount)
		ArmData(eproKey,'ST','Max',setSTMax)
		ArmData(eproKey,'ST','Mean',setSTMean)
		ArmData(eproKey,'ST','p25th',setSTp25th)
		ArmData(eproKey,'ST','Min',setSTMin)
		ArmData(eproKey,'ST','p75th',setSTp75th)
		ArmData(eproKey,'ST','p50th',setSTp50th)
		ArmData(eproKey,'screenfailed','Count',setScreenfailedCount)
		ArmData(eproKey,'screenfailed','Max',setScreenfailedMax)
		ArmData(eproKey,'screenfailed','Mean',setScreenfailedMean)
		ArmData(eproKey,'screenfailed','p25th',setScreenfailedp25th)
		ArmData(eproKey,'screenfailed','Min',setScreenfailedMin)
		ArmData(eproKey,'screenfailed','p75th',setScreenfailedp75th)
		ArmData(eproKey,'screenfailed','p50th',setScreenfailedp50th)
        FeproName(eproKey,setEproName)
  	}, [eproKey]);

return(<>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "7px",fontSize: "25px"}}>{EproName}</div>
<table  style={{maxWidth:"450px"}}>
<tr >
                <th colSpan={1} style={{  padding: "10px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >metric</th>

                <th colSpan={1} style={{  padding: "10px", backgroundColor:"#0034C0",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >ACT</th>
                <th colSpan={1} style={{  padding: "10px", backgroundColor:"#0F8793",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >ST</th>
                <th colSpan={1} style={{  padding: "10px", backgroundColor:"#B00000",color: "#FFFFFF",fontSize: "20px",textAlign: "center"}} >screenfailed</th>
                </tr> 
                
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >participants</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTCount}</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STCount}</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ScreenfailedCount}</td>
                </tr> 
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >mean</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTMean}</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STMean}</td>
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px",height:"25px", backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ScreenfailedMean}</td>
                </tr> 
                
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >max</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTMax}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STMax}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ScreenfailedMax}</td>
                </tr> 
               
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >min</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTMin}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STMin}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ScreenfailedMin}</td>
                </tr> 
                  
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >25th</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTp25th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STp25th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{Screenfailedp25th}</td>
                </tr>
                
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >50th</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTp50th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STp50th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{Screenfailedp50th}</td>
                </tr>
                
                <tr >
                <td colSpan={1} style={{  paddingLeft: "10px",paddingRight: "5px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >75th</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{ACTp75th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#6694BA",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{STp75th}</td>
                <td colSpan={1} style={{ paddingLeft: "10px",paddingRight: "5px",  backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "18px",textAlign: "right",border: "1px solid white"}} >{Screenfailedp75th}</td>
                </tr>
                       </table> 

    </>);

};
export default EproBase005;