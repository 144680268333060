import React from "react";
import "../surveyAdherence.css";

import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";


import { HashLink } from "react-router-hash-link";
import GraphTemplate003Aref from "./GraphTemplate003Aref";
import CmReportTable003Axref from "./CmReportTable003Axref";
import GraphTemplate003A from "../m003A/GraphTemplate003A";
import Graphs003ASwingInternal_indiv from "./Graphs003ASwingInternal_indiv";




const reporttest = (props) => {
    

 return (<>
 <Helmet>
        <title>referrer_report_test 003A</title>
        <meta name="Referrer 003A" content="Referrer 003A" />
    </Helmet>
       
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Referrer Report: Swing Internal</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        
          <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px"  }}>
            <Row>
                <Col>
                <div className="TitleC" style={{ paddingBottom: "0px",  paddingLeft: "0px" }}>Contents</div>
                </Col>
            </Row>

        </Container>
        <Container fluid={true} style={{ paddingBottom: "15px", paddingLeft: "10px", paddingTop: "0px"  }}>
            <Row>
                <Col>
             
                

                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#appUse">app use</HashLink>
                    <br/>
                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#pgic">pgic</HashLink>
                    <br/>
                    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px" }} to="#indivs">individual participant charts</HashLink>


                </Col>
            </Row>

        </Container>
        {/* <Container style={{maxWidth: "7.5"}}>        */}
        {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
<Row>
    <Col>
   

<HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#pg2">jump to next page</HashLink>
</Col>
</Row>

</Container> */}
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px",maxHeight: "10in"}}>
 <Row>
 <Col xs={4}>
 {/* <Col style={{width: "34px"}}>  */}
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "30px", paddingLeft: "0px"}}>Screening Funnel</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"funnelA_swing_internal"} />
        </Col>
        <Col xs={4} style={{paddingLeft: "40px"}}>
        {/* <Col style={{width: "34px"}}>  */}
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px"}}>Participant Statuses</div>
            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"funnelB_swing_internal"} />
        </Col>
        </Row>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>FIQR Scores</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"fiqr_plotA_swing_internal"} />
        </Col>
        </Row>   
        </Container>
        <page_/>
         <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "10px" }}>
            <Row>
                <Col>

                    <HashLink id="appUse" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                </Col>
            </Row>
        </Container>
        <Container  class="page_" fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>

<Row>
<Col xs={7}>
{/* <Col style={{width: "34px"}}>  */}
           <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>App Use</div>
       <GraphTemplate003Aref style={{paddingBottom:"10px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"appUseA_swing_internal"} />
       </Col>

       </Row>
       </Container>
       <br/>
       <br/>
       <br/>
        <Container class="page_" fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px",paddingTop: "30px" }}>    
        <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "10px" }}>
            <Row>
                <Col>

                    <HashLink id="pgic" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                </Col>
            </Row>
        </Container>
        <Row>
 {/* <Col xs={7}> */}
 <Col xs={7}>
            <div className="TitleC" style={{paddingBottom: "30px", paddingRight: "0px", paddingLeft: "0px"}}>PGIC Scores</div>
        <GraphTemplate003Aref class="page_" style={{paddingBottom:"30px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_swing_internal"} />
        </Col>
     
        </Row>  
        <Row>
 <Col xs={11} style={{paddingTop: "40px",paddingBottom: "30px"}}>
            {/* <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>PGIC Scores</div> */}
            <CmReportTable003Axref  bgColor="#6694BA" style={{paddingTop: "30px"}} tableKey={"pgicTableA_swing_internal"} />       
    </Col>
        </Row>  
        </Container>
     
        {/* <Container class="page_" fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px",paddingTop: "30px" }}>    
        <Row>
 <Col xs={10}> */}
            {/* <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>PGIC Scores</div> */}
            {/* <CmReportTable003Axref  bgColor="#6694BA" style={{paddingTop: "30px"}} tableKey={"PGIC_swing_internal"} />       
    </Col>
        </Row>  
        </Container> */}

       


        
        {/* <Container  fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>

 <Row>
 <Col xs={4}>
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>Screening Funnel</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"funnelA_swing_internal"} />
        </Col>
        <Col xs={4}>
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>Participant Statuses</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"funnelB_swing_internal"} />
        </Col>
        </Row>
        </Container> */}

    
       {/* <page_/> */}
      
       
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
       <Container fluid={true} style={{paddingBottom: "1px", paddingTop: "35px"}}>
<Row>
    <Col>

<HashLink id="indivs" className="TitleDB" style={{paddingBottom: "3px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
</Col>
</Row>
</Container>
 <Row>
 <Col xs={7}>
            <div className="TitleC" style={{paddingBottom: "0px", paddingRight: "0px", paddingLeft: "0px"}}>Individual App Progress</div>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00227"} />
        </Col>
        </Row>   
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00301"} />
        </Col>
        </Row>   
        </Container>

<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00299"} />
        </Col>
        </Row>   
        </Container>

<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00357"} />
        </Col>
        </Row>   
        </Container>



<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00264"} />
        </Col>
        </Row>   
        </Container>



<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00327"} />
        </Col>
        </Row>   
        </Container>



        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00372"} />
        </Col>
        </Row>   
        </Container>
    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00388"} />
        </Col>
        </Row>   
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00408"} />
        </Col>
        </Row>   
        </Container>
    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00409"} />
        </Col>
        </Row>   
        </Container>
       
</>);

};

export default reporttest;




        {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00295"} />
        </Col>
        </Row>   
        </Container>
    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "5px", paddingRight: "10px"}}>
 <Row>
 <Col xs={7}>
        <Graphs003ASwingInternal_indiv style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"r00_00268"} />
        </Col>
        </Row>   
        </Container>
</>); */}









