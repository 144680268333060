import { Table } from "react-bootstrap";
import LoadingScreen from "../utils/screens/LoadingScreen";

const standardRows = [{ label: 'Subject ID', key: 'subjectID' }, { label: 'Cohort', key: 'cohort' }, { label: 'Program', key: 'program' }, { label: 'Action', key: 'action' }];
const tableTypes = {
  extension: {
    rows: [{ label: 'timezone', key: 'timezone' },{ label: 'Extension', key: 'extension' }, ...standardRows],
  },
  regular: {
    rows: [{ label: 'Core Content Completed', key: 'coreContentCompleted' }, ...standardRows],
  },
  '005': {
    rows: [{ label: 'timezone', key: 'timezone' },{label: 'Extension', key: 'extension'},{  label: 'Zero completed sessions/journals', key: 'noSessionsOrJournals' }, ...standardRows],
  },
  'optum': {
    rows: [{ label: 'timezone', key: 'timezone' },{ label: 'Subject ID', key: 'subjectID' }, { label: 'Site', key: 'site' }, { label: 'Program', key: 'program' }, { label: 'Action', key: 'action' }, ...standardRows ],
    },
}

/*
* The tableType prop should be one of the above table types ('extension', 'regular', etc)
*/
const PokeTable = ({ users, tableType = 'regular', inactiveType }) => {

  const convertToDays = (hours) => {
    return Math.round(hours / 24);
  }

  return (
    <Table bordered hover responsive="xl">
      <thead style={{ backgroundColor: "rgb(255, 249, 243)" }}>
        <tr>
          {
            tableTypes[tableType]?.rows.map((row) => (
              <th key={row.label}>{row.label}</th>
            ))
          }
          {inactiveType ? null : <th>Days Since Last Used</th>}
        </tr>
      </thead>
      <tbody>
        {!users && (
          <>
            <strong>Subjects Loading</strong>
            <br></br>
            <LoadingScreen></LoadingScreen>
          </>
        )}
        {users?.map((user) => {
          return (
            <>
              <tr
                key={user.uid}
                style={{ backgroundColor: user.hours >= 168 ? "#FF4330" : "#FFFFF", cursor: "pointer", color: user.hours >= 168 ? "white" : "#636161" }}
              >
                {
                  tableTypes[tableType]?.rows.map((row) => (
                    <td key={row.key}>
                      {
                        { // Special cases for certain keys
                          subjectID: <a style={{ textDecoration: "none", color: "rgb(99, 97, 97)" }} href={"https://tempo.systems/users/" + user.id} target="_blank" rel="noreferrer">{user.subjectID}</a>
                        }[row.key] || user[row.key]
                      }
                    </td>
                  ))
                }
                {inactiveType ? null : <td>{convertToDays(user.hours)} days</td>}
              </tr>
            </>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PokeTable;
