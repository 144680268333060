import React from "react";
import {signInWithGoogle} from "../firebase";
import {Button, Card, Container, Row} from "react-bootstrap";

const SignIn = () => {
  return (
  <Container fluid className="text-center sign-in">
    <Row className="justify-content-md-center">
      <h1 style={{marginTop: "50px", marginBottom: "50px"}}>Tempest</h1>
      <br></br>
      <br></br>
    </Row>
    <Row className="justify-content-md-center">
      <Card style={{width: "18rem"}}>
        <Card.Body>
          <Card.Title>Log In</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Please log into tempo analytics.
          </Card.Subtitle>
          <Card.Text>
            This site is exclusively for employees of Swing Therapeutics.
          </Card.Text>
          <Button
          variant="success"
          onClick={signInWithGoogle}
          >
            Sign in with Google
          </Button>
        </Card.Body>
      </Card>
    </Row>
  </Container>
  );
};

export default SignIn;
