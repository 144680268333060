import React from "react";
import "../surveyAdherence.css";
// import { usePageName } from 'react-page-name';
    
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import CmReportTable004B from "./CmReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import TWexpl004B from "./TWexpl004B"; 
import TWexpl004B2 from "./TWexpl004B2";


// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004byTherapyWeek = (props) => {
    // usePageName('PROs-Visit 004-2');
 return (<>
        <AdminNavbar/>
        <Jumbotron >
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other Scores by Therapy Week, Cohort 2</h1>
                        <TWexpl004B2/>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>


        
            
            <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
        <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
        <div className="TitleC">PROs by Therapy Week, group means
        <TWexpl004B/>
        {/* <span className="TitleDB" style={{fontSize: "12px", paddingLeft: "20px"}} >*percent_change_from_baseline is the mean percent_change_from_baseline across all participants;</span><span className="TitleDB" style={{fontSize: "12px", paddingLeft: "4px", color: "#39328A"}} >mean percent_change_from_baseline ≠ mean change_from_baseline / mean therapyWeek00.</span>   */}
        
        </div>
           <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"otherPros004B_means"}></CmReportTable004B>
           </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
        <div className="TitleC">PROs by Therapy Week</div>
           <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"otherPros004B"}></CmReportTable004B>
           </div>
        </Container>

           

       


</>);

};

export default B004byTherapyWeek;

// dsgfdfgdfg
