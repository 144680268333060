import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import CmReportTable004A from "./CmReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const A004byTherapyWeek = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron >
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other PRO Scores, by Therapy Week</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Mean Scores, by Arm
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} >*excludes s10_008</span>
            </div>
            {/* <p></p> */}
            <CmReportTable004A  tableKey={"prosTW_004A_means"}></CmReportTable004A>
                </div>
        </Container>

        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">PGIC Scores, by Arm</div>
            <BReportTable004A  tableKey={"pgicTW_004A"}></BReportTable004A>
                </div>
        </Container> */}
{/*
        <Container fluid={true} style={{backgroundColor: "white" }}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <BReportTable004ACT tableKey={"fiqrTW_004A_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <BReportTable004A tableKey={"fiqrTW_004A_ST"}></BReportTable004A>
                </div>
        </Container> */}


</>);

};

export default A004byTherapyWeek;

