import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import ScrollUpButton from "react-scroll-up-button";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import MedHistBase005 from  "./medHistBase005" ;
import { HashLink } from "react-router-hash-link";


const A005medHist = (props) => {
    const [baselineMedHistA_updated, setBaselineMedHistA_updated] = useState([]);

    const ArmData_updateD = async (setter) => { 
        const sco_doc = firestore.collection("analytics").doc("A005").collection("baselineMedHistA_updated").doc("baselineMedHistA_updated")
        const doc = await sco_doc.get();
        const docData=doc.data()
        const armData_updateD = docData['baselineMedHistA_updated'];
        console.log(armData_updateD)
        setter(armData_updateD);
      } 
     

    
      useEffect(() => {
    ArmData_updateD(setBaselineMedHistA_updated)
    }, [props]);

return (<>
 <Helmet>
        <title>Baseline Medical Info 005A</title>
        <meta name="Baseline Medical Info 005A" content="Baseline Medical Info 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Baseline Medical Info 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#diagCrit">•diagnostic criteria</HashLink>
            

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "5px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "20px"}}>data last retrieved: {baselineMedHistA_updated}</div>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "5px", paddingBottom: "10px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "40px",lineHeight: "1"}}>Fibro History</div>
{/* <div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "20px",lineHeight: "1"}} > average of post-screening and pre-baseline</div> */}
<MedHistBase005 style={{width: "350px"}}  histKey={"years_since_diagnosis"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<MedHistBase005 style={{width: "350px"}}  histKey={"age_at_diag"} />

</div>
</Container>

<Container fluid={true} style={{ paddingBottom: "10px", paddingTop: "35px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="diagCrit" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
             </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "5px", paddingBottom: "5px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "40px",lineHeight: "1"}}>ACR 2016 Diagnostic Criteria</div>
{/* <div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "20px",lineHeight: "1"}} > average of post-screening and pre-baseline</div> */}

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "10px"}}> 
<MedHistBase005 style={{width: "350px"}}  histKey={"diag_overall_score"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<MedHistBase005 style={{width: "350px"}}  histKey={"diag_symptom_severity"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "40px"}}> 
<MedHistBase005 style={{width: "350px"}}  histKey={"diag_widespread_pain"} />

</div>
</Container>
</>);

};

export default A005medHist;


