import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const ReportsListSidebar005A = (props) => {
  return (
    <>
    {/* <noPrint> */}
      <Container  fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px" }  }>
        <div className="sidenav"  style={{'width':"350px",paddingRight: "100px"}}>

      

        <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/dashA">
Summary
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/consortA">
Consort
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/eprocmpA">
EPRO Completion
            </Link>
          </div>
          
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/eprocmplA">
EPRO Compliance
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/sitesA">
Sites
            </Link>
          </div>     
             
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/enrollmentA">
Enrollment
            </Link>
          </div> 

          <div style={{paddingTop: "10px"}} >
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/appuseA">
App Use
            </Link>
          </div>

          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/chptevalsA">
Chapter Evaluations
            </Link>
          </div> 

 {/* <br/> */}
          <div >
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/voicesA">
Voice Selection
            </Link>
          </div> 
          <div >
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/awarenessA">
Part 1 Awareness Activities
            </Link>
          </div> 
          <div style={{paddingTop: "10px"}} >
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/extensionA">
Extension Participation
            </Link>
          </div>
          <div style={{paddingTop: "10px"}} >
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/extensioncheckA">
Extension Monitoring
            </Link>
          </div>

          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/SToutcomesA">
ST Outcomes
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/005/siteHealthA">
          Site Health
          </Link>
          </div>

<div style={{fontsize: "15px",paddingTop: "15px"}}>Baseline Info</div>
<div style={{paddingBottom: "0px",paddingTop: "0px", lineHeight: "1"}}>
<Link className="TitleDB" style={{  fontSize: "12px",paddingLeft:"7px",paddingBottom: "0px" }} to="/005/baselinesA">
Baseline EPROs
</Link>
</div>  
<div style={{paddingBottom: "0px",paddingTop: "0px", lineHeight: "1"}}>
<Link className="TitleDB" style={{  fontSize: "12px",paddingLeft:"7px",paddingBottom: "0px", paddingTop: "0px"    }} to="/005/demsA">
Demographics
</Link>
</div> 
<div style={{paddingBottom: "0px",paddingTop: "0px", lineHeight: "1"}}>
<Link className="TitleDB" style={{  fontSize: "12px",paddingLeft:"7px",paddingBottom: "0px", paddingTop: "0px"   }} to="/005/medhistA">
Medical History
</Link>
</div> 
</div>

      </Container>
  
    </>
  );
};

export default ReportsListSidebar005A; 
