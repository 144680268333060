import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import ScrollUpButton from "react-scroll-up-button";
import { HashLink } from "react-router-hash-link";


const A005SToutcomes = (props) => {
return (<>
 <Helmet>
        <title>ST Outcomes 005A</title>
        <meta name="ST Outcomes 005A" content="ST Outcomes 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ST Outcomes 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
<ReportsListSidebar005A/> 
   <ScrollUpButton/>

       <Row>
<Container fluid={true} style={{ paddingBottom: "20px", paddingLeft: "0px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "8px",fontSize: "45px"}}>ST Consort, post-main</div>

<GraphTemplate005A style={{padding: "0px"}} report={"consortSTfinA"} />

{/* <Row>
<GraphTemplate005A style={{padding: "0px"}} report={"consortExtA"} />
                </Row> */}
                </div>
</Container>
</Row>
<Row>
<Container  fluid={true} style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "20px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "0px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "10px",paddingBottom: "8px",fontSize: "45px"}}>ST Outcomes</div>
<div style={{width: "400px"}}>
<CmReportTable005A2 style={{padding: "0px"}} thFontSize="14px" tdFontSize="12px" bgColor="#9E005D" tableKey=  {"ST_outcomes"} />                
</div>
</div>
</Container>
</Row>


</>);

};

export default A005SToutcomes;