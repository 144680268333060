import React, {useEffect, useState} from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../utils/firebase";
    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import quers from "./quers";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import getSourcesData from "./A003expt";

import getScreening_abandonedData  from "./A003expt";

const A003sources_centers = (props) => {
    // const sources =  firestore.collection("analytics").doc("sources").get()
    // const forupdated = sources.updated
    // console.log(sources.map)
    
    // var fb =  firestore.collection('analytics').doc('sources').collection('sources003A').doc('facebook_instagram').get()
    // console.log(JSON.stringify(fb.source))
    // const wtf = firestore.collection('users').doc('U86HR028VHUJ3pI8XETF9WJ74W72').get()
    // console.log(wtf.subjectID)
   

    return (<>
 
 <Helmet>
        <title>Centers & Sources 003A</title>
        <meta name="Centers & Sources 003A" content="Centers & Sources 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Centers & Sources 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#treatmentCenters">•skip to treatment centers</HashLink>
               {/* <br/>   <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#therChanges">•skip to changes during therapy</HashLink> */}
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>
     

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC">How did you hear about this study?</div>
            {/* <div className="TitleC" style={{fontSize: "15px",paddingBottom: "7px"}}>Compared to two months ago, overall, my fibromyalgia is...</div> */}

          

            <CmReportTable003Ax bgColor="#555557"  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"sources003A"}></CmReportTable003Ax>
                </div>
        </Container>
     
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Row>
            <Col>
                <HashLink id="treatmentCenters" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
           

            </Col>
            </Row>
            <div className="TitleC">Treatment Centers</div>
            <div className="TitleC" style={{fontSize: "15px",paddingBottom: "7px"}}>Do we or should we have relationships with these treatment centers?</div>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#4B216B" tableKey={"treatmentCenters003A_repeats"}></CmReportTable003Ax>
    
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#4B216B" tableKey={"treatmentCenters003A"}></CmReportTable003Ax>
                </div>
        </Container>
   

    
</>);

};

export default A003sources_centers;




