import {React,Suspense,useImage,Img} from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,Image} from "react-bootstrap";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import consortB from './consort_diagram004B.png';
import {Helmet} from "react-helmet";






// function MyImageComponent() {
//     const {src} = useImage({
//       srcList: './consort_diagram.png',
//     })
   
//     return <img src={src} />};

// export default function MyComponent() {
//   return (
//     <Suspense>
//       <MyImageComponent />
//     </Suspense>
//   )
// }

const B004consort = (props) => 
    {
 return (<>
 <Helmet>
        <title>Consort 004-2</title>
        <meta name="Consort 004-2" content="Consort 004-2" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Consort Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            {/* <Image src={'%PUBLIC_URL%/consort_diagram.png'}  /> */}
{/* <Img source={{uri: './consort_diagram.png'}} /> */}
<img src={consortB} alt="BigC"  style={{height: "516px", width: "1200px"}}  />
{/* <consortB/> */}
</div>
</Container>
</>);

};

export default B004consort;


