import React, {useEffect, useState} from "react";
import {firestore} from "./firebase";
import {Button, Col, Table} from "react-bootstrap";
import JSONTree from "react-json-tree";

const LocalQuery = (props) => {

  const [rows, setRows] = useState([]);

  const query = firestore.collection("analyticsEvents").where('screenName', '!=', '').limit(50);
  
  const execute = async ()=>{
     const results = await query.get().catch((error)=>{console.log('Error!!!! ', error)});
     if(!results){
       console.log('returning due to error');
       return;
     }
     console.log('results', results)
     results.docs?.forEach((doc, index)=>{console.log('Result '+ index +' ', doc.data())})
     setRows(results.docs);

  }



  const getTableRow = (progRef, index) => {
    const jsonTheme = {
      scheme: "chalk", author: "chris kempson (http://chriskempson.com)", base00: "transparent", base01: "#333333", base02: "#555555", base03: "#777777", base04: "#999999", base05: "#bbbbbb", base06: "#dddddd", base07: "#ffffff", base08: "#ff4136", base09: "#ff851b", base0A: "#ffdc00", base0B: "#2ecc40", base0C: "#7fdbff", base0D: "#0074d9", base0E: "#b10dc9", base0F: "#85144b",
    };
    let data = progRef.data();
    return (
    <tr key={progRef.id}>
      <td>{index}</td>
      <td>{progRef.id}</td>
      <td>{progRef.ref.path}</td>
      <td>
        <JSONTree
        data={data}
        theme={jsonTheme}
        invertTheme={false}
        shouldExpandNode={() => false}
        />

      </td>

    </tr>
    );
  };

  return (
  <>
    <Col xs={12}>
      <Button onClick={execute}>
        Execute!
      </Button>
    </Col>

    {rows && (<Col xs={12}>
      <Table style={{backgroundColor: "white", width: "99%", paddingLeft: "20px"}}>
        <thead>
        <tr>
          <th>Result Number</th>
          <th>ID</th>
          <th>Location</th>
          <th>Data</th>

        </tr>
        </thead>

        <tbody>{rows.map((u, i) => getTableRow(u,i))}</tbody>
      </Table>
    </Col>)}

  </>
  );
};

export default LocalQuery;
