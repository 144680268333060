import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";

import { HashLink } from "react-router-hash-link";


const A005Consort = (props) => {
return (<>
 <Helmet>
        <title>Consort 005A</title>
        <meta name="Consort 005A" content="Consort 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Consort 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
<ReportsListSidebar005A/> 


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 

<Row>
                    <Col>





<GraphTemplate005A style={{padding: "0px"}} report={"consortA"} />


</Col>
                </Row>
                </div>
</Container>



</>);

};

export default A005Consort;