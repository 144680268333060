import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

// import CmReportTable004B from "./CmReportTable004B";
// import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
// import AsmallerReportTable004Bweekly from "./AsmallerReportTable004Bweekly";
import PROkeyTableXSext2 from "./PROkeyTableXSext2";
import GraphTemplate004Bext from "./GraphTemplate004Bext";
import {Helmet} from "react-helmet";


const Bext004JournalMets = (props) => {
 return (<>
  <Helmet>
		<title>Journal Metrics 004-2x</title>
		<meta name="Journal Metrics 004-2" content="Journal Metrics 004-2x" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ACT Journals, Cohort 2x</h1>
                        <h2 className="TitleDB" style={{paddingBottom: "25px", fontSize: "32px",paddingTop: "10px"}}>TempoACT2, ST → ACT arm</h2>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "150px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            {/* <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ST</div> */}
            <div className="TitleC" style={{ paddingLeft: "100px",paddingBottom: "1px",paddingTop: "25px"}} >By Journal Type and ACT Group</div>
              <GraphTemplate004Bext style={{paddingBottom:"28px"}} report={"ACT004Bx0_journals"} />
            </Col>
          </Row>
          </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            {/* <div className="TitleC" style={{paddingBottom: "25px"}}   >Journal Completion</div> */}
            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >By Journal Type</div>
            <PROkeyTableXSext2 style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_byJournalID004Bx0"}></PROkeyTableXSext2>
            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >By ACT Group</div>
            <PROkeyTableXSext2 style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_by_group004Bx0"}></PROkeyTableXSext2>
            </div>
        </Container>
           
            
            <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
           
            <div className="TitleC" style={{ paddingLeft: "10px",paddingBottom: "1px",paddingTop: "25px"}} >By Journal Type and ACT Group</div>
          <PROkeyTableXSext2 style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"journal_metrics_byJournalID_group004Bx0"}></PROkeyTableXSext2>
         
          <div className="TitleC" style={{ paddingLeft: "10px",paddingBottom: "1px",paddingTop: "25px"}} >By Participant</div>
          <PROkeyTableXSext2 style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"journal_metrics_by_subjectID004Bx0"}></PROkeyTableXSext2>

          </div>
        </Container>


 
 
 

    


</>);

};

export default Bext004JournalMets;




