import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";



const A005EproComp = (props) => {




 return (<>
 <Helmet>
        <title>EPRO Completion 005A</title>
        <meta name="EPRO Completion 005A" content="EPRO Completion 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>EPRO Completion 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 



<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
            
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#closedE">•closed EPROs</HashLink>  
            <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#intreat">•in-treatment EPRO completion</HashLink>

<div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container> 

{/* <Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "40px", width: "auto"}}> 
<Col>
<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Open EPROs
<span style={{fontSize: "12px",paddingLeft: "10px"}}>all participants</span>
</div>
</Row>
<Row>
<CmReportTable005A1 style={{padding: "0px"}} tdFontSize="10px" bgColor="#4F002F" tableKey={"openEpros005A"} />
</Row>
</Col>
</div>
</Container> */}
{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "45px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="closedE" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            <br/>
                        <HashLink id="closedE" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#intreat">jump to in-treatment EPRO completion</HashLink>  
            </Col>
            </Row>
            </div>
            </Container> */}
<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "40px", width: "auto" }}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Closed EPRO Completion<span style={{fontSize: "12px",paddingLeft: "10px"}}>all participants</span></div>

{/* visitStats005A */}

<CmReportTable005A1 style={{padding: "0px"}} tdFontSize="10px" bgColor="#4B216B" tableKey={"closedEpros005A"} />


</div>
</Container>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "35px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="intreat" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                         <br/>
                        {/* <HashLink id="intreat" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#closedE">jump to closed EPROs</HashLink>          */}
            </Col>
            </Row>
            </div>
            </Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", width: "auto" }}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>In-Treatment EPRO Completion</div>



<CmReportTable005A1 style={{padding: "0px"}} tdFontSize="10px" bgColor="#33A4FF" tableKey={"visitStats005A"} />


</div>
</Container>


</>);

};

export default A005EproComp;