import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003AxS from "./CmReportTable003AxS";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003AxSF from "./CmReportTable003AxSF";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";


const A003insights = (props) => {
    return (<>
 
 <Helmet>
        <title>Insights 003A</title>
        <meta name="Insights 003A" content="Insights 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Insights 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 
 

 

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "35px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "5px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px" }}   >Mean Use, by Status</div>
            {/* <div className="TitleC" style={{paddingBottom: "10px",paddingTop: "0px" }}   >Do you have a regular religious or spiritual practice?</div> */}

    
            <CmReportTable003AxSF  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#A2C8EE" tableKey={"mean_insights_workflowStatus003A"}></CmReportTable003AxSF>
                </div>
        </Container>
  
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "35px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "5px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px" }}   >Insight Use, by subjectID</div>
            <div className="TitleC" style={{paddingBottom: "4px",paddingTop: "0px", fontSize: "20px" }}   >excludes early terminations; sorted by total_insights</div>

    
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#123A63" tableKey={"insights_by_subjectID003A"}></CmReportTable003Ax>
                </div>
        </Container>

{/* insights_by_subjectID003A */}

 </>);

};

export default A003insights;
