import React from "react";
import {Link} from "react-router-dom";
import "./surveyAdherence.css";
import {Container} from "react-bootstrap";

const AreportsListSidebar = (props) => {
  return (
  <>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="sidenav">
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/kpis"> KPIs </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/app_adherence"> Engagement </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/app_progress"> App Progress </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/FIQR002">FIQR Scores</Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/FIQR_adherence"> FIQR Adherence </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/nps_scores"> NPS Scores </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/pande"> Pacing & Exercise </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{paddingLeft: "10px",fontSize: "14px" }} to="/interviews"> Interviews </Link>
        </div>
      </div>
    </Container>
  </>
  );
};

export default AreportsListSidebar;
