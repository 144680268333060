import React, {useState} from "react";
import {Route, useHistory} from "react-router-dom";
import {Col, Container, Jumbotron, NavLink, Row,Table} from "react-bootstrap";
import NavItem from "react-bootstrap/NavItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdminNavbar from "../components/AdminNavbar";

// @ts-ignore
const Landing = ({match, location}) =>
{
  let history = useHistory();


  const [active, setActive] = useState<string>("overview");


  const navLinker = (newActive: string, text: string, icon: any) =>
  {
    return (<NavItem>
      <NavLink

      onClick={() =>
      {
        setActive(newActive);
        history.push( "/" + newActive);
      }}
      >
        <Row>
          <Col>
            <strong
            className={active === newActive ? "text-primary" : "text-secondary"}
            >
              {text}
            </strong>
          </Col>
          <Col xs={"auto"}>
            {" "}
            <FontAwesomeIcon icon={icon}/>
          </Col>
        </Row>
      </NavLink>
    </NavItem>);
  };

  return (<>
    <AdminNavbar/>
    <Container fluid={true}>
      <Jumbotron>
        <h1 className={"jumbotron-heading"}>
          Swing Analytics
        </h1>
        <h2 className={"jumbotron-heading"}> <small> What's happening in Tempo? This is a good place to look. </small></h2>
        
      </Jumbotron>
      <div className={"album bg-light"}>
        <Container fluid={true} style={{backgroundColor: "white"}}>
          <Row>
         
            <Col xs={"12"} className={"main-content"}>
              {(<>
                {" "}
                {match.isExact && <div className={"TitleC"} style={{fontSize: "30px", paddingTop: "10px"}} >Patients</div>}
               
               {/* width: "25%", */}
               <Table hover responsive="xl" style={{backgroundColor: "white",width: "355px", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px"}}>
               <tr style={{paddingRight: "10px",paddingLeft: "10px", lineHeight: "1.2px" }}>
                 {/* <td style={{paddingRight: "48px"}}></td> */}
                 <td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, lineHeight: "1.2px" }} href="/VS/SCdash" target="_blank">Swing Care</a></td>
                 </tr>
                 <tr>
                  {/* <td>  </td> */}
                  <td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, lineHeight: "1.2px" }} href="/ORX/stanza" target="_blank">Other Prescribing Clinics</a></td>
                  </tr>
                 </Table>


                {match.isExact && <div className={"TitleC"} style={{fontSize: "30px", paddingTop: "30px"}} >Current Cohorts</div>}
               
                {/* width: "25%", */}
                <Table hover responsive="xl" style={{backgroundColor: "white",width: "355px", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px"}}>
                <tr style={{paddingRight: "10px",paddingLeft: "10px", lineHeight: "1.2px" }}>
            
                  <td>003</td><td> <a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/003/consortA" target="_blank">ReactFM - Pragmatic</a></td>
                  </tr>
                  <tr>
                  <td>003</td><td> <a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/003/extA" target="_blank">ReactFM - Extension</a></td>
                  </tr>
                  <tr style={{paddingRight: "10px",paddingLeft: "10px" }}>
                  <td>005</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/005/extensionA" target="_blank">ProsperFM - Extension</a></td>
                  </tr>
                  </Table>

                  {/* {match.isExact && <div className={"TitleC"} style={{fontSize: "30px"}} >Pending Cohorts</div>}
                  <Table hover responsive="xl" style={{backgroundColor: "white",width: "355px", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px"}}>
                  <tr style={{paddingRight: "10px",paddingLeft: "10px" }}>
                  </tr>
                  </Table> */}
                  {match.isExact && <div className={"TitleC"} style={{fontSize: "30px"}} >Previous Cohorts</div>}
                  <Table hover responsive="xl" style={{backgroundColor: "white",width: "355px", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px"}}>

                    {/*   <tr style={{paddingRight: "10px",paddingLeft: "10px" }}>
                  <td>005</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/005/dashA" target="_blank">ProsperFM - A</a></td>
                  </tr> */}
                  <tr style={{paddingRight: "10px",paddingLeft: "10px", lineHeight: "1.2px" }}>
                  <td>005</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, lineHeight: "1.2px" }} href="/005/dashA" target="_blank">ProsperFM</a></td>
                  </tr>
                  <tr style={{paddingRight: "10px",paddingLeft: "10px", lineHeight: "1.2px" }}>
                  <td>004</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, lineHeight: "1.2px" }} href="/004/consortBext" target="_blank">Cohort 2 Extension</a></td>
                  </tr>
                <tr style={{paddingRight: "10px",paddingLeft: "10px", lineHeight: "1.2px" }}>
                  <td>004</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, lineHeight: "1.2px" }} href="/004/consortB" target="_blank">Cohort 2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></td>
                  </tr>
                  <tr>
                  <td>004</td><td> <a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/004/consortA" target="_blank">Cohort 1   </a></td>
                  </tr>                  
                  <tr>
                  <td>002</td><td> <a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/002/graphs002" target="_blank">Fukui</a></td>
                  </tr>
                  <tr>
                  <td>002</td><td> <a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500 }} href="/002/graphs002" target="_blank">Brachio   </a></td>
                  </tr>
                 
                  </Table>
                  {/* <Table style={{backgroundColor: "white", width: "25%", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px"}}> */}
                  {/* <Table hover responsive="xl" style={{backgroundColor: "white",width: "355px", paddingLeft: "10px", fontSize: "20px", lineHeight: "1.2px", textAlign:"left"}}> */}
                  {/* style={{paddingRight: "10px",paddingLeft: "10px" }} */}
                  {/* <tr style={{paddingRight: "0px",paddingLeft: "1px" }}>
                  <td>004</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, textAlign:"left" }} href="/004/consortB" target="_blank">Cohort 2</a></td>
                  </tr>
                  <tr >
                  <td >004</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, textAlign:"left" }} href="/004/consortA" target="_blank">Cohort 1 </a></td>
                  </tr>
                  <tr>
                  <td >002</td><td><a className={"TitleC"} style={{fontSize: "20px", color: "#2a3876", fontWeight: 500, textAlign:"left" }} href="/kpis" target="_blank">Brachio</a></td>
                  </tr>
                  </Table> */}

<Route path={match.url + "/overview"} render={() => <p>Overview</p>}/>
<Route path={match.url + "/002"}render={() => <p>002</p>}/>

              </>)}
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  </>);
};

export {Landing};
