import React from "react";
import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";

// import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import AsmallerReportTable from "./AsmallerReportTable";
// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AnotherReportsListSidebar004Bext = (props) => {
  return (
    <>
      <Container className="no-print" fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px" }  }>
        <div className="sidenav">
        <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/consortBext">
Consort
            </Link>
          </div>    
        <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/notificationsBext">
               Notifications
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/favoritesBext">
               Favorites
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fibroflareBext">
               Fibro Flare Toolkit
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/insightsBext">
               Insights
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/chptevalsBext">
               Chapter Evals
            </Link>
          </div>      
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/prosBext">
               PROs - Visit
            </Link>
          </div>

          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqrBext">
FIQR - Visit
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqrTWBext">
FIQR - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/prosTWBext">
PROs - Therapy Week
            </Link>
          </div>       
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/ffmqnrTWBext">
FFQM-NR - Therapy Week
            </Link>
          </div> 
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/app_progressBext">
App Progress
            </Link>
          </div>           
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/awarenessBext">Awareness Activities pt I</Link>
          </div>  
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/part2Bext">Part 2 Playlists</Link>
          </div>  
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/journalsBext">Other ACT Journals</Link>
          </div>     


        </div>
      </Container>
    </>
  );
};

export default AnotherReportsListSidebar004Bext;
