import { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
import PokeTable from "./PokeListTable";
import "../reports/surveyAdherence.css";
import { isExcludedEmail } from "./ExcludeFromStats";
import { diffHr } from "../utils/helpers/pokeListHelpers";
// import { user } from "firebase-functions/v1/auth";

const PokeList005 = () => {
  const [inactiveUsers, setInactiveUsersToContact] = useState([]);
  const [OO5usersToContact, setOO5usersToContact] = useState([]);

  const getUserInfo = (user, timeCompleted, actionText, noSessionsOrJournals = false) => {
    return {
      id: user.uid,
      subjectID: user.subjectID,
      hours: timeCompleted,
      cohort: user.cohort,
      program: user.activeProgram,
      action: actionText,
      extension: user.extension,
      email: user.email,
      noSessionsOrJournals,
      timezone: user.timezone,
      role: user.role,
      site: user.site
    };
  };

  useEffect(() => {
    (async () => {
      const allUsers = await firestore
        .collection("users")
        .where("cohort", "in", ["005-cohort-1"])
        .get();

      for (const doc of allUsers.docs) {
        const userData = doc.data();
          if (userData.extension!='accepted')
          {userData.extension=''};
        // for control (ST)
        if (userData.activeProgram === "control_v2"
          && userData.do_not_nudge !== true
          && userData.role=='patient'
          && userData.site!=='99'
          ) {
          const journalEntries = await firestore
            .collection("users")
            .doc(doc.id)
            .collection("programs")
            .doc(userData.activeProgram)
            .collection("journalEntries")
            .orderBy("dateCreated", "desc")
            .limit(1)
            .get();
          if (journalEntries.docs[0]) {
            const journalEntry = journalEntries.docs[0];
            const journalData = journalEntry?.data();
            const created = journalData?.dateCreated;

            const lastCreated = diffHr(new Date(), created?.toDate());
            /*
            *  If journalEntry dateCreated is within range of 48 to 72 hours ago,
            *  put them in text list
            */
            if (lastCreated > 48 && lastCreated < 72) {
              setOO5usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCreated, 'text'),
              ])
            }
            /*
            *  If journalEntry dateCreated is over 72 hours ago,
            *  put them in call list
            */
            if (lastCreated >= 72) {
              setOO5usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCreated, 'text + needs compliance call'),
              ])
            }
          } else {
            // No journal entries
            setOO5usersToContact((prevState) => [
              ...prevState,
              getUserInfo(userData, 'N/A', 'text + needs compliance call', true),
            ])
          }
        }
        else if ( // for active (ACT)
          userData.activeProgram === "tempoACT3" && userData.role=='patient' 
          && userData.site!=='99'
          && userData.do_not_nudge !== true && !isExcludedEmail(userData.email)) {

          const sessions = await firestore
            .collection("users")
            .doc(doc.id)
            .collection("programs")
            .doc(userData.activeProgram)
            .collection("sessions")
            .orderBy("dateCompleted", "desc")
            .limit(1)
            .get();

          if (sessions.docs[0]) {
            const sessionEntry = sessions.docs[0];
            const sessionData = sessionEntry?.data();
            const completed = sessionData?.dateCompleted;
            const started = sessionData?.dateStarted;
            let lastCompleted;

            if (completed !== null) {
              lastCompleted = diffHr(new Date(), completed?.toDate());
            } else {
              const firstCompletedProgram = diffHr(new Date(), started?.toDate());
              setInactiveUsersToContact([
                getUserInfo(userData, firstCompletedProgram, "n/a"),
              ])
            }

            /*
             * If the session lastCompleted is within range of 48 to 72 hours ago,
             * put them in the text list 
             */
            if (lastCompleted > 48 && lastCompleted < 72) {
              setOO5usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCompleted, 'text'),
              ])
            }
            /*
             * If the session lastCompleted is over 72 hours ago,
             * put them in the compliance call list 
             */
            if (lastCompleted > 72) {
              setOO5usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCompleted, 'text + needs compliance call'),
              ])
            }
          } else {
            // No sessions
            setOO5usersToContact((prevState) => [
              ...prevState,
              getUserInfo(userData, 'N/A', 'text + needs compliance call', true),
            ])
          }
        }
      }
    })();
  }, []);

  return (
    <>
      <h4 className="pokelist-header">
        Contact these 005 people today
      </h4>
      <PokeTable users={OO5usersToContact} tableType='005' />
      <h4 className="pokelist-header">
        Contact these 005 inactive users today
      </h4>
      <PokeTable inactiveType={true} users={inactiveUsers} tableType='005' />
    </>
  );
};

export default PokeList005;
