import React from "react";
import {Link} from "react-router-dom";
import "./surveyAdherence.css";
import {Container} from "react-bootstrap";

const AnotherReportsListSidebar = (props) => {
  return (
  <>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="sidenav">
        <div>
          <Link className="TitleDB" style={{ontSize: "14px"}} to="/screeningWeek">
            Screening Week
          </Link>
        </div>

        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/sites">
            Sites
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/kpis">
            KPIs
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/act004a">
            ACT Progress charts
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/st004a">
            ST Progress charts
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/app_progress004">
            App Progress
          </Link>
        </div>


        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/pros">
            PROs
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/pro_compliance004">
            PRO Compliance
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/fiqr004A">
            FIQR Scores - Visit
          </Link>
        </div>
        <div><Link className="TitleDB" style={{fontSize: "14px"}} to="/004/fiqrap004">
          FIQR Scores - App Progress
        </Link>
        </div>
        <div><Link className="TitleDB" style={{fontSize: "14px"}} to="/004/fiqrtherapyweek004">
          FIQR Scores - Therapy Week
        </Link>
        </div>
        <div><Link className="TitleDB" style={{fontSize: "14px"}} to="/004/other_prosA">
          Other PROs - Visit
        </Link>
        </div>
        <div><Link className="TitleDB" style={{fontSize: "14px"}} to="/004/scoresap004">
          Other PROs - App Progress
        </Link>
        </div>
        <div><Link className="TitleDB" style={{fontSize: "14px"}} to="/004/scorestherapyweek004">
          Other PROs - Therapy Week
        </Link>
        </div>

        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/visits">
            Visits
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/promis004atw">
            PROMIS - Therapy Week
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/promis004tatw">
            PROMIS - converted - Therapy Week
          </Link>
        </div>
        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/promis004">
            PROMIS - Visit
          </Link>
        </div>


        <div>
          <Link className="TitleDB" style={{fontSize: "14px"}} to="/004/promis004T">
            PROMIS - converted - Visit
          </Link>
        </div>
        <div>
          <li class="dropdown">
            <a href="javascript:void(0)" class="TitleDB">PRO charts</a>
            <div class="dropdown-content">
              <Container fluid={true} style={{backgroundColor: "white"}}>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "14px"}} to="/004/fiqr004graphs">
                  FIQR
                </Link>


                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "14px"}} to="/004/ffnr004graphs">
                  Five Facets NR
                </Link>

                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "14px"}} to="/004/pips004graphs">
                  PIPS
                </Link>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "14px"}} to="/004/wi004graphs">
                  Weekly Intensity & Interference
                </Link>
              </Container>
            </div>
          </li>


        </div>


      </div>
    </Container>
  </>
  );
};

export default AnotherReportsListSidebar;
