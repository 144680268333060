import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1stickyNoIndNoCols from "./CmReportTableVS1stickyNoIndNoCols";
import GraphTemplateVSA from "./GraphTemplateVSA";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1stickyHH from "./CmReportTableVS1stickyHH";

import CmReportTableVS1stickyNoIndFML from "./CmReportTableVS1stickyNoIndFML";
import CmReportTableVS1stickyNoIndNoCols2 from "./CmReportTableVS1stickyNoIndNoCols2";
import CmReportTableVS1NoCols2 from "./CmReportTableVS1NoCols2";
import CmReportTableVS1stickyHRfml from "./CmReportTableVS1stickyHRfml";
import CmReportTableVS1stickyHnoIndRfml from "./CmReportTableVS1stickyHnoIndRfml";

import CmReportTableVS1noIndSpcM from "./CmReportTableVS1noIndSpcM";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import CmReportTableVS1xxx from "./CmReportTableVS1xxx";
// import label00 from "./SCinfoCalls1";
// import label01 from "./SCinfoCalls1";
// label00
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCyAd = (props) => {
 
    const [updated, setUpdated] = useState([]);
    const [ptitleAp, setPtitleAp] = useState([]);
    const [ptitleAb, setPtitleAb] = useState([]);
    const [total_active_SC_patients, setTotal_active_SC_patients] = useState([]);
    const [total_SC_patients_2023intakes, setTotal_SC_patients_2023intakes] = useState([]);
    const [total_active_SC_patients_2023intakes, setTotal_active_SC_patients_2023intakes] = useState([]);
    const [total_lost_SC_patients_2023intakes, setTotal_lost_SC_patients_2023intakes] = useState([]);
    const [label00, setLabel00] = useState([]);
   
    const [total_lost_SC_patients,setTotal_lost_SC_patients] = useState([]);
    const [last4weeks, setLast4weeks] = useState([]);   
    
     
      const [completed_intake, setCompleted_intake] = useState([]);
   

    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("appointments");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
        getTitleCs('ptitleAp',setPtitleAp)
        getTitleCs('ptitleAb',setPtitleAb)
        getTitleCs('total_active_SC_patients',setTotal_active_SC_patients)
        getTitleCs('total_SC_patients_2023intakes',setTotal_SC_patients_2023intakes)
        getTitleCs('total_active_SC_patients_2023intakes',setTotal_active_SC_patients_2023intakes)
        getTitleCs('total_lost_SC_patients_2023intakes',setTotal_lost_SC_patients_2023intakes)
        getTitleCs('total_lost_SC_patients',setTotal_lost_SC_patients)
       
		getTitleCs('completed_intake',setCompleted_intake)
		getTitleCs('label00',setLabel00)
        getTitleCs('last4weeks',setLast4weeks)


    }, [props]);
return (<>
 <Helmet>
        <title>Info Calls SCA</title>
        <meta name="Info Calls SCA" content="Info Calls SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebarSCA/>
        


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>

<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patient_metrics">•patient metrics</HashLink>  
             <br/> 
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#info_calls">•info calls</HashLink>  
             <br/> 
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#info_callsW">•info calls, by week</HashLink>  
             <br/>             
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#info_callsM">•info calls, by month</HashLink>  
             <br/>  
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#per_completed">•appointments, proportion completed</HashLink>  
             <br/>
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#info2intake">•info calls to intakes</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#states">•info calls by state</HashLink>
<br/>

                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true} >
<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}>
                   <Row >
                   <Col style={{maxWidth: "auto"}}>
                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",color: "#000000"}}>{ptitleAb} <span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
                   </Col>
                   </Row>
</div>
</Row>
                   </Container>

                   <Container fluid={true} >
<Row>
<div className="articleVSB" style={{paddingTop: "6px", paddingBottom: "10px", maxWidth: "70%"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "2px",fontSize: "14px"}}>Definitions</div>

           <div style={{paddingTop: "0px",paddingLeft: "0px", maxWidth: "550px"}} >

<Row >
<Col>
<CmReportTableVS1stickyNoIndNoCols2 style={{padding: "0px"   }} tdfColor="#FFFFFF" tdPaddingLeft="5px"  tdPaddingRight="0px" tdFontSize="12px"  tableKey={"info_calls_expl"} />
</Col>
</Row>
</div>
</div>
</Row>
</Container>

<Container fluid={true}>
<Row>
<div className="articleVSB" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="patient_metrics" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Metrics</HashLink>

</Col>
</Row>

</div>
</Row>
</Container>


<Container fluid={true}  >
<Row>
<div className="articleVSB" style={{paddingTop: "10px", paddingBottom: "20px",maxWidth: "90%"}}>   
<Row>
<Col>
   <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total patients:<span style={{fontWeight: "750",paddingLeft: "245px"}}>{completed_intake}</span></div>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total patients with 2023 Intakes:<span style={{fontWeight: "750",paddingLeft: "76px"}}>{total_SC_patients_2023intakes}</span></div>

            <div className="TitleC" style={{fontSize: "12px", paddingRight: "4px",paddingTop: "8px"}} >active: patients completed an intake appointment and have an upcoming appointment scheduled. Intakes include OTC conversions.</div>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total active patients:<span style={{fontWeight: "750",paddingLeft: "187px"}}>{total_active_SC_patients}</span></div>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total active patients with 2023 Intakes:<span style={{fontWeight: "750",paddingLeft: "18px"}}>{total_active_SC_patients_2023intakes}</span></div>
         
            <div className="TitleC" style={{fontSize: "12px", paddingRight: "4px",paddingTop: "8px"}} >lost: patients completed an intake appointment and have no upcoming appointment scheduled.</div>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total lost patients:<span style={{fontWeight: "750",paddingLeft: "210px"}}>{total_lost_SC_patients}</span></div>
            <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "2px",paddingBottom: "3px",fontSize: "21px",lineHeight: "1"}}>total lost patients with 2023 Intakes:<span style={{fontWeight: "750",paddingLeft: "40px"}}>{total_lost_SC_patients_2023intakes}</span></div>

</Col>
</Row>
</div>
</Row>
</Container>







<Container fluid={true} >
<Row>
<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "10px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "15px"}}>
        <Row >
            <Col>
                <HashLink id="info_calls" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Info Calls</HashLink>
                
                <div style={{ borderTop: "1px solid #941751",paddingTop: "0px", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            </div> 
           <div style={{paddingTop: "5px",paddingLeft: "0px", maxWidth: "auto", width: "850px"}} >

<Row >
<Col>
<CmReportTableVS1stickyNoIndNoCols style={{padding: "0px"   }} tdfColor="#FFFFFF" tdPaddingLeft= "5px"  tdPaddingRight="18px" tdPaddingTop="4px"   tableKey={"info_calls_summ"} />
</Col>
</Row>
</div>
</div>
</Row>
</Container>

<Container fluid={true}>
<Row>
<div className="articleVS" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%", paddingTop: "10px"}}>
<Row>
<Col>
<HashLink id="info_callsW" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">Info Calls, by week</HashLink>

</Col>
</Row>
<div style={{paddingTop: "20px",paddingLeft: "0px",paddingBottom: "8px", maxWidth: "auto", width: "850px"}} >

<Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px", paddingBottom: "0px"}}>

<Col>

<CmReportTableVS1stickyNoIndFML style={{padding: "0px"}}  fColor="#FFFFFF"   thFontSize="10px" tdFontSize="10px" thPaddingRight="2px"  thPaddingLeft="2px"  tableKey={"info_calls_summWeeks"} /></Col>
</Row>

</div>
<div style={{paddingTop: "30px",paddingBottom: "20px", paddingRight: "0px",paddingLeft: "0px"}}>
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}     report={"info_occWk"} />
</Col>
</Row>
</div>
</div>

</Row>
</Container>



<Container fluid={true}  >

<Row>
<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "40px", maxWidth: "90%"}}>   

<div style={{paddingBottom: "15px"}}>
<Row>
<Col>
<HashLink id="info_callsM" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">Info Calls, by month</HashLink>

</Col>
</Row>

</div>
{/* <div className="article" style={{paddingTop: "20px", paddingBottom: "5px",maxWidth: "94%"}}>    */}
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}     report={"info_occMn"} />
</Col>

</Row>
</div>
</Row>


</Container>




<Container fluid={true} >

<Row>
<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "40px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "15px"}}>
        <Row>
            <Col>
                <HashLink id="per_completed" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Appointments</HashLink>
                
                <div style={{ borderTop: "1px solid #941751",paddingTop: "0px", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Proportion Completed 2023</div>

            </div> 
            <div  className="TitleC" style={{ fontSize: "13px",lineHeight: "1",paddingLeft: "10px",paddingBottom: "4px"}} ><span style={{color: "#945200"}}>Total3</span> == <span style={{color: "#FF0000"}}>No-Show</span> + <span style={{color: "#FFC000"}}>Cancelled</span> + <span style={{color: "#003EC0"}}>Occurred</span> <span style={{paddingLeft: "35px"}} ><span style={{color: "#7001A0"}}>Total2</span> == <span style={{color: "#FF0000"}}>No-Show</span> + <span style={{color: "#003EC0"}}>Occurred</span></span> </div>
<div  className="TitleC" style={{ fontSize: "13px",lineHeight: "1",paddingLeft: "10px"}} ><span style={{color: "#945200"}}>Proportion_Occurred3</span> == <span style={{color: "#003EC0"}}>Occurred</span>/ <span style={{color: "#945200"}}>Total3</span> <span style={{paddingLeft: "35px"}} ><span style={{color: "#7001A0"}}>Proportion_Occurred2</span> == <span style={{color: "#003EC0"}}>Occurred</span>/ <span style={{color: "#7001A0"}}>Total2</span></span> </div>

            <div className="TitleC" style={{paddingTop: "18px",paddingBottom: "5px",fontSize: "18px"}}>by category</div>
           
            <Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1noIndSpcM style={{padding: "0px"}}  fColor="#FFF9F3"  thFontSize="10px" tdFontSize="10px" thPaddingRight="3px"  thPaddingLeft="5px" tableKey={"prop_appt_occurred2023"} />

</Col>
</Row>


</div>
</Row>
</Container>





<Container fluid={true}>
<Row>
<div className="articleVS" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="info2intake" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Info Calls to Intakes</HashLink>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>{label00}</div>

</Col>
</Row>
<div style={{paddingTop: "5px",paddingLeft: "0px",paddingBottom: "8px", maxWidth: "auto", width: "850px"}} >

<Row >
<Col>
<div className="TitleC" style={{paddingLeft: "250px",paddingTop: "0px",paddingBottom: "1px",fontSize: "14px", letterSpacing: "4px"}}>Intake appointment statuses</div>

<CmReportTableVS1xxx style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"info_to_intakeI"} /></Col>
</Row>
</div>
</div>
</Row>
</Container>




<Container fluid={true}>
<Row>
<div className="articleVS" style={{paddingTop:"20px",paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
  
  <Row>
  <Col>
  
  <HashLink id="states" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Info Calls by State</HashLink>
  <div className="TitleC" style={{paddingTop: "10px",paddingBottom: "1px",fontSize: "20px"}}>Counts by State & Status, last 4 Weeks</div>
  <div className="TitleC" style={{paddingTop: "1px",paddingBottom: "0px",fontSize: "12px",color: "#000000"}}>{last4weeks}</div>
  <Row >
  <Col style={{ width: "auto",maxHeight:"300px", maxWidth: "450px"}}>
  <div >
  <CmReportTableVS1stickyHH style={{padding: "0px"}} fColor="#FFFFFF"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  bgColor="#40BBE3" thPaddingLeft="6px"  cPaddingTop="3px" cPaddingBottom="2px"  tableKey={"info_call_states_last4w"} />
  </div>
  </Col>
  </Row>
  
  {/* cPaddingBottom */}
  </Col>
  </Row>
  
  </div>
</Row>
</Container>

<Container fluid={true}>
<Row>
<div className="articleVS" style={{paddingTop:"0px",paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<div style={{paddingTop: "0px",paddingLeft: "0px",paddingBottom: "4px", maxWidth: "auto", width: "850px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "4px",fontSize: "20px"}}>Counts by Week, State, & Status</div>

<Row style={{overflow: "scroll", width: "auto",maxHeight:"300px", maxWidth: "650px"}}>
<Col>

<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFFFFF"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  bgColor="#660063" thPaddingLeft="6px"  tableKey={"info_call_states1"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "25px",paddingLeft: "0px",paddingBottom: "18px", maxWidth: "auto", width: "850px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Sign-up Rate by Week & State</div>
<Row style={{overflow: "scroll", width: "auto",maxHeight:"300px", maxWidth: "650px"}}>
<Col>


<CmReportTableVS1stickyHRfml style={{padding: "0px"}} fColor="#FFFFFF"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  bgColor="#40E0D0" thPaddingLeft="6px"  tableKey={"info_call_statesPerSU"} />
</Col>
</Row>
</div>

</div>
</Row>
</Container>


{/* info_call_statesPerSU CmReportTableVS1stickyHRfml*/}




</>);




};

export default SCyAd;