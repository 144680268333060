import React from "react";
import "../surveyAdherence.css";
// import { usePageName } from 'react-page-name';
    
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import BReportTable004B from "./BReportTable004B";
import CmReportTable004Bext from "./CmReportTable004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
// import TWexpl004B from "./TWexpl004B";
import { HashLink } from "react-router-hash-link";

import {Helmet} from "react-helmet";






const Bext004FIQRbyTherapyWeek = (props) => {
    // usePageName('FIQR-TW 004-2X');
 return (<>
 <Helmet>
        <title>FIQR-TW 004-2X</title>
        <meta name="FIQR-TW 004-2X" content="FIQR-TW 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores by Therapy Week, Cohort 2 Extension</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>
        {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "175px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container> */}

      

       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">ACT FIQR Scores</div>
            <CmReportTable004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fiqrTW_004Bext_ACT"}></CmReportTable004Bext>
                </div>
        </Container>
        {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="ST" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container> */}
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">ST FIQR Scores</div>
            <CmReportTable004BextST style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fiqrTW_004Bext_ST"}></CmReportTable004BextST>
                </div>
        </Container>


</>);

};

export default Bext004FIQRbyTherapyWeek;

// fthfghfg
