import React from "react";
import "./surveyAdherence.css";
import AdminNavbar from "../components/AdminNavbar";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import PROkeyTable from "./PROkeyTable";
import PROkeyTableXL from "./PROkeyTableXL";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";

import PROkeyTableXLB from "./PROkeyTableXLB";
import {Helmet} from "react-helmet";

// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const PROkey = (props) => {
  return (
    <>
 <Helmet>
		<title>PRO Reference</title>
		<meta name="PRO Reference" content="PRO Reference" />
	</Helmet>    
      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>PRO Reference</h1>
            </Col>
          </Row>
        </Container>
        <ScrollUpButton />
      </Jumbotron>
      

      <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div  style={{paddingBottom: "30px", paddingLeft: "0px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="ACT" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "0px" }} to="#quest">•PRO Questions</HashLink>
         <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "0px" }} to="#BDI">•BDI II Questions</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>

      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div  style={{paddingBottom: "40px"}}>
          <div className="TitleC"  style={{paddingBottom: "8px" }}>PROs</div>
          <PROkeyTable  tableKey={"pro_score_key"}></PROkeyTable>
        </div>
      </Container>

      <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="quest" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#bdi">jump to BDI II Questions</HashLink>
            </Col>
            </Row>
            </Container>

      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div >
          <div className="TitleC" style={{paddingBottom: "8px" }}>PRO Questions</div>
          <PROkeyTableXL fluid={true}  tableKey={"pro_question_key"}></PROkeyTableXL>
        </div>
      </Container>
      <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="bdi" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#quest">jump to PRO Questions</HashLink>
            </Col>
            </Row>
            </Container>
      <Container fluid={true} style={{ backgroundColor: "white" }}>
        <div >
          <div className="TitleC" style={{paddingBottom: "8px" }}>BDI II Questions</div>
          <PROkeyTableXLB fluid={true}  tableKey={"bdi_questions"}></PROkeyTableXLB>
        </div>
      </Container>


    </>
  );
};

export default PROkey;
// FFF9F3
