import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1stickyHnoInd from "./CmReportTableVS1stickyHnoInd";
import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1stickyHRfml  from "./CmReportTableVS1stickyHRfml";
import CmReportTableVS1stickynoIndWL  from "./CmReportTableVS1StickyNoIndWL"; 

import GraphTemplateVSA from "./GraphTemplateVSA";

import ReportsListSidebarSCA from "./ReportsListSidebarSCA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCresultsA = (props) => {
 
    const [updated, setUpdated] = useState([]);
    const [ptitleA, setPtitleA] = useState([]);
    const [meanFIQR_all_intakes, setMeanFIQR_all_intakes] = useState([]);
    const [meanFIQR_all_intakesN, setMeanFIQR_all_intakesN] = useState([]);
    const [meanFIQR_intakes_wsubsq_score, setMeanFIQR_intakes_wsubsq_score] = useState([]);
    const [meanFIQR_intakes_wsubsq_scoreN, setMeanFIQR_intakes_wsubsq_scoreN] = useState([]);
    const [meanPAIN_INTR_all_intakes, setMeanPAIN_INTR_all_intakes] = useState([]);
    const [meanPAIN_INTR_all_intakesN, setMeanPAIN_INTR_all_intakesN] = useState([]);
    const [meanPAIN_INTR_intakes_wsubsq_score, setMeanPAIN_INTR_intakes_wsubsq_score] = useState([]);
    const [meanPAIN_INTR_intakes_wsubsq_scoreN, setMeanPAIN_INTR_intakes_wsubsq_scoreN] = useState([]);
	const [meanCPAQ8_all_intakes, setMeanCPAQ8_all_intakes] = useState([]);
    const [meanCPAQ8_all_intakesN, setMeanCPAQ8_all_intakesN] = useState([]);
    const [meanCPAQ8_intakes_wsubsq_score, setMeanCPAQ8_intakes_wsubsq_score] = useState([]);
    const [meanCPAQ8_intakes_wsubsq_scoreN, setMeanCPAQ8_intakes_wsubsq_scoreN] = useState([]);
	const [meanphq2ANHED_all_intakes, setMeanphq2ANHED_all_intakes] = useState([]);
    const [meanphq2ANHED_all_intakesN, setMeanphq2ANHED_all_intakesN] = useState([]);
    const [meanphq2ANHED_intakes_wsubsq_score, setMeanphq2ANHED_intakes_wsubsq_score] = useState([]);
    const [meanphq2ANHED_intakes_wsubsq_scoreN, setMeanphq2ANHED_intakes_wsubsq_scoreN] = useState([]);
	const [meanphq2DEPRS_all_intakes, setMeanphq2DEPRS_all_intakes] = useState([]);
    const [meanphq2DEPRS_all_intakesN, setMeanphq2DEPRS_all_intakesN] = useState([]);
    const [meanphq2DEPRS_intakes_wsubsq_score, setMeanphq2DEPRS_intakes_wsubsq_score] = useState([]);
    const [meanphq2DEPRS_intakes_wsubsq_scoreN, setMeanphq2DEPRS_intakes_wsubsq_scoreN] = useState([]);
	const [meanphq2SlfHARM_all_intakes, setMeanphq2SlfHARM_all_intakes] = useState([]);
    const [meanphq2SlfHARM_all_intakesN, setMeanphq2SlfHARM_all_intakesN] = useState([]);
    const [meanphq2SlfHARM_intakes_wsubsq_score, setMeanphq2SlfHARM_intakes_wsubsq_score] = useState([]);
    const [meanphq2SlfHARM_intakes_wsubsq_scoreN, setMeanphq2SlfHARM_intakes_wsubsq_scoreN] = useState([]);


    


     

        


    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("epros");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
        getTitleCs('ptitleA',setPtitleA)
        getTitleCs('meanFIQR_all_intakes',setMeanFIQR_all_intakes)
        getTitleCs('meanFIQR_all_intakesN',setMeanFIQR_all_intakesN)
        getTitleCs('meanFIQR_intakes_wsubsq_score',setMeanFIQR_intakes_wsubsq_score)
        getTitleCs('meanFIQR_intakes_wsubsq_scoreN',setMeanFIQR_intakes_wsubsq_scoreN)
        getTitleCs('meanPAIN_INTR_all_intakes',setMeanPAIN_INTR_all_intakes)
        getTitleCs('meanPAIN_INTR_all_intakesN',setMeanPAIN_INTR_all_intakesN)
        getTitleCs('meanPAIN_INTR_intakes_wsubsq_score',setMeanPAIN_INTR_intakes_wsubsq_score)
        getTitleCs('meanPAIN_INTR_intakes_wsubsq_scoreN',setMeanPAIN_INTR_intakes_wsubsq_scoreN)
        getTitleCs('meanCPAQ8_all_intakes',setMeanCPAQ8_all_intakes)
        getTitleCs('meanCPAQ8_all_intakesN',setMeanCPAQ8_all_intakesN)
        getTitleCs('meanCPAQ8_intakes_wsubsq_score',setMeanCPAQ8_intakes_wsubsq_score)
        getTitleCs('meanCPAQ8_intakes_wsubsq_scoreN',setMeanCPAQ8_intakes_wsubsq_scoreN)
        getTitleCs('meanphq2ANHED_all_intakes',setMeanphq2ANHED_all_intakes)
        getTitleCs('meanphq2ANHED_all_intakesN',setMeanphq2ANHED_all_intakesN)
        getTitleCs('meanphq2ANHED_intakes_wsubsq_score',setMeanphq2ANHED_intakes_wsubsq_score)
        getTitleCs('meanphq2ANHED_intakes_wsubsq_scoreN',setMeanphq2ANHED_intakes_wsubsq_scoreN)
        getTitleCs('meanphq2DEPRS_all_intakes',setMeanphq2DEPRS_all_intakes)
        getTitleCs('meanphq2DEPRS_all_intakesN',setMeanphq2DEPRS_all_intakesN)
        getTitleCs('meanphq2DEPRS_intakes_wsubsq_score',setMeanphq2DEPRS_intakes_wsubsq_score)
        getTitleCs('meanphq2DEPRS_intakes_wsubsq_scoreN',setMeanphq2DEPRS_intakes_wsubsq_scoreN)
        getTitleCs('meanphq2SlfHARM_all_intakes',setMeanphq2SlfHARM_all_intakes)
        getTitleCs('meanphq2SlfHARM_all_intakesN',setMeanphq2SlfHARM_all_intakesN)
        getTitleCs('meanphq2SlfHARM_intakes_wsubsq_score',setMeanphq2SlfHARM_intakes_wsubsq_score)
        getTitleCs('meanphq2SlfHARM_intakes_wsubsq_scoreN',setMeanphq2SlfHARM_intakes_wsubsq_scoreN)

 

    }, [props]);
return (<>
 <Helmet>
        <title>Results SCA</title>
        <meta name="Results SCA" content="Results SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebarSCA/>
        


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="articleVSB" style={{ paddingRight: "0px",paddingBottom: "0px",width: "90%"}}>
           <Row>
               <Col>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#pgic">•pgic</HashLink>
<br/>
    <  HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#pgic_overall">•overall</HashLink>
    <br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#pgic_indiv">•by patient</HashLink>
    <br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#fiqr">•fiqr</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#cpaq8">•cpaq-8</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#pain_intr">•pain interference</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#phq2pA">•phq2+ anhedonia</HashLink>
<br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pA_overall">•overall</HashLink>
    <br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pA_indiv">•by patient</HashLink>
    <br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#phq2pB">•phq2+ depression</HashLink>
<br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pB_overall">•overall</HashLink>
    <br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pB_indiv">•by patient</HashLink>
    <br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#phq2pC">•phq2+ self-harm</HashLink>
<br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pC_overall">•overall</HashLink>
    <br/>
    <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "13px", paddingLeft: "35px" }} to="#phq2pC_indiv">•by patient</HashLink>
    <br/>
{/* phq2pA_indiv */}



                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true}>
                   <div className="articleVSB" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{ptitleA} <span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
</div>
                   </Container>

      


<Container fluid={true} >

<div className="articleVSB" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>
        <Row>
            <Col>
                <HashLink id="pgic" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">PGIC</HashLink>
                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>
            </Col>
            </Row>
            <HashLink id="pgic_overall" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px" }} to="#top">Patient’s Global Impression Of Change Scale - Overall</HashLink>

            </div> 

    <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "10px",paddingBottom: "10px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"pgicSC"} />

</Col>
</Row>

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "900px",paddingBottom: "5px"}} >
<Row style={{ width: "850px",maxWidth: "90%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickynoIndWL style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="9px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#000080" tableKey={"pgic_tableYAB"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "10px",paddingLeft: "110px", maxWidth: "900px",paddingBottom: "20px"}} >
<Row style={{ width: "850px",maxWidth: "90%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickynoIndWL style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="9px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#006FCB" tableKey={"pgic_tableYA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "700px", paddingBottom: "50px"}} >
<Row style={{overflowY: "scroll", width: "auto",maxWidth: "90%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="9px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#00AEE5" tableKey={"pgic_tableSummA"} />
</Col>
</Row>
</div>
<Row >
    <Col>
    <HashLink id="pgic_indiv" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px", paddingTop: "40px" }} to="#top">Patient’s Global Impression Of Change Scale -  by patient</HashLink>

    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"pgicBSC"} />
  

</Col>
</Row>



<div style={{paddingTop: "15px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0034C0" tableKey={"pgic_tableA"} />
</Col>
</Row>
</div>

<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"pgic_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>


<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "60px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                {/* <HashLink id="fiqr" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">to top</HashLink> */}
                <HashLink id="fiqr" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">FIQR</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Fibromyalgia Impact Questionnaire - Revised</div>

            </div> 

            <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}

    <GraphTemplateVSA style={{padding: "0px"}} report={"fiqrSC"} />

</Col>
</Row>




<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanFIQR_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean FIQR score = {meanFIQR_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanFIQR_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean FIQR score = {meanFIQR_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#123A63" tableKey={"fiqrSumm_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#8C8785" tableKey={"fiqr_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"fiqr_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>

<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "60px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="cpaq8" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">CPAQ-8</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Chronic Pain Acceptance Questionnaire - 8</div>

            </div> 

    <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"cpaq8SC"} />

</Col>
</Row>

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanCPAQ8_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean Intake score = {meanCPAQ8_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanCPAQ8_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean Intake score = {meanCPAQ8_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>

<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#662D91" tableKey={"cpaq8Summ_tableA"} />
</Col>
</Row>
</div>



<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#A5A3D0" tableKey={"cpaq8_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"cpaq8_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>

<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "60px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="pain_intr" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Pain Interference</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Pain Interference Scale</div>

            </div> 

    <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"pain_intrSC"} />

</Col>
</Row>

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanPAIN_INTR_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean Intake score = {meanPAIN_INTR_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanPAIN_INTR_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean Intake score = {meanPAIN_INTR_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#D80000" tableKey={"pain_intrSumm_tableA"} />
</Col>
</Row>
</div>



<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#FF8733" tableKey={"pain_intr_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"pain_intr_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>
<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "60px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="phq2pA" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">PHQ2+ Anhedonia</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Patient Health Questionnaire - Anhedonia</div>
            <HashLink id="phq2pA_overall" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px" }} to="#top">Overall</HashLink>

            </div> 
<Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_anhedonia2"} />

</Col>
</Row>

<div style={{paddingBottom: "20px"}}>
<Row >
    <Col>
    <HashLink id="phq2pA_indiv" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px", paddingTop: "40px" }} to="#top">PHQ Anhedonia - by patient</HashLink>

{/* <div className="TitleC" style={{paddingTop: "10px",paddingBottom: "10px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_anhedonia"} />

</Col>
</Row>
</div>
  

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanphq2ANHED_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean Intake score = {meanphq2ANHED_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanphq2ANHED_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean Intake score = {meanphq2ANHED_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#375623" tableKey={"phq2anhedSumm_tableA"} />
</Col>
</Row>
</div>




<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#376823" tableKey={"phq2anhedonia_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"phq2anhedonia_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>


<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "30px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="phq2pB" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">PHQ2+ Depression</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Patient Health Questionnaire - Depression</div>
            <HashLink id="phq2pB_overall" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px" }} to="#top">Overall</HashLink>
            </div> 
            <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_depression2"} />

</Col>
</Row>

    <Row >
    <Col>
    <HashLink id="phq2pB_indiv" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px", paddingTop: "40px" }} to="#top">PHQ Depression - by patient</HashLink>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_depression"} />

</Col>
</Row>

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanphq2DEPRS_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean Intake score = {meanphq2DEPRS_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanphq2DEPRS_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean Intake score = {meanphq2DEPRS_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#92706F" tableKey={"phq2deprsSumm_tableA"} />
</Col>
</Row>
</div>




<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#AE8785" tableKey={"phq2depression_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"phq2depression_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>
<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "30px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="phq2pC" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">PHQ2+ Self-Harm</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Patient Health Questionnaire - Self-Harm</div>
                      <HashLink id="phq2pC_overall" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px" }} to="#top">Overall</HashLink>

            </div> 
            <Row >
    <Col>
    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_self_harm2"} />

</Col>
</Row>

    <Row >
    <Col>
    <HashLink id="phq2pC_indiv" className="TitleC" style={{ paddingBottom: "0px", fontSize: "18px", paddingLeft: "0px", paddingTop: "40px" }} to="#top">PHQ Self-Harm - by patient</HashLink>

    {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px"}}>original cadence</div> */}
    <GraphTemplateVSA style={{padding: "0px"}} report={"phq2p_self_harm"} />

</Col>
</Row>

<div style={{paddingTop: "25px",paddingLeft: "110px", maxWidth: "750px"}} >

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Intakes<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "142px",lineHeight: "1"}}>N = {meanphq2SlfHARM_all_intakesN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>mean Intake score = {meanphq2SlfHARM_all_intakes}</span> </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>Intakes with ≥1 Subsequent Score<span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "20px",lineHeight: "1"}}>N = {meanphq2SlfHARM_intakes_wsubsq_scoreN}</span><span style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "12px",color:"#18BC9C",paddingLeft: "27px",lineHeight: "1"}}>mean Intake score = {meanphq2SlfHARM_intakes_wsubsq_score}</span> </div>



<Row style={{width: "auto",maxWidth: "65%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#B80079" tableKey={"phq2slfharmSumm_tableA"} />
</Col>
</Row>
</div>



<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#FF2F92" tableKey={"phq2self_harm_tableA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "5px",paddingLeft: "110px", maxWidth: "780px"}} >
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>BCBS TX</div>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "98%", height: "auto",maxHeight: "220px"}}>
<Col>
<CmReportTableVS1stickyHRfml  style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#0D0887" tableKey={"phq2self_harm_tableA_BCBS"} />
</Col>
</Row>
</div>
    </div>
</Container>










</>);




};

export default SCresultsA;
