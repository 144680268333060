import React, { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
import AdminNavbar from "../components/AdminNavbar";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
// import PROkeyTable from "../reports/PROkeyTable";
// import PROkeyTableXS from "../reports/PROkeyTableXS";
import "../reports/surveyAdherence.css";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

import PokeListOptum from "./PokeListOptum";

// PokeListOptum
import ScrollUpButton from "react-scroll-up-button";

const OptumPokeList = () => {

  return (
    <>
      <Helmet>
        <title>Southwest Medical Poke List</title>
        <meta name="Southwest Medical Poke List" content="Optum Poke List" />
      </Helmet>
      <AdminNavbar />
      <Jumbotron>
      <ScrollUpButton />

        <h1 className="pokelist-header">Southwest Medical Poke List</h1>


        {/* <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "20px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{  fontSize: "14px" }} to="#NotificationChecks">• jump to Notification Check</HashLink>
            <br/>
 
        <HashLink id="top" className="TitleDB" style={{  fontSize: "14px" }} to="#003">• jump to 003</HashLink>

       
  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "0px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container> */}





      </Jumbotron>
 
      
 
 
 
      <Container fluid={true} style={{ backgroundColor: "white", marginBottom: "3rem" }}>
        <Col xs={8}>
        <PokeListOptum/>
            <div style={{paddingBottom: "40px"}}></div>
           
            
     

      
         
       
        
        </Col>
      </Container>

  
     
    
    
    
    </>
  );
};

export { OptumPokeList };
