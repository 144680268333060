import React,{ useState, useEffect,Component }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import CmReportTableVS1stickyHnoInd from "./CmReportTableVS1stickyHnoInd";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1noIndWL from "./CmReportTableVS1noIndWL";
import CmReportTableVS1sticky from "./CmReportTableVS1sticky";


import ReportsListSidebarSCA from "./ReportsListSidebarSCA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";




const SCInsightsA = (props) => {
        const [caveat, setCaveat] = useState([]);
    
      
    
        const getTitleCs = async (titleC,setter) => { 
            const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("dash");
            const doc = await dashes.get();
            const docData=doc.data()
            const titC = docData[titleC];
                console.log(titC)
                setter(titC);
           }
    
           useEffect(() => {  
            getTitleCs('caveat',setCaveat)
            
    
        }, [props]);


return (<>


 <Helmet>
        <title>Insights SCA</title>
        <meta name="Insights SCA" content="Insights SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        

       

      
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#insightsComp">•Insights Completion</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#insightScores">•Insight Scores</HashLink>
               <br/>
          
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true}>
                   <div className="article" style={{paddingLeft: "0px", paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>Insights<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {caveat}</span></div>
</div>
                   </Container>
       

<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "30px", paddingBottom: "10px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>
        <Row>
            <Col>
                <HashLink id="insightsComp" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Insight Completion</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
          </div>
  </div>

  </Row>

</Container>
<Container fluid={true}>
<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<Row>
           <div >
            <Row style={{overflowY: "scroll", width: "auto",maxHeight:"400px",maxWidth: "95%"}}>

    <Col style={{paddingLeft: "40px"}}>
   
<CmReportTableVS1stickyH style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#9FC5D4" tableKey={"compInsightsA"} />

</Col>
</Row>
</div>

</Row>
</div>
</Row>
</Container>


<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "30px", paddingBottom: "10px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>
        <Row>
            <Col>
                <HashLink id="insightScores" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Insight Scores</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
          </div>
  </div>

  </Row>

</Container>


<Container fluid={true}>
<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<Row>
           <div >
            <Row style={{overflow: "scroll", width: "auto",maxHeight:"400px",maxWidth: "75%"}}>

    <Col style={{paddingLeft: "40px"}}>
   
<CmReportTableVS1stickyH style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF2F92" tableKey={"insightScoresA"} />

</Col>
</Row>
</div>

</Row>
</div>
</Row>
</Container>

</>);

};

export default SCInsightsA;