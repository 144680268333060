import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";
import CmReportTable003Ax3  from "./CmReportTable003Ax3";
import { HashLink } from "react-router-hash-link";

import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import  ApplicantGroups003A from "./ApplicantGroups003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import {Helmet} from "react-helmet";

import GraphTemplate003A from "./GraphTemplate003A"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref";

const A003patientPreference = (props) => {
  return (<>
   <Helmet>
     <title>Patient Preferences 003A</title>
     <meta name="Patient Preferences 003A" content="Patient Preferences 003A" />
   </Helmet>
         <AdminNavbar/>
         <Jumbotron>
             <Container fluid={true} style={{paddingBottom: "30px"}}>
                 <Row>
                     <Col>
                         <h1>Patient Preferences, Cohort 003A</h1>
 
                     </Col>
                 </Row>
             </Container>
         </Jumbotron>
         <AnotherReportsListSidebar003A/>
         <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>

           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#week12">•skip to week 12</HashLink>
{/* <br/> */}
{/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#plots">•skip to plots</HashLink> */}

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "0px",paddingBottom: "0px"}}>
       <ApplicantGroups003A/>
        <Row style={{paddingBottom:"8px"}}>
            {/* <Col md="auto" style={{paddingRight:"0px"}}> */}
               <Col xs={10}>
               <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "5px",paddingTop: "0px",lineHeight: "1"}} >Patient Preferences, week 6</div>

            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "2px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• how_valuable, 1 - 7:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;How valuable was your experience using Tempo so far?</div>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "5px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• meets_requirements, 1 - 7:&nbsp;&nbsp;&nbsp;&nbsp;The Tempo app's capabilities meet my requirements.</div>


              <CmReportTable003Ax2 bgColor="#F55C48" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"patient_pref_week6_003A"} />
            </Col>
           

          </Row>
          </Container>
       
     


        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px", paddingBottom: "20px"}}>
        <Row style={{paddingBottom:"0px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "35px",paddingBottom: "0px",paddingTop: "0px"}} >Patient Preferences, week 6</div>
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"patient_pref_week6_003A"} />
            </Col>
          </Row>
          </Container>
    

          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "40px"}}>
        <Row style={{paddingBottom:"0px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "35px",paddingBottom: "0px",paddingTop: "0px"}} >Patient Preferences, week 6 v week 12</div>
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"patient_pref_w6w12_003A"} />
            </Col>
          </Row>
          </Container>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "20px",paddingLeft: "0px" }}>

          <div className="article" style={{paddingBottom: "10px", paddingLeft: "0px", paddingRight: "0px",paddingTop: "20px"}}>

        <Row>
            <Col>

                <HashLink id="week12" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
         
            </div>
               </Container>
          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "10px",paddingBottom: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
               <Col xs={10}>
               <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "5px",paddingTop: "0px",lineHeight: "1"}} >Patient Preferences, week 12</div>

               <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "5px",paddingTop: "0px",lineHeight: "1",fontSize: "25px"}} >Slider Questions</div>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "2px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• how_valuable, 1 - 7:&nbsp;&nbsp;&nbsp;&nbsp;How valuable was your experience using Tempo?</div>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "5px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• meets_requirements, 1 - 7:&nbsp;&nbsp;&nbsp;&nbsp;The Tempo app's capabilities meet my requirements.</div>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "2px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• how_useful, 1 - 7:&nbsp;&nbsp;&nbsp;&nbsp;How useful is the Tempo app?</div>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "5px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• how_val_smartphone, 1 - 10:&nbsp;&nbsp;&nbsp;&nbsp;How valuable was it to be able to use Tempo anytime or anywhere on your smartphone?</div>

            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "5px",paddingTop: "0px",fontSize: "13px",lineHeight: "1",color: "#747373"}} >• likely_recommend, 1 - 10:&nbsp;&nbsp;&nbsp;&nbsp;How likely are you to recommend Tempo to a friend or acquaintance with fibromyalgia?</div>


              <CmReportTable003Ax2 bgColor="#9E005D" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"patient_prefSliders_003A"} />
            </Col>
           

          </Row>
          </Container>
 
          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "10px",paddingBottom: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
               <Col xs={10}>
               <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "5px",paddingTop: "0px",lineHeight: "1",fontSize: "25px"}} >Y/N Questions</div>
 
          <CmReportTable003Ax3 bgColor="#17BAFF"  text_align="left" style={{  paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"patient_pref_set2_003A"} />
          </Col>
           

           </Row>
           </Container>

           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "10px",paddingBottom: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
               <Col xs={10}>
               <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "5px",paddingTop: "0px",lineHeight: "1",fontSize: "25px"}} >What types of issues did you encounter while using Tempo?</div>
 
          <CmReportTable003Ax2 bgColor="#F32E51"  text_align="left" style={{  paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"patient_pref_p000247_003A"} />
          </Col>
           

           </Row>
           </Container>

           {/* What types of issues did you encounter while using Tempo? */}

</>);

};

export default A003patientPreference;



{/* <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop: "10px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
        <Row>
            <Col>
               <HashLink id="plots" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
               <br/>
               <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#comments">skip to comments</HashLink>

            </Col>
            </Row>
            </Container>
            </div> */}
