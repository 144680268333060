import React, {useEffect, useState} from "react";
import "./surveyAdherence.css";
import {firestore} from "../utils/firebase";
import {Col, Table} from "react-bootstrap";

const PROkeyTableXLC = (props) => {
    const {tableKey} = props;
    const [tableDoc, setTableDoc] = useState();
    const [name, setName] = useState("");
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        firestore
            .doc("analytics/reports/reportTables005/" + tableKey)
            .get()
            .then((doc) => {
                const data = doc.data();
                setColumns(data.columns);
                setName(data.name);
                setTableDoc(doc);
                const path = data.collectionPath ? data.collectionPath : "analytics/reports/reportTables005/" + tableKey + "/rows";
                let query = data ? firestore
                                     .collection(path)
                    .orderBy(data.orderBy.field, data.orderBy.direction)
                                 : firestore.collection(path);
                query.onSnapshot((snap) => {
                    setRows(snap.docs);
                });
            });
    }, [tableKey]);

    const getTableRow = (progRef) => {
        let data = progRef.data();

        let cols = columns.map((c) => {
            let val = data[c];
            if (typeof data[c] === "object") {
                try {
                    // val = data[c].toDate().toLocaleDateString();
                    val = data[c].toDate().toISOString().slice(0,10);
                } catch (error) {
                    console.log('couldnt format something', {data, error})
                }
            }
            return <td fluid={true} style={{  fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px",padding: "15px"}}>{val}</td>;
        });

        return (<tr key={progRef.id} onClick={() => {
            }}>
                {cols}
            </tr>);
    };
    if (!(columns && rows)) {
        return <> </>;
    }
    return (<>

            <Col className={"reports_table"} fluid={true} xs={12}>
                {/* <Table fluid={true} style={{backgroundColor: "white", width: "87%", borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px", padding: "11px"}}> */}
                <Table fluid={true} style={{backgroundColor: "white", width: "auto", paddingLeft: "0px", paddingRight: "0px", borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px"}}>

                    <thead key={"tableHead" + (tableDoc ? tableDoc.id : "")}>
                    <tr fluid={true} style={{borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px",padding: "8px"}} key={"headerRow"}>
                        {columns.map((c) => {
                            return <th style={{lineHeight: "1.1", backgroundColor: "#FFF9F3", fontSize: "18px"}} key={c.id}>{c}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody className={"twoArm_tableB"} style={{ lineHeight: "1.1",fontSize: "16px"}}>{rows.map((u) => getTableRow(u))}</tbody>
                </Table>
            </Col>

        </>);
};

export default PROkeyTableXLC;
