import React from "react";
import GraphTemplateEPROsB from "../../graphs/GraphTemplateEPROsB";
// import GraphTemplateEPROsA from "./src/graphs/GraphTemplateEPROsA";

// import GraphTemplateEPROsA from "../../graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

import AdminNavbar from "../../components/AdminNavbar";import {Col, Container, Row} from "react-bootstrap";

const B004CFQgraphs = () => {
  return (
    <>
      <AdminNavbar />
      <AnotherReportsListSidebar004B/>
      <Container fluid={true} >
        <div className="mt-4">
        <div className="article">
          <div className="TitleC">Cognitive Fusion Questionnaire</div>
          <p className="TitleDB" style={{paddingTop: "5px"}} >cfq goal: -</p>
          
          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "25px"}}>ACT Daily</div>
              <GraphTemplateEPROsB report={"ACT_cfq004Bdaily"} />
            </Col>
          </Row>
        
 <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ACT Weekly</div>
              <GraphTemplateEPROsB report={"ACT_cfq004Bweekly"} />
            </Col>
          </Row>

          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ST</div>
              <GraphTemplateEPROsB report={"ST_cfq004B"} />
            </Col>
          </Row>

          

          </div>
        </div>
      </Container>
    </>
  );
};

export default B004CFQgraphs;

