import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";

import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";

import GraphTemplate003A from "./GraphTemplate003A"; 



import { HashLink } from "react-router-hash-link";


const A003consort = (props) => {

    const [data_retrieved, setData_retrieved] = useState([]);
	const [total, setTotal] = useState([]);
    const [row1_screened, setRow1_screened] = useState([]);
	const [row1_screening, setRow1_screening] = useState([]);
	const [row1_screening_abandoned, setRow1_screening_abandoned] = useState([]);
	const [row2_screenfail, setRow2_screenfail] = useState([]);
	const [row2_enrolled, setRow2_enrolled] = useState([]);
	const [row3_pre_therapy, setRow3_pre_therapy] = useState([]);
	const [row3_started_therapy, setRow3_started_therapy] = useState([]);
	const [row3_abandoned_before_therapy, setRow3_abandoned_before_therapy] = useState([]);
	const [row4_therapy, setRow4_therapy] = useState([]);
	const [row4_left_therapy, setRow4_left_therapy] = useState([]);
	const [row4_finished_therapy, setRow4_finished_therapy] = useState([]);
    const [consent_abandoned, setConsent_abandoned] = useState([]);
	const [baseline_abandoned, setBaseline_abandoned] = useState([]);
	const [never_started, setNever_started] = useState([]);
	const [extension_accepted, setExtension_accepted] = useState([]);
	const [extension_declined, setExtension_declined] = useState([]);
const [extension_started,setExtension_started] = useState([]);
const [extension_finished,setExtension_finished] = useState([]);


    const getConsortData = async (cell,setter) => { 
        const cells = firestore.collection("analytics").doc("A003").collection("consort").doc("consortA1");
        const doc = await cells.get();
        const docData=doc.data()
        const consortCell = docData[cell];
        console.log(consortCell)
        setter(consortCell);
   }

 

  useEffect(() => {
	getConsortData('total',setTotal)
    getConsortData('row1_screened',setRow1_screened)
	getConsortData('row1_screening',setRow1_screening)
	getConsortData('row1_screening_abandoned',setRow1_screening_abandoned)
	getConsortData('row2_screenfail',setRow2_screenfail)
	getConsortData('row2_enrolled',setRow2_enrolled)
	getConsortData('row3_pre_therapy',setRow3_pre_therapy)
	getConsortData('row3_started_therapy',setRow3_started_therapy)
	getConsortData('row3_abandoned_before_therapy',setRow3_abandoned_before_therapy)
	getConsortData('row4_therapy',setRow4_therapy)
	getConsortData('row4_left_therapy',setRow4_left_therapy)
	getConsortData('row4_finished_therapy',setRow4_finished_therapy)
	getConsortData('consent_abandoned',setConsent_abandoned)
	getConsortData('baseline_abandoned',setBaseline_abandoned)
	getConsortData('never_started',setNever_started)
	getConsortData('extension_accepted',setExtension_accepted)
    getConsortData('extension_declined',setExtension_declined)
    getConsortData('extension_started',setExtension_started)
    getConsortData('extension_finished',setExtension_finished)

    
    // extension_started,setExtension_started
}, [props]);


 return (<>
 <Helmet>
        <title>Consort 003A</title>
        <meta name="Consort 003A" content="Consort 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Consorts 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
<AnotherReportsListSidebar003A/> 

<Container fluid={true}>

<div className="article" style={{paddingTop: "20px", paddingBottom: "60px"}}> 




<table style={{width: "1000px"}} >

                <tr >
                <td colSpan={4} style={{width: "100px", height:"25px", backgroundColor:"#DA36DB",color: "#FFFFFF",fontSize: "30px",textAlign: "center",border: "1px solid white"}} >{row1_screened}</td>
                <td colSpan={2} style={{width: "40px", height:"25px", backgroundColor:"#4B216B",color: "#FFFFFF",fontSize: "12px",textAlign: "center",border: "1px solid white"}} >{row1_screening_abandoned}</td>

                <td colSpan={1} style={{ width: "100px",height:"25px", backgroundColor:"#1C5895",color: "#FFFFFF",fontSize: "12px",textAlign: "center",border: "1px solid white"}} >{row1_screening}</td>

                {/* <td  colSpan={1}  ></td>  */}

                </tr> 
                <tr style={{height: "20px"}}></tr>
              

                <tr >
                <td colSpan={3} style={{width: "500px",height:"25px", backgroundColor:"#0F8793",color: "#FFFFFF",fontSize: "15px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row2_enrolled}</td>

                <td colSpan={3} style={{width: "200px",height:"25px", backgroundColor:"#DF270D",color: "#FFFFFF",fontSize: "15px",textAlign: "center", padding:"0px",border: "1px solid white"}} > {row2_screenfail}</td>
                {/* <td  colSpan={1}  ></td>  */}
                {/* <td  colSpan={1}  ></td>  */}



             
                             </tr> 
                <tr style={{height: "20px"}}></tr>
                <tr >
                <td  colSpan={2} style={{width: "80px",height:"25px", backgroundColor:"#4491F6",color: "#FFFFFF",fontSize: "14px",textAlign: "center", padding:"0px",border: "1px solid white"}} > {row3_started_therapy}</td>
                <td  colSpan={1} style={{width: "80px",height:"25px", backgroundColor:"#FF4D00",color: "#FFFFFF",fontSize: "11px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row3_abandoned_before_therapy}</td>

                <td  colSpan={1} style={{width: "80px",height:"25px", backgroundColor:"#947878",color: "#FFFFFF",fontSize: "11px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row3_pre_therapy}</td>

                {/* <td  colSpan={2}  ></td>  */}

                            </tr>  
                            
                {/* <tr style={{height: "20px"}}></tr>
                <tr >
                <td   style={{width: "140px",height:"25px", backgroundColor:"#123A63",color: "#FFFFFF",fontSize: "13px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row4_finished_therapy}</td>

                <td  style={{width: "140px",height:"25px", backgroundColor:"#17BECF",color: "#FFFFFF",fontSize: "13px",textAlign: "center", padding:"0px",border: "1px solid white"}} > {row4_therapy}</td>
                <td   style={{width: "140px",height:"25px", backgroundColor:"#951B09",color: "#FFFFFF",fontSize: "13px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row4_left_therapy}*</td>
                             </tr>   */}

                             <tr style={{height: "20px"}}></tr>
                <tr >
                <td  colSpan={2} style={{width: "20px",height:"25px", backgroundColor:"#123A63",color: "#FFFFFF",fontSize: "12px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row4_finished_therapy}</td>

                <td colSpan={2} style={{width: "25px",height:"25px", backgroundColor:"#17BECF",color: "#FFFFFF",fontSize: "11px",textAlign: "center", padding:"0px",border: "1px solid white"}} > {row4_therapy}</td>
                <td colSpan={1}  style={{width: "35px",height:"25px", backgroundColor:"#951B09",color: "#FFFFFF",fontSize: "11px",textAlign: "center", padding:"0px",border: "1px solid white"}} >{row4_left_therapy}*</td>
                {/* <td  colSpan={2}  ></td>  */}
                {/* <td  colSpan={1}  ></td>  */}
                {/* <td  colSpan={1}  ></td>  */}

                             </tr> 


                             <tr style={{height: "20px",paddingBottom: "0px"}}></tr>
                            <tr >
                            <td colSpan={1}  style={{width: "80px",height:"25px", backgroundColor:"#00CC96",color: "#FFFFFF",fontSize: "12px",textAlign: "center", padding:"0px",border: "1px solid white"}} >extension_accepted: {extension_accepted}</td>
                            <td colSpan={1} style={{width: "80px",height:"25px", backgroundColor:"#FF7F5A",color: "#FFFFFF",fontSize: "12px",textAlign: "center", padding:"0px",border: "1px solid white"}} >extension_declined: {extension_declined}</td> 
                            <td  colSpan={4}  ></td> 
                                         </tr> 
                
                                         {/* <tr style={{height: "20px",paddingTop: "0px"}}></tr> */}
                            <tr >
                            <td colSpan={1}  style={{width: "80px",height:"25px", backgroundColor:"#A66BD1",color: "#FFFFFF",fontSize: "12px",textAlign: "center", padding:"0px",border: "1px solid white"}} >extension_started: {extension_started}</td>
                            <td  colSpan={5}  ></td> 
                                         </tr> 
                                         <tr >
                            <td colSpan={1}  style={{width: "80px",height:"25px", backgroundColor:"#570054",color: "#FFFFFF",fontSize: "12px",textAlign: "center", padding:"0px",border: "1px solid white"}} >extension_finished: {extension_finished}</td>
                            <td  colSpan={5}  ></td> 
                                         </tr> 

                                         {/* 570054 */}
                    </table> 

</div>
</Container>





<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "0px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px",paddingTop: "40px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", paddingLeft: "80px"}}>
            <div style={{paddingBottom: "0px",letterSpacing: "4px", backgroundColor:"#00D000",color: "#FFFFFF", fontSize: "18px", maxWidth: "650px", paddingLeft: "70px"}} >total = {total}</div>

            </div>
        
            <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"consortA"} />
        </div>
        </Container>




</>);

};

export default A003consort;


