
import React,{ useState, useEffect }  from "react";
import {firestore} from "../utils/firebase";

import "./surveyAdherence.css";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import PROkeyTableXSBB from "./PROkeyTableXSBB";
import PROkeyTableXSBBwh7 from "./PROkeyTableXSBBwh7";

const GAD7explanation = (props) => {
return(<>

            <PROkeyTableXSBBwh7  style={{paddingBottom:"0px",paddingTop: "20px", paddingRight: "40px", paddingLeft: "0px"}} tableKey={"gad7_interp_key"} />

    </>);

};
export default GAD7explanation;

