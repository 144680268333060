import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A22F from "./CmReportTable005A22F";

import CmReportTable005A4f from "./CmReportTable005A4f";

import CmReportTable005A2Ind from "./CmReportTable005A2Ind";
import CmReportTable005A3Ind from "./CmReportTable005A3Ind";

import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A2S from "./CmReportTable005A2S";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";

const A005Enrollment = (props) => {




 return (<>
 <Helmet>
        <title>Enrollment 005A</title>
        <meta name="Enrollment 005A" content="Enrollment 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Enrollment 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#bySite">•counts by site</HashLink>
           

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "20px", width: "auto"}}> 

<Row >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Screening & Enrollment</div>
</Row>
<Row style={{paddingTop: "0px"}}>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>by Month</div>
</Row>
<div style={{width: "600px"}}>
<Row>
<CmReportTable005A22F  style={{padding: "0px"}} thFontSize="16px" tdFontSize="16px" bgColor="#2475C7" tableKey={"monthlyEnrollment005A"} />
</Row>
</div>


</div>
</Container>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "45px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "15px"}}>
        <Row>
            <Col>
            <HashLink id="bySite" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            {/* <br/> */}
                        {/* <HashLink id="closedE" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#intreat">jump to in-treatment EPRO completion</HashLink>   */}
            </Col>
            </Row>
            </div>
            </Container>
            <Container fluid={true} style={{ paddingBottom: "20px", paddingTop: "45px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "10px", width: "auto"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Screening & Enrollment by Site</div>

<GraphTemplate005A style={{padding: "0px"}} report={"enrollmentQmonth005A"} />

</div>


<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", width: "auto"}}> 

{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Enrollment by Site</div> */}

<GraphTemplate005A style={{padding: "0px"}} report={"enrolledQmonth005A"} />

</div>
</Container>

{/* <Row></Row> */}

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", width: "75%", lineHeight: "1"}}> 
<Row>

{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Screening & Enrollment by Site</div> */}

{/* <Row style={{paddingTop: "0px"}}> */}

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px", paddingBottom:"9px"}}>Currently Screening</div>

<CmReportTable005A22F  style={{padding: "10px", lineHeight: "1"}} thPaddingRight="3px" thPaddingLeft="6px" thFontSize="15px" tdFontSize="13px" bgColor="#A66BD1" tableKey={"currentScreensSite005A"} />

{/* </Row> */}

</Row>
</div>
</Container>
<div></div>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "0px", width: "75%", lineHeight: "1"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Screening & Enrollment by Site, Status</div>

<Row>
{/* <Col style={{paddingRight: "20px",paddingLeft: "0px"}}> */}
<div className="TitleC" style={{padding: "0px",fontSize: "25px",paddingRight: "20px", paddingBottom:"9px", paddingTop:"9px"}}>Screened</div>
<CmReportTable005A22F  style={{paddingLeft: "0px",paddingRight: "0px"}} thPaddingRight="5px" thPaddingLeft="6px" thFontSize="15px" tdFontSize="13px" bgColor="#F55C48" tableKey={"monthlyEnrollmentBySiteScreens005A"} />

{/* </Col> */}


{/* <Col style={{paddingLeft: "20px"}}> */}
<div className="TitleC" style={{padding: "0px",fontSize: "25px",paddingLeft: "0px", paddingBottom:"9px"}}>Enrolled</div>
<CmReportTable005A22F  style={{paddingLeft: "0px",paddingRight: "0px"}} thFontSize="15px" tdFontSize="13px" bgColor="#17BAFF" tableKey={"monthlyEnrollmentBySiteEnrolls005A"} />


<div className="TitleC" style={{padding: "0px",fontSize: "25px",paddingLeft: "0px", paddingBottom:"9px"}}>Total</div>
<CmReportTable005A22F  style={{paddingLeft: "0px",paddingRight: "0px"}} thPaddingRight="0px" thPaddingLeft="6px" thFontSize="15px" tdFontSize="13px" bgColor="#FF2BAA" tableKey={"monthlyEnrollmentBySiteTotal005A"} />

{/* </Col> */}
</Row>
</div>
</Container>

</>);

};

export default A005Enrollment;

// {/* <Col> */} <Col></Col>
// {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>by Month & Participant Status</div> */}

// {/* <CmReportTable005A3Ind  style={{paddingTop: "0px"}} thFontSize="14px" tdFontSize="14px" bgColor="#A66BD1" tableKey={"monthlyEnrollmentByStatusSite005A"} /> */}
// {/* </Col> CurrentScreensSite005A
// // </Row>
// <Row style={{paddingTop: "20px"}}>
// <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>by Month & Participant Status</div>

// <CmReportTable005A3Ind  style={{paddingTop: "0px"}} thFontSize="16px" tdFontSize="16px" bgColor="#A66BD1" tableKey={"monthlyEnrollmentByStatusSite005A"} />
// </Row> */}