

import { MapContainer, TileLayer,Marker,CircleMarker,Circle,FeatureGroup,Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import  "../../surveyAdherence.css";
import TopojKYTN from "./topojKYTN";
import topojson from "./KYTNtopoJ.json";

// console.log(topojson);

export default function KYTNgeoApp() {
  return (

    <MapContainer
      center={[36.363,-86.024]}
      zoom={7}
      // 37.09, -85  37.094/-85.979  transform: translate3d(95px, -75px, 0px);  transform: translate3d(-175px, 5px, 0px);   36.363,-86.024
      style={{ height: "700px",width: "800px",padding: "0px", backgroundColor: "rgba(255,255,255,.5)" }}
    >
      <TopojKYTN data={topojson}  />
      <CircleMarker center={[38.199267, -84.873430]} radius={5} pathOptions = {{color: '#73F6D2',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "1",stroke: "2" }}>
        <Tooltip className="leaflet-tooltip-own" direction="bottom" permanent>Frankfort</Tooltip>
        </CircleMarker>

        <CircleMarker center={[36.157956, -86.778894]} radius={5} pathOptions = {{color: '#73F6D2',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "1",stroke: "2" }}>
        <Tooltip className="leaflet-tooltip-own" direction="bottom" permanent>Nashville</Tooltip>
        </CircleMarker>

        
        <CircleMarker center={[35.149483, -90.040894]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "1",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own" direction="top" permanent>Memphis</Tooltip>
        </CircleMarker>
        <CircleMarker center={[38.249596, -85.750879]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent>Louisville</Tooltip>
        </CircleMarker>
     
        <CircleMarker center={[38.038970, -84.499376]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="right" permanent >Lexington</Tooltip>
        </CircleMarker>
     
      {/* 

     

      
        
    


        
      


  <CircleMarker center={[32.754890,-97.330411]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own" direction="left" permanent>Fort Worth</Tooltip>
        </CircleMarker>


        <CircleMarker center={[31.770049, -106.489873]} radius={2} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="right" permanent >El Paso</Tooltip>
        </CircleMarker>

        <CircleMarker center={[32.728935, -97.098675]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip"  direction="bottom"  >Arlington</Tooltip>
        </CircleMarker>

    

        <CircleMarker center={[27.780081, -97.462069]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent >Corpus Christi</Tooltip>
        </CircleMarker>
        <CircleMarker center={[33.021437, -96.679671]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip"  direction="bottom" >Plano</Tooltip>
        </CircleMarker>
        <CircleMarker center={[33.565963, -101.868732]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent >Lubbock</Tooltip>
        </CircleMarker> */}

    </MapContainer>
  );
}


