import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

// import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableSMW1sticky from "./CmReportTableSMW1sticky";
import CmReportTableUPMC1sticky from "./CmReportTableUPMC1sticky";
import ReportsListSidebarORX from "./ReportsListSidebarORX";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SWMdashA = (props) => {
    const [titleC1, setTitleC1] = useState([]);
    const [updatedP, setUpdatedP] = useState([]);
    const [stanzaRX, setStanzaRX] = useState([]);

    const [titleCU, setTitleCU] = useState([]);
    const [updatedP_upmc, setUpdatedP_upmc] = useState([]);
    const [stanzaRX_upmc, setStanzaRX_upmc] = useState([]);
  

    const getTitleBCs = async (titleC,dC,setter) => { 
        const dashes = firestore.collection("analytics").doc(dC).collection("pages").doc("dash");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleBCs('titleC1',"swA",setTitleC1)
        getTitleBCs('updatedP',"swA",setUpdatedP)
        getTitleBCs('stanzaRX',"swA",setStanzaRX)
        getTitleBCs('titleCU',"upmcA",setTitleCU)
        getTitleBCs('updatedP',"upmcA",setUpdatedP_upmc)
        getTitleBCs('stanzaRX',"upmcA",setStanzaRX_upmc)

    }, [props]);
return (<>
 <Helmet>
        <title>Stanza Rx</title>
        <meta name="Stanza Rx" content="Stanza Rx" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Prescribing Clinics</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarORX/> 
        
<Container fluid={true}>

<Container fluid={true} style={{ paddingBottom: "50px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#SWM">•Southwest Medical</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#UPMC">•UPMC</HashLink>

                   <div style={{ borderTop: "1px solid #941751",paddingTop: "40px", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>


                   <Container fluid={true}  >
                   <div className="article" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>Stanza Engagement<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updatedP}</span></div>
</div>
                   </Container>



                
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="SWM" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">{titleC1}</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Total Stanza Activated Stanza Prescriptions<span style={{paddingLeft:"20px",fontWeight: "700"}} > {stanzaRX}</span></div>

</div>
</Container>
       
                   <Container fluid={true}>
{/* <div className="articleVS" style={{paddingTop: "20px", paddingBottom: "10px", maxWidth: "90%"}}> 
 */}
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "15px"}}>
<Row > 
<Col>
<table style={{padding: "0px", width: "200px"}}>
    <thead>
        <tr>
        <th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>sessions_per_week_mean</th>
<th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>journal_completion_rate</th>
<th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>sessions_per_week_needed</th>

        </tr>
    </thead>
<tbody>
<tr>
</tr>
<tr>

<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≥5</td>
<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≥75%</td>
<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≤4</td>

</tr>
<tr>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4</td>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>75% - 50%</td>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4 - 6</td>

</tr>
<tr>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4 - 1</td>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>50% - 25%</td>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>6 - 7</td>

</tr>
<tr>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>0</td>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>&lt;25%</td>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>&gt;7</td>
</tr>
</tbody>
</table>
</Col>
</Row>
{/* <div className="TitleC" style={{paddingTop: "10px",paddingBottom: "0px",fontSize: "25px"}}>Total Stanza Activated Stanza Prescriptions<span style={{paddingLeft:"20px"}} > {healthieRx}</span></div> */}
</div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "11px"}}>*sessions_per_week_needed → to finish part1</div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "11px"}}>*days_until_78 → until 1st day of week 12</div>




<div style={{paddingBottom: "10px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "95%",maxHeight: "400px"}}>
    <Col>
<CmReportTableSMW1sticky  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF8733" tableKey={"table1"} />
</Col>
</Row>
</div>
</div>
</Container>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="UPMC" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">{titleCU}</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Total Stanza Activated Stanza Prescriptions<span style={{paddingLeft:"20px",fontWeight: "700"}} > {stanzaRX_upmc}</span></div>

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>

<div style={{paddingBottom: "20px"}}>
{/* <Row> */}
<Row style={{overflow: "scroll", width: "auto",maxWidth: "95%",maxHeight: "400px"}}>
    <Col>
<CmReportTableUPMC1sticky  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#6D6CA5" tableKey={"table1"} />
</Col>
</Row>
</div>
</div>
</Container>


</>);

};

export default SWMdashA;