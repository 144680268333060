import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable003Ax2 from "./CmReportTable003Ax2";

import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import ApplicantGroups003A from "./ApplicantGroups003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import {Helmet} from "react-helmet";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref";

const A003AearlyTerms = (props) => {
 return (<>
  <Helmet>
		<title>Participation 003A</title>
		<meta name="Participation 003A" content="Participation 003A" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Participation By Week, Cohort 003A</h1>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar003A/>

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               {/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#newUsers">•skip to attribution plot</HashLink>
         <br/> */}
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#statuses">•skip to status table</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>
    

        
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "0px", paddingRight: "0px",paddingTop: "0px"}}>
          <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px", paddingRight: "0px"}}>

        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "5px",paddingBottom: "10px",paddingTop: "0px", lineHeight:"1"}} >Participation, Entrances, Exits</div>
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px", paddingLeft:"20px"}} report={"participation_003A"} />
              </Col>
          </Row>
          </div>
          </Container>

          
      
     
 
         
          <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
                <HashLink id="statuses" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                {/* <br/>
                <HashLink id="statuses" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#newUsers">jump to attribution plot</HashLink> */}

            </Col>
            </Row>
            </div>
            </Container>
          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "0px", paddingRight: "0px"}}>
        <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "5px",paddingBottom: "10px",paddingTop: "0px", lineHeight:"1"}} >Status, by week</div>
              <CmReportTable003Ax2 bgColor="#662D91" style={{paddingBottom:"28px",paddingTop: "0px", paddingLeft:"20px"}} tableKey={"participation003A"} />
            </Col>
          </Row>
          </div>
          </Container>

    


</>);

};

export default A003AearlyTerms;




