import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
// import CmReportTable004BextST from "./CmReportTable004BextST"; 
// import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 






const A003AppUse = (props) => {
    

 return (<>
 <Helmet>
        <title>App Use 003A</title>
        <meta name="App Use 003A" content="App Use 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>App Use 003A, by referrer</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

 <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
            <Row>
                <Col>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#supportfibro">•skip to supportfibro</HashLink>
                    <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
                </Col>
            </Row>
        </Container>
        </div>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px", fontSize: "35px"}}>Swing Internal</div>
            

            <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "5px"}}>Swing Internal, Therapy</div>

        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_swing_internal"} />
        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "20px"}}>Swing Internal, Early Exits</div>
            


        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_swing_internal_earlyExits"} />

        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "20px"}}>Swing Internal, 12-Week Completers</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_swing_internal_completers"} />

        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "20px"}}>Swing Internal, 12-Week Non-Completers <span className="TitleC" style={{fontSize: "15px",paddingLeft: "20px",paddingBottom: "1px"}} >Non-completer: did not complete part1</span></div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_swing_internal_non_completers"} />
        </div>
        
        </Container>

        <div className="article" style={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px",paddingTop: "20px"}}>

        {/* <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "0px",paddingLeft: "0px" }}> */}
                <Row>
                    <Col>

                        <HashLink id="supportfibro" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    {/* </Container> */}
                    </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px", fontSize: "35px"}}>SupportFibro</div>
            


     
        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "5px"}}>SupportFibro, 12-Week Completers</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_supportfibro_completers"} />
        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "20px"}}>SupportFibro, 12-Week Non-Completers</div>
        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_supportfibro_non_completers"} />
        <div className="TitleC" style={{paddingBottom: "5px", paddingTop: "20px"}}>SupportFibro, Therapy</div>

        <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_supportfibro"} />
        </div>
        </Container>
</>);

};

export default A003AppUse;
