import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A22F from "./CmReportTable005A22F";
import CmReportTable005A2 from "./CmReportTable005A2";
import ScrollUpButton from "react-scroll-up-button";
 
import { HashLink,NavHashLink } from "react-router-hash-link";


const A005Extension = (props) => {
return (<>
 <Helmet>
        <title>Extension 005A</title>
        <meta name="Extension 005A" content="Extension 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Extension 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#actETs">•ACT early terms</HashLink>
 <br/>
 {/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#act_statuses">•ACT dispositions</HashLink> */}
 <NavHashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#act_statuses">•ACT dispositions </NavHashLink>

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>

       <Row>
<Container fluid={true} style={{ paddingBottom: "20px", paddingLeft: "0px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "8px",fontSize: "45px"}}>ACT Consort, post-main</div>

<GraphTemplate005A style={{padding: "0px"}} report={"consortExtA"} />

{/* <Row>
<GraphTemplate005A style={{padding: "0px"}} report={"consortExtA"} />
                </Row> */}
                </div>
</Container>
</Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "25px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "5px"}}>
<Row>
<Col>
<NavHashLink id="act_statuses" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</NavHashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "600px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "45px"}}>Extension Statuses</div>

<CmReportTable005A2 style={{padding: "0px",width: "450px"}} thFontSize="14px" tdFontSize="12px" bgColor="#9E005D" tableKey={"extensionA_indiv"} />                
</div>
                
</Container>
</Row>


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
<Row>
<Col>
<HashLink id="actETs" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "8px",fontSize: "45px"}}>ACT Early Terminations</div>
<CmReportTable005A22F style={{padding: "0px"}} thFontSize="14px" tdFontSize="12px" bgColor="#2475C7" tableKey=  {"ACT_early_terms"} />                
</div>
</Container>
</Row>
</>);

};

export default A005Extension;