import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003AxSmaller from "./CmReportTable003AxSmaller";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";
import CmReportTable003Ax2sp from "./CmReportTable003Ax2sp";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003sessions = (props) => {
    return (<>
 
 <Helmet>
        <title>Sessions 003A</title>
        <meta name="Sessions 003A" content="Sessions 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Sessions 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

        
        
     
 <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

 <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
            <Row>
                <Col>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#thiswWeek">•skip to this week</HashLink>
                    <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
                </Col>
            </Row>
        </Container>
        </div>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "40px"}}>
            <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC" style={{fontSize: "20px"}}  >Sessions Per Week, Completed Weeks<span className="TitleD" style={{fontSize: "11px", paddingLeft: "80px"}}></span></div>

<CmReportTable003Ax2  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#2475C7" tableKey={"sessionsPerWeekA"}></CmReportTable003Ax2>
{/* Smaller */}


</div>
        </Container>
        
    
     
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingTop: "30px",paddingBottom: "30px"}}>
            <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

         
             

            <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
                <Row>
                    <Col>

                        <HashLink id="thiswWeek" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    </Container>

<div className="TitleC" style={{fontSize: "20px"}}  >Sessions Per Week, TherapyWeek in-progress<span className="TitleD" style={{fontSize: "11px", paddingLeft: "80px"}}></span></div>
{/* <div className="TitleC" style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "80px"}}>TherapyWeek in-progress   */}
{/* <span className="TitleC" style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "300px"}}>Completed TherapyWeeks →</span>*/}
{/* </div>  */}
<CmReportTable003Ax2sp  style={{paddingLeft: "0px", paddingRight: "0px"}} bgColor="#2475C7" tableKey={"sessionsThisWeekA"}></CmReportTable003Ax2sp>
{/* Smaller */}


</div>
        </Container>
         
</>);

};

export default A003sessions;




