import React, {useEffect, useState} from "react";
import Plot from "react-plotly.js";
import {firestore} from "../utils/firebase";

const GraphTemplateEPROsA = (props) => {
  const [lines, setLines] = useState([]);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState("");

  let { report } = props;

  const getLines = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const query = await firestore
      .collection("analytics")
      .doc("reports")
      .collection("reportGraphs");

    const lineQuery = await query.doc(`${report}`).collection("lines").get();

    // const layoutQuery = await query.doc(`${report}`).collection("layout").get();
    // const layoutData = layoutQuery.data();
    // setLayout(data.layoutData);

    const titleQuery = await query.doc(`${report}`).get();
    const layoutQuery = await query.doc(`${report}`).get();

    const layoutdata = layoutQuery.data();
    const layout = layoutdata.layout  ? layoutdata.layout : "";
    setLayout(layoutdata.layout);


    const data = titleQuery.data();
    const title = data.title  ? data.title : "";
    setTitle(data.title);

    const lineArray = [];
    lineQuery.docs.map((r) => {
      lineArray.push(r.data());
    });
    setLines(lineArray);
  };

  useEffect(() => {
    getLines();
  }, [lines]);

  var Playout = {
    xaxis: { autotick: false, ticks: 'inside',  dtick: 1,tickfont:{size:10} },
    yaxis: { tickfont:{size:8},autotick: false, range: [0, 101], ticks: 'inside',  dtick: 4, ticklen: 3,title: {font: {"size": 10}, standoff: 3, text: "FIQR Scores"}},
    margin: { l: 100, r: 100, t: 100},
    title: title,
    xaxis_showgrid:false,
     yaxis_showgrid:false,
     width:1200,height:600,
     hoverlabel: { font: {"size": 10} },
     hovermode:'x unified',
    legend: {x: 1,y:.9, bgcolor: 'white', font_size: 8,title:{text:"FIQR ACT, 4+"}}
    };

  let graph = (
    <Plot
      scrollZoom={true}
      data={lines}
      className="col"
      useResizeHandler={true}
      layout={layout}
      // {{
      //   title: title,
      //   margin: {
      //     l: 100,
      //     r: 100,
      //     t: 100,
      //   },
      //        }}
    />
  );

  return graph;
};

export default GraphTemplateEPROsA;
