
import React,{ useState, useEffect }  from "react";
import {firestore} from "../utils/firebase";

import "./surveyAdherence.css";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import PROkeyTableXSB from "./PROkeyTableXSB";


const BDIexplanation = (props) => {
return(<>

            <PROkeyTableXSB  style={{paddingBottom:"0px",paddingTop: "20px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"bdi_interp_key"} />

    </>);

};
export default BDIexplanation;

// ../bdi_interp_key