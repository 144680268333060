import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import BReportTable from "./BReportTable";
import CmReportTable004B from "./CmReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

// import AsmallerReportTable from "./AsmallerReportTable";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004Visits = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 Participants Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B style={{ paddingRight: "50px"}}/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>

            <div className="sidenav">
            <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/sites">
                Sites</Link></p>
               <p><Link className="TitleD" style={{paddingLeft: "20px"}} to="/004/pros">
                PROs</Link></p>

            </div>
             </Container> */}
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "50px"}}>
            <div className="article">
            <div className="TitleC">Visits</div>
            <CmReportTable004B tableKey={"visits004B"}></CmReportTable004B>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"engagement_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable tableKey={"lesson_kpis"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}

</>);

};

export default B004Visits;

