import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";

import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1stickyHRfml from "./CmReportTableVS1stickyHRfml";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1stickyRKMN from "./CmReportTableVS1stickyRKMN";

import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCcancellations = (props) => {


    const [updated, setUpdated] = useState([]);
    const [ret_subA, setRet_subA] = useState([]);
    const [ret_subB, setRet_subB] = useState([]);
    const [cavA, setCavA] = useState([]);
    const [cavB, setCavB] = useState([]);

    // const [ret_subC, setRet_subC] = useState([]);
    // const [ret_subD, setRet_subD] = useState([]);

       



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("cancellations");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
           getTitleCs('ret_subA',setRet_subA)
           getTitleCs('ret_subB',setRet_subB)
           getTitleCs('cavA',setCavA)
        getTitleCs('cavB', setCavB)
        //    getTitleCs('ret_subC',setRet_subC)
        //    getTitleCs('ret_subD',setRet_subD)
    }, [props]);



return (<>
 <Helmet>
        <title>Cancellations SCA</title>
        <meta name="Cancellations SCA" content="Cancellations SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>


<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#cancelsA">•{ret_subA}</HashLink>
<br/>
{/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#payersB">•payers by completed intake month</HashLink> */}
{/* <br/> */}
<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

   

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "20px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "10px",paddingBottom: "0px",fontSize: "45px",color: "#000000",lineHeight: "1"}}>Cancellations<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
<div style={{fontSize: "14px",paddingLeft: "10px",paddingBottom: "5px",lineHeight: "1"}} >* {cavA}</div>
<div style={{fontSize: "14px",paddingLeft: "10px",paddingBottom: "20px",lineHeight: "1"}} >* {cavB}</div>

</div>
</Container>



<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>

<HashLink id="cancelsA" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Cancellations</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subA}</div>


</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div style={{overflow: "scroll", paddingTop: "5px",  maxWidth: "50%",maxHeight: "400px"}} >
{/* maxWidth: "680px",overflow: "scroll", */}
{/* style={{overflow: "scroll", width: "auto",maxHeight: "400px"}} */}
<Row >
<Col >
<CmReportTableVS1stickyRKMN style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="2px" tdPaddingTop="4px" thPaddingRight="2px" thPaddingLeft="4px" bgColor="#F76345" tableKey={"cancellByTypeA"} />
</Col>
</Row>
</div>
</div>
{/* </Container>
<Container fluid={true}  > */}

</Container>


</>);

};

export default SCcancellations;