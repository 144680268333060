import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import ScrollUpButton from "react-scroll-up-button";
import { HashLink } from "react-router-hash-link";


const A005AppUse = (props) => {

    const [data_retrieved, setData_retrieved] = useState([]);
    const [screeningWeek_opens_updated, setScreeningWeek_opens_updated] = useState([]);
    const [ACT_data_updated005A, setACT_data_updated005A] = useState([]);
    const [ST_data_updated005A, setST_data_updated005A] = useState([]);
    const [screened, setScreened] = useState([]);
    const [screening, setScreening] = useState([]);
    const [enrolled, setEnrolled] = useState([]);
    const [screenfailed, setScreenfailed] = useState([]);
    const [terminatedearly, setTerminatedearly] = useState([]);
    const [ACT, setACT] = useState([]);
    const [ST, setST] = useState([]);

    
        
    const getStatusesData = async (status,setter) => { 
        const statuses = firestore.collection("analytics").doc("A005").collection("data005").doc("statuses005A");
        const doc = await statuses.get();
        const docData=doc.data()
        const statusCounts = docData[status];
        console.log(statusCounts)
        setter(statusCounts);
   }

  const screeningWeek_opens_updateD = async (setter) => { 
    const sco_doc = firestore.collection("analytics").doc("A005").collection("screeningWeek_opens_updated").doc("screeningWeek_opens_updated");
    const doc = await sco_doc.get();
    const docData=doc.data()
    const screeningWeek_opens_updated = docData['screeningWeek_opens_updated'];
    console.log(screeningWeek_opens_updated)
    setter(screeningWeek_opens_updated);
  } 

// /analytics/A005/data005/retrieval_times005A
// db.collection('analytics').document('A005').collection('data005').document('retrieval_times005A').doc('ACT_data_updated005A')

const ACT_updateD = async (setter) => { 
    const act_doc = firestore.collection('analytics').doc('A005').collection('data005').doc('retrieval_times005A');
    const doc = await act_doc.get();
    const docData=doc.data()
    const ACT_data_updated005A = docData['ACT_data_updated005A'];
    console.log(ACT_data_updated005A)
    setter(ACT_data_updated005A);
  } 


  const ST_updateD = async (setter) => { 
    const st_doc = firestore.collection('analytics').doc('A005').collection('data005').doc('retrieval_times005A');
    const doc = await st_doc.get();
    const docData=doc.data()
    const ST_data_updated005A = docData['ST_data_updated005A'];
    console.log(ST_data_updated005A)
    setter(ST_data_updated005A);
  } 


  useEffect(() => {
    getStatusesData('data_retrieved', setData_retrieved)
    getStatusesData('screened',setScreened)
    getStatusesData('screening',setScreening)
    getStatusesData('enrolled',setEnrolled)
    getStatusesData('terminatedearly',setTerminatedearly)
    getStatusesData('screenfailed',setScreenfailed)
    getStatusesData('ACT',setACT)
    getStatusesData('ST',setST)
    screeningWeek_opens_updateD(setScreeningWeek_opens_updated)
    ACT_updateD(setACT_data_updated005A)
    ST_updateD(setST_data_updated005A)

}, [props]);


 return (<>
 <Helmet>
        <title>App Use 005A</title>
        <meta name="App Use 005A" content="App Use 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>App Use 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "100%" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="ACT" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#ST">•ST</HashLink>
            {/* <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#finScreeningWeek">•skip to finished screening week</HashLink> */}


  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", width: "auto"}}> 



<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>ACT App Use  <span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {ACT_data_updated005A}</span></div>
{/* <div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>In Therapy  <span style={{paddingLeft: "10px",fontSize: "14px"}} >(split by subjectID, for easier viewing)</span></div> */}

{/* <GraphTemplate005A style={{padding: "0px"}} report={"ACTuseA1"} />
<br/>
<GraphTemplate005A style={{padding: "0px"}} report={"ACTuseA2"} />
<br/>
<GraphTemplate005A style={{padding: "0px"}} report={"ACTuseA3"} />
<br/>
<GraphTemplate005A style={{padding: "0px"}} report={"ACTuseA4"} /> */}
<div  className="TitleC" style={{paddingTop: "10px",paddingBottom: "10px",fontSize: "30px"}}>Exited Main Study</div>

<GraphTemplate005A style={{padding: "0px"}} report={"ACTuseA_exits"} />
</div>
</Container>

{/* <Container fluid={true}>

<div className="article" style={{paddingTop: "0px", paddingBottom: "40px", width: "auto"}}> 



<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>Has Session in Progress</div>

<CmReportTable005A2 style={{padding: "0px"}} thPaddingRight="5px" thPaddingLeft="6px" thFontSize="14px" tdFontSize="14px" bgColor="#17BAFF" tableKey={"ACT_inprogress003A"} />

</div>

</Container> */}


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "35px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="ST" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#ACT">jump to ACT</HashLink>
                        {/* <br/> */}
                        {/* <HashLink id="finScreeningWeek" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#visitsEar">jump to visit counts</HashLink>                 */}
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 



<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>ST App Use   <span style={{paddingLeft: "10px",fontSize: "14px"}} >data last retrieved: {ST_data_updated005A}</span></div>
{/* <div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "30px"}}>In Therapy</div>
<GraphTemplate005A style={{padding: "0px"}} report={"STuseA"} /> */}
<div  className="TitleC" style={{paddingTop: "10px",paddingBottom: "10px",fontSize: "30px"}}>Exited Main Study</div>

<GraphTemplate005A style={{padding: "0px"}} report={"STuseA_exits"} />

</div>
</Container>
</>);

};

export default A005AppUse;


