import React, {Component, createContext} from "react";
import {auth, firestore} from "../firebase";
import LoadingScreen from "../screens/LoadingScreen";

export const UserContext = createContext({user: null});

class UserProvider extends Component {
  state = {
    user: null,
    unauthorized: false,
    loading: true,
  };

  componentDidMount = async () => {
    auth.onAuthStateChanged(async (userAuth) => {
      const user = userAuth;
      if (user && user.email) {
        let roleMember = await firestore.collection('roles').doc('tempest').collection('members').doc(user.email).get();
        if(!roleMember.exists){
          roleMember = await firestore.collection('roles').doc('admin').collection('members').doc(user.email).get();
        }
        if(!roleMember.exists){
          roleMember = await firestore.collection('roles').doc('analytics').collection('members').doc(user.email).get();
        }

        if (roleMember.exists) {
          this.setState({ user, unauthorized: false, loading: false });
        } else {
          this.setState({ unauthorized: true, loading: false });
        }
      } else {
        auth.signOut();
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { user, unauthorized, loading } = this.state;

    if (loading) {
      return <LoadingScreen />;
    }

    return !unauthorized ? (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    ) : (
      <div style={{ textAlign: "center" }}>
        <h1>Unauthorized Access</h1>
        <p>
          Looks like you don't have permissions to view this page. Please
          contact your system administrator for access.
        </p>
      </div>
    );
  }
}

export default UserProvider;
