import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1stickyNoIndNoCols from "./CmReportTableVS1stickyNoIndNoCols";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCnoShows = (props) => {


    const [updated, setUpdated] = useState([]);
    const [total_noshows, setTotal_noshows] = useState([]);
const [total_onedones, setTotal_onedones] = useState([]);
const [intakes_occ, setIntakes_occ] = useState([]);
const [percent_no_shows_intakes, setPercent_no_shows_intakes] = useState([]);
const [percent_onedones_intakes, setPercent_onedones_intakes] = useState([]);
const [percent_occ_intakes_onedones, setPercent_occ_intakes_onedones] = useState([]);
const [saturday_appts, setSaturday_appts] = useState([]);
const [outside_hours, setOutside_hours] = useState([]);
const [unique_user_appt_noshows, setUnique_user_appt_noshows] = useState([]);
const [key_metrics_maxWidth, setKey_metrics_maxWidth] = useState([]);
const [key_metrics_fontsize, setKey_metrics_fontsize] = useState([]);
// key_metrics_fontsize

       



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("no_shows");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
        getTitleCs('total_noshows',setTotal_noshows)
        getTitleCs('total_onedones',setTotal_onedones)
        getTitleCs('intakes_occ',setIntakes_occ)
        getTitleCs('percent_no_shows_intakes',setPercent_no_shows_intakes)
        getTitleCs('percent_onedones_intakes',setPercent_onedones_intakes)
        getTitleCs('percent_occ_intakes_onedones',setPercent_occ_intakes_onedones)
        getTitleCs('saturday_appts',setSaturday_appts)
        getTitleCs('outside_hours',setOutside_hours)
        getTitleCs('unique_user_appt_noshows', setUnique_user_appt_noshows)
        getTitleCs('key_metrics_maxWidth', setKey_metrics_maxWidth)
        getTitleCs('key_metrics_fontsize', setKey_metrics_fontsize)

    }, [props]);



return (<>
 <Helmet>
        <title>No Shows SCA</title>
        <meta name="Info Calls SCA" content="No Shows SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#keymets">•key metrics</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nssch">•appts scheduled & outcomes</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nsW">•by week</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nsWoM">•by week of month</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nsDoW">•by day of week</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#nsHoD">•by hour of appointment</HashLink>
<br/>


<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

   

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "20px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "38px",fontSize: "45px",color: "#000000",lineHeight: "1"}}>No Shows<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>

<div className="TitleC" style={{maxWidth: "50%",borderTop: "1px solid #941751",paddingBottom: "8px"}}></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "18px",lineHeight: "1"}}>Notes</div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• 2023<span style={{color:"#636161",paddingLeft: "4px"}}>appointments only</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• Healthie<span style={{color:"#636161",paddingLeft: "4px"}}>appointments only, excludes info</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• otherwise relevant appointments<span style={{color:"#636161",paddingLeft: "4px"}}>occurrying on weekends ({saturday_appts}), before 6am, or after 7pm ({outside_hours}) were excluded</span></div>


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• one done:<span style={{color:"#636161"}}> an appointment completed the 1st and only time scheduled</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• no show, abandoned:<span style={{color:"#636161",paddingLeft: "4px"}}> a no show appointment never rescheduled</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#000000"}}>• no show, rescheduled +/or completed:<span style={{color:"#636161",paddingLeft: "4px"}}> a no show appointment that has been rescheduled or eventually occurred</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#B80079",fontWeight: "900"}}>• percentages:<span style={{color:"#636161",paddingLeft: "10px",fontWeight: "500"}}>percent of total scheduled appointments at that interval</span>  </div>

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="keymets" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Key Metrics</HashLink>

</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "30px",width: "max"}}>


{/* <div className="TitleC" style={{maxWidth: "50%",borderTop: "1px solid #941751",paddingBottom: "8px"}}></div>
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "5px",fontSize: "22px",lineHeight: "1"}}>Key Metrics</div> */}
<div style={{paddingTop: "5px",paddingLeft: "0px", maxWidth: key_metrics_maxWidth}} >

<Row >
<Col>
<CmReportTableVS1stickyNoIndNoCols style={{padding: "0px"   }} tdFontSize={key_metrics_fontsize} tdfColor="#FFFFFF" tdPaddingLeft= "5px"  tdPaddingRight="5px" tdPaddingTop="4px"   tableKey={"no_show_summA"} />
</Col>
</Row>
</div>
</div>
</Container>
{/* <CmReportTableVS1stickyNoIndNoCols style={{padding: "0px"   }} tdfColor="#FFFFFF" tdPaddingLeft= "5px"  tdPaddingRight="18px" tdPaddingTop="4px"   tableKey={"no_show_summA"} /> */}

{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>total no shows = <span style={{color:"#000000",paddingLeft: "4px"}}>{total_noshows}</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>total one dones = <span style={{color:"#000000",paddingLeft: "4px"}}>{total_onedones}</span></div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>no shows, unique patient-appointments = <span style={{color:"#000000",paddingLeft: "4px"}}>{unique_user_appt_noshows}</span></div>


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>total completed intake appointments = <span style={{color:"#000000"}}>{intakes_occ}</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>percent of intakes that are one dones = <span style={{color:"#000000",paddingLeft: "4px"}}>{percent_occ_intakes_onedones}</span></div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>percent of no shows that are intakes = <span style={{color:"#000000"}}>{percent_no_shows_intakes}</span></div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "16px",lineHeight: "1",paddingLeft: "10px", color:"#636161"}}>percent of one dones that are intakes = <span style={{color:"#000000",paddingLeft: "4px"}}>{percent_onedones_intakes}</span></div> */}



<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nssch" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">No Show Scheduling</HashLink>
<div className="TitleC"  style={{fontSize: "30px"}}>Appointments Scheduled & Final Outcomes</div>
</Col>
</Row>

</div>
</Container>
<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "30px",maxWidth: "90%"}}>   
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="11px" tdFontSize="11px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#3E70CA" tableKey={"no_show_dispA"} />
</Col>
</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nsW" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">No Shows, by Week</HashLink>

</Col>
</Row>

</div>
</Container>
<Container fluid={true}  >
<div className="article" style={{paddingTop: "30px", paddingBottom: "40px",maxWidth: "99%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}   report={"noshow_weeksA"} />
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nsWoM" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">No Shows, by Week of Month</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1"}}>The week of the 1st == week 1; week 2 starts 7 days later, etc</div>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "12px",lineHeight: "1",paddingLeft: "8px"}}>*this differs from the weeks by Monday dates we use above & elsewhere</div>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "12px",lineHeight: "1",paddingLeft: "10px"}}>and does so to capture any relationship to income, work activity, and other factors that vary with week of the month</div>
{/* <div className="TitleC" style={{paddingTop: "12px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1", color:"#000000"}}>percentages<span style={{color:"#636161",paddingLeft: "4px"}}>== percent of total no shows and one dones, respectively</span>  </div> */}

</Col>
</Row>

</div>
</Container>





<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "50px",maxWidth: "90%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  report={"noshow_wkMnA"} />
</Col>
</Row>
<div style={{paddingTop: "15px",paddingLeft: "110px", maxWidth: "350px"}} >
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#8C8785" tableKey={"noshow_wkMnA"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nsDoW" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">No Shows, by Day of Week</HashLink>
{/* <div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1", color:"#000000"}}>percentages<span style={{color:"#636161",paddingLeft: "4px"}}>== percent of total no shows and one dones, respectively</span>  </div> */}

</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  report={"noshow_weekdayA"} />
</Col>
</Row>
<div style={{paddingTop: "15px",paddingLeft: "140px", maxWidth: "350px"}} >
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#8C8785" tableKey={"noshow_weekdayA"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "30px",maxWidth: "90%"}}>   
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "20px",fontSize: "30px",lineHeight: "1",paddingLeft: "15px"}}>No Shows, by Day of Week and Appointment Type</div>

<Row>
<Col>

<GraphTemplateVSA style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  report={"noshow_weekday2A"} />

</Col>

</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="nsHoD" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">No Shows, by Appointment Hour</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1", color:"#000000"}}>percentages<span style={{color:"#636161",paddingLeft: "4px"}}>== percent of total no shows and one dones, respectively</span>  </div>

</Col>
</Row>

</div>
</Container>





<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "30px",maxWidth: "90%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  report={"noshow_hourA"} />
</Col>
</Row>
<div style={{paddingTop: "15px",paddingLeft: "140px", maxWidth: "350px"}} >
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#8C8785" tableKey={"noshow_hourA"} />
</Col>
</Row>
</div>
</div>
</Container>





</>);

};

export default SCnoShows;