import {React,Suspense,useImage,Img} from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,Image} from "react-bootstrap";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";
import consortA from './consort_diagram004A.png';


const A004consort = (props) => 
    {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Consort Cohort 1</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>

           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>


<img src={consortA} alt="BigC"  style={{height: "600px", width: "1400px"}}  />

</div>
</Container>
</>);

};

export default A004consort;


