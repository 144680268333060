import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AsmallerReportTable004Bextdaily from "./AsmallerReportTable004Bextdaily";
import AsmallerReportTable004Bextweekly from "./AsmallerReportTable004Bextweekly";
import GraphTemplate004Bext from "./GraphTemplate004Bext";
import { HashLink } from "react-router-hash-link";
import  PROkeyTableXSext2 from "./PROkeyTableXSext2";
import  PROkeyTableXSext from "./PROkeyTableXSext";
import CmReportTable004BextX from "./CmReportTable004BextX";


import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import {Helmet} from "react-helmet";

const Bext004ChapterResponses = (props) => {
 return (<>
  <Helmet>
		<title>Chapter Responses 004-2x</title>
		<meta name="Chapter Responses 004-2x" content="Chapter Responses 004-2x" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1 style={{paddingBottom: "9px"}}>ACT Chapter Responses, Cohort 2 Extension </h1>
                        <div className="TitleDB" style={{fontSize: "24px", paddingLeft: "0px",paddingBottom: "10px"}} >TempoACT2, ST → ACT arm</div>

                       {/* <h2 className="TitleDB" style={{paddingBottom: "25px", fontSize: "32px"}}>Awareness Activities Introduced During Sessions</h2> */}


                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>
       

     
                

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>

  

    
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
            <Row>
                <Col>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#comments">•skip to comments</HashLink>

                <br />
                    <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#plots">•skip to plots</HashLink>

                    <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
                </Col>
            </Row>

        </Container>
        </div> 
        </Container> 
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "10px",paddingBottom: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "0px",paddingTop: "0px"}} >Score Summary</div>
            <CmReportTable004BextX bgColor="#F55C48" style={{paddingBottom:"0px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_summary004Bx0"} />
            </Col>
          </Row>
          </Container>

            
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px",paddingTop: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "25px",paddingBottom: "0px",paddingTop: "0px"}} >Score Counts</div>
              <CmReportTable004BextX bgColor="#17BECF" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_counts004Bx0"} />
            </Col>
          </Row>
          </Container>


          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop: "10px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
        <Row>
            <Col>
               <HashLink id="plots" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
               <br/>
               <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#comments">skip to comments</HashLink>

            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "0px",paddingTop: "0px"}} >Chapter Relevance Scores</div>
              <GraphTemplate004Bext style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"chapterResponses004Bx0_relevant"} />
            </Col>
          </Row>
          </Container>
    

          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "0px",paddingTop: "25px"}} >Chapter Worth Scores</div>
              <GraphTemplate004Bext style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} report={"chapterResponses004Bx0_worthwhile"} />
            </Col>
          </Row>
           </Container>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop: "40px"}}>

<Container fluid={true} style={{ paddingBottom: "10px", paddingTop: "0px" }}>
        <Row>
            <Col>

                <HashLink id="comments" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
            </Container>


          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "0px", paddingRight: "0px",paddingTop: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "20px",paddingBottom: "0px",paddingTop: "0px"}} >Comments</div>
              <CmReportTable004BextX bgColor="#005276" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"chapter_eval_comments004Bx0"} />
            </Col>
          </Row>
          </Container>
          </div>
     
</>);

};

export default Bext004ChapterResponses;

    
