import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";

import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";

import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCretention = (props) => {


    const [updated, setUpdated] = useState([]);
    const [ret_subA, setRet_subA] = useState([]);
    const [ret_subB, setRet_subB] = useState([]);
    const [ret_subBa, setRet_subBa] = useState([]);
    const [ret_subC, setRet_subC] = useState([]);
    const [ret_subD, setRet_subD] = useState([]);

    const [ret_subE, setRet_subE] = useState([]);    



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("no_shows");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
           getTitleCs('ret_subA',setRet_subA)
           getTitleCs('ret_subB',setRet_subB)
           getTitleCs('ret_subBa',setRet_subBa)
           getTitleCs('ret_subC',setRet_subC)
           getTitleCs('ret_subD',setRet_subD)
           getTitleCs('ret_subE',setRet_subE)

        }, [props]);



return (<>
 <Helmet>
        <title>Patient Retention SCA</title>
        <meta name="Patient Retention SCA" content="Patient Retention SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>


<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientretenB">•retention by calendar month</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientretenCo">•retention by intake month</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientretenMoM">•month over month retention</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientretenA">•retention by patient month</HashLink>
<br/>

<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientdur">•patient duration</HashLink>
<br/>
<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

   

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "20px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "38px",fontSize: "45px",color: "#000000",lineHeight: "1"}}>Patient Retention<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>


</div>
</Container>



<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>

<HashLink id="patientretenB" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Retention</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subA}</div>


</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div style={{paddingTop: "5px", maxWidth: "650px",overflow: "scroll", width: "auto",maxHeight: "400px"}} >
{/* <Row> */}
{/* style={{overflow: "scroll", width: "auto",maxHeight: "400px"}} */}
<Row >
<Col>
<CmReportTableVS1sticky style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="2px" tdPaddingTop="4px" thPaddingRight="2px" thPaddingLeft="4px" bgColor="#F76345" tableKey={"retention_by_intake_monthA"} />
</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>

<HashLink id="patientretenCo" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Retention</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subB}<span style={{paddingLeft: "14px",fontSize: "20px", color: "#005176"}} >{ret_subBa}</span></div>


</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div  style={{overflow: "scroll",paddingTop: "5px", maxWidth:"750px",maxHeight: "400px"}}  >
    {/* style={{overflow: "scroll",paddingTop: "5px", maxWidth: "730px",maxHeight: "400px"}} */}
{/* <Row style={{overflow: "scroll", width: "auto",maxHeight: "400px"}}> */}
{/* style={{ width: "auto",maxHeight: "400px"}}  style={{lineHeight: "1"}} */}
<Row  >
<Col >
{/* <div  style={{ width:"auto", maxWidth: "580px"}}  ></div> */}


<CmReportTableVS1sticky style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="0px" tdPaddingTop="4px" thPaddingRight="0px" thPaddingLeft="4px" bgColor="#430040" tableKey={"retention_by_intake_month_cohortA"} />

</Col>
</Row>
</div>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%",paddingTop: "20px"}}>
<Row>
<Col>

<HashLink id="patientretenMoM" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Retention</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subE}<span style={{paddingLeft: "14px",fontSize: "20px", color: "#005176"}} >{ret_subD}</span></div>


</Col>
</Row>

</div>
</Container>

<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div style={{paddingTop: "5px", maxWidth: "460px",overflowY: "scroll", width: "auto",maxHeight: "400px",overflowX: "hidden", padding: "3px"}} >

<Row >
<Col>
<CmReportTableVS1stickyH style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="2px" tdPaddingTop="4px" thPaddingRight="2px" thPaddingLeft="4px" bgColor="#CB1C49" tableKey={"retention_monthOver_month"} />
</Col>
</Row>
</div>

</div>
</Container>

<Container fluid={true}  >
<div className="articleVSB" style={{paddingTop: "30px", paddingBottom: "30px",maxWidth: "90%", paddingLeft: "120px"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}    report={"patient_retentMoM"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="patientretenA" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Retention</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subC}<span style={{paddingLeft: "14px",fontSize: "20px", color: "#005176"}} >{ret_subD}</span></div>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1"}}>inactive:<span style={{paddingLeft: "8px"}} >percent who became inactive at month x</span></div>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "14px",lineHeight: "1"}}>active:  <span style={{paddingLeft: "16px"}} >percent still active after month x</span></div>


</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "30px",maxWidth: "90%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}   thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px"  report={"patient_mtmretenA"} />
</Col>
</Row>

</div>
</Container>




<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="patientdur" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Duration</HashLink>

</Col>
</Row>

</div>
</Container>
<Container fluid={true}  >
<div className="article" style={{paddingTop: "30px", paddingBottom: "40px",maxWidth: "90%"}}>   
<Row style={{paddingTop: "0px",paddingBottom: "0px"}}>
    <Col style={{padding:"0px"}}>
    <GraphTemplateVSA style={{padding: "0px",paddingTop: "0px"}} report={"patient_monthsA"} />

</Col>
</Row>

</div>
</Container>





</>);

};

export default SCretention;