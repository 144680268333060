import React from "react";
import GraphTemplateEPROsB from "../../graphs/GraphTemplateEPROsB";
// import GraphTemplateEPROsA from "./src/graphs/GraphTemplateEPROsA";
// import { usePageName } from 'react-page-name';
	
// import GraphTemplateEPROsA from "../../graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

import AdminNavbar from "../../components/AdminNavbar";import {Col, Container, Row} from "react-bootstrap";

const B004BDIgraphs = () => {
  // usePageName('BDI-graphs 004-2');
  return (
    <>
      <AdminNavbar />
      <AnotherReportsListSidebar004B/>
      <Container fluid={true} >
        <div className="mt-4">
        <div className="article">
          <div className="TitleC">Beck Depression Inventory II</div>
          <p className="TitleDB" style={{paddingTop: "5px"}} >bdi goal: -</p>
          
          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "25px"}}>ACT Daily</div>
              <GraphTemplateEPROsB report={"ACT_bdi004Bdaily"} />
            </Col>
          </Row>
        
 <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ACT Weekly</div>
              <GraphTemplateEPROsB report={"ACT_bdi004Bweekly"} />
            </Col>
          </Row>

          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ST</div>
              <GraphTemplateEPROsB report={"ST_bdi004B"} />
            </Col>
          </Row>

          

          </div>
        </div>
      </Container>
    </>
  );
};

export default B004BDIgraphs;

