import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004B from "./CmReportTable004B";
import CmReportTable004BST from "./CmReportTable004BST";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import {Helmet} from "react-helmet";

import GraphTemplateEPROsB from "../../graphs/GraphTemplateEPROsB";



const B004PGIC = (props) => {
 return (<>
 <Helmet>
		<title>PGIC-Visit 004-2</title>
		<meta name="PGIC-Visit 004-2" content="PGIC-Visit 004-2" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>PGIC Scores by Visit, Cohort 2</h1>
                    </Col>
                </Row>
  
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

        <Container fluid={true} style={{paddingBottom: "30px"}} >
        <div className="mt-4">
        <div className="article" style={{paddingBottom: "30px"}}>
        <div className="TitleC" style={{ paddingLeft: "55px", paddingRight: "0px",paddingBottom: "0px"}}>PGIC Scores, arm - percentage</div>
        {/* ,width: "90%" */}
          <Row>
            <Col xs={10}>

            <GraphTemplateEPROsB style={{ paddingTop: "0px"}} report={"pgic_percents004B"} />
            </Col>
          </Row>
              
          </div>
        </div>  
        </Container>

           

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "15px", paddingRight: "0px",width: "90%"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

            <div className="TitleC">ST PGIC Score Summary
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} ></span>
            </div>
            <CmReportTable004BST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pgicSTsumm004B"}></CmReportTable004BST>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "15px", paddingRight: "0px",width: "90%"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

            <div className="TitleC">ACT Weekly PGIC Score Summary
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} ></span>
            </div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pgicACTwsumm004B"}></CmReportTable004B>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "15px", paddingRight: "0px",width: "90%"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

            <div className="TitleC">ACT Daily PGIC Score Summary
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} ></span>
            </div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pgicACTdsumm004B"}></CmReportTable004B>
                </div>
        </Container>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "15px", paddingRight: "0px",width: "90%"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ACT PGIC Scores</div>
            <CmReportTable004B  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pgicACT004B"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "15px", paddingRight: "0px",width: "90%"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ST PGIC Scores</div>
            <CmReportTable004BST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"pgicST004B"}></CmReportTable004BST>
                </div>
        </Container>
       


</>);

};

export default B004PGIC;




