import React from "react";
import GraphTemplateEPROsB from "../../graphs/GraphTemplateEPROsB";
// import GraphTemplateEPROsA from "./src/graphs/GraphTemplateEPROsA";

// import GraphTemplateEPROsA from "../../graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

import AdminNavbar from "../../components/AdminNavbar";import {Col, Container, Row} from "react-bootstrap";

const B004FIQRgraphs = () => {
  return (
    <>
      <AdminNavbar />
      <AnotherReportsListSidebar004B/>
      <Container fluid={true} >
      {/* style={{align:'left'}} */}
        <div className="mt-4">
        <div className="article">
          <div className="TitleC">FIQR</div>
          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ACT Daily</div>
              <GraphTemplateEPROsB report={"ACT_fiqr004Bdaily"} />
            </Col>
          </Row>
        
 <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ACT Weekly</div>
              <GraphTemplateEPROsB report={"ACT_fiqr004Bweekly"} />
            </Col>
          </Row>

          <Row>
            <Col xs={10}>
            <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ST</div>
              <GraphTemplateEPROsB report={"ST_fiqr004B"} />
            </Col>
          </Row>

          

          </div>
        </div>
      </Container>
    </>
  );
};

export default B004FIQRgraphs;
{/* <AdminNavbar/>
<Jumbotron>
    <Container fluid={true}>
        <Row>
            <Col>
                <h1>004 Participants</h1>
            </Col>
        </Row>
    </Container>
</Jumbotron>
<AnotherReportsListSidebar004B/> */}
