import React from "react";
import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const TWexpl004B2 = (props) => {
  return (
    <>


                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use. All scores ≤ end_week12.</div>

   </>
  );
};

export default TWexpl004B2;