import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";

import AreportsListSidebar from "./AreportsListSidebar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import AReportTable from "./AReportTable";
// const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const PandExFablesOfTables = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Pacing & Exercise</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AreportsListSidebar/>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}> */}
            {/* <TabContainer defaultActiveKey={"overview"}> */}
{/* <Container fluid={true}> */}
            {/* <div className={"sidebar-sticky"}> */}
            {/* <Nav className={"flex-column "}> */}
            {/* wtf */}
            {/* <NavItem> */}

            {/* </Nav> */}
                {/* </TabContainer> */}
             {/* </Container> */}
        {/* </Container> */}

        {/* <div className="article.TitleC">
        KPIs
        </div> */}
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
        </Container>  */}
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Pacing</p>
            <AReportTable tableKey={"pacing"}></AReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Exercise</p>
            <AReportTable tableKey={"exercise"}></AReportTable>
                </div>
        </Container>

</>);

};

export default PandExFablesOfTables;

