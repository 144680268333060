import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003AxS from "./CmReportTable003AxS";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003Ax2smaller from "./CmReportTable003Ax2smaller";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

const A003addpracticesJF = (props) => {
    return (<>
 
 <Helmet>
        <title>JF Other Therapies and Practices 003A</title>
        <meta name="JF Other Therapies and Practices 003A" content="JF Other Therapies and Practices 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>*JF* Other Therapies and Practices 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 
 

 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "40px",paddingTop: "20px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "20px", fontSize: "40px",lineHeight: "1" }}   >Baseline Counts By Workflow, Completion Status
            <span style={{fontSize: "15px",paddingLeft:"15px",paddingBottom: "0px"}} >includes completers only; done_complete & done_incomplete</span>
            <span style={{fontSize: "13px",paddingLeft: "20px", paddingTop: "0px",paddingBottom: "0px"}}>(done_incomplete: did not complete part1)</span>
            </div>
        
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "20px"}} report={"ACT003A_otherPrac_baselineJF"} />


                </div>
        </Container>
   

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingTop: "40px", paddingBottom: "20px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "20px", fontSize: "40px",lineHeight: "1" }}   >Baseline Counts By Workflow, FIQR Response
            <span style={{fontSize: "15px",paddingLeft:"15px",paddingBottom: "0px"}} >includes completers only; done_complete & done_incomplete</span>
            <span style={{fontSize: "13px",paddingLeft: "20px", paddingTop: "0px",paddingBottom: "0px"}}>(done_incomplete: did not complete part1)</span>
            </div>
        
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "20px"}} report={"ACT003A_otherPrac_fiqr_baselineJF"} />


                </div>
        </Container>


      
 </>);

};

export default A003addpracticesJF;
