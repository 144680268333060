import React from "react";
import "../surveyAdherence.css";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import BReportTable004B from "./BReportTable004B";
import CmReportTable004Bext from "./CmReportTable004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
// import TWexpl004B from "./TWexpl004B";

import { HashLink } from "react-router-hash-link";






const B004extFivefacetsNR_byTherapyWeek = (props) => {
    // usePageName('FFQM-NR-TW 004-2X');
 return (<>
 <Helmet>
        <title>FFQM-NR-TW 004-2X</title>
        <meta name="FFQM-NR-TW 004-2X" content="FFQM-NR-TW 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>

            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FFQM-NR Scores by Therapy Week, Cohort 2 Extension</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>

 {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "175px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container> */}
      

       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>
                   

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
               
            </Container>
            <div className="TitleC">ACT FFQM-NR Scores</div>
            <CmReportTable004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fivefacetsNRTW_004Bext_ACT"}></CmReportTable004Bext>
                </div>
        </Container>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="ST" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">ST FFQM-NR Scores</div>
            <CmReportTable004BextST style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fivefacetsNRTW_004Bext_ST"}></CmReportTable004BextST>
                </div>
        </Container>


</>);

};

export default  B004extFivefacetsNR_byTherapyWeek;

// fthfghfg
