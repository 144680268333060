import React,{ useState, useEffect } from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row, Table} from "react-bootstrap";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AsmallerReportTable004Bextdaily from "./AsmallerReportTable004Bextdaily";
import AsmallerReportTable004Bextweekly from "./AsmallerReportTable004Bextweekly";
import GraphTemplate004Bext from "./GraphTemplate004Bext";
import { HashLink } from "react-router-hash-link";
import  PROkeyTableXSext2 from "./PROkeyTableXSext2";
import  PROkeyTableXSext3 from "./PROkeyTableXSext3";

import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../utils/firebase";


import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import {Helmet} from "react-helmet";

const Bext004Part2 = (props) => {
    const [times_all_tasks_started, setTimes_all_tasks_started] = useState([]);
    const [times_chosen, setTimes_chosen] = useState([]);
    const [loading, setLoading] = useState(false);

    let { report } = props;
    const getTimes_all_tasks_started = async () => {
       if (loading) {
         return;
       }
       setLoading(true);
       const query = await firestore.collection("analytics").doc("part2_playlists004Bx0").collection("playlistSelection004Bx0_sums").doc("playlistSelection004Bx0_sums").get();
       const times_all_tasks_started = query.data().times_all_tasks_started;
       setTimes_all_tasks_started(times_all_tasks_started);
       console.log(times_all_tasks_started)
       return times_all_tasks_started;
       
    
    }
    useEffect(() => {
        getTimes_all_tasks_started();
      }, [times_all_tasks_started]);
   
      const getTimes_chosen = async () => {
        if (loading) {
          return;
        }
        setLoading(true);
        const query = await firestore.collection("analytics").doc("part2_playlists004Bx0").collection("playlistSelection004Bx0_sums").doc("playlistSelection004Bx0_sums").get();
        const times_chosen = query.data().times_chosen;
        setTimes_chosen(times_chosen);
        console.log(times_chosen)
        return times_chosen;
        
     
     }
     useEffect(() => {
         getTimes_chosen();
       }, [times_chosen]);
  


 return (<>
  <Helmet>
		<title>Part 2 Playlists 004-2x</title>
		<meta name="Part 2 Playlists 004-2x" content="Part 2 Playlists 004-2x" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1 style={{paddingBottom: "9px"}}>Part 2 Playlists, Cohort 2 Extension </h1>
                            


                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>
       

     
                

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>



    
        <Container fluid={true} style={{ paddingBottom: "10px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
            <Row>
                <Col>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#journal_completion_by_playlist">•skip to Journal Completion by Playlist</HashLink>
                <br/>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#lesson_completion_byPlaylist">•skip to Lesson Completion  by Playlist</HashLink>
                <br/>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#audio_completion_byPlaylist">•skip to Audio Completion  by Playlist</HashLink>
                <br/> <br/>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#journal_completion">•skip to Journal Completion</HashLink>
                <br/>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#lesson_completion">•skip to Lesson Completion</HashLink>
                <br/>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#audio_completion">•skip to Audio Completion</HashLink>
                

            <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>

                <br />
         
                </Col>
            </Row>

        </Container>

{/* <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#oneShots">•skip to One Shot Wonders</HashLink>
					<br />
                    <HashLink  className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#effectEncounter">•skip to Completion by Encounter</HashLink>
                    <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div> */}
        </div> 
        </Container> 
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>

   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <div className="TitleDB" style={{fontSize: "42px", paddingLeft: "0px",paddingBottom: "10px"}} >TempoACT2, ST → ACT arm</div>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "1px", paddingTop: "14px"}}>Playlist Selection</div>
   <div className="TitleC" style={{fontSize: "20px",paddingBottom: "1px", paddingTop: "0px",paddingLeft: "15px"}}>Key</div>

<div style={{paddingLeft: "15px", paddingTop: "4px"}}>

<Table className={"twoArm_tableB"} fluid={true} style={{ width: "auto", paddingLeft: "3px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px"}}>
    {/* <thead>
    <tr >
        <th style={{fontSize: "20px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,paddingLeft: "15px",lineHeight: "1.0"}}>
Key
        </th>
        <th></th>
    </tr >
    </thead> */}
  <tbody>
    <tr >
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>tasks</td>
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}># of tasks in playlist</td>
    </tr>
    <tr >
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>mean_tasks_started</td>
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>average # of tasks participants using that playlist at least started</td>
    </tr>
    <tr >
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>times_all_tasks_started</td>
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>total # of times participants at least started all of the playlist's tasks</td>
    </tr>
    <tr >
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>times_chosen</td>
    <td style={{fontSize: "14px",borderStyle: "solid", borderColor: "#CDCDCD",borderWidth: "1px" ,padding: "5px",lineHeight: "1.0"}}>total # of playlist was selected</td>
    </tr>
    </tbody>
</Table>
</div>

   <PROkeyTableXSext2  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"playlistSelection004Bx0"}></PROkeyTableXSext2>
  
   </div>
   <div style={{paddingLeft: "800px"}}>
   <PROkeyTableXSext2  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"playlistSelection004Bx0_sums"}></PROkeyTableXSext2>
   </div>
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="journal_completion_by_playlist" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Journal Completion by Playlist</div>



   <PROkeyTableXSext3 bgColor="#DBF1F8"   style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"journal_completion004Bx0_byPlaylist"}></PROkeyTableXSext3>
  
   </div>
  
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="lesson_completion_byPlaylist" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Lesson Completion by Playlist</div>



   <PROkeyTableXSext3 bgColor="#CED4DA"  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"lesson_completion004Bx0_byPlaylist"}></PROkeyTableXSext3>
  
   </div>
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="audio_completion_byPlaylist" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Audio Completion by Playlist</div>



   <PROkeyTableXSext3 bgColor="#A6ADD6"  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"audio_completion004Bx0_byPlaylist"}></PROkeyTableXSext3>
  
   </div>
  
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="journal_completion" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Journal Completion</div>



   <PROkeyTableXSext3 bgColor="#DBF1F8"  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"journal_completion004Bx0"}></PROkeyTableXSext3>
  
   </div>
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="lesson_completion" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Lesson Completion</div>



   <PROkeyTableXSext3 bgColor="#CED4DA"  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"lesson_completion004Bx0"}></PROkeyTableXSext3>
  
   </div>
   
   <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>
   <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
                <Row>
                    <Col>

                        <HashLink id="audio_completion" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    
            </Container>
   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px", lineHeight: "1"}}>Audio Completion</div>



   <PROkeyTableXSext3 bgColor="#A6ADD6"  style={{ paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px"}} tableKey={"audio_completion004Bx0"}></PROkeyTableXSext3>
  
   </div>
   
       
       </Container>





</>);

};

export default Bext004Part2;

         
