import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";

    
import CmReportTable004Bext from "./CmReportTable004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext"; 

import { HashLink } from "react-router-hash-link";

 




const Bext004FIQR = (props) => {
    
    // usePageName('FIQR-Visit 004-2X');
 return (<>
 <Helmet>
        <title>FIQR-Visit 004-2X</title>
        <meta name="FIQR-Visit 004-2X" content="FIQR-Visit 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores by Visit, Cohort 2 Extension</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
     <AnotherReportsListSidebar004Bext/>
     {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>
                   

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "175px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
               
            </Container> */}
        

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>
                   

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
               
            </Container>
            <div className="TitleC">ACT FIQR Scores</div>
            <CmReportTable004Bext  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fiqrBV_004B_ACText"}></CmReportTable004Bext>
                </div>
        </Container>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="ST" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">ST FIQR Scores</div>
            <CmReportTable004BextST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fiqrBV_004B_SText"}></CmReportTable004BextST>
                </div>
        </Container>
       


</>);

};

export default Bext004FIQR;




