import React from "react";
import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const AnotherReportsListSidebar003A = (props) => {
  return (
    <>
    {/* <noPrint> */}
      <Container className="no-print" fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px" }  }>
        <div className="sidenav">

        <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/pokeListexA">
        Poke List Supplement
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/inprogressA">
        Open Screens & Baselines
            </Link>
          </div>
          <br/>

        {/* <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/dashA">
Summary
            </Link>
          </div> */}
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/consortA">
Consorts
            </Link>
          </div>
          <div>
        <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/sources_centersA">
        Centers & Sources
            </Link>
          </div>
                  
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/screeningA">
Screening
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/eligibilityA">
Eligibility
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/appsattribA">
Apps & Attribution
            </Link>
          </div>
          {/* <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/baselinesA">
Baselines
            </Link>
          </div> */}
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/demographicsA">
Demographics
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/addpracticesA">
Other Therapies & Practices
            </Link>
          </div>
         
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/earlytermsA">
Entrances & Exits
            </Link>
          </div>       
       
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/indiv_scoresA">
Scores by Participant
            </Link>
          </div>
          {/* App Use */}
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/appuseA">
App Use
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/journalsA">
Other ACT Journals
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/sessionsA">
Sessions
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/chptevalsA">
Chapter Evals
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/insightsA">
Insights
            </Link>
          </div>

          <br/>

          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/mauqA">
MAUQ
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/patientprefsA">
Patient Preferences
            </Link>
          </div>
 
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/fiqrA">
FIQR
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/pgicA">
PGIC
            </Link>
          </div>
 
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/painintA">
Pain Intensity
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/paininfA">
Pain Interference
            </Link>
          </div>
 
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/sleepinfA">
Sleep Interference
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/bdiA">
BDI
            </Link>
          </div>
          <br/>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/ffqmnrA">
FFQM-NR
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/mhcsA">
MHCSA5
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/gad7A">
GAD7
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/whoqA">
WHOQ
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/cpaq8AEA">
CPAQ8-AE
            </Link>
          </div>
        <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/cpaq8PWA">
CPAQ8-PW
            </Link>
          </div>
        <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/fiqrFnA">
FIQR Function
            </Link>
          </div>
        <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/fiqrImA">
FIQR Impact
            </Link>
          </div>
        <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/fiqrSyA">
FIQR Symptoms
            </Link>
          </div>

          <br/>
          <div>Extension</div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/extA">
Extension Summary
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/appextA">
Extension App Use
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/003/pgicextA">
Extension PGIC
            </Link>
          </div>

          <br/>
          <br/>
          <div >Tempest Links</div>      

        
          <div>
<a className="TitleDB" style={{  fontSize: "12px" }} href="https://tempo.systems/surveysaurus/stats">
               Surveysaurus Tempest
            </a>
          </div>

        </div>
      </Container>
   
    </>
  );
};

export default AnotherReportsListSidebar003A;
