import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A99Ind from "./CmReportTable005A99Ind";
import CmReportTable005A2 from "./CmReportTable005A2";
import ScrollUpButton from "react-scroll-up-button";
import { HashLink } from "react-router-hash-link";


const A005siteHealth = (props) => {
return (<>
 <Helmet>
        <title>Site Health 005A</title>
        <meta name="ST Outcomes 005A" content="Site Health 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Site Health 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
<ReportsListSidebar005A/> 
   <ScrollUpButton/>

     
<Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "0px", paddingTop: "20px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "0px", width: "auto"}}> 
<div className="TitleC" style={{paddingTop: "10px",paddingBottom: "12px",fontSize: "45px"}}>Site Participant Statuses</div>
<CmReportTable005A99Ind style={{padding: "0px"}} thFontSize="13px" bgColor="#9E005D" tableKey=  {"siteHealthA1"} />                

                </div>
</Container>
</Row>


</>);

};

export default A005siteHealth;