import { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
import PokeTable from "./PokeListTable";
import "../reports/surveyAdherence.css";
import { isExcludedEmail } from "./ExcludeFromStats";
import { diffHr } from "../utils/helpers/pokeListHelpers";

const PokeList003 = () => {
  const [inactiveUsers, setInactiveUsersToContact] = useState("");
  const [OO3usersToContact, setOO3usersToContact] = useState("");

  // 003-cohort-1

  const getUserInfo = (user, timeCompleted, actionText) => {
    return {
      id: user.uid,
      subjectID: user.subjectID,
      hours: timeCompleted,
      cohort: user.cohort,
      program: user.activeProgram,
      action: actionText,
      extension: user.extension,
      email: user.email,
      timezone: user.timezone,
      role: user.role

    };
  };
  // "002-brachio","002-fukui",
  useEffect(() => {
    const getUsers = async () => {
      let allUsers = await firestore
        .collection("users")
        .where("cohort", "in", ["003-cohort-1"])
        .get();

      for (let doc of allUsers.docs) {
        const userData = doc.data();

        // for active (ACT) 'ext therapy', 'therapy'
        if (
          (userData.activeProgram === "tempoACT2" ||
            userData.activeProgram === "tempoACT3") &&
          userData.do_not_nudge !== true &&
          userData.extension!=='accepted'  &&
          userData.workflowStatus === 'therapy' 
          && userData.role=='patient'
          && !isExcludedEmail(userData.email)
        ) {
          let sessions = await firestore
            .collection("users")
            .doc(doc.id)
            .collection("programs")
            .doc(userData.activeProgram)
            .collection("sessions")
            .orderBy("dateCompleted", "desc")
            .limit(1)
            .get();

          if (sessions.docs) {
            let sessionEntry = sessions.docs[0];
            let sessionData = sessionEntry?.data();
            let completed = sessionData?.dateCompleted;
            let started = sessionData?.dateStarted;

            if (completed !== null && started !== null) {
              var lastCompleted = diffHr(new Date(), completed?.toDate());
            } else {
              var firstStartedProgram = diffHr(new Date(), started?.toDate());
              setInactiveUsersToContact([
                getUserInfo(userData, 'needs to start Tempo', "text + needs compliance call"),
              ]);
            }
            /*
             *  If session lastCompleted within range of 48 to 72 hours ago,
             *  put them in text list
             */
            if (lastCompleted >= 24 && lastCompleted <= 48) {
              setOO3usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCompleted, " push"),
              ]);
            }
            if (lastCompleted > 48 && lastCompleted <= 72) {
              setOO3usersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, lastCompleted, "text + push"),
              ]);
            }

            if (lastCompleted > 72) {
              setOO3usersToContact((prevState) => [
                ...prevState,
                getUserInfo(
                  userData,
                  lastCompleted,
                  "push + text every 36 hours"
                ),
              ]);
            }
            else {
             
            }
          }
        }
      }
    };

    getUsers();
  }, []);

  return (
    <>
      {OO3usersToContact.length > 0 && (
        <>
          <h4 className="pokelist-header">
            Contact these 003 people today
          </h4>
          <PokeTable users={OO3usersToContact} tableType='extension' />
        </>
      )}
      {inactiveUsers.length > 0 && (
        <>
          <h4 className="pokelist-header">
            Contact these 003 inactive users today
          </h4>
          <PokeTable inactiveType={true} users={inactiveUsers} tableType='extension' />
        </>
      )}
    </>
  );
};

export default PokeList003;
