import React,{ useState, useEffect,Component }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import CmReportTableVS1stickyHnoInd from "./CmReportTableVS1stickyHnoInd";
import CmReportTableVS1noIndRTMS from "./CmReportTableVS1noIndRTMS";
import CmReportTableVS1stickyRKMN from "./CmReportTableVS1stickyRKMN";
import CmReportTableVS1stickyHnoIndRfml from "./CmReportTableVS1stickyHnoIndRfml";
import CmReportTableVS1stickyHRfml from "./CmReportTableVS1stickyHRfml";
import GraphTemplateVSA from "./GraphTemplateVSA";

import CmReportTableVS1noInd from "./CmReportTableVS1noInd";


import ReportsListSidebarSCA from "./ReportsListSidebarSCA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";




const SCopensA = (props) => {
        const [updated, setUpdated] = useState([]);
        const [med_risk, setMed_risk] = useState([]);
        const [high_risk_disq, setHigh_risk_disq] = useState([]);
        const [on_track, setOn_track] = useState([]);
        const [high_risk, setHigh_risk] = useState([]);
        const [met_requirement, setMet_requirement] = useState([]);
        const [med_risk_label, setMed_risk_label] = useState([]);
        const [med_risk_label_expl, setMed_risk_label_expl] = useState([]);

        const [fruit, setFruit] = useState([]);
        const [fruit_label, setFruit_label] = useState([]);
        const [fruit_label_expl, setFruit_label_expl] = useState([]);

        const [red_fruit, setRed_fruit] = useState([]);
        const [red_fruit_label, setRed_fruit_label] = useState([]);
        const [red_fruit_label_expl, setRed_fruit_label_expl] = useState([]);



        const [high_risk_disq_label, setHigh_risk_disq_label] = useState([]);
        const [on_track_label, setOn_track_label] = useState([]);
        const [on_track_label_expl, setOn_track_label_expl] = useState([]); 
        const [high_risk_label, setHigh_risk_label] = useState([]);
        const [high_risk_label_expl, setHigh_risk_label_expl] = useState([]);
        const [met_requirement_label, setMet_requirement_label] = useState([]);
    
        const [percent_days, setPercent_days] = useState([]);
        const [percent_days_label, setPercent_days_label] = useState([]);

        const [percent_remaining_days, setPercent_remaining_days] = useState([]);
        const [percent_remaining_days_label, setPercent_remaining_days_label] = useState([]);

        const [chpt_expl, setChpt_expl] = useState([]);
        const [chpt_label, setChpt_label] = useState([]);

        const [starredIDs_expl, setStarredIDs_expl] = useState([]);
        const [starredIDs_label, setStarredIDs_label] = useState([]);


        const [riskgroup1, setRiskgroup1] = useState([]);
        const [riskgroup2, setRiskgroup2] = useState([]);
        const [riskgroup3, setRiskgroup3] = useState([]);
        const [riskgroup4, setRiskgroup4] = useState([]);
        const [riskgroup5, setRiskgroup5] = useState([]);
        const [riskgroup6, setRiskgroup6] = useState([]);
        const [riskgroup7, setRiskgroup7] = useState([]);
        const [riskgroup8, setRiskgroup8] = useState([]);
        const [riskgroup1_expl, setRiskgroup1_expl] = useState([]);
        const [riskgroup2_expl, setRiskgroup2_expl] = useState([]);
        const [riskgroup3_expl, setRiskgroup3_expl] = useState([]);
        const [riskgroup4_expl, setRiskgroup4_expl] = useState([]);
        const [riskgroup5_expl, setRiskgroup5_expl] = useState([]);
        const [riskgroup6_expl, setRiskgroup6_expl] = useState([]);
        const [riskgroup7_expl, setRiskgroup7_expl] = useState([]);
        const [riskgroup8_expl, setRiskgroup8_expl] = useState([]);
        
         
        const [under30, setUnder30] = useState([]);
        const [under30_label, setUnder30_label] = useState([]);
        const [under30_expl, setUnder30_expl] = useState([]);
        
        const [sixday_risk_expl, setSixday_risk_expl] = useState([]);
const [sixday_risk_label, setSixday_risk_label] = useState([]);
const [sixday_risk_transitions_label, setSixday_risk_transitions_label] = useState([]);
const [sixday_risk_transitions_expl, setSixday_risk_transitions_expl] = useState([]);

const [patients_removed, setPatients_removed] = useState([]);
const [patients_removed_expl, setPatients_removed_expl] = useState([]);   

const [plot_expl, setPlot_expl] = useState([]);
// 

const [since_date, setSince_date] = useState([]);

const [patients_with_no_opens, setPatients_with_no_opens] = useState([]);
const [patients_with_no_opens_user_ids, setPatients_with_no_opens_user_ids] = useState([]);



        const getTitleCs = async (titleC,setter) => { 
            const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("app_opens");
            const doc = await dashes.get();
            const docData=doc.data()
            const titC = docData[titleC];
                console.log(titC)
                setter(titC);
           }
    
           useEffect(() => {  
            getTitleCs('updated',setUpdated)
            getTitleCs('med_risk',setMed_risk)
            getTitleCs('high_risk_disq',setHigh_risk_disq)
            getTitleCs('on_track',setOn_track)
            getTitleCs('high_risk',setHigh_risk)
            getTitleCs('met_requirement',setMet_requirement)
            getTitleCs('med_risk_label',setMed_risk_label)
            getTitleCs('med_risk_label_expl',setMed_risk_label_expl)

            getTitleCs('high_risk_disq_label',setHigh_risk_disq_label)
            getTitleCs('on_track_label',setOn_track_label)
            getTitleCs('on_track_label_expl',setOn_track_label_expl) 
            getTitleCs('high_risk_label',setHigh_risk_label)
            getTitleCs('met_requirement_label',setMet_requirement_label)
              getTitleCs('high_risk_label_expl',setHigh_risk_label_expl)

              getTitleCs('fruit',setFruit)
              getTitleCs('fruit_label',setFruit_label)
              getTitleCs('fruit_label_expl',setFruit_label_expl)
              getTitleCs('red_fruit', setRed_fruit)
              getTitleCs('red_fruit_label', setRed_fruit_label)
              getTitleCs('red_fruit_label_expl', setRed_fruit_label_expl)
              
            getTitleCs('under30', setUnder30)
            getTitleCs('under30_label', setUnder30_label)
            getTitleCs('under30_expl', setUnder30_expl)


              getTitleCs('percent_remaining_days',setPercent_remaining_days)
              getTitleCs('percent_days',setPercent_days)
              getTitleCs('percent_days_label',setPercent_days_label)
              getTitleCs('percent_remaining_days_label',setPercent_remaining_days_label)

              getTitleCs('chpt_expl',setChpt_expl)
              getTitleCs('chpt_label',setChpt_label)
          getTitleCs('starredIDs_expl',setStarredIDs_expl)
              getTitleCs('starredIDs_label',setStarredIDs_label)

              getTitleCs('riskgroup1',setRiskgroup1)
              getTitleCs('riskgroup2',setRiskgroup2)
              getTitleCs('riskgroup3',setRiskgroup3)
              getTitleCs('riskgroup4',setRiskgroup4)
              getTitleCs('riskgroup5',setRiskgroup5)
              getTitleCs('riskgroup6',setRiskgroup6)
              getTitleCs('riskgroup7',setRiskgroup7)
              getTitleCs('riskgroup8', setRiskgroup8)             
              getTitleCs('riskgroup1_expl',setRiskgroup1_expl)
              getTitleCs('riskgroup2_expl',setRiskgroup2_expl)
              getTitleCs('riskgroup3_expl',setRiskgroup3_expl)
              getTitleCs('riskgroup4_expl',setRiskgroup4_expl)
              getTitleCs('riskgroup5_expl',setRiskgroup5_expl)
              getTitleCs('riskgroup6_expl',setRiskgroup6_expl)
              getTitleCs('riskgroup7_expl',setRiskgroup7_expl)
              getTitleCs('riskgroup8_expl', setRiskgroup8_expl)                 

              getTitleCs("sixday_risk_expl",setSixday_risk_expl)
              getTitleCs("sixday_risk_label",setSixday_risk_label)
              getTitleCs("sixday_risk_transitions_label",setSixday_risk_transitions_label)
              getTitleCs("sixday_risk_transitions_expl",setSixday_risk_transitions_expl)
              getTitleCs('since_date',setSince_date)

              getTitleCs('patients_removed', setPatients_removed)
getTitleCs('patients_removed_expl', setPatients_removed_expl)
getTitleCs('plot_expl', setPlot_expl)

              getTitleCs('patients_with_no_opens',setPatients_with_no_opens)
            getTitleCs('patients_with_no_opens_user_ids',setPatients_with_no_opens_user_ids)
        }, [props]);


return (<>


 <Helmet>
        <title>App Opens SCA</title>
        <meta name="App Opens SCA" content="App Opens SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        

       

      
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#patientsnoopens">•Patients with No Opens & Excluded Patients</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#summary">•Summary</HashLink>
               <br/>

               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#active">•Active Patients</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{lineHeight: "1", paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#inactive">•Inactive Patients</HashLink>
               <br/>
               <Row style={{paddingTop: "3px"}}></Row>
               <HashLink id="top" className="TitleDB" style={{ lineHeight: "1",paddingTop: "20px",paddingBottom: "50px", fontSize: "17px", paddingLeft: "15px",color: "#000000" }} to="#summary_metrics">Summary Metrics</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#billing_perMonth">•Billing Cycles per Month</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#billing_perWeek">•Billing Cycles per Week</HashLink>
               <br/>
      {/* billing_perWeek */}
      <Row style={{paddingTop: "6px"}}></Row>
      <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#removed">•Patients Removed from RTM</HashLink>
               <br/>
      {/* removed  Patients Removed from RTM */}
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true}>
                   <div className="article" style={{paddingLeft: "0px", paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>App Opens<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
</div>


                   </Container>
                   <Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "30px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div >
    {/* ,paddingLeft: "200px"  style={{paddingBottom: "15px"}} */}
<Row > 
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px",color: "#000000"}}>Risk Legend</div>
<table style={{padding: "0px",border: "1px solid  #B9B9B9"}}>
 
<tbody>
<tr>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{high_risk_disq}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{high_risk_disq_label}</td>
</tr>
<tr>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{under30}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{under30_label}</td>
</tr>
<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{high_risk}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{high_risk_label}<span style={{fontSize: "10px" ,paddingLeft: "62px",paddingRight: "0px"}}>{high_risk_label_expl}</span></td>
{/* <td style={{width: "155px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{high_risk_label_expl}</td> */}
</tr>

<tr>

<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000",borderRight: "double white",paddingRight:"2px",paddingLeft: "8px", fontSize: "12px",verticalAlign: "top",paddingTop: "0px"}}>{red_fruit}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "14px", fontSize: "11px",verticalAlign: "top",paddingTop: "0px"}}>{red_fruit_label}<span style={{fontSize: "10px" ,paddingLeft: "55px"}}>{red_fruit_label_expl}</span></td>
{/* paddingBottom: "4px", */}
</tr>

<tr>

<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000",borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{med_risk}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px",paddingBottom: "0px"}}>{med_risk_label}<span style={{fontSize: "10px" ,paddingLeft: "42px",paddingRight: "0px"}}>{med_risk_label_expl}</span></td>

</tr>
<tr>

<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000",borderRight: "double white",paddingRight:"2px",paddingLeft: "8px", fontSize: "12px",verticalAlign: "top",paddingTop: "0px"}}>{fruit}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "14px", fontSize: "11px",verticalAlign: "top",paddingTop: "0px"}}>{fruit_label}<span style={{fontSize: "10px" ,paddingLeft: "15px"}}>{fruit_label_expl}</span></td>
{/* paddingBottom: "4px", */}
</tr>
<tr>
{/* <td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}></td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}><span style={{fontSize: "10px" ,paddingLeft: "65px"}}></span></td> */}

</tr>
<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{on_track}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{on_track_label}<span style={{fontSize: "10px" ,paddingLeft: "65px"}}>{on_track_label_expl}</span></td>
{/* on_track_label_expl */}
</tr>


<tr style={{borderBottom: "2px solid  #B9B9B9"}}>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000", borderRight: "double white",borderBottom: "1px solid  #B9B9B9",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{met_requirement}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",borderBottom: "1px solid  #B9B9B9",paddingLeft: "4px", fontSize: "12px"}}>{met_requirement_label}</td>


</tr>

</tbody>
</table>
</Col>


<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "15px",color: "#000000"}}>Risk Group</div>
<table style={{padding: "0px",border: "1px solid  #B9B9B9"}}>
 
<tbody>
<tr>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup1}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup1_expl}</td>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup2}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup2_expl}</td>
</tr>



<tr>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000",borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup3}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px",paddingBottom: "0px"}}>{riskgroup3_expl}</td>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup4}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup4_expl}</td>
</tr>


<tr>
{/* <td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}></td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}><span style={{fontSize: "10px" ,paddingLeft: "65px"}}></span></td> */}
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup5}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup5_expl}</td>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup6}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup6_expl}</td>
</tr>

<tr>
<td style={{width: "25px",backgroundColor: "#F4F4F4", color: "#000000", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup7}</td>
<td style={{width: "290px",backgroundColor: "#FFFFFF", color: "#000000", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup7_expl}</td>
</tr>


<tr style={{borderBottom: "2px solid  #B9B9B9"}}>
<td style={{width: "25px",backgroundColor: "#FFFFFF", color: "#000000", borderRight: "double white",borderBottom: "1px solid  #B9B9B9",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup8}</td>
<td style={{width: "290px",backgroundColor: "#F4F4F4", color: "#000000", borderLeft: "double white",paddingRight:"5px",borderBottom: "1px solid  #B9B9B9",paddingLeft: "4px", fontSize: "12px"}}>{riskgroup8_expl}</td>
</tr>

</tbody>
</table>
</Col>

</Row>


<div style={{paddingTop: "30px",paddingBottom: "0px", maxWidth:"100%"}}>
<Row >

<Col>

<table style={{padding: "0px",border: "double white"}}>
 
<tbody>
<tr>
</tr>

<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{percent_days_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{percent_days}</td>
</tr>

<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{percent_remaining_days_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{percent_remaining_days}</td>
</tr>





<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{sixday_risk_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{sixday_risk_expl}<span style={{paddingLeft: "8px", fontSize: "9px"}}>({since_date} — today)</span></td>
</tr>

<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{sixday_risk_transitions_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{sixday_risk_transitions_expl}</td>
</tr>
{/* {since_date} */}
<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{chpt_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{chpt_expl}</td>
</tr>

<tr>
<td style={{width: "115px",backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>*{starredIDs_label}:</td>
<td style={{width: "400px",backgroundColor: "#FFFFFF", color: "#636161", borderLeft: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{starredIDs_expl}</td>
</tr>
{/* starredIDs_label starredIDs_expl */}
</tbody>
</table>
</Col>
</Row>
</div>
{/* <div className="TitleC" style={{paddingTop: "30px",paddingBottom: "0px",fontSize: "10px",lineHeight: "1"}}>*{percent_days_label}:<span style={{fontSize: "10px",paddingLeft: "68px"}} >{percent_days}</span></div>
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "0px",fontSize: "10px",lineHeight: "1"}}>*{percent_remaining_days_label}:<span style={{fontSize: "10px",paddingLeft: "20px"}} >{percent_remaining_days}</span></div>
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "0px",fontSize: "10px",lineHeight: "1"}}>*{chpt_label}:<span style={{fontSize: "10px",paddingLeft: "108px"}} >{chpt_expl}</span></div> */}

</div>
  </div>

  </Row>

</Container>

<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "35px", paddingBottom: "5px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
            <div style={{ borderTop: "1.5px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"100%"  }}></div>

                <HashLink id="patientsnoopens" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Patients with No Opens: <span className="tooltipSCX TitleC" style={{paddingLeft: "12px",color: "#005176", fontSize: "40px"}}  >{patients_with_no_opens}<span class="tooltipSCX tooltiptext" style={{fontSize:"11px"}}>{patients_with_no_opens_user_ids}</span></span></HashLink>
                {/* <div class="tooltipSCX">Hover over me<span class="tooltipSCX tooltiptext">Tooltip text</span></div> */}
                <div style={{ borderTop: "1.5px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"100%"  }}></div>
<div className="TitleC" style={{ maxWidth:"70%",lineHeight: "1.1",paddingBottom: "0px", fontSize: "15px", paddingLeft: "0px",paddingTop: "15px" }}>{patients_removed_expl}</div>
<div className="TitleC" style={{color: "#000000", maxWidth:"70%",lineHeight: "1.1",paddingBottom: "0px", fontSize: "15px", paddingLeft: "0px",paddingTop: "2px" }} >{patients_removed}</div>
<div className="TitleC" style={{ maxWidth:"70%",lineHeight: "1.1",paddingBottom: "16px", fontSize: "15px", paddingLeft: "0px",paddingTop: "8px" }}>{plot_expl}</div>


<div style={{ borderTop: "1.5px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"100%"  }}></div>

            </Col>
            </Row>
            
            
          </div>
  </div>

  </Row>

</Container>


<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "10px", paddingBottom: "2px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="summary" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Summary</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
            
          </div>
  </div>

  </Row>

</Container>




<Container fluid={true}>
<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "30px", maxWidth: "90%"}}> 
<Row>
           <div >

            <Row style={{overflowX: "scroll", width: "auto",maxHeight:"200px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="3px"  thPaddingLeft="3px" bgColor="#000000" tableKey={"opens_risk_SummA"} />

</Col>
</Row>
</div>

</Row>
</div>

</Row>
</Container>


<Container fluid={true}>
<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "30px", maxWidth: "90%"}}> 
<Row>
        <div >
           <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "2px",fontSize: "25px"}}>By Week</div>

            <Row style={{overflowY: "scroll", width: "auto",maxHeight:"200px"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1noIndRTMS style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="9px" tdFontSize="9px" thPaddingRight="3px"  thPaddingLeft="3px" bgColor="#636161" tableKey={"opensSummTabx"} />

</Col>
</Row>
</div>

</Row>
</div>




</Row>
</Container>
<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "15px",maxWidth: "98%",paddingLeft: "90px"}}>   
<Row>
  <div>

  
  <Row>
 
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "2px",fontSize: "25px", paddingLeft: "15px"}}>SCP and no tag patients only</div>
<div style={{overflowX: "scroll", width: "auto",maxWidth: "98%"}}>
<GraphTemplateVSA style={{padding: "0px"}}    report={"app_opensSumm"} />
</div>
</Col>
</Row>
</div>
</Row>
</div>
</Row>
</Container>
{/* opensSummTabx */}

<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "2px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="active" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Active Patients</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
        
          </div>
  </div>

  </Row>

</Container>

<Container fluid={true}>

<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 
<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "9px",fontSize: "25px"}}>patients in part1, core content</div>
</Col>
</Row>
<Row>
           <div >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#0071CC" tableKey={"opens_risk_active_part1A"} />

</Col>
</Row>
</div>

</Row>
<Row>
           <div style={{padding: "0px",paddingTop: "30px"}} >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#1C5895" tableKey={"opens_risk_active_part1AB"} />

</Col>
</Row>
</div>
</Row>

</div>
</Row>
</Container>
<Container fluid={true}>

<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}>

<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "20px",paddingBottom: "9px",fontSize: "25px"}}>patients in part2, reinforcement content</div>
</Col>
</Row>
<Row>
           <div >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF8733" tableKey={"opens_risk_active_part2A"} />

</Col>
</Row>
</div>

</Row>
<Row>
           <div style={{padding: "0px",paddingTop: "30px"}} >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#F55C48" tableKey={"opens_risk_active_part2AB"} />

</Col>
</Row>
</div>
</Row>

</div>
</Row>

</Container>


<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "30px", paddingBottom: "2px", maxWidth: "95%"}}> 
<div style={{paddingBottom: "20px"}}>
        <Row>
            <Col>
                <HashLink id="inactive" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">Inactive Patients</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
          </div>
  </div>

  </Row>

</Container>


<Container fluid={true}>
<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<Row>
<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "9px",fontSize: "25px"}}>patients in part1, core content</div>
</Col>
</Row>
           <div >
            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

    <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} tdfcolor="#b9b9b9" fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF2F92" tableKey={"opens_risk_inactive_part1A"} />

</Col>
</Row>
</div>

</Row>
<Row>
           <div style={{padding: "0px",paddingTop: "30px"}} >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

    <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#D63D6D" tableKey={"opens_risk_inactive_part1AB"} />

</Col>
</Row>
</div>
</Row>

<Row>
<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "50px",paddingBottom: "9px",fontSize: "25px"}}>patients in part2, reinforcement content</div>
</Col>
</Row>
           <div >
            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} tdfcolor="#b9b9b9" fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#9843D1" tableKey={"opens_risk_inactive_part2A"} />

</Col>
</Row>
</div>
</Row>

<Row>
           <div style={{padding: "0px",paddingTop: "30px"}} >

            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

            <Col style={{paddingLeft: "10px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#8987D0" tableKey={"opens_risk_inactive_part2AB"} />

</Col>
</Row>
</div>
</Row>
</div>
</Row>
</Container>

<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "30px", paddingBottom: "2px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="summary_metrics" className="TitleC" style={{paddingTop: "0px", paddingBottom: "0px", fontSize: "45px", paddingLeft: "0px" }} to="#top">Summary Metrics</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div>


            </Col>
            </Row>
  
          </div>
  </div>

  </Row>

</Container>
<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="billing_perMonth" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "0px" }} to="#top">Billing Cycles per Month</HashLink>

                {/* <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div> */}


            </Col>
            </Row>
  
          </div>
  </div>

  </Row>

</Container>
<Container fluid={true} style={{paddingLeft: "0px"}}  >
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "15px",maxWidth: "98%",paddingLeft: "90px"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}    report={"app_opensA"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "0px",paddingLeft: "210px", maxWidth: "780px", paddingBottom: "0px"}} >
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "3px",fontSize: "12px",color:"#235D82",lineHeight: "1"}}>All Patients</div> */}

<Row style={{overflow: "scroll", width: "auto",maxWidth: "580px", height: "auto",maxHeight: "200px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"app_opensSummA"} />
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "50px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="billing_perWeek" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "0px" }} to="#top">Billing Cycles per Week, last 12 weeks</HashLink>

                {/* <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div> */}


            </Col>
            </Row>
  
          </div>
  </div>

  </Row>

</Container>
<Container fluid={true} style={{paddingLeft: "0px"}}  >
<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "15px",maxWidth: "98%",paddingLeft: "90px"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}    report={"app_opensB"} />
</Col>
</Row>
</div>
<div style={{paddingTop: "20px",paddingLeft: "210px", maxWidth: "780px"}} >

<Row style={{overflow: "scroll", width: "auto",maxWidth: "580px", height: "auto",maxHeight: "200px"}}>
<Col>
<CmReportTableVS1stickyHRfml style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#011893" tableKey={"app_opensSummB"} />
</Col>
</Row>
</div>
</Container>

<Container fluid={true}>

<Row>
<div className="articleVS" style={{paddingTop: "50px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="removed" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "0px" }} to="#top">Patients Removed from RTM</HashLink>

                {/* <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"95%"  }}></div> */}


            </Col>
            </Row>
  
          </div>
  </div>

  </Row>

</Container>
<Container fluid={true}>
<Row>

<div className="articleVS" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 

<Row>

           <div >
            <Row style={{overflow: "scroll", width: "auto",maxHeight:"300px",maxWidth: "78%"}}>

    <Col style={{paddingLeft: "40px"}}>
   
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px"}} tdfcolor="#b9b9b9" fColor="#FFF9F3" thFontSize="10px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF4D00" tableKey={"opens_remRTM"} />

</Col>
</Row>
</div>

</Row>

</div>

</Row>
</Container>

</>);

};

export default SCopensA;