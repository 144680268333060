import React, { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
import AdminNavbar from "../components/AdminNavbar";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
// import PROkeyTable from "../reports/PROkeyTable";
// import PROkeyTableXS from "../reports/PROkeyTableXS";
import "../reports/surveyAdherence.css";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

import PokeListUpmc from "./PokeListUpmc";


import ScrollUpButton from "react-scroll-up-button";

const upmcPokeList = () => {

  return (
    <>
      <Helmet>
        <title>UPMC Poke List</title>
        <meta name="UPMC Poke List" content="UPMC Poke List" />
      </Helmet>
      <AdminNavbar />
      <Jumbotron>
      <ScrollUpButton />

        <h1 className="pokelist-header">UPMC</h1>


       




      </Jumbotron>
 
      
 
 
 
      <Container fluid={true} style={{ backgroundColor: "white", marginBottom: "3rem" }}>
        <Col xs={8}>
        <PokeListUpmc/>
            <div style={{paddingBottom: "40px"}}></div>
           
            
     

      
         
       
        
        </Col>
      </Container>

  
     
    
    
    
    </>
  );
};

export { upmcPokeList };
