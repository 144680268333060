import React, {useEffect, useState} from "react";
import "../surveyAdherence.css";
import {firestore} from "../../utils/firebase";

import {Col, Table} from "react-bootstrap";

const CmReportTable003A = (props) => {
    const {tableKey} = props;
    const [tableDoc, setTableDoc] = useState();
    const [name, setName] = useState("");
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        firestore
            .doc("analytics/reports/reportTables003/" + tableKey)
            .get()
            .then((doc) => {
                const data = doc.data();
                setColumns(data.columns);
                setName(data.name);
                setTableDoc(doc);
                const path = data.collectionPath ? data.collectionPath : "analytics/reports/reportTables003/" + tableKey + "/rows";
                // surveysaurus_
                let query = data ? firestore
                .collection(path)
                .orderBy(data.orderBy.field, data.orderBy.direction) : firestore.collection(path);
                query = data?.orderBy2? query.orderBy(data.orderBy2.field, data.orderBy2.direction) : query;
                // let query = data ? firestore
                //                      .collection(path)
                //     .orderBy(data.orderBy.field, data.orderBy.direction)
                //                  : firestore.collection(path);
                query.onSnapshot((snap) => {
                    setRows(snap.docs);
                });
            });
    }, [tableKey]);

    const getTableRow = (progRef) => {
        let data = progRef.data();

        let cols = columns.map((c) => {
            let val = data[c];
            if (typeof data[c] === "object") {
                try {
                    // val = data[c].toDate().toLocaleDateString();
                    val = data[c].toDate().toISOString().slice(0,10);
                } catch (error) {
                    console.log('couldnt format something', {data, error})
                }
            }
            return <td style={{ fontSize: "12px", lineHeight: "1.1"}}>{val}</td>;
        });

        return (<tr key={progRef.id} onClick={() => {
            }}>
                {cols}
            </tr>);
    };
    if (!(columns && rows)) {
        return <> </>;
    }
    return (<>



            <Col className={"reports_tableX"} xs={12}>
                <Table style={{backgroundColor: "white", width: "55%", paddingLeft: "20px",paddingRight: "10px"}}>
                    <thead key={"tableHead" + (tableDoc ? tableDoc.id : "")}>
                    {/* <tr className={"twoArm_tableB"} key={"headerRow"}> */}
                    <tr style={{backgroundColor: "#555557"}}  key={"headerRow"} >
                        {columns.map((c) => {
                            return <th className={"twoArm_tableB"} style={{color:"#FFF9F3", fontSize: "14px"}} key={c.id}>{c}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody className={"twoArm_tableB"}  >{rows.map((u) => getTableRow(u))}</tbody>
                </Table>
            </Col>

        </>);
};

export default CmReportTable003A;


// sdfsdf
// style={{lineHeight: "1.1" }}