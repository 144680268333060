import "./App.css";
import "./bootstrap.min.css";
import { BrowserRouter, Route } from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import SignIn from "./utils/screens/SignIn";
import ProtectedRoute from "./utils/auth/ProtectedRoute";
import { Landing } from "./screens/Landing";
import UserProvider from "./utils/auth/UserProvider";
import { PokeList } from "./screens/PokeList";
import { OptumPokeList } from "./screens/OptumPokeList";
import { upmcPokeList}  from "./screens/upmcPokeList";

import PROkey from "./reports/PROkey";
import Insightskey from "./reports/Insightskey";
import AudioKey from "./reports/AudioKey";

import Graphs002 from "./reports/f002/Graphs002";

// import FiqrVfablesOfTable from "./reports/FiqrVfablesOfTable";
// import AppFablesOfTables from "./reports/AppFablesOfTables";
// import FablesOfTables from "./reports/FablesOfTables";
// import LessonsFablesOfTables from "./reports/LessonsFablesOfTables";
// import PandExFablesOfTables from "./reports/PandExFablesOfTables";
// import AreportsList from "./reports/AreportsList";
// import NpsFablesOfTables from "./reports/NpsFablesOfTables";

import FiqrVfablesOfTable from "./reports/f002Brachio/FiqrVfablesOfTable";
import AppFablesOfTables from "./reports/f002Brachio/AppFablesOfTables";
import FablesOfTables from "./reports/f002Brachio/FablesOfTables";
import LessonsFablesOfTables from "./reports/f002Brachio/LessonsFablesOfTables";
import PandExFablesOfTables from "./reports/f002Brachio/PandExFablesOfTables";
import AreportsList from "./reports/f002Brachio/AreportsList";
import NpsFablesOfTables from "./reports/f002Brachio/NpsFablesOfTables";

import A004Pro_compliance from "./reports/m004/A004Pro_compliance";
import B004Pro_compliance from "./reports/m004B/B004Pro_compliance";
import B004Insights from "./reports/m004B/B004Insights";
import B004PGIC from "./reports/m004B/B004PGIC";
import A004consort from "./reports/m004/A004consort";

import LessonsFablesOfTables004A from "./reports/m004/LessonsFablesOfTables004A";
import A004fivefacets_TW from "./reports/m004/A004fivefacets_TW";
import A004PromisT_TW from "./reports/m004/A004PromisT_TW";
import A004Promis_TW from "./reports/m004/A004Promis_TW";
import A004PromisT_all from "./reports/m004/A004PromisT_all";
import A004Promis_all from "./reports/m004/A004Promis_all";
import fuckthis004 from "./reports/m004/fuckthis004";
import A004FIQR from "./reports/m004/A004FIQR";
import A004byAppProgress from "./reports/m004/A004byAppProgress";
import A004FIQRbyAppProgress from "./reports/m004/A004FIQRbyAppProgress";
import A004FIQRbyTherapyWeek from "./reports/m004/A004FIQRbyTherapyWeek";
import A004byTherapyWeek from "./reports/m004/A004byTherapyWeek";
import Graphs004AST from "./reports/m004/Graphs004AST";
import Graphs004A from "./reports/m004/Graphs004A";
import A004FFNRgraphs from "./reports/m004/A004FFNRgraphs";
import A004WIgraphs from "./reports/m004/A004WIgraphs";
import A004PIPSgraphs from "./reports/m004/A004PIPSgraphs";
import A004FIQRgraphs from "./reports/m004/A004FIQRgraphs";
import A004OtherPROs from "./reports/m004/A004OtherPROs";
import A004Visits from "./reports/m004/A004Visits";
import AreportsList004A from "./reports/m004/AreportsList004A";
import A004Pros from "./reports/m004/A004Pros";
import A004Sites from "./reports/m004/A004Sites";
import exitInterviews004 from "./reports/s004all/exitInterviews004";
import AnInterviewsList from "./reports/AnInterviewsList";

import B004FIQRbyTherapyWeek from "./reports/m004B/B004FIQRbyTherapyWeek";
import B004byTherapyWeek from "./reports/m004B/B004byTherapyWeek";
import B004FIQR from "./reports/m004B/B004FIQR";
import B004Sites from "./reports/m004B/B004Sites";
import B004Visits from "./reports/m004B/B004Visits";
import B004Pros from "./reports/m004B/B004Pros";
import B004fivefacets_TW from "./reports/m004B/B004fivefacets_TW";
import B004cfq_TW from "./reports/m004B/B004cfq_TW";
import B004cpaq8PW_TW from "./reports/m004B/B004cpaq8PW_TW";
import B004cpaq8AE_TW from "./reports/m004B/B004cpaq8AE_TW";
import B004favorites from "./reports/m004B/B004favorites";
import B004fibroFlare from "./reports/m004B/B004fibroFlare";
import B004JournalTimes from "./reports/m004B/B004JournalTimes";

import Graphs004B from "./reports/m004B/Graphs004B";
import Graphs004BST from "./reports/m004B/Graphs004BST";
import SessionsFablesOfTables004B from "./reports/m004B/SessionsFablesOfTables004B";
import AreportsList004B from "./reports/m004B/AreportsList004B";
import B004consort from "./reports/m004B/B004consort";
import B004JournalMets from "./reports/m004B/B004JournalMets";
import B004FIQRgraphs from "./reports/m004B/B004FIQRgraphs";
import B004CPAQ8AEgraphs from "./reports/m004B/B004CPAQ8AEgraphs";
import B004CPAQ8PWgraphs from "./reports/m004B/B004CPAQ8PWgraphs";
import B004CFQgraphs from "./reports/m004B/B004CFQgraphs";
import B004FFNRgraphs from "./reports/m004B/B004FFNRgraphs";
import B004BDIgraphs from "./reports/m004B/B004BDIgraphs";
import LocalQuery from "./utils/LocalQuery";

import Bext004FIQR from "./reports/m004Bext/Bext004FIQR";
import Bext004Pros from "./reports/m004Bext/Bext004Pros";
import Bext004FIQRbyTherapyWeek from "./reports/m004Bext/Bext004FIQRbyTherapyWeek";
import Bext004ProsTherapyWeek from "./reports/m004Bext/Bext004ProsTherapyWeek";
import B004extFivefacetsNR_byTherapyWeek from "./reports/m004Bext/B004extFivefacetsNR_byTherapyWeek";
import Bext004notifications from "./reports/m004Bext/Bext004notifications";
import Bext004favorites from "./reports/m004Bext/Bext004favorites";
import Bext004fibroFlare from "./reports/m004Bext/Bext004fibroFlare";
import Bext004byAppProgress from "./reports/m004Bext/Bext004byAppProgress";
import B004extconsort from "./reports/m004Bext/B004extconsort";
import Bext004Insights from "./reports/m004Bext/Bext004Insights";
import Bext004Audio from "./reports/m004Bext/Bext004Audio";
import Bext004JournalMets from "./reports/m004Bext/Bext004JournalMets";
import Bext004Part2 from "./reports/m004Bext/Bext004Part2";
import Bext004ChapterResponses from "./reports/m004Bext/Bext004ChapterResponses";
// Bext004JournalMets

import A003screening from "./reports/m003A/A003screening";
import A003baselines from "./reports/m003A/A003baselines";
import A003demographics from "./reports/m003A/A003demographics";
import A003by_subjectID from "./reports/m003A/A003by_subjectID";
// import A003Dash from "./reports/m003A/A003Dash";
import A003sources_centers from "./reports/m003A/A003sources_centers";
import A003AppUse from "./reports/m003A/A003AppUse";
import A003sessions from "./reports/m003A/A003sessions";
import A003protoPay from "./reports/m003A/A003protoPay";
import A003FIQR from  "./reports/m003A/A003FIQR";
import A003PGIC from  "./reports/m003A/A003PGIC";
import A003JournalMets from  "./reports/m003A/A003JournalMets";
import A003PainInf from  "./reports/m003A/A003PainInf";
import A003SleepInf from  "./reports/m003A/A003SleepInf";
import A003PainInt from  "./reports/m003A/A003PainInt";
import A003ffqmNR from  "./reports/m003A/A003ffqmNR";

import A003MhcsA5 from  "./reports/m003A/A003MhcsA5";
import A003Gad7A from  "./reports/m003A/A003Gad7A";
import A003WhoqA6 from  "./reports/m003A/A003WhoqA6";

import A003CPAQae from  "./reports/m003A/A003CPAQae";
import A003CPAQpw from  "./reports/m003A/A003CPAQpw";
import A003FIQRfn from  "./reports/m003A/A003FIQRfn";
import A003FIQRim from  "./reports/m003A/A003FIQRim";
import A003FIQRsy from  "./reports/m003A/A003FIQRsy";
import A003reapplicants from "./reports/m003A/A003reapplicants";
import A003chapterResponses from "./reports/m003A/A003chapterResponses";
import A003patientPreference from "./reports/m003A/A003patientPreference";
import A003Eligibility from "./reports/m003A/A003Eligibility";
import A003AppsAttribution from "./reports/m003A/A003AppsAttribution";
import A003addpractices from "./reports/m003A/A003addpractices";
import A003insights from "./reports/m003A/A003insights";
import A003EXTDash from "./reports/m003A/A003EXTDash";
import A003eprosEarlyTerms from "./reports/m003A/A003eprosEarlyTerms";
import A003AppUseEXT from "./reports/m003A/A003AppUseEXT";
import reporttest from "./reports/m003Areferrers/reporttest";
import A003BDI from  "./reports/m003A/A003BDI";
import A003pokeListSupp from "./reports/m003A/A003pokeListSupp";
import A003inProgress from "./reports/m003A/A003inProgress";
import A003AearlyTerms from "./reports/m003A/A003AearlyTerms";
import A003consort from "./reports/m003A/A003consort";
import A003consortJF from "./reports/m003A/A003consortJF";
import A003Mauq from "./reports/m003A/A003Mauq";


import A003addpracticesJF from "./reports/m003A/A003addpracticesJF";
import A003EXT_PGIC from "./reports/m003A/A003EXT_PGIC";

// A003Mauq

import A005Dash from "./reports/p005A/A005Dash";
import A005EproComp from "./reports/p005A/A005EproComp";
import A005Sites from "./reports/p005A/A005Sites";
import A005AppUse from "./reports/p005A/A005AppUse";
import A005Enrollment from "./reports/p005A/A005Enrollment";
import A005VoiceActors from "./reports/p005A/A005VoiceActors";
import A005ChapterEvals from "./reports/p005A/A005ChapterEvals";
import A005AwarenessAct from "./reports/p005A/A005AwarenessAct";
import A005Consort from "./reports/p005A/A005Consort";
import A005baselines from "./reports/p005A/A005baselines";
import A005baselineDems from "./reports/p005A/A005baselineDems";
import A005EproCompli from "./reports/p005A/A005EproCompli"; 
import A005medHist from "./reports/p005A/A005medHist";
import A005Extension from "./reports/p005A/A005Extension";
import A005SToutcomes from "./reports/p005A/A005SToutcomes";
import A005siteHealth from "./reports/p005A/A005siteHealth";

import A005ExtensionDash from "./reports/p005A/A005ExtensionDash";



import SCresultsA from "./reports/pVS/SCresultsA";
import SCdashA from "./reports/pVS/SCdashA";
import SCappointmentsA from "./reports/pVS/SCappointmentsA";
import SCfeedbackA from "./reports/pVS/SCfeedbackA";
import SCchptevalsA from "./reports/pVS/SCchptevalsA";
import SCweekdashA from "./reports/pVS/SCweekdashA";
import SCappointmentsAB from "./reports/pVS/SCappointmentsAB";
import SCdemosA from "./reports/pVS/SCdemosA";
import SCwaitlistA from "./reports/pVS/SCwaitlistA";
import SCinfoCalls1 from "./reports/pVS/SCinfoCalls1";
import SCnoShows from "./reports/pVS/SCnoShows";
import SCretention from "./reports/pVS/SCretention";
import SCyAd from "./reports/pVS/SCyAd";
import SCInsightsA from "./reports/pVS/SCInsightsA";
import SCpayers from "./reports/pVS/SCpayers";
 
import SCopensA from "./reports/pVS/SCopensA";
import SCcancellations from "./reports/pVS/SCcancellations";
import SCfirstAppts from "./reports/pVS/SCfirstAppts";

import SCopensSCP1A from "./reports/pVS/SCopensSCP1A";



import geoApp from "./reports/pVS/Texas/GeojApp";

import SWMdashA from "./reports/oRX/SWMdashA";

import NicPage from "./reports/oRX/NicPage";



function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/signin" component={SignIn} />
          <ProtectedRoute exact path="/" component={Landing} />
          <ProtectedRoute exact path="/pokeList" component={PokeList} />
          <ProtectedRoute exact path="/proref" component={PROkey} />
          <ProtectedRoute exact path="/insightsref" component={Insightskey} />
          <ProtectedRoute exact path="/audioref" component={AudioKey} />
          {/* <ProtectedRoute exact path="/pokeListSMA" component={OptumPokeList} /> */}
          <ProtectedRoute exact path="/ORX/pokeListSMA" component={OptumPokeList} />
          <ProtectedRoute exact path="/ORX/pokeListUPMC" component={upmcPokeList} />


{/* /ORX */}
{/* OptumPokeList */}


          <ProtectedRoute exact path="/002/graphs002" component={Graphs002} />
          <ProtectedRoute exact path="/FIQR002" component={FiqrVfablesOfTable}  />
          <ProtectedRoute exact path="/app_adherence" component={AppFablesOfTables}  />
          <ProtectedRoute exact path="/fiqr_adherence" component={FablesOfTables}  />
          <ProtectedRoute exact path="/app_progress" component={LessonsFablesOfTables}  />
          <ProtectedRoute exact path="/nps_scores" component={NpsFablesOfTables}  />
          <ProtectedRoute exact path="/kpis" component={AreportsList} />
          <ProtectedRoute exact path="/pande" component={PandExFablesOfTables}  />
          <ProtectedRoute exact path="/interviews" component={AnInterviewsList}  />
          <ProtectedRoute exact path="/004/interviews004" component={exitInterviews004}  />{" "}
          <ProtectedRoute exact path="/004/sites" component={A004Sites} />
          <ProtectedRoute exact path="/004/sitesB" component={B004Sites} />
          <ProtectedRoute exact path="/004/visitsB" component={B004Visits} />
          <ProtectedRoute exact path="/004/prosB" component={B004Pros} />
          <ProtectedRoute exact path="/004/act004b" component={Graphs004B} />
          <ProtectedRoute exact path="/004/st004b" component={Graphs004BST} />
          <ProtectedRoute exact path="/004/app_progress004B" component={SessionsFablesOfTables004B}  />
          <ProtectedRoute exact path="/004/kpisB" component={AreportsList004B}  />
          <ProtectedRoute exact path="/004/fiqr004B" component={B004FIQR} />
          <ProtectedRoute exact path="/004/fiqrTW004B" component={B004FIQRbyTherapyWeek}  />
          <ProtectedRoute exact path="/004/prosTWB" component={B004byTherapyWeek}  />
          <ProtectedRoute exact path="/004/pro_compliance004B" component={B004Pro_compliance}  />
          <ProtectedRoute exact path="/004/consortB" component={B004consort} />
          <ProtectedRoute exact path="/004/insightsB" component={B004Insights}  />
          <ProtectedRoute exact path="/004/journalsB" component={B004JournalMets}  />
          <ProtectedRoute exact path="/004/fiqrgraphsB" component={B004FIQRgraphs}  />
          <ProtectedRoute exact path="/004/cpaq8aegraphsB" component={B004CPAQ8AEgraphs}  />
          <ProtectedRoute exact path="/004/cpaq8pwgraphsB" component={B004CPAQ8PWgraphs}  />
          <ProtectedRoute exact path="/004/cfqgraphsB" component={B004CFQgraphs}  />
          <ProtectedRoute exact path="/004/bdigraphsB" component={B004BDIgraphs}  />
          <ProtectedRoute exact path="/004/pgicB" component={B004PGIC} />
          <ProtectedRoute exact path="/004/ffmqnrTWB" component={B004fivefacets_TW}  />
          <ProtectedRoute exact path="/004/cfqTWB" component={B004cfq_TW} />
          <ProtectedRoute exact path="/004/cpaqpwTWB" component={B004cpaq8PW_TW}  />
          <ProtectedRoute exact path="/004/cpaqaeTWB" component={B004cpaq8AE_TW}  />
          <ProtectedRoute exact path="/004/journalstartsB" component={B004JournalTimes}  />

          <ProtectedRoute exact path="/004/ffnrgraphsB" component={B004FFNRgraphs}  />
          <ProtectedRoute exact path="/004/consortA" component={A004consort} />
          <ProtectedRoute exact path="/004/pros" component={A004Pros} />
          <ProtectedRoute exact path="/004/kpis" component={AreportsList004A} />
          <ProtectedRoute exact path="/004/visits" component={A004Visits} />
          <ProtectedRoute exact path="/004/other_prosA" component={A004OtherPROs}  />
          {/* <ProtectedRoute exact path="/004/other_pros_all" component=   {A004OtherPROs_all}  /> */}
          <ProtectedRoute exact path="/004/fiqr004graphs" component={A004FIQRgraphs}  />
          <ProtectedRoute exact path="/004/pips004graphs" component={A004PIPSgraphs}  />
          <ProtectedRoute exact path="/004/wi004graphs" component={A004WIgraphs}  />
          <ProtectedRoute exact path="/004/ffnr004graphs" component={A004FFNRgraphs}  />
          <ProtectedRoute exact path="/004/act004a" component={Graphs004A} />
          <ProtectedRoute exact path="/004/st004a" component={Graphs004AST}  />{" "}
          <ProtectedRoute exact path="/004/scorestherapyweek004" component={A004byTherapyWeek}  />
          <ProtectedRoute exact path="/004/fiqrtherapyweek004" component={A004FIQRbyTherapyWeek}  />
          {/* A004byAppProgress */}
          <ProtectedRoute exact path="/004/fiqrap004" component={A004FIQRbyAppProgress}  />
          <ProtectedRoute exact path="/004/scoresap004" component={A004byAppProgress}  />{" "}
          <ProtectedRoute exact path="/004/fiqr004A" component={A004FIQR} />
          <ProtectedRoute exact path="/004/app_progress004" component={LessonsFablesOfTables004A}  />
          <ProtectedRoute exact path="/004/pro_compliance004" component={A004Pro_compliance}  />
          <ProtectedRoute exact path="/004/consorts" component={fuckthis004} />
          <ProtectedRoute exact path="/004/promis004" component={A004Promis_all}  />
          <ProtectedRoute exact path="/004/promis004T" component={A004PromisT_all}  />
          <ProtectedRoute exact path="/004/promis004atw" component={A004Promis_TW}  />
          <ProtectedRoute exact path="/004/promis004tatw" component={A004PromisT_TW}  />
          <ProtectedRoute exact path="/004/fivefacets004atw" component={A004fivefacets_TW}  />
          <ProtectedRoute exact path="/004/favoritesB" component={B004favorites}  />
          <ProtectedRoute exact path="/004/fibroflareB" component={B004fibroFlare}  />
          <ProtectedRoute exact path="/004/fiqrBext" component={Bext004FIQR} />
          <ProtectedRoute exact path="/004/prosBext" component={Bext004Pros} />
          <ProtectedRoute exact path="/004/fiqrTWBext" component={Bext004FIQRbyTherapyWeek}  />
          <ProtectedRoute exact path="/004/prosTWBext" component={Bext004ProsTherapyWeek}  />
          <ProtectedRoute exact path="/004/ffmqnrTWBext" component={B004extFivefacetsNR_byTherapyWeek}  />
          <ProtectedRoute exact path="/004/notificationsBext" component={Bext004notifications}  />
          <ProtectedRoute exact path="/004/favoritesBext" component={Bext004favorites}  />
          <ProtectedRoute exact path="/004/consortBext" component={B004extconsort}  />
          <ProtectedRoute exact path="/004/insightsBext" component={Bext004Insights}  />
          <ProtectedRoute exact path="/004/awarenessBext" component={Bext004Audio}  />
          <ProtectedRoute exact path="/004/fibroflareBext" component={Bext004fibroFlare}  />
		  <ProtectedRoute exact  path="/004/part2Bext" component={Bext004Part2}/>
      <ProtectedRoute exact path="/004/journalsBext" component={Bext004JournalMets}  />
      <ProtectedRoute exact path="/004/chptevalsBext" component={Bext004ChapterResponses}  />



      {/* <ProtectedRoute exact path="/004/journalstartsB" component={B004JournalTimes}  /> */}

          {/* Bext004JournalMets */}

          {/* Bext004byAppProgress app_progress004B */}
          <ProtectedRoute exact path="/004/app_progressBext" component={Bext004byAppProgress}  />
          <ProtectedRoute exact path="/003/screeningA" component={A003screening}  />
          {/* <ProtectedRoute exact path="/003/baselinesA" component={A003baselines}  /> */}
          <ProtectedRoute exact path="/003/demographicsA" component={A003demographics}  />
          {/* <ProtectedRoute exact path="/003/dashA" component={A003Dash} /> */}
          <ProtectedRoute exact path="/003/sources_centersA" component={A003sources_centers}  />
          <ProtectedRoute exact path="/003/indiv_scoresA" component={A003by_subjectID}  />
          <ProtectedRoute exact path="/003/reporttestA" component={reporttest}  />
          {/* <ProtectedRoute exact path="/003/protopayA" component={A003protoPay}  /> */}
          <ProtectedRoute exact path="/003/appuseA" component={A003AppUse} />
          <ProtectedRoute exact path="/003/sessionsA" component={A003sessions}  />
          <ProtectedRoute exact path="/003/fiqrA" component={A003FIQR}  />
          <ProtectedRoute exact path="/003/pgicA" component={A003PGIC}  />
          <ProtectedRoute exact path="/003/journalsA" component={A003JournalMets}  />
          <ProtectedRoute exact path="/003/paininfA" component={A003PainInf}  />
          <ProtectedRoute exact path="/003/sleepinfA" component={A003SleepInf}  />
          <ProtectedRoute exact path="/003/painintA" component={A003PainInt}  />
          <ProtectedRoute exact path="/003/ffqmnrA" component={A003ffqmNR}  />
          <ProtectedRoute exact path="/003/gad7A" component={A003Gad7A}  />
<ProtectedRoute exact path="/003/mhcsA" component={A003MhcsA5}  />
<ProtectedRoute exact path="/003/whoqA" component={A003WhoqA6}  />
          
<ProtectedRoute exact path="/003/cpaq8AEA" component={A003CPAQae}  />
<ProtectedRoute exact path="/003/cpaq8PWA" component={A003CPAQpw}  />
<ProtectedRoute exact path="/003/fiqrFnA" component={A003FIQRfn}  />
<ProtectedRoute exact path="/003/fiqrImA" component={A003FIQRim}  />
<ProtectedRoute exact path="/003/fiqrSyA" component={A003FIQRsy}  />
<ProtectedRoute exact path="/003/chptevalsA" component={A003chapterResponses}  />
<ProtectedRoute exact path="/003/patientprefsA" component={A003patientPreference}  />
<ProtectedRoute exact path="/003/eligibilityA" component={A003Eligibility}  />
<ProtectedRoute exact path="/003/appsattribA" component={A003AppsAttribution}  />
<ProtectedRoute exact path="/003/addpracticesA" component={A003addpractices}  />
<ProtectedRoute exact path="/003/insightsA" component={A003insights}  />
<ProtectedRoute exact path="/003/earlyterm_prosA" component={A003eprosEarlyTerms}  />
<ProtectedRoute exact path="/003/extA" component={A003EXTDash}  />
<ProtectedRoute exact path="/003/appextA" component={A003AppUseEXT}  />
<ProtectedRoute exact path="/003/bdiA" component={A003BDI}  />
<ProtectedRoute exact path="/003/pokeListexA" component={A003pokeListSupp}  />
<ProtectedRoute exact path="/003/inprogressA" component={A003inProgress}  />

<ProtectedRoute exact path="/003/earlytermsA" component={A003AearlyTerms}  />

<ProtectedRoute exact path="/003/consortA" component={A003consort}  />
<ProtectedRoute exact path="/003/jf" component={A003addpracticesJF}  />
<ProtectedRoute exact path="/003/consortAjf" component={A003consortJF}  />

{/* A003consortJF */}
<ProtectedRoute exact path="/003/mauqA" component={A003Mauq}  />

<ProtectedRoute exact path="/003/pgicextA" component={A003EXT_PGIC}  />

{/* A003EXT_PGIC */}

{/* A003addpracticesJF */}

<ProtectedRoute exact path="/005/dashA" component={A005Dash}  />
<ProtectedRoute exact path="/005/eprocmpA" component={A005EproComp}  />
<ProtectedRoute exact path="/005/sitesA" component={A005Sites}  />
<ProtectedRoute exact path="/005/appuseA" component={A005AppUse}  />
<ProtectedRoute exact path="/005/enrollmentA" component={A005Enrollment}  />
<ProtectedRoute exact path="/005/voicesA" component={A005VoiceActors}  />
<ProtectedRoute exact path="/005/chptevalsA" component={A005ChapterEvals}  />
<ProtectedRoute exact path="/005/awarenessA" component={A005AwarenessAct}  />
<ProtectedRoute exact path="/005/consortA" component={A005Consort}  />
<ProtectedRoute exact path="/005/baselinesA" component={A005baselines}  />
<ProtectedRoute exact path="/005/demsA" component={A005baselineDems}  />
<ProtectedRoute exact path="/005/eprocmplA" component={A005EproCompli}  />
<ProtectedRoute exact path="/005/medhistA" component={A005medHist}  />
<ProtectedRoute exact path="/005/extensionA" component={A005Extension}  />
<ProtectedRoute exact path="/005/SToutcomesA" component={A005SToutcomes}  />
<ProtectedRoute exact path="/005/siteHealthA" component={A005siteHealth}  />

<ProtectedRoute exact path="/005/testingA" component={A005ExtensionDash}  />
<ProtectedRoute exact path="/005/extensioncheckA" component={A005ExtensionDash}  />



{/* <ProtectedRoute exact path="/VS/testingA" component={SCresultsA}  /> */}
<ProtectedRoute exact path="/VS/SCresults" component={SCresultsA}  />
<ProtectedRoute exact path="/VS/SCdash" component={SCdashA}  />
<ProtectedRoute exact path="/VS/SCstanza" component={SCdashA}  />


<ProtectedRoute exact path="/VS/SCtesting" component={SCopensSCP1A}  />
<ProtectedRoute exact path="/VS/SCmetrics" component={SCappointmentsAB}  />
<ProtectedRoute exact path="/VS/SCfeedback" component={SCfeedbackA}  />
<ProtectedRoute exact path="/VS/SCchptevals" component={SCchptevalsA}  />
<ProtectedRoute exact path="/VS/SCweeklydash" component={SCweekdashA}  />
<ProtectedRoute exact path="/VS/SCdemos" component={SCdemosA}  />
<ProtectedRoute exact path="/VS/SCwaitlist" component={SCwaitlistA}  />
{/* <ProtectedRoute exact path="/VS/SCinfocalls" component={SCinfoCalls1}  /> */}
<ProtectedRoute exact path="/VS/SCnoshows" component={SCnoShows}  />
<ProtectedRoute exact path="/VS/SCretention" component={SCretention}  />
<ProtectedRoute exact path="/VS/SCyAd" component={SCyAd}  />
<ProtectedRoute exact path="/VS/SCinsights" component={SCInsightsA}  />
<ProtectedRoute exact path="/VS/SCpayers" component={SCpayers}  />
<ProtectedRoute exact path="/VS/SCopens" component={SCopensA}  />
<ProtectedRoute exact path="/VS/SCcancels" component={SCcancellations}  />
<ProtectedRoute exact path="/VS/SCoutreach" component={SCwaitlistA}  />
<ProtectedRoute exact path="/VS/SCfirstappts" component={SCfirstAppts}  />
<ProtectedRoute exact path="/VS/SCPopens" component={SCopensSCP1A}  />

{/* NicPage */}
<ProtectedRoute exact path="/ORX/stanza" component={SWMdashA}  />
<ProtectedRoute exact path="/ORX/nics" component={NicPage}  />

{/* SWMdashA */}


{/* A005baselines */}
{/* A005AwarenessAct */}

<ProtectedRoute exact path="/003/reappsA" component={A003reapplicants}  />
          
          {/* A003screenfails */}
          {/* <ProtectedRoute exact path="/004/fiqr004B" component={B004FIQR} /> */}
          {/* <ProtectedRoute exact path="/004/fiqrTW004B" component={B004FIQRbyTherapyWeek} /> */}
          <ProtectedRoute exact path="/004/prosTWB" component={B004byTherapyWeek}  />
        </Switch>

        <ProtectedRoute exact path="/localQuery" component={LocalQuery} />
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
