import React, {useState} from "react";
import {useCollection} from "react-firebase-hooks/firestore";
import LoadingScreen from "../../utils/screens/LoadingScreen";
import "../surveyAdherence.css";
import {firestore} from "../../utils/firebase";
import AdminNavbar from "../../components/AdminNavbar";
import {useHistory} from "react-router-dom";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";
import {Col, Container, Jumbotron, Row, Table,} from "react-bootstrap";

const LessonsFablesOfTables004A = (props) => {
  const [filter, setFilter] = useState("");
  const query = firestore.collection("analytics").doc("session_adherence004A").collection("sessionAdhere").orderBy('subjectID', 'desc');
  const columns = ['subjectID', 'txw1', 'txw2', 'txw3', 'txw4', 'txw5', 'txw6', 'txw7', 'txw8', 'txw9', 'txw10', 'txw11', 'txw12', 'txw13', 'mean_weekly_sessions']
  let history = useHistory();
  const [rows, rowsLoading, rowsError] = useCollection(query, {
    snapshotListenOptions: {includeMetadataChanges: true},
  });

  // const summaryQuery = firestore.collection("analytics").doc('FIQRweek');
  // const [summaryRow, rowsLoading2, rowsError2] = useDocument(summaryQuery, {
  // snapshotListenOptions: {includeMetadataChanges: true},
  // });

  const getTableRow = (progRef) => {
    // if (!journalType.matchesFilter(filter)) {
    //     return <></>;
    // }
    let data = progRef.data();
    let numerator = 0;
    let denominator = 0;//

    let cols = columns.map((c) => {
      numerator = data[c] === 1 ? numerator + 1 : numerator;
      denominator = data[c] === 1 || data[c] === 0 ? denominator + 1 : denominator;
      return (
      <td className={data[c] >= 4 ? "table-success" : Number(data[c]) == data[c] ? "table-danger" : data[c] ? "" : "table-active"}>{data[c]}</td>
      )

      // return (<td className={data[c] >= 4 ? "table-success" :  [3,2,1,0].includes(data[c])  ? "table-danger" : data[c] ? "" : "table-active"}>{data[c]}</td>)
    });

    return (
    <tr key={progRef.id} onClick={() => {
    }}>
      {/* <td>{denominator !== 0 && numerator / denominator}</td> */}
      {cols}

    </tr>
    );

  };

  return (
  <>
    <AdminNavbar/>
    <Jumbotron>
      <Container fluid={true}>
        <Row>
          <Col>
            <h1>App Progress Adherence</h1>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    <AnotherReportsListSidebar/>

    <Container fluid={true} style={{backgroundColor: "white", width: "85%"}}>
      <div className="articleB">
        <p class="TitleC" style={{paddingLeft: "20px"}}>By Treatment Week & ACT Participant: Sessions per Week</p>


        {/* <Row>
                    <Col>
                        <p class="TitleA">By Participant</p>
                    </Col>
                </Row> */}
        <Row>
          {rowsError && (
          <>
            <strong>Error: {JSON.stringify(rowsError)}</strong>
            <br></br>
          </>
          )}
          {rowsLoading && (
          <>
            <strong>Reports Loading: {JSON.stringify(rowsError)}</strong>
            <br></br>
            <LoadingScreen></LoadingScreen>
          </>
          )}
          {rows && (
          <Col className={'reports_table'} xs={12}>
            <Table>
              <thead key="programs">
              <tr>
                {/* <th>adherence</th> */}
                {columns.map((c) => {
                  return (
                  <th>{c}</th>
                  )
                })}


              </tr>
              </thead>
              <tbody>{rows.docs.map((u) => getTableRow(u))}</tbody>
            </Table>
          </Col>
          )}
        </Row>
      </div>
    </Container>


  </>
  );

};

export default LessonsFablesOfTables004A;
