import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import GraphTemplateVSA  from "./GraphTemplateVSA";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";





const SCdashA = (props) => {
    const [titleC1, setTitleC1] = useState([]);
    const [caveat, setCaveat] = useState([]);
    const [healthieRx, setHealthieRx] = useState([]);

  
    // percent_veryLikely

    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("dash");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleCs('titleC1',setTitleC1)
        getTitleCs('caveat',setCaveat)
        getTitleCs('healthieRx',setHealthieRx)
        

    }, [props]);
return (<>
 <Helmet>
        <title>Stanza SCA</title>
        <meta name="Stanza SCA" content="Stanza SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true}>

<Container fluid={true} style={{ paddingBottom: "50px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#engage">•Stanza Engagement</HashLink>
<br/>
{/* <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#appUse">•App Use</HashLink> */}
<Row style={{paddingTop: "3px"}}></Row>
               <HashLink id="top" className="TitleDB" style={{ lineHeight: "1",paddingTop: "20px",paddingBottom: "50px", fontSize: "17px", paddingLeft: "15px",color: "#000000" }} to="#appUse">App Use</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#appUse">•Day 1 - Day 90</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#day90p">•Day 91 - Day 180</HashLink>
               <br/>
                   <div style={{ borderTop: "1px solid #941751",paddingTop: "40px", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>


                   <Container fluid={true}  >
                   <div className="article" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{titleC1}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {caveat}</span></div>
</div>
                   </Container>



                
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="engage" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Stanza Engagement</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Total Stanza Activated Stanza Prescriptions<span style={{paddingLeft:"20px",fontWeight: "700"}} > {healthieRx}</span></div>

</div>
</Container>
       
                   <Container fluid={true}>
{/* <div className="articleVS" style={{paddingTop: "20px", paddingBottom: "10px", maxWidth: "90%"}}> 
 */}
<div className="article" style={{paddingTop: "0px", paddingBottom: "50px", maxWidth: "90%"}}> 

<div style={{paddingBottom: "15px"}}>
<Row > 
<Col>
<table style={{padding: "0px", width: "200px"}}>
    <thead>
        <tr>
        <th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>sessions_per_week_mean</th>
<th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>journal_completion_rate</th>
<th style={{backgroundColor: "#8C8785", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>sessions_per_week_needed</th>

        </tr>
    </thead>
<tbody>
<tr>
</tr>
<tr>

<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≥5</td>
<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≥75%</td>
<td style={{backgroundColor: "#00BE00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>≤4</td>

</tr>
<tr>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4</td>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>75% - 50%</td>
<td style={{backgroundColor: "#FDCF16", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4 - 6</td>

</tr>
<tr>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>4 - 1</td>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>50% - 25%</td>
<td style={{backgroundColor: "#FF8E00", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>6 - 7</td>

</tr>
<tr>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>0</td>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>&lt;25%</td>
<td style={{backgroundColor: "#FF0000", color: "#FFFFFF", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "12px"}}>&gt;7</td>
</tr>
</tbody>
</table>
</Col>
</Row>
{/* <div className="TitleC" style={{paddingTop: "10px",paddingBottom: "0px",fontSize: "25px"}}>Total Stanza Activated Stanza Prescriptions<span style={{paddingLeft:"20px"}} > {healthieRx}</span></div> */}
</div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "11px"}}>*sessions_per_week_needed → to finish part1</div>




<div style={{paddingBottom: "20px"}}>
<Row style={{overflow: "scroll", width: "auto",maxWidth: "95%",maxHeight: "500px"}}>
    <Col>
<CmReportTableVS1sticky  style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="10px" tdFontSize="9px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#FF8733" tableKey={"table1"} />
</Col>
</Row>
</div>
</div>
</Container>
</Container>


<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "20px", paddingBottom: "30px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="appUse" className="TitleC" style={{ paddingBottom: "0px", fontSize: "40px", paddingLeft: "0px" }} to="#top">App Use</HashLink>

                <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div>


            </Col>
            </Row>
            <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "25px"}}>Day 1 - Day 90</div>

            </div> 

    <Row >
    <Col>
    <GraphTemplateVSA style={{padding: "0px"}} report={"SCappUse90"} />

</Col>
</Row>
    </div>
</Container>

<Container fluid={true} style={{paddingTop:"10px"}}>

<div className="articleVSB" style={{paddingTop: "20px", paddingBottom: "30px", maxWidth: "99%"}}> 
<div style={{paddingBottom: "20px"}}>

        <Row>
            <Col>
                <HashLink id="day90p" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "0px" }} to="#top">Day 91 - 180</HashLink>

                {/* <div style={{ borderTop: "1px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"45%"  }}></div> */}


            </Col>
            </Row>
            {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "18px"}}>Day 1 - Day 90</div> */}

            </div> 

    <Row >
    <Col >
    <GraphTemplateVSA style={{padding: "0px"}} report={"SCappUse180"} />
{/* style={{maxWidth:"45%"}} */}
</Col>
</Row>
    </div>
</Container>


</>);

};

export default SCdashA;