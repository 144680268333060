import {ReactComponent as Loading} from "./loading.svg";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const LoadingScreen = (props) => {
  return (
  <>

    <Container>
      <br></br>

      <Row className="justify-content-md-center">
        <Loading/>
      </Row>
      <Row className="justify-content-md-center">
        <br></br>
        <Col>
          {" "}
          <h1 className="text-center">Loading...</h1>
        </Col>
      </Row>
    </Container>
  </>
  );
};

export default LoadingScreen;
