import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const ReportsListSidebarSCA = (props) => {
  return (
    <>
    {/* <noPrint> */}
      <Container  fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px",paddingRight:"20px" }  }>
        <div className="sidenav" style={{paddingLeft: "0px",paddingRight: "20px",marginRight: "10px"}}>

      

        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCstanza">
Stanza Summary
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px",paddingBottom: "0px", paddingTop: "0px" }} to="/VS/SCfeedback">
Patient Feedback
        </Link>
        </div>

        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCresults">
Results
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCmetrics">
Clinic Metrics
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCchptevals">
Stanza Chapter Evals
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCweeklydash">
Weekly Dash
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCretention">
Patient Retention
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCyAd">
YA Dash
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCfirstappts">
First Appointments
        </Link>
        </div>

        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCdemos">
Demographics
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCinsights">
Insights
        </Link>
        </div>
       

        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCpayers">
Payers
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCopens">
App Opens
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCPopens">
SCP App Opens
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCnoshows">
No Shows
        </Link>
        </div>
{/* SCcancels */}
<div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCcancels">
Cancellations
        </Link>
        </div>
        <div style={{lineHeight: "1"}}>
        <Link className="TitleDB" style={{  fontSize: "11px" }} to="/VS/SCoutreach">
Patient Outreach
        </Link>
        </div>
          </div>
      </Container>
  

    </>
  );
};

export default ReportsListSidebarSCA; 
