import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import AReportTable004A from "./AReportTable004A";
import AsmallerReportTable004A from "./AsmallerReportTable004A";
import AnotherReportsListSidebar from "../AnotherReportsListSidebar";

// import AreportsListSidebar from "../AreportsListSidebar";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const fuckthis004 = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Reports 004</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">Site Counts</p>
            {/* <AReportTable tableKey={"nps_kpis"}></AReportTable> */}
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AReportTable004A tableKey={"screenedA004"}></AReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ACT participants completing at least 4 lessons / week</p>
            <AReportTable004A tableKey={"lesson_kpis004"}></AReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ST participants completing at least 4 entries / week</p>
            <AsmallerReportTable004A tableKey={"ST_app_kpis004A"}></AsmallerReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of all participants completing weekly PROs</p>
            <AsmallerReportTable004A tableKey={"pro_adherence_kpis004A"}></AsmallerReportTable004A>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}


</>);

};

export default fuckthis004;

