import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A22F from "./CmReportTable005A22F";
import CmReportTable005A2 from "./CmReportTable005A2";
import ReportTable1sticky from "./ReportTable1sticky";
// ReportTable1sticky
import ScrollUpButton from "react-scroll-up-button";
 
import { HashLink,NavHashLink } from "react-router-hash-link";


const A005ExtensionDash = (props) => {
return (<>
 <Helmet>
        <title>Extension Monitoring 005A</title>
        <meta name="Extension Monitoring 005A" content="Extension Monitoring 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Extension Monitoring 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#to_monitor">•participants to monitor</HashLink>
 <br/>
 <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#bdi9">•bdi2-q9</HashLink>
 <br/>

 <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#surveyCompl">•survey completion</HashLink>
 <br/>
 {/* surveyCompl */}
 <NavHashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#extSurvs">•extension surveys</NavHashLink>
 <br/>
 <NavHashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#extSurvScores">•extension survey scores</NavHashLink>

 <br/>

{/* extSurvScores */}
  
                   <div style={{paddingTop:"3px", borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "25px", paddingLeft: "30px" }}>
<div className="article" style={{paddingBottom: "10px", paddingLeft: "30px", paddingRight: "0px", lineHeight:"1", paddingTop: "5px"}}>
<Row>
<Col>
<NavHashLink id="to_monitor" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</NavHashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "30px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "650px", paddingLeft: "45px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "45px"}}>To Monitor</div>
<div className="TitleC" style={{paddingLeft: "10px",paddingTop: "4px",paddingBottom: "4px",fontSize: "12px",fontWeight: "400"}}>*has_workflows & has_surveyBayResponses are firestore collections required for survey receipt</div>

<CmReportTable005A2 style={{padding: "0px",width: "450px"}} thFontSize="12px" tdFontSize="11px" bgColor="#FF7323" tableKey={"ext_checksA"} />                
</div>
                
</Container>
</Row>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", paddingLeft: "30px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "30px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
<Row>
<Col>
<HashLink id="bdi9" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "20px",  paddingTop: "0px", lineHeight: "1", paddingLeft: "30px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "30px"}}> 

<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "8px",fontSize: "45px"}}>BDI-2, <span style={{paddingLeft: "2px",fontSize: "30px"}} >question #9, </span><span style={{paddingLeft: "7px",fontSize: "24px"}} >all scores &gt; 0</span></div>
<Row style={{overflowY: "scroll", width: "auto",maxWidth: "650px", maxHeight: "550px",paddingBottom: "30px", paddingLeft: "30px"}} >

<ReportTable1sticky style={{padding: "0px"}} fColor="#FFFFFF" thPaddingLeft="8px" thPaddingRight="2px" tdPaddingLeft="6px" tdPaddingRight="2px" thFontSize="12px" tdFontSize="11px" bgColor="#D80000" tableKey=  {"ext_bdiq9"} />                
</Row>
</div>
</Container>
</Row>



<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", paddingLeft: "30px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "30px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
<Row>
<Col>
<HashLink id="surveyCompl" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "20px", paddingLeft: "0px", paddingTop: "0px", lineHeight: "1", paddingLeft: "30px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "30px"}}> 
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "8px",fontSize: "45px"}}>Extension Survey Completion</div>
<Row style={{overflowY: "scroll", width: "auto",maxWidth: "650px", maxHeight: "550px",paddingBottom: "30px", paddingLeft: "30px"}} >

<ReportTable1sticky style={{padding: "0px"}} fColor="#FFFFFF" thPaddingLeft="8px" thPaddingRight="2px" tdPaddingLeft="6px" tdPaddingRight="2px" thFontSize="12px" tdFontSize="11px" bgColor="#123A63" tableKey=  {"ext_survey_completion"} />                
</Row>
</div>
</Container>
</Row>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "0px", paddingLeft: "30px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "30px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
<Row>
<Col>
<HashLink id="extSurvs" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "0px",  paddingTop: "0px", lineHeight: "1", paddingLeft: "30px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "30px"}}> 
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "8px",fontSize: "45px"}}>Extension Surveys <span style={{paddingLeft: "7px",fontSize: "18px"}} >by date assigned</span></div>
{/* overflow: "scroll", width: "auto",maxWidth: "auto" */}
<Row style={{overflow: "scroll",width: "auto",maxWidth: "auto", maxHeight: "550px", paddingLeft: "30px"}} >
<ReportTable1sticky style={{padding: "0px"}} fColor="#FFFFFF" thPaddingLeft="4px" thPaddingRight="1px" tdPaddingLeft="4px" tdPaddingRight="1px" thFontSize="10px" tdFontSize="10px" bgColor="#2475C7" tableKey=  {"ext_surveysA"} />                

{/* <CmReportTable005A22F style={{padding: "0px"}} thFontSize="11px" tdFontSize="11px" bgColor="#2475C7" tableKey=  {"ext_surveysA"} />        */}
</Row>         
</div>
</Container>
</Row>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "0px", paddingLeft: "30px" }}>
<div className="article" style={{paddingBottom: "0px", paddingLeft: "30px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
<Row>
<Col>
<HashLink id="extSurvScores" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
</Col>
</Row>
</div>
</Container>
<Row>
<Container fluid={true} style={{ paddingBottom: "0px",  paddingTop: "0px", lineHeight: "1", paddingLeft: "30px" }}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", paddingLeft: "30px"}}> 
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "8px",fontSize: "45px"}}>Extension Survey Scores<span style={{paddingLeft: "7px",fontSize: "18px"}} ></span></div>
{/* overflow: "scroll", width: "auto",maxWidth: "auto" */}
<Row style={{overflow: "scroll",width: "auto",maxWidth: "650px", maxHeight: "550px", paddingLeft: "30px"}} >
<ReportTable1sticky style={{padding: "0px"}} fColor="#FFFFFF" thPaddingLeft="4px" thPaddingRight="1px" tdPaddingLeft="4px" tdPaddingRight="1px" thFontSize="10px" tdFontSize="10px" bgColor="#430040" tableKey=  {"ext_epros"} />                

{/* <CmReportTable005A22F style={{padding: "0px"}} thFontSize="11px" tdFontSize="11px" bgColor="#2475C7" tableKey=  {"ext_surveysA"} />        */}
</Row>         
</div>
</Container>
</Row>
</>);

};

export default A005ExtensionDash;