import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyBjPHYv1kzmjDalGqhwcN0bZHJJimEO1Nc", authDomain: "tempo-dev-8db49.firebaseapp.com", databaseURL: "https://tempo-dev-8db49.firebaseio.com", projectId: "tempo-dev-8db49", storageBucket: "tempo-dev-8db49.appspot.com", messagingSenderId: "412515673024", appId: "1:412515673024:web:56a8a9fcc17e95a8e9de25", measurementId: "G-DH51WX72C2"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export {firebase};
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () =>
{
  auth.signInWithPopup(provider);
};

export type DocumentData = firebase.firestore.DocumentData;
export type DocumentRef<T> = firebase.firestore.DocumentReference<T>;
export type DocumentSnapshot<T> = firebase.firestore.QueryDocumentSnapshot<T>;
export type Timestamp = firebase.firestore.Timestamp;
