import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";

import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import CmReportTableVS1stickyHnoInd from "./CmReportTableVS1stickyHnoInd";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCpayers = (props) => {


    const [ins_updated, setIns_updated] = useState([]);
    const [ret_subA, setRet_subA] = useState([]);
    const [ret_subB, setRet_subB] = useState([]);
    const [cavA, setCavA] = useState([]);
    const [ret_subC, setRet_subC] = useState([]);
    // const [ret_subD, setRet_subD] = useState([]);

       



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("payers");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('ins_updated',setIns_updated)
           getTitleCs('ret_subA',setRet_subA)
           getTitleCs('ret_subB',setRet_subB)
           getTitleCs('cavA',setCavA)
           getTitleCs('ret_subC',setRet_subC)
        //    getTitleCs('ret_subD',setRet_subD)
    }, [props]);



return (<>
 <Helmet>
        <title>Payers SCA</title>
        <meta name="Payers SCA" content="Payers SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>


<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#payersA">•payers overall summary</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#payersB">•payers by completed intake month</HashLink>
<br/>
<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

   

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "20px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "10px",paddingBottom: "0px",fontSize: "45px",color: "#000000",lineHeight: "1"}}>Payers<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {ins_updated}</span></div>
<div style={{fontSize: "14px",paddingLeft: "10px",paddingBottom: "20px"}} >{cavA}</div>

</div>
</Container>



<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>

<HashLink id="payersA" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Payers</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subA}</div>


</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div style={{paddingTop: "5px",  width: "650px",maxHeight: "400px"}} >
{/* maxWidth: "680px",overflow: "scroll", */}
{/* style={{overflow: "scroll", width: "auto",maxHeight: "400px"}} */}
<Row >
<Col>
<CmReportTableVS1sticky style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="2px" tdPaddingTop="4px" thPaddingRight="2px" thPaddingLeft="4px" bgColor="#F76345" tableKey={"payerSumm_tableA"} />
</Col>
</Row>
</div>
</div>
{/* </Container>
<Container fluid={true}  > */}

</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>
<Row>
<Col>

<HashLink id="payersB" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "5px", fontSize: "40px",maxWidth: "100%" }} to="#top">Payers</HashLink>
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "24px",lineHeight: "1"}}>{ret_subB}</div>

{/* <span style={{paddingLeft: "14px",fontSize: "20px", color: "#005176"}} >{ret_subBa}</span> */}
</Col>
</Row>

</div>
</Container>



<Container fluid={true}  >

<div className="article" style={{paddingTop: "10px", paddingBottom: "10px",maxWidth: "90%", lineHeight: "1"}}>   

<div  style={{overflow: "scroll",paddingTop: "5px", width:"700px",maxWidth: "750px",maxHeight: "400px"}}  >

<Row  >
<Col >
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="0px" tdPaddingTop="4px" thPaddingRight="0px" thPaddingLeft="4px" bgColor="#430040" tableKey={"payerSumm_byIntakeMonth"} />
</Col>
</Row>
</div>
</div>
<div className="article" style={{paddingTop: "20px", paddingBottom: "5px",maxWidth: "94%"}}>   
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}     report={"payers_plotA"} />
</Col>
</Row>
</div>
</Container>









<Container fluid={true}  >
<div className="article" style={{paddingTop: "5px", paddingBottom: "50px",maxWidth: "90%", lineHeight: "1"}}>   
<div className="TitleC" style={{paddingTop: "4px",paddingBottom: "0px",fontSize: "20px",lineHeight: "1"}}>{ret_subC}</div>

<div  style={{overflow: "scroll",paddingTop: "5px", width:"700px",maxWidth: "750px",maxHeight: "400px"}}  >

<Row  >
<Col >
<CmReportTableVS1stickyHnoInd style={{padding: "0px"   }} fColor="#FFFFFF"  thFontSize="10px" tdFontSize="9px" tdPaddingRight="0px" tdPaddingTop="4px" thPaddingRight="0px" thPaddingLeft="4px" bgColor="#815080" tableKey={"payerSumm_byIntakeMonthStatus"} />
</Col>
</Row>
</div>
</div>
</Container>




















</>);

};

export default SCpayers;