import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003reapplicants = (props) => {
    return (<>
 
 <Helmet>
        <title>reapplicants 003A</title>
        <meta name="reapplicants 003A" content="reapplicants 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Reapplicants 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

        
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

         
             



<div className="TitleC" style={{fontSize: "20px"}}  >Reapplications, by Referrer</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#DE784E" tableKey={"reapplicants003A_1"}></CmReportTable003Ax>

</div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

         
             



<div className="TitleC" style={{fontSize: "20px"}}  >Reapplicant Workflowstatuses, by Referrer</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#00618A" tableKey={"reapplicants003A_2"}></CmReportTable003Ax>
</div>
        </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>

         
             



<div className="TitleC" style={{fontSize: "20px"}}  >Eligible & have not Reapplied, by Referrer</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",   borderRightWidth: "1px", borderRightColor:"#aaaaaa", borderRightStyle:'solid', borderLeftWidth: "1px", borderLeftColor:"#aaaaaa", borderLeftStyle:'solid'}} bgColor="#9E005D" tableKey={"reapplicants003A_3"}></CmReportTable003Ax>
</div>
        </Container>

      
</>);

};

export default A003reapplicants;




