import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import AsmallerReportTable004A from "./AsmallerReportTable004A";
import AsmallerReportTable004ACT from "./AsmallerReportTable004ACT";
import AnotherReportsListSidebar from "../AnotherReportsListSidebar";

// import AreportsListSidebar from "../AreportsListSidebar";
    // const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AreportsList004A = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Reports 004</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="TitleC">KPIs</p>
            {/* <AReportTable tableKey={"nps_kpis"}></AReportTable> */}
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ACT participants engaging at least 5 days / week</p>
            <AsmallerReportTable004ACT  tableKey={"engagement_kpis004A"}></AsmallerReportTable004ACT>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="norms">80% of ACT participants completing at least 4 lessons / week</p>
            <AReportTable004ACT tableKey={"lesson_kpis004"}></AReportTable004ACT>
                </div>
        </Container> */}
         <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ACT participants completing at least 4 sessions / week</p>
            <AsmallerReportTable004ACT tableKey={"session_kpis004"}></AsmallerReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of ST participants completing at least 4 entries / week</p>
            <AsmallerReportTable004A tableKey={"ST_app_kpis004A"}></AsmallerReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingLeft: "50px"}}>
            <p className="norms">80% of all participants completing weekly PROs</p>
            <AsmallerReportTable004A tableKey={"pro_adherence_kpis004A"}></AsmallerReportTable004A>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
                </div>
        </Container> */}


</>);

};

export default AreportsList004A;

