import React from "react";
import "./surveyAdherence.css";
import AdminNavbar from "../components/AdminNavbar";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import PROkeyTable from "./PROkeyTable";
import PROkeyTableXL from "./PROkeyTableXL";

import PROkeyTableXLC from "./PROkeyTableXLC";
import {Helmet} from "react-helmet";

const AudioKey = (props) => {
  return (
    <>
     <Helmet>
		<title>Audio Duration Key</title>
		<meta name="Audio Duration key" content="Audio Duration key" />
	</Helmet>

      <AdminNavbar />
      <Jumbotron>
        <Container fluid={true}>
          <Row>
            <Col>
              <h1>Audio Duration Key</h1>
              <div className="TitleC"  style={{fontSize: "24px", paddingBottom: "1px" }}>005A TempoACT3 Audio</div>

            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container fluid={true} style={{ backgroundColor: "white",width:"100%",paddingTop: "20px" }}>
        <div  style={{paddingBottom: "40px"}}>
          <div className="TitleC"  style={{fontSize: "36px", paddingBottom: "8px" }}>Audio Duration <span  style={{fontSize: "16px",paddingBottom: "8px",paddingLeft: "4px"}}>in seconds</span></div>


         

          <PROkeyTableXLC style={{fontSize: "14px"}}  tableKey={"awareActivitiesRef005A"}></PROkeyTableXLC>
        </div>
      </Container>
  


    </>
  );
};

export default AudioKey;
// db.collection(u'analytics').document('reports').collection('reportTables005').document('awareActivitiesRef005A')