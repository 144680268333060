import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import AReportTable from "./AReportTable";
import AsmallerReportTable from "./AsmallerReportTable";
import AreportsListSidebar from "./AreportsListSidebar";

const AreportsList = (props) => {
  return (
  <>
    <AdminNavbar/>
    <Jumbotron>
      <Container fluid={true}>
        <Row>
          <Col>
            <h1>Reports 002</h1>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    <AreportsListSidebar/>

    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="TitleC">KPIs</p>
        <p></p>
        <p className="norms">NPS scores</p>
        <AReportTable tableKey={"nps_kpis"}></AReportTable>
      </div>
    </Container>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="norms">80% of ACT participants engaging at least 5 days / week</p>
        <AReportTable tableKey={"engagement_kpis"}></AReportTable>
      </div>
    </Container>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="norms">80% of ACT participants completing at least 4 lessons / week</p>
        <AReportTable tableKey={"lesson_kpis"}></AReportTable>
      </div>
    </Container>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="norms">80% of ST participants completing at least 4 entries / week</p>
        <AsmallerReportTable tableKey={"ST_app_kpis"}></AsmallerReportTable>
      </div>
    </Container>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="norms">80% of all participants completing weekly PROs.</p>
        <AsmallerReportTable tableKey={"pro_adherence_kpis"}></AsmallerReportTable>
      </div>
    </Container>
    <Container fluid={true} style={{backgroundColor: "white"}}>
      <div className="article">
        <p className="norms">Average Improvement & Proportion with 25% improvement in FIQR</p>
        <AReportTable tableKey={"fiqr_kpis002"}></AReportTable>
      </div>
    </Container>
    {/* wtf */}

  </>
  );

};

export default AreportsList;

