import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004B from "./CmReportTable004B";
import CmReportTable004BST from "./CmReportTable004BST";
import TWexpl004B from "./TWexpl004B";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import {Helmet} from "react-helmet";
import TWexpl004B2 from "./TWexpl004B2";

const B004cfq_TW = (props) => {
 return (<>
  <Helmet>
		<title>CFQ-TW 004-2</title>
		<meta name="CFQ-TW 004-2" content="CFQ-TW 004-2" />
	</Helmet>

        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Cognitive Fusion Questionnaire, by Therapy Week, Cohort 2</h1>
                        <TWexpl004B2/>
                        {/* <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div> */}

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>


		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">Mean CFQ Scores
            <TWexpl004B/>
            </div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"cfq004B_means"}></CmReportTable004B>
                </div>
        </Container>
		


		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ACT-Daily CFQ Scores</div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"ACT_cfq004Bdaily"}></CmReportTable004B>
                </div>
        </Container>

		<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ACT-Weekly CFQ Scores</div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"ACT_cfq004Bweekly"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ST CFQ Scores</div>
            <CmReportTable004BST style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"ST_cfq004B"}></CmReportTable004BST>
                </div>
        </Container>




</>);

};

export default B004cfq_TW;




