import React from "react";
// import React, {  useEffect } from 'react';
import "../surveyAdherence.css";
import {Helmet} from "react-helmet";

// import { usePageName } from 'react-page-name';

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004Bext from "./CmReportTable004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import PROkeyTableXSext from "./PROkeyTableXSext";
import PROkeyTableXrl from "./PROkeyTableXrl";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext"; 

import { HashLink } from "react-router-hash-link";

 




const Bext004fibroFlare = (props) => {
 return (<>
 <Helmet>
        <title>Fibro Flare 004-2X</title>
        <meta name="Fibro Flare 004-2X" content="Fibro Flare 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Fibro Flare Toolkit, Cohort 2 Extension</h1>
                        <div className="TitleCb" >Includes only FibroFlare Toolkit activity during the extension period</div>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
     <AnotherReportsListSidebar004Bext/>
     <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>
                   

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#key">jump to key</HashLink>
        </Col>
                </Row>
               
            </Container>
            <div className="TitleC">Fibro Flare Toolkit, total use by group</div>
             <div className="TitleCb" style={{ paddingBottom: "15px",paddingTop: "5px"}}>Participants who have used/accessed without using/ignored the Fibro Flare Toolkit.</div> 
            <PROkeyTableXSext  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fibroflare_overallUseBx"}></PROkeyTableXSext>
                </div>
           
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
        
            <div className="TitleC">Fibro Flare Toolkit, activity use</div>
             <div className="TitleCb" style={{ paddingBottom: "15px",paddingTop: "5px"}}>total activity interactions; opened means accessed without using</div> 
            <PROkeyTableXSext  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fibroflare_activitiesBx"}></PROkeyTableXSext>
                </div>
        </Container>

        
   
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
         
            <div className="TitleC">Fibro Flare Toolkit, overall use by participant</div>
             <div className="TitleCb" style={{ paddingBottom: "15px"}}>Count of participants' <strong>use</strong> of each Fibro Flare Toolkit activity.</div> 
            <PROkeyTableXrl  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fibroflare_genUse004Bx"}></PROkeyTableXrl>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="key" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC"  style={{fontSize: "20px"}} >Fibro Flare Toolkit, key</div>
            <PROkeyTableXSext  style={{ paddingLeft: "0px", paddingRight: "0px", width:"85%", fontSize: "10px"}} tableKey={"fibroflareKey"}></PROkeyTableXSext>
                </div>
        </Container> 
</>);

};

export default Bext004fibroFlare;




