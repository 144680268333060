import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1stickyHnoIndRfml from "./CmReportTableVS1stickyHnoIndRfml";
import GraphTemplateVSA from "./GraphTemplateVSA";
import CmReportTableVS1stickyHnoInd from "./CmReportTableVS1stickyHnoInd";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";

import CmReportTableVS1stickyNoIndFML from "./CmReportTableVS1stickyNoIndFML";
import CmReportTableVS1stickyNoIndNoCols2 from "./CmReportTableVS1stickyNoIndNoCols2";
import CmReportTableVS1sticky from "./CmReportTableVS1sticky";

// CmReportTableVS1noIndYA CmReportTableVS1stickyHnoIndRfml
import CmReportTableVS1noIndSpcM from "./CmReportTableVS1noIndSpcM";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import CmReportTableVS1xxx from "./CmReportTableVS1xxx";
// import label00 from "./SCinfoCalls1";
// import label01 from "./SCinfoCalls1";
// label00
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCfirstAppts = (props) => {
 
    const [updated, setUpdated] = useState([]);
    const [ptitleAQ, setPtitleAQ] = useState([]);
    const [ptitleAb, setPtitleAb] = useState([]);
       const [label00, setLabel00] = useState([]);
   
   
    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("appointments");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
   
        getTitleCs('updated',setUpdated)
        getTitleCs('ptitleAQ',setPtitleAQ)
        getTitleCs('ptitleAb',setPtitleAb)
       
		getTitleCs('label00',setLabel00)
	

    }, [props]);
return (<>
 <Helmet>
        <title>First Appointments 2023 SCA</title>
        <meta name="First Appointments 2023 SCA" content="First Appointments 2023 SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebarSCA/>
        


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>

<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#1stAppt_mnT">•First Appointments, by Status & Month</HashLink>  
             <br/> 
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#1stAppt_mn">•First Appointments, by Status, Month, & Type</HashLink>  
             <br/> 
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#1stAppt_wkT">•First Appointments, by Status & Month</HashLink>  
             <br/> 
             <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#1stAppt_wk">•First Appointments, by Status, Week, & Type</HashLink>  
             <br/> 
           
             
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

       <Container fluid={true} >
                   <div className="articleVS" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}>
                   <Row >
                   <Col style={{maxWidth: "auto"}}>
                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",color: "#000000"}}>{ptitleAQ} <span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
                   {/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "14px", color:"#000000", lineHeight: "1"}}>all_1sts <span style={{color:"#636161", paddingRight: "3px"}}>combines all first appointment types:</span> <span style={{fontSize: "12px", lineHeight: "1",paddingLeft: "3px", color:"#636161"}}>Intake + Intake45 + one_time_cons etc</span></div> */}
                    {/* <div className="TitleC" style={{paddingTop: "2px",paddingBottom: "2px",fontSize: "12px", lineHeight: "1",paddingLeft: "3px"}}>Intake + Intake45 + one_time_cons etc</div> */}

                   </Col>
                   </Row>
</div>
                   </Container>

     
             
 <Container fluid={true}  >
<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "20px", maxWidth: "90%"}}>   
<div style={{paddingBottom: "15px"}}>
<Row>
<Col>
<HashLink id="1stAppt_mnT" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">First Appointments, by Status & Month</HashLink>

</Col>
</Row>

</div>

<Row >
<Col>
<div style={{overflow: "scroll", width: "auto",maxWidth: "800px", height: "auto",maxHeight: "200px", paddingBottom: "0px"}}>
<CmReportTableVS1sticky style={{padding: "0px",width: "1000px"   }} bgColor="#71006B" thFontSize="9px" tdFontSize="9px" fColor="#FFFFFF" thPaddingLeft= "3px"  thPaddingRight="3px" thPaddingTop="1px"   tableKey={"1stappt_occurredMnTots"} />

</div>
</Col>
</Row>
<div style={{paddingBottom: "0px",paddingTop: "35px"}}>
    {/* Percent Occurred, No-Showed, by Month */}
    <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "6px",fontSize: "24px", lineHeight: "1"}}>Percent Occurred, No-Showed, by Month</div>
<Row>
<Col>
<GraphTemplateVSA style={{padding: "0px"}}     report={"1stappt_attnd"} />
</Col>
</Row>
</div>
</div>
</Container>


<Container fluid={true}  >

<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "40px", maxWidth: "90%"}}>   
<div style={{paddingBottom: "15px"}}>
<Row>
<Col>
<HashLink id="1stAppt_mn" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">First Appointments, by Status, Month, & Type</HashLink>

</Col>
</Row>
<div className="TitleC" style={{paddingTop: "6px",paddingBottom: "0px",fontSize: "14px", color:"#000000", lineHeight: "1"}}>all_1sts <span style={{color:"#636161", paddingRight: "3px"}}>combines all first appointment types:</span> <span style={{fontSize: "12px", lineHeight: "1",paddingLeft: "3px", color:"#636161"}}>Intake + Intake45 + one_time_cons etc</span></div>

</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px", paddingBottom: "0px"}}>

<Col>
<div >
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px",width: "1000px"   }} bgColor="#FF8733" thFontSize="9px" tdFontSize="9px" fColor="#FFFFFF" thPaddingLeft= "3px"  thPaddingRight="3px" thPaddingTop="1px"   tableKey={"1stappt_occurredMn"} />

</div>
</Col>
</Row>
</div>
</Container>

<Container fluid={true}  >

<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "20px", maxWidth: "90%"}}>   
<div style={{paddingBottom: "15px"}}>
<Row>
<Col>
<HashLink id="1stAppt_wkT" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">First Appointments, by Status & Week</HashLink>

</Col>
</Row>

</div>

<Row >

<Col>
<div style={{overflow: "scroll", width: "auto",maxWidth: "800px", height: "auto",maxHeight: "200px", paddingBottom: "0px"}}>
<CmReportTableVS1sticky style={{padding: "0px",width: "1000px"   }} bgColor="#2A87FF" thFontSize="9px" tdFontSize="9px" fColor="#FFFFFF" thPaddingLeft= "3px"  thPaddingRight="3px" thPaddingTop="1px"   tableKey={"1stappt_occurredWkTots"} />

</div>
</Col>
</Row>
</div>
</Container>
<Container fluid={true}  >

<div className="articleVS" style={{paddingTop: "20px", paddingBottom: "40px", maxWidth: "90%"}}>   
<div style={{paddingBottom: "15px"}}>
<Row>
<Col>
<HashLink id="1stAppt_wk" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "8px", fontSize: "40px",maxWidth: "100%" }} to="#top">First Appointments, by Status, Week, & Type</HashLink>

</Col>
</Row>
<div className="TitleC" style={{paddingTop: "6px",paddingBottom: "0px",fontSize: "14px", color:"#000000", lineHeight: "1"}}>all_1sts <span style={{color:"#636161", paddingRight: "3px"}}>combines all first appointment types:</span> <span style={{fontSize: "12px", lineHeight: "1",paddingLeft: "3px", color:"#636161"}}>Intake + Intake45 + one_time_cons etc</span></div>

</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "auto", height: "auto",maxHeight: "300px", paddingBottom: "0px"}}>

<Col>
<div >
<CmReportTableVS1stickyHnoIndRfml style={{padding: "0px",width: "1000px"   }} bgColor="#FFA15A" thFontSize="9px" tdFontSize="9px" fColor="#FFFFFF" thPaddingLeft= "3px"  thPaddingRight="3px" thPaddingTop="1px"   tableKey={"1stappt_occurredWk"} />

</div>
</Col>
</Row>
</div>
</Container>









</>);




};

export default SCfirstAppts;