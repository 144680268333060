import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCchptevalsA = (props) => {
    const [eval_title, setEval_title] = useState([]);
    const [updated, setUpdated] = useState([]);

    const [label00, setLabel00] = useState([]);
    const [label01, setLabel01] = useState([]);
    // const [label02, setLabel02] = useState([]);
    // const [label03, setLabel03] = useState([]);

    // const [label04, setLabel04] = useState([]);
    // const [percent_veryLikely, setPercent_veryLikely] = useState([]);
    // const [promoters, setPromoters] = useState([]);
    // const [passives, setPassives] = useState([]);
    // const [detractors, setDetractors] = useState([]);

    // const [fnps, setFnps] = useState([]);



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("chpt_evals");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleCs('eval_title',setEval_title)
        getTitleCs('updated',setUpdated)
        getTitleCs('label00',setLabel00)
        getTitleCs('label01',setLabel01)
        // getTitleCs('label02',setLabel02)
        // getTitleCs('label03',setLabel03)
        // getTitleCs('label04',setLabel04)
        // getTitleCs('percent_veryLikely',setPercent_veryLikely)
        // getTitleCs('promoters',setPromoters)
        // getTitleCs('passives',setPassives)
        // getTitleCs('detractors',setDetractors)
        // getTitleCs('fnps',setFnps)


    }, [props]);
return (<>
 <Helmet>
        <title>Chpt Evals SCA</title>
        <meta name="Chpt Evals SCA" content="Chpt Evals SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#score_summ">•score summaries</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#score_plots">•score plots</HashLink>
<br/>
<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

      
{/* <Container fluid={true}  style={{paddingLeft: "0px"}} >
<div className="article" style={{paddingLeft: "0px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{eval_title}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
</Col>
</Row>
</div>
</Container> */}

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{eval_title}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>

</div>
</Container>


{/* <Container fluid={true}>
<div className="article" style={{paddingLeft: "0px",paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<HashLink id="score_summ" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Score Summaries</HashLink>


</div>
</Container> */}


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="score_summ" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Score Summaries</HashLink>
</Col>
</Row>
{/* <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{eval_title}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div> */}

</div>
</Container>





       {/* <Container fluid={true} style={{ paddingBottom: "0px" }}>  
<div className="articleVS" style={{paddingTop: "10px",  paddingBottom: "0px",maxWidth: "90%"}}>
           <Row>
               <Col>
               <HashLink id="score_summ" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Score Summaries</HashLink>
               </Col>
           </Row>
           </div>
       </Container> */}
{/* <Container fluid={true} style={{ paddingBottom: "0px" }}>  
<div className="articleVS" style={{paddingTop: "10px",  paddingBottom: "0px",maxWidth: "90%"}}>
<div style={{paddingBottom: "10px"}}>
<Row>
<Col>
<HashLink id="score_summ" className="TitleC" style={{paddingLeft: "0px", borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Score Summaries</HashLink>

</Col>    
</Row>
         
        
           </div>
           </div>
       </Container> */}
{/* style={{paddingLeft: "0px", paddingTop:"0px" }}    style={{paddingLeft: "10px"}}*/}
<Container fluid={true}  >
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px",maxWidth: "90%"}}> 
<Row>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>{label00}</div>
</Col>
</Row>   
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px"}} fColor="#D3DD88"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"chpt_evals_worthwhileSummA"} />
</Col>
</Row>
<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>{label01}</div>
</Row>
   
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px"}} fColor="#226277" thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#D3DD88" tableKey={"chpt_evals_relevantSummA"} />
</Col>
</Row>
</div>

</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="score_plots" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Score Plots</HashLink>
</Col>
</Row>
</div>
</Container>

<Container fluid={true}  >
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px",maxWidth: "90%"}}> 
<Row>
<Col>
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>Content Worthwhile</div> */}
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>{label00}</div>
</Col>
</Row>   
<Row >
    <Col>
    <GraphTemplateVSA style={{padding: "0px"}} report={"worthwhileA"} />

</Col>
</Row>



</div>

</Container>
<Container fluid={true}  >
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px",maxWidth: "90%"}}> 
<Row>
<Col>
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>Content Relevance</div> */}
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>{label01}</div>
</Col>
</Row>   
<Row >
    <Col>
    <GraphTemplateVSA style={{padding: "0px"}} report={"relevantA"} />

</Col>
</Row>



</div>

</Container>

</>);

};

export default SCchptevalsA;