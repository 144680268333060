import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A22F from "./CmReportTable005A22F";
import CmReportTable005A3X9 from "./CmReportTable005A3X9";


import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";



const A005EproCompli = (props) => {




 return (<>
 <Helmet>
        <title>EPRO Compliance 005A</title>
        <meta name="EPRO Compliance 005A" content="EPRO Compliance 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>EPRO Compliance 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 



{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
            
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#closedE">•skip to closed EPROs</HashLink>  
            <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#intreat">•skip to in-treatment EPRO completion</HashLink>

<div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>  */}

<Container fluid={true}>
<div className="article" style={{paddingTop: "5px", paddingBottom: "10px", width: "auto"}}> 
<Col>
<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Overall EPRO Compliance, by Site
{/* <span style={{fontSize: "12px",paddingLeft: "10px"}}>all participants</span> */}
</div>
</Row>
<Row>
<CmReportTable005A22F style={{padding: "0px"}} thFontSize="16px" tdFontSize="14px" bgColor="#662D91" tableKey={"site_eproComplianceA"} />
</Row>
</Col>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px", width: "auto"}}> 
<Col>
<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px",lineHeight:"1"}}>EPRO Compliance at Goal, by Site</div>
</Row>
<Row>
<div className="TitleC" style={{fontSize: "30px",paddingTop: "10px",paddingBottom: "10px",lineHeight:"1"}}>participant-visits with at least 80% compliance</div>
</Row>

<Row>
<CmReportTable005A22F style={{padding: "0px"}} thFontSize="16px" tdFontSize="14px" bgColor="#00CC96" tableKey={"site_eproComplianceKpiA"} />
</Row>
</Col>
</div>
</Container>




{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "35px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="intreat" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                         <br/>
                        <HashLink id="intreat" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#closedE">jump to closed EPROs</HashLink>         
            </Col>
            </Row>
            </div>
            </Container> */}




</>);

};

export default A005EproCompli;