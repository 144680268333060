import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";

import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import CmReportTable004A from "./CmReportTable004A";

import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

const A004OtherPROs = (props) => {
  return (
  <>
    <AdminNavbar/>
    <Jumbotron>
      <Container fluid={true} style={{paddingBottom: "30px"}}>
        <Row>
          <Col>
            <h1>Other PRO Scores, by Visit</h1>
            {/* <div className="TitleCb">only includes participants with 4+ in-treatment scores</div> */}
          </Col>
        </Row>
      </Container>
    </Jumbotron>
    <AnotherReportsListSidebar/>

    <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
      <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
        <div className="TitleC">PRO means<span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}}> *excludes s10_008</span></div>
        {/* <div className="norms">BDI-II, fivefacetsNR, PIPS, weekly pain intensity, weekly pain interference, weekly sleep interference </div> */}

        <CmReportTable004A tableKey={"prosBV_004A_means"}></CmReportTable004A>
      </div>
    </Container>
    {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>


            <p><div className="TitleC">ACT PIPS Scores</div>  */}
    {/* <div className="norms">12 questions, 1-7pts, max=84; goal: decreased scores</div> */}
    {/* </p>
            <BReportTable004ACT tableKey={"pips_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <p><div className="TitleC">ST PIPS Scores</div> <div className="norms">12 questions, 1-7pts, max=84; goal: decreased scores</div></p>
            <BReportTable004A tableKey={"pips_004_ST"}></BReportTable004A>
                </div>
        </Container> */}
    {/*
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ACT Five Facets NonReactive Subscale Scores</div> <div className="norms">7 questions, 1-5pts, max=35; goal: increased scores</div></p>
            <BReportTable004ACT tableKey={"fivefacetsNR_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ST Five Facets NonReactive Subscale Scores</div> <div className="norms">7 questions, 1-5pts, max=35; goal: increased scores</div></p>
            <BReportTable004A tableKey={"fivefacetsNR_004_ST"}></BReportTable004A>
                </div>
        </Container>

                <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ACT Weekly Pain Intensity Scores</div> <div className="norms">"Thinking about the last 7 days, how intense was your pain on average?"  0-10pts; goal: decreased scores</div></p>
            <BReportTable004ACT tableKey={"weeklypainintensity_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ST Weekly Pain Intensity Scores</div> <div className="norms">"Thinking about the last 7 days, how intense was your pain on average?"  0-10pts; goal: decreased scores</div></p>
            <BReportTable004A tableKey={"weeklypainintensity_004_ST"}></BReportTable004A>
                </div>
        </Container>


        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ACT Weekly Pain Interference Scores</div> <div className="norms">"Thinking about the last 7 days, how much did your pain interfere with activities at work, leisure, or home?"  0-10pts; goal: decreased scores</div></p>
            <BReportTable004ACT tableKey={"weeklypaininterference_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ST Weekly Pain Interference Scores</div> <div className="norms">"Thinking about the last 7 days, how much did your pain interfere with activities at work, leisure, or home?"  0-10pts; goal: decreased scores</div></p>
            <BReportTable004A tableKey={"weeklypaininterference_004_ST"}></BReportTable004A>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ACT Weekly Sleep Interference Scores</div> <div className="norms">"Thinking about the last 7 days, how much did pain interfere with your sleep?"  0-10pts; goal: decreased scores</div></p>
            <BReportTable004ACT tableKey={"weeklysleepinterference_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ST Weekly Sleep Interference Scores</div> <div className="norms">"Thinking about the last 7 days, how much did pain interfere with your sleep?"  0-10pts; goal: decreased scores</div></p>

            <BReportTable004A tableKey={"weeklysleepinterference_004_ST"}></BReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ACT Beck Depression Inventory II</div> <div className="norms">21 questions, 0-3pts, max=63; goal: decreased scores</div></p>

            <BReportTable004ACT tableKey={"bdi_004_ACT"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p><div className="TitleC">ST Beck Depression Inventory II</div> <div className="norms">21 questions, 0-3pts, max=63; goal: decreased scores</div></p>

            <BReportTable004A tableKey={"bdi_004_ST"}></BReportTable004A>
                </div>
        </Container> */}
  </>
  );

};

export default A004OtherPROs;

//  <Container fluid={true} style={{backgroundColor: "white"}}>
//             <div className="article">
//             <p> <span className="TitleC">PIPS Scores</span>
//             <div className="norms">PIPS, 12 questions, 1-7pts; goal: decreased scores</div> </p>
//             <p className="TitleCC">ACT</p>
//             <BReportTable004ACT tableKey={"pips_004_ACT"}></BReportTable004ACT>
//             <p className="TitleCC">ST</p>
//             <BReportTable004A tableKey={"pips_004_ST"}></BReportTable004A>
//                 </div>
//         </Container>
//     <Container fluid={true} style={{backgroundColor: "white"}}>
//     <div className="article">
//     <p> <span className="TitleC">Five Facets NonReactive Subscale</span>
//     <div className="norms">7 questions, 1-5pts; goal: increased scores</div> </p>
//     <p className="TitleCC">ACT</p>
//     <BReportTable004ACT tableKey={"fivefacetsNR_004_ACT"}></BReportTable004ACT>
//     <p className="TitleCC">ST</p>
//     <BReportTable004A tableKey={"fivefacetsNR_004_ST"}></BReportTable004A>
//         </div>
// </Container>
