import { useEffect, useState } from "react";
import { firestore } from "../utils/firebase";
// import PokeTable from "./PokeListTable";
import PokeListTableOptum from "./PokeListTableOptum";
import "../reports/surveyAdherence.css";
import { isExcludedEmail } from "./ExcludeFromStats";
import { diffHr } from "../utils/helpers/pokeListHelpers";

const PokeListOptum = () => {
  const [inactiveUsers, setInactiveUsersToContact] = useState("");
  const [optumUsersToContact, setOptumUsersToContact] = useState("");

  // 003-cohort-1

  const getUserInfo = (user, timeCompleted, actionText) => {
    return {
      id: user.uid,
      subjectID: user.subjectID,
      hours: timeCompleted,
      cohort: user.cohort,
      program: user.activeProgram,
      action: actionText,
      extension: user.extension,
      email: user.email,
      timezone: user.timezone,
      role: user.role,
      site: user.site,
      patientID:user.patientID,

    };
  };
  // "002-brachio","002-fukui",
  useEffect(() => {
    const getUsers = async () => {
      let allUsers = await firestore
        .collection("users")
        .where("site", "in", ["SM"])
        .get();

      for (let doc of allUsers.docs) {
        const userData = doc.data();

        // for active (ACT) 'ext therapy', 'therapy'
        if (
          (userData.activeProgram === "stanzaACT1" ||
            userData.activeProgram === "tempoACT3") &&
          userData.do_not_nudge !== true &&
          userData.extension!=='accepted'  
          && userData.email!=='paulinetest7777@gmail.com'
          // &&        userData.workflowStatus === 'therapy' 
          && userData.role=='patient'
          && !isExcludedEmail(userData.email)
        ) {
          let sessions = await firestore
            .collection("users")
            .doc(doc.id)
            .collection("programs")
            .doc(userData.activeProgram)
            .collection("sessions")
            .orderBy("dateCompleted", "desc")
            .limit(1)
            .get();

          if (sessions.docs) {
            let sessionEntry = sessions.docs[0];
            let sessionData = sessionEntry?.data();
            let completed = sessionData?.dateCompleted;
            let started = sessionData?.dateStarted;

            if (completed !== null && started !== null) {
              var lastCompleted = diffHr(new Date(), completed?.toDate());
              if (lastCompleted >= 48 && lastCompleted < 120) {
                setOptumUsersToContact((prevState) => [
                  ...prevState,
                  getUserInfo(userData, lastCompleted, " push"),
                ]);
              }
              if (lastCompleted >= 120 && lastCompleted <= 144) {
                setOptumUsersToContact((prevState) => [
                  ...prevState,
                  getUserInfo(userData, lastCompleted, "text + push"),
                ]);
              }
  
              if (lastCompleted >144 && lastCompleted < 168) {
                setOptumUsersToContact((prevState) => [
                  ...prevState,
                  getUserInfo(userData, lastCompleted, "text"),
                ]);
              }
  
          
              if (lastCompleted >= 168) {
                setOptumUsersToContact((prevState) => [
                  ...prevState,
                  getUserInfo(
                    userData,
                    lastCompleted,
                    "call"
                  ),
                ]);
              }
              
              else {
               
              }

            } else {
              var firstStartedProgram = diffHr(new Date(), started?.toDate());
              setInactiveUsersToContact((prevState) => [
                ...prevState,
                getUserInfo(userData, 'needs to start Stanza', "text + call"),
              ]);
            }
          }
        }
      }
    };

    getUsers();
  }, []);

  return (
    <>
      {optumUsersToContact.length > 0 && (
        <>
          <h4 className="pokelist-header">
            Contact these SMA patients today
          </h4>
          <PokeListTableOptum users={optumUsersToContact} tableType='regular' />
        </>
      )}
      {inactiveUsers.length > 0 && (
        <>
          <h4 className="pokelist-header">
            Contact these inactive SMA patients today
          </h4>
          <PokeListTableOptum inactiveType={true} users={inactiveUsers} tableType='regular' />
        </>
      )}
      {optumUsersToContact.length == 0 && inactiveUsers.length == 0  && (
        <>
        <h4 className="pokelist-header">
            No SMA people to contact today 🎉
          </h4>
        </>
      )}
    </>
  );
};

export default PokeListOptum;
