import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";

import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import BReportTable004A from "./BReportTable004A";
import BReportTable004ACT from "./BReportTable004ACT";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

const A004Promis_TW = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>

                <Row>
                    <Col>
                        <h1>PROMIS Scores, by Therapy Week</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Pain Intensity</div> <div className="norms">1 question, 1-10pts, max=10; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisRYP_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Pain Intensity</div> <div className="norms">1 question, 1-10pts, max=10; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisRYP_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Pain Interference</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisPI_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
                </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Pain Interference</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisPI_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Ability to Participate in Social Roles & Activities</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004ACT tableKey={"promisAP_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
                </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Ability to Participate in Social Roles & Activities</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004A tableKey={"promisAP_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Physical Function</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004ACT tableKey={"promisPF_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
                </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Physical Function</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004A tableKey={"promisPF_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Cognitive Function</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004ACT tableKey={"promisCF_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
                </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Cognitive Function</div> <div className="norms">4 questions, 1-5pts, max=20; goal: increased scores</div>
            <BReportTable004A tableKey={"promisCF_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>





        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Fatigue in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisFtq_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Fatigue in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisFtq_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Sleep Disturbance in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisSD_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Sleep Disturbance in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisSD_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Anxiety in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisAnx_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Anxiety in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisAnx_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ACT PROMIS Depression in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004ACT tableKey={"promisDS_rawTW_ACT_004A"}></BReportTable004ACT>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "30px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "30px"}}>
            <div className="TitleC">ST PROMIS Depression in the past 7 days</div> <div className="norms">4 questions, 1-5pts, max=20; goal: decreased scores</div>
            <BReportTable004A tableKey={"promisDS_rawTW_ST_004A"}></BReportTable004A>
                </div>
        </Container>


</>);

};

export default A004Promis_TW;




