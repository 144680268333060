import React, {useEffect, useState} from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../utils/firebase";
    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import quers from "./quers";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 



const getSourcesData = async () => { 
      const sources = firestore.collection("analytics").doc("sources");
      const doc = await sources.get();
      console.log(doc.data().updated.toDate());
 }
getSourcesData();


const getScreening_abandonedData = async () => { 
      const sources = firestore.collection("analytics").doc("screenfails003A").collection("screening_abandoned003A").doc("screening_abandoned");
      const doc = await sources.get();
      var screening_abandoned=doc.data().screening_abandoned;
      console.log(screening_abandoned);
      // return screening_abandoned;
 }
getScreening_abandonedData();








