import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref"; 




const A003PGIC = (props) => {
    

 return (<>
 <Helmet>
        <title>PGIC 003A</title>
        <meta name="App Use 003A" content="PGIC 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>PGIC 003A, by referrer</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#supportfibro">•skip to supportfibro</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#swinginternal">•skip to swing internal</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>

       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px"}}>All, by participant<span style={{fontSize: "11px",paddingLeft: "5px"}} >includes only participants with week 12 scores</span></div>


<table style={{padding: "0px"}}>
<tr>
</tr>
<tr>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#00B050"}}>█</span>  Very Much Improved</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#70AD47"}}>█</span>  Much Improved</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#C5E0B4"}}>█</span>  Minimally Improved</td>

<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#DCD9D9"}}>█</span>  No Change</td>

<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#F99383"}}>█</span>  Minimally Worse</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#FC6454"}}>█</span>  Much Worse</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#FF0000"}}>█</span>  Very Much Worse</td>
</tr>
</table>

                    <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "10px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicM"} />


        </div>

        </Container>

       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px"}}>All</div>
            


            <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_All"} />
        </div>
        

        </Container>
      
        <div className="article" style={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px",paddingTop: "20px"}}>

<Row>
    <Col>

        <HashLink id="swinginternal" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
    </Col>
    </Row>

    </div>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Swing Internal</div>
            


            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_swing_internal"} />
        </div>
        <div className="article" style={{paddingBottom: "30px"}}>       {/* , paddingLeft: "0px", paddingRight: "0px" */}
            <div className="TitleC" style={{paddingBottom: "5px"}}>Individual Scores, Swing Internal</div>
            


            <CmReportTable003Axref bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"pgicTableA_swing_internal"} />
        </div>
        </Container>
        <div className="article" style={{paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px",paddingTop: "20px"}}>

        <Row>
            <Col>

                <HashLink id="supportfibro" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>

            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>      
            <div className="TitleC" style={{paddingBottom: "5px"}}>Support Fibro</div>
            


            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_supportfibro"} />
        </div>
        <div className="article" style={{paddingBottom: "30px"}}>       {/* , paddingLeft: "0px", paddingRight: "0px" */}
            <div className="TitleC" style={{paddingBottom: "5px"}}>Individual Scores, Support Fibro</div>
            


            <CmReportTable003Axref bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"pgicTableA_supportfibro"} />
        </div>
        </Container>
       

</>);

};

export default A003PGIC;
