import React from "react";
import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";


const TWexpl004B = (props) => {
  return (
    <>


            <span className="TitleDB" style={{fontSize: "12px", paddingLeft: "20px"}} >*percent_change_from_baseline is the mean percent_change_from_baseline across all participants;</span><span className="TitleDB" style={{fontSize: "12px", paddingLeft: "4px", color: "#39328A"}} >mean percent_change_from_baseline ≠ mean change_from_baseline / mean therapyWeek00.</span>

   </>
  );
};

export default TWexpl004B;