import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref"; 
import CmReportTable003AxLref from "./CmReportTable003AxLref";
import CmReportTable003AxL from "./CmReportTable003AxL";



const A003eprosEarlyTerms = (props) => {
    

 return (<>
 <Helmet>
        <title>Early Term PRO Scores 003A</title>
        <meta name="Early Term PRO Scores 003A" content="Early Term PRO Scores 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Early Termination PRO Scores 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Pain Interference Summary Metrics</div>
            <div className="TitleC" style={{fontSize: "18px", paddingBottom: "2px", paddingLeft: "185px", paddingTop: "0px"}}>Score
            <span style={{paddingBottom: "15px", paddingLeft: "55px"}}>Percent Change from Baseline</span>
            </div>
            <CmReportTable003AxL bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "2px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"painIf_earlyTerms_changesA"} />
        </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>GAD7 Summary Metrics</div>
            <div className="TitleC" style={{fontSize: "18px", paddingBottom: "2px", paddingLeft: "185px", paddingTop: "0px"}}>Score
            <span style={{paddingBottom: "15px", paddingLeft: "35px"}}>Percent Change</span>
            </div>
            <CmReportTable003AxL bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "2px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"gad7XX_earlyTerms_changesA"} />
        </div>
        </Container> 

</>);

};

export default A003eprosEarlyTerms;
