import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCfeedbackA = (props) => {
    const [ftitle, setFtitle] = useState([]);
    const [feedback_update_time, setFeedback_update_time] = useState([]);

    const [label00, setLabel00] = useState([]);
    const [label01, setLabel01] = useState([]);
    const [label02, setLabel02] = useState([]);
    const [label03, setLabel03] = useState([]);

    const [label04, setLabel04] = useState([]);
    const [percent_veryLikely, setPercent_veryLikely] = useState([]);
    const [promoters, setPromoters] = useState([]);
    const [passives, setPassives] = useState([]);
    const [detractors, setDetractors] = useState([]);

    const [fnps, setFnps] = useState([]);

const [label07, setLabel07] = useState([]);
const [label08, setLabel08] = useState([]);
const [label09, setLabel09] = useState([]);

const [labeldf10, setLabeldf10] = useState([]);
const [labelsdf00, setLabelsdf00] = useState([]);
const [labelsdf01, setLabelsdf01] = useState([]);
const [labelsdf02, setLabelsdf02] = useState([]);
const [labelsdf03, setLabelsdf03] = useState([]);





    // percent_veryLikely

    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("feedback");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleCs('ftitle',setFtitle)
        getTitleCs('feedback_update_time',setFeedback_update_time)
        getTitleCs('label00',setLabel00)
        getTitleCs('label01',setLabel01)
        getTitleCs('label02',setLabel02)
        getTitleCs('label03',setLabel03)
        getTitleCs('label04',setLabel04)
        getTitleCs('percent_veryLikely',setPercent_veryLikely)
        getTitleCs('promoters',setPromoters)
        getTitleCs('passives',setPassives)
        getTitleCs('detractors',setDetractors)
        getTitleCs('fnps',setFnps)
        getTitleCs('label07',setLabel07)
        getTitleCs('label08',setLabel08)
        getTitleCs('label09',setLabel09)
        getTitleCs('labeldf10',setLabeldf10)
        getTitleCs('labelsdf00',setLabelsdf00)
        getTitleCs('labelsdf01',setLabelsdf01)
        getTitleCs('labelsdf02',setLabelsdf02)
        getTitleCs('labelsdf03',setLabelsdf03)

    }, [props]);
return (<>
 <Helmet>
        <title>Feedback SCA</title>
        <meta name="Feedback SCA" content="Feedback SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df00">•{label00}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df01">•{label01}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df02">•{label02}</HashLink>
               <br/>               
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df03">•{label03}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df04">•{label04}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df07">•{label07}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df08">•{label08}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df09">•{label09}</HashLink>
               <br/> <br/>
               {/* <div className="TitleDB" style={{fontSize: "20px",paddingBottom: "12px",paddingTop:'5px'}} >Stanza-RTM Feedback</div> */}
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "20px", paddingLeft: "15px",paddingTop:'24px' }} to="#stanza">Stanza-RTM Feedback</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#df10">•{labeldf10}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#sdf00">•{labelsdf00}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#sdf01">•{labelsdf01}</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#sdf02">•{labelsdf02}</HashLink>
               <br/>
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "60px", paddingRight: "30px", maxWidth:"40%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>
       

      
                   <Container fluid={true}  >
                   <div className="article" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{ftitle}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {feedback_update_time}</span></div>
</div>
                   </Container>

                 
       
       



            {/* paddingTop: "0px", lineHeight: "1" */}


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df00" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label00}</HashLink>
</Col>
</Row>
</div>
</Container>

{/* <div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{eval_title}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div> */}


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#CEDE82" thFontSize="11px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"df00"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df01" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label01}</HashLink>
</Col>
</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="11px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"df01"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df02" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label02}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#CEDE82" thFontSize="11px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"df02"} />
</Col>
</Row>

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df03" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label03}</HashLink>
</Col>
</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="11px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"df03"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df04" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label04}</HashLink>
</Col>
</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

<Row>
<div className="TitleC" style={{paddingLeft: "30px",paddingTop: "0px",paddingBottom: "5px",fontSize: "18px"}}>Percent Very Likely: <span style={{fontWeight: "700"}}>{percent_veryLikely}</span> </div>
</Row>
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}}fColor="#CEDE82" thFontSize="11px" tdFontSize="10px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"df04"} />
</Col>
</Row>
<Row style={{paddingTop: "0px",paddingBottom: "20px",paddingLeft: "15px"}}>
<table style={{paddingTop: "0px",paddingBottom: "20px"}}>
<tr>
<td  style={{ paddingTop: "30px",backgroundColor:"#FFFFFF",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FFFFFF",borderLeft: "1px solid  #FFFFFF",borderBottom: "1px solid  #FFFFFF",borderRight: "1px solid  #FFFFFF"    }}></td>
<td  style={{backgroundColor:"#00B050",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #00B050",borderBottom: "1px solid  #00B050" ,borderRight: "2px solid  #00B050"}}>promoters:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{promoters}</span></td> 
<td  style={{backgroundColor:"#FDCF16",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FDCF16",borderBottom: "1px solid  #FDCF16" ,borderRight: "1px solid  #FDCF16"    }}>passives:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{passives}</span></td>
<td  style={{ backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderBottom: "1px solid #FF8733"   }}></td>
<td  style={{ backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderBottom: "1px solid #FF8733"    }}>detractors:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{detractors}</span></td>
<td  style={{  backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderLeft: "1px solid  #FF8733",borderBottom: "1px solid  #FF8733",borderRight: "1px solid  #FF8733"    }}></td>
</tr>
<tr>
<td  style={{ paddingTop: "30px",backgroundColor:"#FFFFFF",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FFFFFF",borderLeft: "1px solid  #FFFFFF",borderBottom: "1px solid  #FFFFFF",borderRight: "1px solid  #B9B9B9"    }}></td>
<td colSpan={4}  style={{color: "#FFFFFF",fontSize: "18px",backgroundColor:"#000000",lineHeight: "2",paddingLeft: "15px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #000000",borderRight: "1px solid  #000000",alignContent: "center",textAlign: "center",borderBottom: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9"    }}>NPS: <span style={{fontWeight: "700"}}>{fnps}</span></td>
<td  style={{  backgroundColor:"#000000",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #000000",borderLeft: "1px solid  #000000",borderBottom: "1px solid  #B9B9B9",borderRight: "1px solid  #B9B9B9"    }}></td>

</tr>
</table>

</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df07" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label07}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"ndf01"} />
</Col>
</Row>

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df08" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label08}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#CEDE82" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"df08"} />
</Col>
</Row>

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df09" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{label09}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"ndf02"} />
</Col>
</Row>

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "30px",paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
{/* fontSize: "20px", */}
<HashLink id="stanza" className="TitleC" style={{ paddingBottom: "0px", maxWidth: "100%",fontSize: "40px" }} to="#top">Stanza-RTM Feedback</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "4px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="df10" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{labeldf10}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#CEDE82" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"df10"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="sdf00" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{labelsdf00}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"sdf00"} />
</Col>
</Row>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="sdf01" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{labelsdf01}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#CEDE82" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"sdf01"} />
</Col>
</Row>

</div>
</Container>





<Container fluid={true}>
<div className="article" style={{paddingTop: "10px",paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="sdf02" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "20px",maxWidth: "100%" }} to="#top">{labelsdf02}</HashLink>
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 

   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px"}} fColor="#226277" thFontSize="10px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#CEDE82" tableKey={"sdf02"} />
</Col>
</Row>

</div>
</Container>
</>);

};

export default SCfeedbackA;