import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003AxSmaller from "./CmReportTable003AxSmaller";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003Ax2 from "./CmReportTable003Ax2";
import CmReportTable003Ax2sp from "./CmReportTable003Ax2sp";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003pokeListSupp = (props) => {
    return (<>
 
 <Helmet>
        <title>pokeList Extra 003A</title>
        <meta name="pokeList Extra 003A" content="pokeList Extra 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>pokeList Extra 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 


        
 

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "40px"}}>
            <div className="article" style={{ paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC"   >Participants Currently In First Skip, <span className="TitleC" style={{fontSize: "14px", paddingLeft: "0px"}}>subsequent to completion of ≥1 session</span></div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#DF270D" tableKey={"first_skip003A"}></CmReportTable003Ax>



</div>
        </Container>
       
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "40px"}}>
        <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC" style={{paddingBottom: "0px",lineHeight: "1"}} >Early Termination Risk Factors</div>
<div className="TitleC" style={{paddingBottom: "2px",fontSize: "14px",paddingTop: "0px"}} >length 1st skip, total skips between 1st session & therapyDay 22</div>
<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#B1394A" tableKey={"skip_risks003A"}></CmReportTable003Ax>
</div>
        </Container>
      
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "40px"}}>
        <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>


<div className="TitleC" >Rate Required to Finish Part1, <span className="TitleC" style={{fontSize: "14px", paddingLeft: "0px"}}>ranked worst to best</span></div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#750046" tableKey={"completion_requirements003A"}></CmReportTable003Ax>
</div>
        </Container>
  
    
     
   
         
</>);

};

export default A003pokeListSupp;




