import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import CmReportTable004A from "./CmReportTable004A";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

const A004byAppProgress = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron >
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other PRO Scores, by App Progress</h1>
                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>by completed sessions/ entries; app-content weeks</div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar/>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Mean Scores, by Arm
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} > *excludes s10_008</span></div>
            {/* <p></p>otherPROsAP_004A_means */}
            <CmReportTable004A  tableKey={"prosAP_004A_means"}></CmReportTable004A>
                </div>
        </Container>
        {/* <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">PGIC Scores, by Arm<span className="TitleDB" style={{fontSize: "15px"}} >, by count of completed sessions/ entries; excludes s10_008</span></div>
            <BReportTable004A  tableKey={"pgicAP_004A"}></BReportTable004A>
                </div>
        </Container> */}


{/*
        <Container fluid={true} style={{backgroundColor: "white" }}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <BReportTable004ACT tableKey={"fiqrTW_004A_ACT"}></BReportTable004ACT>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <BReportTable004A tableKey={"fiqrTW_004A_ST"}></BReportTable004A>
                </div>
        </Container> */}


</>);

};

export default A004byAppProgress;

