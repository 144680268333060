import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004B from "./CmReportTable004B";
import CmReportTable004BST from "./CmReportTable004BST";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import {Helmet} from "react-helmet";



const B004FIQR = (props) => {
 return (<>
  <Helmet>
		<title>FIQR-Visit 004-2</title>
		<meta name="FIQR-Visit 004-2" content="FIQR-Visit 004-2" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores by Visit, Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            {/* <div className="TitleC" style={{fontSize: "40px", paddingBottom: "10px"}}  >FIQR by Visit</div> */}

            <div className="TitleC">Mean FIQR Scores
            <span className="TitleDB" style={{fontSize: "13px", paddingLeft: "10px"}} ></span>
            </div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fiqrBV_004B_means"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <CmReportTable004B  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fiqrBV_004B_ACT"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <CmReportTable004BST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"fiqrBV_004B_ST"}></CmReportTable004BST>
                </div>
        </Container>
       


</>);

};

export default B004FIQR;




