

import { MapContainer, TileLayer,Marker,CircleMarker,Circle,FeatureGroup,Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import  "../../surveyAdherence.css";
import Topoj from "./topoj";
import topojson from "./geojT.json";

// console.log(topojson);

export default function GeoApp() {
  return (

    <MapContainer
      center={[30.991,-99.01]}
      zoom={6}
      style={{ height: "700px",width: "700px",padding: "0px", backgroundColor: "rgba(255,255,255,.5)" }}
    >
      <Topoj data={topojson}  />
      <CircleMarker center={[29.799472, -95.317202]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "1",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own-houston" direction="top" permanent>Houston</Tooltip>
        </CircleMarker>

        <CircleMarker center={[29.4714,-98.5399]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent>San Antonio</Tooltip>
        </CircleMarker>

      
        
    

  <CircleMarker center={[32.798701, -96.760836]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="right" permanent >Dallas</Tooltip>
        </CircleMarker>
        
        <CircleMarker center={[30.2838717,-97.7320853]} radius={5} pathOptions = {{color: '#73F6D2',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "1",stroke: "2" }}>
        <Tooltip className="leaflet-tooltip-own" direction="bottom" permanent>Austin</Tooltip>
        </CircleMarker>


  <CircleMarker center={[32.754890,-97.330411]} radius={3} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own" direction="left" permanent>Fort Worth</Tooltip>
        </CircleMarker>


        <CircleMarker center={[31.770049, -106.489873]} radius={2} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="right" permanent >El Paso</Tooltip>
        </CircleMarker>

        <CircleMarker center={[32.728935, -97.098675]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip"  direction="bottom"  >Arlington</Tooltip>
        </CircleMarker>

    

        <CircleMarker center={[27.780081, -97.462069]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent >Corpus Christi</Tooltip>
        </CircleMarker>
        <CircleMarker center={[33.021437, -96.679671]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip"  direction="bottom" >Plano</Tooltip>
        </CircleMarker>
        <CircleMarker center={[33.565963, -101.868732]} radius={1} pathOptions = {{color: '#000000',fillColor: '#000000',fillOpacity: "1", strokeOpacity: "0",stroke: "0" }}>
        <Tooltip className="leaflet-tooltip-own"  direction="bottom" permanent >Lubbock</Tooltip>
        </CircleMarker>
{/* 33.021437, -96.679671 */}

{/* 32.754890, -97.330411 ADD FORT WORTH etc REMOVE Waco */}
    </MapContainer>
  );
}

//       const pathOptions = { fillOpacity: ".5", weight: ".5",strokeOpacity: "1",color: "#000000" }
