import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A4 from "./CmReportTable005A4";

import CmReportTable005A2Ind from "./CmReportTable005A2Ind";
import CmReportTable005A3Ind from "./CmReportTable005A3Ind";

import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A2S from "./CmReportTable005A2S";
import { HashLink } from "react-router-hash-link";


const A005AwarenessAct = (props) => {




 return (<>
 <Helmet>
        <title>Awareness Activities 005A</title>
        <meta name="Awareness Activities 005A" content="Awareness Activities 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Part 1 Awareness Activities 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
<ReportsListSidebar005A/> 

{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#plots">•skip to plots</HashLink>
           

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container> */}


<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
           <Row>
               <Col>
               <Link  className="TitleDB" style={{ paddingBottom: "30px", fontSize: "18px", paddingLeft: "15px", color: "#941751" }} to="/audioref">view all audio durations here</Link>
           

                 </Col>
           </Row>
       </div>
       </Container> 
       <Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", width: "auto"}}> 

<Row>
 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Mean Percent Played by ActivityID and Encounter</div>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "14px"}}>*only includes activities with ≥5 particpant-encounters</div>

<CmReportTable005A2 style={{padding: "2px"}} thPaddingRight="9px" thPaddingLeft="9px" thFontSize="14px" tdFontSize="12px" bgColor="#FF6E2C" tableKey={"awareActivitiesDesc1005B"} />

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "30px", width: "auto"}}> 
<Row>


<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Percent Played by ActivityID and Encounter</div>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "14px"}}>*count_entire: # participants who listened to ≥92% of the audio</div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "14px"}}>*count_skipped: # participants who listened to 0% of the audio</div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "14px"}}>*activityID_encounter: nth time participant had encountered given activity</div>

<CmReportTable005A2 style={{padding: "2px"}} thPaddingRight="12px" thPaddingLeft="12px" thFontSize="14px" tdFontSize="12px" bgColor="#0092FE" tableKey={"awareActivitiesDesc1005A"} />

</div>
</Container>






</>);

};

export default A005AwarenessAct;

