import {React,Suspense,useImage,Img} from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,Image} from "react-bootstrap";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import consortBext from './consort_diagram004Bext.png';
import {Helmet} from "react-helmet";


const B004extconsort = (props) => 
    {
 return (<>
 <Helmet>
        <title>Consort 004-2X</title>
        <meta name="Consort 004-2X" content="Consort 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{ paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>004 Consort Cohort 2 Extension</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>

           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
<img src={consortBext} alt="BigC"  style={{height: "600px", width: "1200px"}}  />
</div>
</Container>
</>);

};

export default B004extconsort;


