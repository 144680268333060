import React from "react";
// import GraphTemplate from "../graphs/GraphTemplate";
import GraphTemplateEPROsA from "../../graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar from "./AnotherReportsListSidebar";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Row} from "react-bootstrap";

const A004FIQRgraphs = () => {
  return (
    <>
      <AdminNavbar />
      <AnotherReportsListSidebar/>
      <Container fluid={true} >
      {/* style={{align:'left'}} */}
        <div className="mt-4">
        <div className="article">
          <h4>FIQR</h4>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ACT_fiqr004A"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateEPROsA report={"ST_fiqr004A"} />
            </Col>
          </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default A004FIQRgraphs;
{/* <AdminNavbar/>
<Jumbotron>
    <Container fluid={true}>
        <Row>
            <Col>
                <h1>004 Participants</h1>
            </Col>
        </Row>
    </Container>
</Jumbotron>
<AnotherReportsListSidebar/> */}
