import React from "react";
import "../surveyAdherence.css";
// import { usePageName } from 'react-page-name';
    
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import {Helmet} from "react-helmet";


const Bext004ProsTherapyWeek = (props) => {
    // usePageName('PROs-TW 004-2X');
 return (<>
 <Helmet>
        <title>PROs-TW 004-2X</title>
        <meta name="PROs-TW 004-2X" content="PROs-TW 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
        <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other PRO Scores by Therapy Week, Cohort 2 Extension</h1>
                        

                        <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use.</div>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext />
        
        {/* <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "175px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
            </Container> */}

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>

            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px" }}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>
                   

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#ST">jump to ST</HashLink>
        </Col>
                </Row>
               
            </Container>
            <div className="TitleC"  >PROs by Therapy Week, ACT</div>

            <CmReportTable004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"otherPros004BextACT"}></CmReportTable004Bext>
                </div>
        </Container>
     
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px" }}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div id="ST" className="TitleC"  >PROs by Therapy Week, ST</div>
            <CmReportTable004BextST style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"otherPros004BextST"}></CmReportTable004BextST>
                </div>
        </Container>
</>);

};

export default Bext004ProsTherapyWeek;

