import React from "react";
import {Link} from "react-router-dom";
import "../surveyAdherence.css";

import {Container} from "react-bootstrap";

// import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import AReportTable from "./AReportTable";
// import AsmallerReportTable from "./AsmallerReportTable";
// const query = firestore.collection("analytics/reports/reportPages");
// const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
// snapshotListenOptions: {includeMetadataChanges: true},
// });
// const query = firestore.collection("analytics").doc("kpi_summary")
const AnotherReportsListSidebar004B = (props) => {
  return (
    <>
      <Container className="no-print" fluid={true} style={{ backgroundColor: "white",paddingLeft: "0px" }  }>
        <div className="sidenav">
          {/* <div>
          <Link className="TitleDB" style={{  }} to="/screeningWeek">
              Screening Week
            </Link>
          </div> */}

                    <div>
                    <Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/sitesB">
              Sites
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/consortB">
              Consort
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/favoritesB">
          Favorites
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fibroflareB">
               Fibro Flare Toolkit
            </Link>
          </div>

          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/kpisB">
               KPIs
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/act004B">
ACT Progress charts
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/st004B">
ST Progress charts
            </Link>
          </div>


          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/insightsB">
Insights
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/journalsB">
Other ACT Journals
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/journalstartsB">
ACT Journal Start Times
            </Link>
          </div>

          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/pro_compliance004B">
PRO Compliance
            </Link>
          </div>

          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/prosB">
               PROs - Visit
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/prosTWB">
PROs - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqr004B">
FIQR - Visit
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqrTW004B">
FIQR - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/ffmqnrTWB">
FFQM-NR - Therapy Week
            </Link>
          </div>  
          <div>

           <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/cfqTWB">
CFQ - Therapy Week
            </Link>
          </div>  
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/cpaqpwTWB">
CPAQ8-PW - Therapy Week
            </Link>
          </div>  
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/cpaqaeTWB">
CPAQ8-AE - Therapy Week
            </Link>
          </div>  

<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/pgicB">
PGIC - Visit
            </Link>
          </div>          
  
         
          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/visitsB">
              Visits
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/app_progress004B">
          ACT App Progress
            </Link>
          </div>
          <div>
          <li class="dropdown">
          <a href="javascript:void(0)" class="TitleDB">PRO charts</a>
<div class="dropdown-content">
<Container  fluid={true} style={{ backgroundColor: "white"}}>
                 <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/fiqrgraphsB">
                FIQR
                </Link>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/cpaq8aegraphsB">
                CPAQ8AE
                </Link>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/cpaq8pwgraphsB">
                CPAQ8PW
                </Link>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/cfqgraphsB">
                CFQ
                </Link>
                <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/ffnrgraphsB">
                fivefacetsNR
                </Link>
                {/* <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/bdigraphsB">
                BDI
                </Link> */}
                {/* slack expt */}
                
                </Container>
                </div>
                    </li>

                    
</div>
        {/* App Progress */}


          {/* <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/kpis">
              KPIs
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/act004a">
              ACT Progress charts
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/st004a">
              ST Progress charts
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/app_progress004">
              App Progress
            </Link>
          </div>


          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/pros">
               PROs
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/pro_compliance004">
               PRO Compliance
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqr004B">
               FIQR Scores - Visit
            </Link>
          </div>
          <div><Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqrap004">
               FIQR Scores - App Progress
            </Link>
          </div>
          <div><Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/fiqrtherapyweek004">
               FIQR Scores - Therapy Week
            </Link>
          </div>
          <div><Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/other_prosA">
          Other PROs - Visit
            </Link>
          </div>
          <div><Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/scoresap004">
          Other PROs - App Progress
            </Link>
          </div>
          <div><Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/scorestherapyweek004">
               Other PROs - Therapy Week
            </Link>
          </div>

          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/visitsB">
              Visits
            </Link>
          </div>
          <div>
          <Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/fivefacets004atw">
              Five Facets - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/promis004atw">
              PROMIS - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/promis004tatw">
              PROMIS - converted - Therapy Week
            </Link>
          </div>
          <div>
<Link className="TitleDB" style={{  fontSize: "12px"}} to="/004/promis004">
              PROMIS - Visit
            </Link>
          </div>


          <div>
<Link className="TitleDB" style={{  fontSize: "12px" }} to="/004/promis004T">
              PROMIS - converted - Visit
            </Link>
          </div>
          <div>
          <li class="dropdown"> */}
          {/* <a href="javascript:void(0)" class="TitleDB">PRO charts</a>
<div class="dropdown-content">
<Container  fluid={true} style={{ backgroundColor: "white"}}>
                 <Link className="TitleDBB" style={{padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/fiqr004graphs">
                FIQR
                </Link>



               <Link className="TitleDBB" style={{ padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/ffnr004graphs">
                Five Facets NR
                </Link>

<Link className="TitleDBB" style={{ padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/pips004graphs">
                PIPS
                </Link>
<Link className="TitleDBB" style={{ padding: "10px 10px 10px 0px", fontSize: "12px"}} to="/004/wi004graphs">
                Weekly Intensity & Interference
                </Link>
                </Container>
                </div>
                    </li> */}


{/* </div> */}


        </div>
      </Container>
    </>
  );
};

export default AnotherReportsListSidebar004B;
