import React, {useEffect, useState} from "react";
import "../surveyAdherence.css";
import {firestore} from "../../utils/firebase";

import {Col, Table} from "react-bootstrap";

const CmReportTableVS1stickyHRfmlUPMC  = (props) => {
    const {tableKey} = props;
    const {bgColor}  = props;
    const {tdFontSize}  = props;
    const {thFontSize}  = props;
    const {thPaddingLeft}  = props;
    const {thPaddingRight}  = props;
    const {fColor}  = props;
    const {tdfColor}  = props;

    // paddingLeft
    const [tableDoc, setTableDoc] = useState();
    const [name, setName] = useState("");
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        firestore
            .doc("analytics/reports/reportTablespUPMC/" + tableKey)
            .get()
            .then((doc) => {
                const data = doc.data();
                setColumns(data.columns);
                setName(data.name);
                setTableDoc(doc);
                const path = data.collectionPath ? data.collectionPath : "analytics/reports/reportTablespUPMC/" + tableKey + "/rows";
                let query = data ? firestore
                .collection(path)
                .orderBy(data.orderBy.field, data.orderBy.direction) : firestore.collection(path);
                query = data?.orderBy2? query.orderBy(data.orderBy2.field, data.orderBy2.direction) : query;
               
                query.onSnapshot((snap) => {
                    setRows(snap.docs);
                });
            });
    }, [tableKey]);

    const getTableRow = (progRef) => {
        let data = progRef.data();

        let cols = columns.map((c) => {
            let val = data[c];
            if (typeof data[c] === "object") {
                try {
                    val = data[c].toDate().toISOString().slice(0,10);
                } catch (error) {
                    console.log('couldnt format something', {data, error})
                }
            }
            return <td className={"specialStickyRFML"}  style={{ color: tdfColor, fontSize: tdFontSize, lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{val}</td>;
        });
        return (<tr className={"specialStickyRFML"} key={progRef.id} onClick={() => {
            }}>
                {cols}
            </tr>);
    };
    if (!(columns && rows)) {
        return <> </>;
    }
    return (<>



            <Col  xs={12} style={{padding: "0px"}}>
                <Table className={"reports_tableX specialStickyRFML"}  style={{backgroundColor: "white", width: "auto"}}>
                    
                    <thead className={"specialStickyRFML"} key={"tableHead" + (tableDoc ? tableDoc.id : "")}>
                    <tr className={"specialStickyRFML"} style={{backgroundColor: bgColor}}  key={"headerRow"} >
                    {columns.map((c) => {
                            return <th className={"twoArm_tableB specialStickyRFML"} style={{ color: fColor,lineHeight: 1, fontSize: thFontSize, paddingLeft: thPaddingLeft,paddingRight: thPaddingRight, paddingBottom: "4px", paddingTop: "4px"}} key={c.id}>{c}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody  className={"twoArm_tableB"}  >{rows.map((u) => getTableRow(u))}</tbody>
                </Table>
            </Col>

        </>);
};

export default CmReportTableVS1stickyHRfmlUPMC ;

