import React from "react";
// import GraphTemplate from "../Graphs/GraphTemplate";
// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import GraphTemplateIndivs004B from "./GraphTemplateIndivs004B";
import {Col, Container, Jumbotron, Row} from "react-bootstrap";

import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
// import GraphTemplateEPROsA from "../../Graphs/GraphTemplateEPROsA";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
// const query = await firestore.collection("analytics").doc("reports").collection("reportGraphs004B_indiv");
// const Graphs004Bx = (plot) => {
//   return (<>
//   <Row>
//   <Col xs={10}>
//     <GraphTemplateIndivs004B report={plot} />
//   </Col>
// </Row></>);
// };


const Graphs004B = () => {
  return (
    <>
      <AdminNavbar />
      <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>004 ACT Cohort 2</h1><div className="TitleCb"></div>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
      <AnotherReportsListSidebar004B/>
      <Container>
        <div className="mt-4">
        <Row>
            <Col xs={10}>
              {/* <span className="norms" style={{paddingLeft: "260px"  }} >daily-insights</span> */}
              <GraphTemplateIndivs004B report={"s10_011"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s10_013"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s10_015"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s10_017"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s13_012"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s13_014"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s13_015"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s13_018"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s14_011"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s14_013"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s14_015"} />
            </Col>
          </Row>
           <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_002"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_005"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_006"} />
            </Col>
            </Row>
            <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_007"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_011"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_012"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_013"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s15_018"} />
            </Col>
          </Row>          
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_001"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_003"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_004"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_005"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_007"} />
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <GraphTemplateIndivs004B report={"s16_009"} />
            </Col>
          </Row>
        </div>
      </Container>


    </>
  );
};

export default Graphs004B;
