import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AsmallerReportTable004Bextdaily from "./AsmallerReportTable004Bextdaily";
import AsmallerReportTable004Bextweekly from "./AsmallerReportTable004Bextweekly";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import {Helmet} from "react-helmet";

const Bext004Insights = (props) => {
 return (<>
  <Helmet>
		<title>Insights 004-2x</title>
		<meta name="Insights 004-2x" content="Insights 004-2x" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ACT Insights, Cohort 2 Extension</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingBottom: "15px"}}   >Insight Completion</div>
            <div className="TitleDB" style={{fontSize: "17px", paddingLeft: "10px",paddingBottom: "15px"}} >Daily Insights</div>
          
            <AsmallerReportTable004Bextdaily style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"daily_completion004Bx"}></AsmallerReportTable004Bextdaily>
               

                <div className="TitleDB" style={{fontSize: "17px", paddingLeft: "10px",paddingBottom: "15px",paddingTop: "10px"}} >Weekly Insights</div>
          
          <AsmallerReportTable004Bextweekly style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"weekly_completion004Bx"}></AsmallerReportTable004Bextweekly>
          </div>
        </Container>

<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingBottom: "15px"}}   >Journal Entry Completion</div>
            <div className="TitleDB" style={{fontSize: "17px", paddingLeft: "10px",paddingBottom: "15px"}} >Daily Insights</div>
          
            <AsmallerReportTable004Bextdaily style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"anythingElse004Bx"}></AsmallerReportTable004Bextdaily>
               

                <div className="TitleDB" style={{fontSize: "17px", paddingLeft: "10px",paddingBottom: "15px",paddingTop: "10px"}} >Weekly Insights</div>
          
          <AsmallerReportTable004Bextweekly style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"anythingElseW004Bx"}></AsmallerReportTable004Bextweekly>
          </div>
        </Container>
 
 
 
 
 
 
 
 
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "15px"}} >Insight Responses</div>
            <div className="TitleDB" style={{fontSize: "20px", paddingLeft: "10px",paddingBottom: "25px"}} >Daily Insights, weekly means</div>
            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 24 hours, as a result of my fibromyalgia symptoms, I found doing my household responsibilities and daily activities:</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Physically not difficult - Physically very difficult</div>
            
            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"householdResponsibilities004Bx"}></AsmallerReportTable004Bextdaily>
   
            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 24 hours, I did things I care about despite fibromyalgia.</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>

            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"livingDespiteFibro004Bx"}></AsmallerReportTable004Bextdaily>

            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 24 hours, my pain did not get in the way of how I want to live my life.</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>

            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"overcomingPain004Bx"}></AsmallerReportTable004Bextdaily>

            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 24 hours, I was able to be aware of my thoughts without necessarily reacting to them.</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>

            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"thoughts004Bx"}></AsmallerReportTable004Bextdaily>


            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>Over the last 24 hours my average fibromyalgia pain level was:</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, No pain at all - Unbearable pain</div>
            
            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"painIntensity004Bx"}></AsmallerReportTable004Bextdaily>

            <div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>Last night my sleep quality was:</div>
            <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Very poor - Very good</div>
 
            
            <AsmallerReportTable004Bextdaily  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"sleepQuality004Bx"}></AsmallerReportTable004Bextdaily>

            <div className="TitleDB" style={{fontSize: "20px", paddingLeft: "10px",paddingBottom: "25px", paddingTop: "25px"}} >Weekly Insights</div>

<div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 7 days, I did things I care about despite fibromyalgia.</div>
<div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>
<AsmallerReportTable004Bextweekly  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"livingDespiteFibroW004Bx"}></AsmallerReportTable004Bextweekly>
<div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 7 days, my pain did not get in the way of how I want to live my life.</div>
<div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>
<AsmallerReportTable004Bextweekly  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"overcomingPainW004Bx"}></AsmallerReportTable004Bextweekly>
<div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 7 days, I acted on my values, even if it was difficult to do so.</div>
<div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>
<AsmallerReportTable004Bextweekly  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"actingOnMyValuesW004Bx"}></AsmallerReportTable004Bextweekly>
<div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 7 days I was able to practice that I am more than my thoughts, feelings, sensations, and symptoms.</div>
<div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>
<AsmallerReportTable004Bextweekly  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"internalExperiencesW004Bx"}></AsmallerReportTable004Bextweekly>
<div className="TitleDB" style={{fontSize: "14px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "5px"}}>In the past 7 days, I was able to stay focused on what's happening in the moment.</div>
<div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px", paddingTop: "10px"}}>0 - 10, Do not agree - Completely agree</div>
<AsmallerReportTable004Bextweekly  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"mindfulnessW004Bx"}></AsmallerReportTable004Bextweekly>

                </div>
                
        </Container>

    


</>);

};

export default Bext004Insights;




