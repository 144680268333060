import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Nav, Navbar, NavDropdown, } from "react-bootstrap";
import { auth } from "../utils/firebase";

const AdminNavbar = (props) => {
  return (
    <>
      <Navbar bg="light" variant="light" style={{ backgroundColor: "#2B3876", color: "white" }}>
        <Container fluid={true} style={{ color: "white", width: "100%" }}>
          <Navbar.Brand href="/">Swing Analytics</Navbar.Brand>
          <Nav className="mr-auto" style={{ color: "white" }} >
            <a className="nav-link d-none d-sm-block " href="https://tempo.systems/users" style={{ color: "white" }} target="_blank">
              Tempest
            </a>

            <Link className="nav-link" to="/002/graphs002" style={{ color: "white" }}>
              002
            </Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white", borderColor: "#fff" }}>
              <Link className="nav-link" to="/interviews">Interviews</Link>
              <Link className="nav-link" to="/kpis">
                Brachio
              </Link>
              <Link className="nav-link" to="/002/graphs002">
                Fukui
              </Link>
            </NavDropdown>
            {/*<Link className="nav-link d-none d-sm-block " to="/004/act004a">004</Link>*/}
            <Link style={{ color: "white" }} className="nav-link d-none d-sm-block " to="/004/fiqrBext">004</Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white" }}>

              <Link className="nav-link" to="/004/interviews004">Interviews</Link>
              <Link className="nav-link" to="/004/sites">Cohort 1</Link>

              <Link className="nav-link" to="/004/sitesB">Cohort 2</Link>
              <Link className="nav-link" to="/004/fiqrBext">Cohort 2 Extension</Link>

            </NavDropdown>

            <Link style={{ color: "white" }} className="nav-link d-none d-sm-block " to="/003/consortA">003</Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white" }}>



              {/* <a className="nav-link" to="https://tempo.systems/surveysaurus/stats">003 Tempest</a> */}
              <Link className="nav-link" to="/003/dashA">003 - A</Link>
              <a className="nav-link d-none d-sm-block " href="https://tempo.systems/surveysaurus/stats">
                Surveysaurus
              </a>
            </NavDropdown>

            <Link style={{ color: "white" }} className="nav-link d-none d-sm-block " to="/005/dashA">005</Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white" }}>
              {/* <a className="nav-link" to="https://tempo.systems/surveysaurus/stats">003 Tempest</a> */}
              <Link className="nav-link" to="/005/dashA">005 - A</Link>
            
            </NavDropdown>
            <Link style={{ color: "white" }} className="nav-link d-none d-sm-block " to="/VS/SCdash">Swing Care</Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white" }}>
              <Link className="nav-link" to="/VS/SCdash">Swing Care A</Link>
            
            </NavDropdown>
            <Link style={{ color: "white" }} className="nav-link d-none d-sm-block " to="/ORX/stanza">Prescribing Clinics</Link>
            <NavDropdown title="" className={"collapsible-wide-dropdown"} style={{ color: "white" }}>
              <Link className="nav-link" to="/ORX/stanza">Prescribing Clinics</Link>
              <Link className="nav-link" to="/ORX/pokeListSMA">Southwest Medical Poke List</Link>
              <Link className="nav-link" to="/ORX/pokeListUPMC">UPMC Poke List</Link>

         
            
            </NavDropdown>

            <Link className="nav-link d-none d-sm-block " to="/pokeList" style={{ color: "white" }}>
              Poke List
            </Link>
            <Link className="nav-link d-none d-sm-block " to="/proref" style={{ color: "white" }}>
              PRO Reference
            </Link>
            <Link className="nav-link d-none d-sm-block " to="/audioref" style={{ color: "white" }}>
              Audio Reference
            </Link>
            <Link className="nav-link d-none d-sm-block " to="/insightsref" style={{ color: "white" }}>
              Insights Reference
            </Link>
          </Nav>
          <Nav style={{ color: "white" }}>
            <NavDropdown title="Under Development" className={"collapsible-wide-dropdown"} style={{ color: "white", borderColor: "white", backgroundColor: "#2B3876" }}>
              {/* <NavDropdown className="nav-link d-none d-sm-block" title="Under Development" style={{color: "white"}}> */}
              <a className="nav-link d-none d-sm-block " href="https://tempo.systems/surveyMonkey" style={{ color: "white" }}>
                Survey Monkey Sync
              </a>

            </NavDropdown>

            <Button
              className="d-none d-sm-block "
              variant="outline-warning"
              onClick={() => {
                auth.signOut();
              }}
            >
              Sign Out
            </Button>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
// ftlofg normal push request