import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import ScrollUpButton from "react-scroll-up-button";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A3bInd from "./CmReportTable005A3bInd";

import CmReportTable005A2Ind from "./CmReportTable005A2Ind";
import EproBase005 from  "./eproBase005" ;
import EproBaseGap005 from  "./eproBaseGap005" ;
import { HashLink } from "react-router-hash-link";


const A005baselineDems = (props) => {
    const [eprosA_updated, setEprosA_updated] = useState([]);
	const [Ethnicity, setEthnicity] = useState([]);
	const [Age, setAge] = useState([]);
	const [Gender, setGender] = useState([]);
	const [Race, setRace] = useState([]);
	const [Education, setEducation] = useState([]);
	const [Employment, setEmployment] = useState([]);
	
 
    const ArmData_updateD = async (setter) => { 
        const sco_doc = firestore.collection("analytics").doc("A005").collection("baselineEprosA_updated").doc("baselineEprosA_updated")
        const doc = await sco_doc.get();
        const docData=doc.data()
        const armData_updateD = docData['baselineEprosA_updated'];
        console.log(armData_updateD)
        setter(armData_updateD);
      } 

      const DemNameD = async (dmName,setter) => { 
		const dem_doc = firestore.collection('analytics').doc('A005').collection('baselineDemsA_names').doc('baselineDemsA_names')
        const doc = await dem_doc.get();
        const dem_docData=doc.data()[dmName]
        console.log(dem_docData)
        setter(dem_docData);
      } 


      useEffect(() => {
    ArmData_updateD(setEprosA_updated)
    DemNameD('Ethnicity',setEthnicity)
    DemNameD('Age',setAge)
    DemNameD('Gender',setGender)
    DemNameD('Race',setRace)
    DemNameD('Education',setEducation)
    DemNameD('Employment',setEmployment)
}, [props]);

return (<>
 <Helmet>
        <title>Demos 005A</title>
        <meta name="Demos 005A" content="Demos 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Demographics 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#extraEproDems">•extra epro demos</HashLink>
            <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#earlyTermDems">•early termination demos</HashLink>


  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>


   
<Container fluid={true} >
<Row>
               <Col>
<div className="article" style={{paddingTop: "20px", paddingBottom: "0px",maxWidth:"450px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "20px"}}>data last retrieved: {eprosA_updated}</div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",lineHeight: "1"}}>{Education}</div>
<div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >education level by arm</div>
<CmReportTable005A2Ind style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#9E005D" tableKey=  {"baselineEprosA_eduSumm"} />

<div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >college degree by study arm</div>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#F55C48" tableKey=  {"baselineEprosA_collegeSumm"} />

</div>
</Col>
           </Row>
</Container>

<Container fluid={true}>
<Row>
               <Col>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px",maxWidth:"450px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "45px",lineHeight: "1"}}>{Age}</div>
<div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >age groups by arm</div>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#17BAFF" tableKey=  {"baselineEprosA_ageSumm"} />

</div>
</Col>
           </Row>
</Container>

<Container fluid={true}>
<Row>
               <Col>
<div className="article" style={{paddingTop: "20px", paddingBottom: "0px",maxWidth:"450px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",lineHeight: "1"}}>{Gender}</div>
{/* <div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >gem level by arm</div> */}
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#947878" tableKey=  {"baselineEprosA_genSumm"} />
</div>
</Col>
           </Row>
</Container>

<Container fluid={true}>
<Row>
<Col>
<div className="article" style={{paddingTop: "20px", paddingBottom: "0px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",lineHeight: "1"}}>{Ethnicity}</div>
{/* <div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >gem level by arm</div> */}
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thPaddingRight="7px" thFontSize="18px" tdFontSize="15px" bgColor="#0092FE" tableKey=  {"baselineEprosA_ethSumm"} />
</div>
</Col>
           </Row>
</Container>
{/* <div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >college degree by study arm</div>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#F55C48" tableKey=  {"baselineEprosA_collegeSumm"} /> */}



<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 
<Row>


<Col >
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "45px",lineHeight: "1"}}>{Race}</div>

{/* <div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >age groups by arm</div> */}
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#FF6E16" tableKey=  {"baselineEprosA_raceSumm"} />
</Col>



</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "0px",maxWidth:"450px"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "45px",lineHeight: "1"}}>{Employment}</div>
<div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >employment by arm</div>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#0F8793" tableKey=  {"baselineEprosA_empSumm"} />

<div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >reason not employed by arm</div>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#00EF81" tableKey=  {"baselineEprosA_reasonUnESumm"} />

</div>
</Container>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="extraEproDems" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
            </div>
            </Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "45px",lineHeight: "1"}}>Extra Epro Demographics</div>
{/* <div style={{paddingTop: "10px",fontSize: "30px",paddingBottom: "3px",lineHeight: "1"}} >age groups by arm</div> */}
<CmReportTable005A2Ind style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#662D91" tableKey=  {"baselineEprosA_extraEprosSumm"} />

</div>
</Container>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="earlyTermDems" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 
<Row>
            <Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "45px",lineHeight: "1"}}>Early Termination Demographics</div>
<CmReportTable005A2Ind style={{padding: "0px",paddingBottom: "20px"}} thFontSize="18px" tdFontSize="15px" bgColor="#A3049F" tableKey=  {"baselineEprosA_earlyTSumm"} />
</Col>
            </Row>
</div>
</Container>



</>);

};

export default A005baselineDems;


