import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import AReportTable004B from "./AReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";

import AsmallerReportTable004B from "./AsmallerReportTable004B";
// const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const B004Pro_compliance = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px", paddingRight: "50px"}}>
                <Row>
                    <Col>
                        <h1>004 PRO Compliance Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC" >KPI Target Met, by week</div>
            < div classname="TitleCc">80% of all participants completing weekly PROs</div>

            <AsmallerReportTable004B tableKey={"pro_kpi_compliance_target004B"}></AsmallerReportTable004B>
                </div>
        </Container>
           <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Compliance by Site</div>
            <AsmallerReportTable004B tableKey={"site_pro_compliance004B"}></AsmallerReportTable004B>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Compliance by Participant</div>
            <AsmallerReportTable004B tableKey={"participant_pro_compliance004B"}></AsmallerReportTable004B>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Overall PRO Compliance</div>
            <AReportTable004B tableKey={"overall_pro_compliance004B"}></AReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "40px"}}>
            <div className="TitleC">Missing PROs</div>
            <AReportTable004B tableKey={"missing_pros004B"}></AReportTable004B>
                </div>
        </Container>







</>);

};

export default B004Pro_compliance;

