import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";

import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";


const A003AppUseEXT = (props) => {
    

 return (<>
 <Helmet>
        <title>App Use EXT 003A</title>
        <meta name="App Use EXT 003A" content="App Use EXT 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Extension App Use 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
             <div className="article" style={{paddingBottom: "30px"}}>
             <div className="TitleC" style={{paddingBottom: "5px"}}>All App Use</div>

        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_EXT"} />

         </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
             <div className="article" style={{paddingBottom: "30px"}}>
             <div className="TitleC" style={{paddingBottom: "5px"}}>App Use, Week 11 onward</div>

        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"appUseA_EXTsp"} />

         </div>
        </Container>

 </>);

};

export default A003AppUseEXT;