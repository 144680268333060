import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A3_noHead from "./CmReportTable005A3_noHead";
// import CmReportTable005A3_1ststicky from "./CmReportTable005A3_1ststicky";
import CmReportTable005A2S from "./CmReportTable005A2S";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";

const A005Sites = (props) => {




 return (<>
 <Helmet>
        <title>Sites 005A</title>
        <meta name="Sites 005A" content="Sites 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Sites 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#bysubjectID">•counts by subjectID</HashLink>
           

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>

       <Container fluid={true} style={{padding: "0px"}}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto", paddingRight: "10px",overflow: "scroll", width: "80%"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Site Status Counts</div>
{/* <Row style={{ overflow: "scroll", width: "80%", paddingLeft: "10px", paddingBottom: "0px", marginLeft: "10px"}}> */}
<CmReportTable005A3  style={{width: "80%", padding: "0px", paddingBottom: "10px"}} tdFontSize="12px" thFontSize="13px" bgColor="#2475C7" tableKey={"siteStatusesA"} />
{/* </Row>
<Row style={{overflow: "scroll", width: "80%", paddingLeft: "10px", paddingBottom: "0px"}}> */}
<CmReportTable005A3  style={{paddingTop: "10px"}} tdFontSize="12px" thFontSize="13px" bgColor="#9E005D"  tableKey={"siteStatusesA_percents"} />
{/* </Row> */}
{/* <Row style={{overflow: "scroll", width: "80%", paddingLeft: "10px", paddingBottom: "0px"}}> */}
<CmReportTable005A3  style={{padding: "10px", width: "auto"}} bgColor="#F55C48" tdFontSize="12px" thFontSize="13px" tableKey={"site_Statuses_eproComplA"} />
{/* </Row> */}

</div>
       </Container>



<Container fluid={true}>
<div className="article" style={{paddingTop: "5px", paddingBottom: "50px", width: "auto"}}> 

<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Status Counts</div>
</Row>
<Row>
<GraphTemplate005A  style={{padding: "0px"}}  report={"currentstatuses005A"} />
</Row>

</div>
</Container>

{/* bysubjectID */}
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "40px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1"}}>
        <Row>
            <Col>
            <HashLink id="bysubjectID" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                                </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", width: "auto"}}> 

<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Status by subjectID</div>
</Row>
<Row style={{width: "400px"}}>
<CmReportTable005A2S   style={{padding: "0px"}} thFontSize="15px" tdFontSize="14px" bgColor="#9E005D" tableKey={"indivStatusesA"} />
</Row>

</div>
</Container>

</>);

};

export default A005Sites;

