import React, { useRef, useEffect, useState} from "react";
import { GeoJSON,Marker,Circle,circleMarker } from "react-leaflet";
import * as topojson from "topojson-client";
import {firestore} from "../../../utils/firebase";


const Topoj = (props) => {
  const layerRef = useRef(null);
     const [color,setColor] = useState(null);
  const { data, ...otherProps } = props;
  const {countyColor}  = props;

 
  
  const GetcountyData = async (county,setter) => {
    const forCountyData = await firestore.collection("analytics").doc("preVS").collection("counties").doc(county).get();
    const countyData = forCountyData.data();
    return countyData
  }
 

  function addData(layer, jsonData) {
    if (jsonData.type === "Topology") {
      for (let key in jsonData.objects) {
        let geojson = topojson.feature(jsonData, jsonData.objects[key]);
        layer.addData(geojson);
      }
    } else {
      layer.addData(jsonData);
    }
  }



  async function onEachFeature(feature, layer) {
    if (feature.properties) {
      const { county,url } = feature.properties;
      const thisCounty  = await GetcountyData(county)
      // console.log(thisCounty.color);
      layer.setStyle({ color: "#8C8785", fillColor: thisCounty.color, weight: .5,fillOpacity:1 }).bindPopup(`<a  href= "${url}">${county} County</a></br>patients: ${thisCounty.patients}`).on('click');
    }
   
  }
  

  useEffect(() => {
    // console.log('oy');
    const layer = layerRef.current;
    addData(layer, props.data);
  }, [props.data]);

  return (
    
    <GeoJSON    ref={layerRef} {...otherProps} onEachFeature={onEachFeature}  />
  );
}

export default Topoj;
// style={{fillOpacity: ".65", weight: ".5",strokeOpacity: "1",fillColor: "#C6C6C6"}
// style={{fillOpacity: ".65", weight: ".5",strokeOpacity: "1",fillColor: "#C6C6C6",color: "#A1A1A1"}}

// fillColor={countyColor}