import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004B from "./CmReportTable004B";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import AsmallerReportTable004Bweekly from "./AsmallerReportTable004Bweekly";
import AmediumReportTable004B from "./AmediumReportTable004B";
import GraphTemplateJournalsB from "../../graphs/GraphTemplateJournalsB";
import {Helmet} from "react-helmet";


const B004JournalMets = (props) => {
 return (<>
  <Helmet>
		<title>Journal Metrics 004-2</title>
		<meta name="Journal Metrics 004-2" content="Journal Metrics 004-2" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ACT Journals, Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "150px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            {/* <div className="TitleDB" style={{paddingBottom:"8px", paddingTop: "20px"}}>ST</div> */}
            <div className="TitleC" style={{ paddingLeft: "100px",paddingBottom: "1px",paddingTop: "25px"}} >By Journal Type and ACT Group</div>
              <GraphTemplateJournalsB style={{paddingBottom:"28px"}} report={"ACT004B_journals"} />
            </Col>
          </Row>
          </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            {/* <div className="TitleC" style={{paddingBottom: "25px"}}   >Journal Completion</div> */}
            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >By Journal Type</div>
            <AsmallerReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_byJournalID004B"}></AsmallerReportTable004B>
            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >By ACT Group</div>
            <AmediumReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_by_group004B"}></AmediumReportTable004B>
            </div>
        </Container>
            {/* <AsmallerReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px",width: "50%",lineHeight: "1.3"}} tableKey={"journal_metrics_by_group004B"}></AsmallerReportTable004B> */}
           
            
            <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
           
            <div className="TitleC" style={{ paddingLeft: "10px",paddingBottom: "1px",paddingTop: "25px"}} >By Journal Type and ACT Group</div>
          <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"journal_metrics_byJournalID_group004B"}></CmReportTable004B>
         
          <div className="TitleC" style={{ paddingLeft: "10px",paddingBottom: "1px",paddingTop: "25px"}} >By Participant</div>
          <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"journal_metrics_by_subjectID004B"}></CmReportTable004B>

          </div>
        </Container>


 
 
 

    


</>);

};

export default B004JournalMets;




