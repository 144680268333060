import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import AReportTable from "./AReportTable";
import BReportTable004B from "./BReportTable004B";
import CmReportTable004B from "./CmReportTable004B";
import CmReportTable004BST from "./CmReportTable004BST";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import TWexpl004B from "./TWexpl004B";
import TWexpl004B2 from "./TWexpl004B2";

import {Helmet} from "react-helmet";

 


const B004FIQRbyTherapyWeek = (props) => {
 return (<>
 <Helmet>
		<title>FIQR-TW 004-2</title>
		<meta name="FIQR-TW 004-2" content="FIQR-TW 004-2" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>FIQR Scores by Therapy Week, Cohort 2</h1>
                        <TWexpl004B2/>
                        {/* <div className="TitleCb" style={{ paddingLeft: "30px"}}>weeks since app start, where week 0 averages the two scores before app use. Only scores ≤ end_week12</div> */}

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>


      

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">Mean FIQR Scores
            <TWexpl004B/>

            {/* <div className="TitleDB" style={{fontSize: "11px", paddingLeft: "10px",paddingBottom: "10px"}} >*percent_change_from_baseline is the mean percent_change_from_baseline across all participants; it is not calculated from the mean change_from_baseline.</div> */}
            </div>

            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fiqrTW_004B_means"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ACT FIQR Scores</div>
            <CmReportTable004B style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fiqrTW_004B_ACT"}></CmReportTable004B>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">ST FIQR Scores</div>
            <CmReportTable004BST style={{ paddingLeft: "0px", paddingRight: "0px"}}  tableKey={"fiqrTW_004B_ST"}></CmReportTable004BST>
                </div>
        </Container>


</>);

};

export default B004FIQRbyTherapyWeek;

// fthfghfg
