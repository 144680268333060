import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004Bext from "./CmReportTable004Bext";
import AsmallerReportTable004Bextdaily from "./AsmallerReportTable004Bextdaily";
import AsmallerReportTable004Bextweekly from "./AsmallerReportTable004Bextweekly";
import GraphTemplate004Bext from "./GraphTemplate004Bext";
import { HashLink } from "react-router-hash-link";
import  PROkeyTableXSext2 from "./PROkeyTableXSext2";
import  PROkeyTableXSext from "./PROkeyTableXSext";



import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import {Helmet} from "react-helmet";

const Bext004Audio = (props) => {
 return (<>
  <Helmet>
		<title>Awareness Activities 004-2x</title>
		<meta name="Awareness Activities 004-2x" content="Awareness Activities 004-2x" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1 style={{paddingBottom: "9px"}}>ACT Awareness Activities, Cohort 2 Extension </h1>
                            
                        <h2 className="TitleDB" style={{paddingBottom: "25px", fontSize: "32px"}}>Awareness Activities Introduced During Sessions</h2>


                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004Bext/>
       

     
                

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>

   {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingBottom: "10px"}}>
            <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px", lineHeight: "1"}}>
             */}

    
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
            <Row>
                <Col>
                <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#timeOfDay">•skip to Time of Day</HashLink>
                <br />
                    <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#oneShots">•skip to One Shot Wonders</HashLink>
					<br />
                    <HashLink  className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#effectEncounter">•skip to Completion by Encounter</HashLink>
                    <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
                </Col>
            </Row>

        </Container>
        </div> 
        </Container> 
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>

   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "4px"}}>Part 1 Awareness Activity Completion</div>
   <div className="TitleC" style={{fontSize: "20px",paddingBottom: "9px", paddingTop: "0px"}}>By Awareness Activity & Encounter</div>

   <div className="TitleC" style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "4px"}}>
   <PROkeyTableXSext2  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"awareness_pt1_mean_percentCompletedV2_004BX0"}></PROkeyTableXSext2>

   
</div>
<div className="TitleC" style={{fontSize: "20px",paddingBottom: "9px", paddingTop: "0px"}}>By Awareness Activity, Encounter, & Session Location<span style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "500px"}}>----------Launch Screen Count Totals----------</span></div>

   {/* <div className="TitleC" style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "80px", paddingTop: "0px"}}>  
<span className="TitleC" style={{fontSize: "15px",paddingBottom: "0px",paddingLeft: "850px"}}>----------Launch Screen Count Totals----------</span>
</div> */}
   <PROkeyTableXSext2  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"awareness_pt1_mean_percentCompleted004BX0"}></PROkeyTableXSext2>

   
</div>
       
</Container>
{/* paddingLeft: "10px", paddingRight: "0px",paddingTop:"30px" */}
<div className="article" style={{paddingBottom: "30px",width:"auto"}}  >
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
                <Row>
                    <Col>

                        <HashLink id="timeOfDay" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                    </Row>
                    <Row>
                    <div style={{paddingBottom: "20px", paddingLeft: "0px", paddingRight: "0px",paddingTop:"0px",fontSize: "32px", width: "auto"}}>The next 4 charts include any awareness activity presented as a session task, <span style={{fontWeight: "700"}}>regardless</span> of launch location</div>

                      
                </Row>
            </Container> 
</div>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>

</Container> 

{/* float: "left" */}
{/* className="article"  */}
{/* float: left;
    padding-left: 20px;
    width: 90%;
    margin-left: 10%; */}

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px",paddingTop:"0px"}}>
   



   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"0px"}}>

 

   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "4px"}}>During which hours of the day do participants listen to ≥50% of an audio file?</div>

 
   <GraphTemplate004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} report={"awareness4"}></GraphTemplate004Bext>
   
</div>
       
</Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "20px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>
   <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <Row>
                    <Col>

                        <HashLink id="effectEncounter" className="TitleDB" style={{ paddingBottom: "3px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                </Row>
            </Container> 

   <div className="TitleC" style={{fontSize: "34px",paddingBottom: "0px", paddingTop: "0px"}}>Awareness Activity Completion by Encounter</div>

 

   <div className="TitleC" style={{paddingBottom: "5px",fontSize: "22px", paddingTop: "0px"}}>How is activity completion affected by repeated encounters with that activity?</div>

   <GraphTemplate004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} report={"awareness3"}></GraphTemplate004Bext>
   
</div>
       
</Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>
            <Container fluid={true} style={{ paddingBottom: "1px", paddingTop: "5px" }}>
                <Row>
                    <Col>

                        <HashLink id="oneShots" className="TitleDB" style={{ paddingBottom: "3px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                    </Col>
                </Row>
            </Container> 
            <div style={{ borderTop: "2px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"80%"  }}></div>


            <div className="TitleC" style={{fontSize: "34px",paddingBottom: "9px", paddingTop: "0px"}}>One Shot Wonders</div>
            <div className="TitleDB" style={{fontSize: "17px", paddingLeft: "3px",paddingBottom: "20px"}}>Awareness Activities listened to when encountered, in their entirety, and in a single sitting</div>
          

            <div className="TitleC" style={{paddingBottom: "5px", color: "#941751"}}>Awareness Activity Completion, by hour of day started & launch screen</div>

            <GraphTemplate004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} report={"awareness1"}></GraphTemplate004Bext>
            
            <div className="TitleC" style={{paddingBottom: "5px", color: "#941751"}}>Awareness Activity Completion, by hour of day started & awareness activity</div>

<GraphTemplate004Bext style={{ paddingLeft: "0px", paddingRight: "0px"}} report={"awareness2"}></GraphTemplate004Bext>
<div style={{ borderTop: "2px solid #941751", paddingBottom: "0px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"80%"  }}></div>

   
                </div>
                
        </Container>

      



        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "25px", paddingRight: "0px"}}>
   
   <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingTop:"15px"}}>

   </div>
                
                </Container>

</>);

};

export default Bext004Audio;

            {/* <div className="TitleC" style={{fontSize: "21px",paddingBottom: "9px"}}>One Shot Wonders:by </div> */}

            {/* <div className="TitleDB" style={{fontSize: "24px", paddingLeft: "10px",paddingBottom: "15px"}}>One Shot Wonders</div> */}

  {/* <div className="TitleC" style={{fontSize: "38px",paddingBottom: "15px"}}>Awareness Activities Encountered in Sessions</div> */}
            {/* ST ➔➔ ACT Arm Only */}
