import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

import CmReportTable004B from "./CmReportTable004B";
import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B";
import AsmallerReportTable004Bweekly from "./AsmallerReportTable004Bweekly";
import AmediumReportTable004B from "./AmediumReportTable004B";
import GraphTemplateJournalsB from "../../graphs/GraphTemplateJournalsB";
import {Helmet} from "react-helmet";


const B004JournalTimes = (props) => {
 return (<>
  <Helmet>
		<title>Journal Times 004-2</title>
		<meta name="Journal Times 004-2" content="Journal Times 004-2" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ACT Journal Start & Finish Times, Cohort 2</h1>
               <div className="TitleCb" style={{ paddingLeft: "10px",paddingBottom: "30px"}}>in participants' local times</div>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar004B/>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "150px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "100px",paddingBottom: "1px",paddingTop: "25px"}} >Journal Start Times  <span className="TitleCb" style={{ paddingLeft: "1px",paddingBottom: "30px"}}>for all types except Insights</span></div>

            
              <GraphTemplateJournalsB style={{paddingBottom:"28px"}} report={"ACT004B_journalStartTimes"} />
            </Col>
          </Row>
          </Container>

          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "150px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "100px",paddingBottom: "1px",paddingTop: "25px"}} >Journal Finish Times  <span className="TitleCb" style={{ paddingLeft: "1px",paddingBottom: "30px"}}>for all types except Insights</span></div>

            
              <GraphTemplateJournalsB style={{paddingBottom:"28px"}} report={"ACT004B_journalFinishTimes"} />
            </Col>
          </Row>
          </Container>



          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "150px", paddingRight: "0px"}}>
        <Row style={{paddingBottom:"8px"}}>
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "100px",paddingBottom: "10px",paddingTop: "25px"}} >Journal Starts & Completions, Count Unique Participants, <span className="TitleCb" style={{ paddingLeft: "1px",paddingBottom: "30px"}}>for all types except Insights</span></div>

            
              <GraphTemplateJournalsB style={{paddingBottom:"28px"}} report={"ACT004B_journalHourUparticipants"} />
            </Col>
          </Row>
          </Container>
 
          

    


</>);

};

export default B004JournalTimes;




