import React from "react";

import "../surveyAdherence.css";
import {Helmet} from "react-helmet";



import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

// import CmReportTable004Bext from "./CmReportTable004Bext";
// import CmReportTable004BextST from "./CmReportTable004BextST";
import PROkeyTableXS004B from "./PROkeyTableXS004B";
import PROkeyTableXrl from "./PROkeyTableXrl";
import AnotherReportsListSidebar004B from "./AnotherReportsListSidebar004B"; 

import { HashLink } from "react-router-hash-link";

 




const B004favorites = (props) => {
 return (<>
 <Helmet>
        <title>Favorites 004</title>
        <meta name="Favorites 004" content="Favorites 004" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Favorites, Cohort 2</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
     <AnotherReportsListSidebar004B/>
    
      <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="top" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#nextTable">jump to next table</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">Top Favorites, counts</div>
            <div className="TitleCb" style={{ paddingBottom: "15px",paddingTop: "1px"}}>For all activities favorited at least 2x, the # of times each activity appears on each type of favorites list.</div>
            <PROkeyTableXS004B  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"favoritesCounts004B"}></PROkeyTableXS004B>
                </div>
        </Container>

       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="nextTable" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#bottom">jump to next table</HashLink>
        </Col>
                </Row>
            </Container>

            <div className="TitleC">Favorites, use</div>
            <div className="TitleCb" style={{ paddingBottom: "15px",paddingTop: "1px"}}>Extent of Favorites use by each participant.</div>
            <PROkeyTableXrl  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"totalfavorites004B"}></PROkeyTableXrl>
                </div>
        </Container>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="bottom" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC">Favorites, details</div>
            <div className="TitleCb" style={{ paddingBottom: "15px",paddingTop: "1px"}}>Details of how participants are using Favorites.</div>

            <PROkeyTableXrl  style={{ paddingLeft: "0px", paddingRight: "0px", width:"85%"}} tableKey={"favoritesdetails004B"}></PROkeyTableXrl>
                </div>
        </Container>
        
        {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <Container fluid={true} style={{paddingBottom: "1px"}}>
                <Row>
                    <Col>

        <HashLink id="key" className="TitleDB" style={{paddingBottom: "30px",fontSize: "14px",paddingLeft: "0px"}} to="#top">jump to top</HashLink>
        </Col>
                </Row>
            </Container>
            <div className="TitleC"  style={{fontSize: "20px"}} >Favorites, key</div>
            <PROkeyTableXSext  style={{ paddingLeft: "0px", paddingRight: "0px", width:"85%", fontSize: "10px"}} tableKey={"favoritesKey004Bext"}></PROkeyTableXSext>
                </div>
        </Container> */}

</>);

};

export default B004favorites;




