import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref"; 
import CmReportTable003AxLref from "./CmReportTable003AxLref";
import CmReportTable003AxL from "./CmReportTable003AxL";

import CmReportTable003Ax2 from "./CmReportTable003Ax2";





const A003CPAQae = (props) => {
    

 return (<>
 <Helmet>
        <title>Chronic Pain Acceptance Activity Engagement 003A</title>
        <meta name="Chronic Pain Acceptance Activity Engagement 003A" content="Chronic Pain Acceptance Activity Engagement 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Chronic Pain Acceptance Activity Engagement 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#supportfibro">•skip to supportfibro</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#swing_internal">•skip to swing_internal</HashLink>
<br/>
<HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_done">•skip to individual scores, done</HashLink>
<br/>
 <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_therapy">•skip to individual scores, therapy</HashLink>
<br/>
 <HashLink className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#indiv_tables_early_terms">•skip to individual scores, early terms</HashLink>

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>

       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Summary Metrics, All Participants</div>
            <div className="TitleC" style={{fontSize: "18px", paddingBottom: "2px", paddingLeft: "185px", paddingTop: "0px"}}>Score
            <span style={{paddingBottom: "15px", paddingLeft: "55px"}}>Percent Change from Baseline</span>
            </div>
            <CmReportTable003AxL bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "2px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"cpaqAE_percentChangesA"} />
            <div className="TitleC" style={{fontSize: "18px", paddingBottom: "2px", paddingLeft: "185px", paddingTop: "0px"}}>Score
            <span style={{paddingBottom: "15px", paddingLeft: "55px"}}>Score Change from Baseline</span>
            </div>
            <CmReportTable003AxL bgColor="#6694BA" style={{paddingBottom:"0px",paddingTop: "20px", paddingRight: "0px", paddingLeft: "0px"}} tableKey={"cpaqAE_scoreChangesA"} />

        </div>
        </Container>

        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="swing_internal" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#supportfibro">jump to supportfibro</HashLink>
            </Col>
            </Row>
            </Container>
            </div>
 
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Swing Internal, Percent Change</div>
            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"cpaqAE_plotB_swing_internal"} />
        </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Swing Internal, Scores</div>
            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"cpaqAE_plotA_swing_internal"} />
        </div>
        </Container>

        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="supportfibro" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
             <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#swing_internal">jump to swing_internal</HashLink>
            </Col>
            </Row>
            </Container>
            </div>


 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Support Fibro, Percent Change</div>
            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"cpaqAE_plotB_supportfibro"} />
        </div>
        </Container>


        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Support Fibro, Scores</div>
            <GraphTemplate003Aref style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"cpaqAE_plotA_supportfibro"} />
        </div>
        </Container>

        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="indiv_tables_done" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_therapy">jump to individual scores, in-therapy</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_early_terms">jump to individual scores, early terms</HashLink>

                {/* <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#supportfibro">jump to supportfibro</HashLink> */}
            </Col>
            </Row>
            </Container>
            </div>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Scores by Participant, Done</div>
        <CmReportTable003Ax2 bgColor="#2475C7" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"cpaqAE_done_Ind003A"} />
        </div>
        </Container>

        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="indiv_tables_therapy" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_done">jump to individual scores, done</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_early_terms">jump to individual scores, early terms</HashLink>

                {/* <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#supportfibro">jump to supportfibro</HashLink> */}
            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Scores by Participant, Therapy</div>
        <CmReportTable003Ax2 bgColor="#9E005D" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"cpaqAE_therapy_Ind003A"} />
        </div>
        </Container>

        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="indiv_tables_early_terms" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_done">jump to individual scores, done</HashLink>
                <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#indiv_tables_therapy">jump to individual scores, therapy</HashLink>

                {/* <br/>
                <HashLink  className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#supportfibro">jump to supportfibro</HashLink> */}
            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}>
            <div className="TitleC" style={{paddingBottom: "5px"}}>Scores by Participant, Early Terms & Never Starteds</div>
        <CmReportTable003Ax2 bgColor="#DF270D" style={{paddingBottom:"28px",paddingTop: "0px",paddingLeft: "15px"}} tableKey={"cpaqAE_early_term_Ind003A"} />
        </div>
        </Container>


</>);

};

export default A003CPAQae;
