import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
// import {firestore} from "../../utils/firebase";

    
import CmReportTable003A from "./CmReportTable003A";
import CmReportTable003Ax from "./CmReportTable003Ax";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

 




const A003demographics = (props) => {
    return (<>
 
 <Helmet>
        <title>Demographics 003A</title>
        <meta name="Demographics 003A" content="Demographics 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Demographics 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 

        
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "80px"}}>

            <div className="TitleC" style={{fontSize: "20px"}}>What is your race? Select all that apply.</div>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#5C7A7D" tableKey={"baseli_000119A"}></CmReportTable003Ax>
             

<div className="TitleC" style={{fontSize: "20px"}}  >What is your ethnicity? Select all that apply.</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#6694BA" tableKey={"baseli_000120A"}></CmReportTable003Ax>

<div className="TitleC" style={{fontSize: "20px"}}  >What is your gender?</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#DE784E" tableKey={"baseli_000118A"}></CmReportTable003Ax>


<div className="TitleC" style={{fontSize: "20px"}}  >What is your level of education?</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#984D59 " tableKey={"baseli_000121A"}></CmReportTable003Ax>

<div className="TitleC" style={{fontSize: "20px"}}  >Are you currently employed?</div>

<CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#4E4388" tableKey={"baseli_000122A"}></CmReportTable003Ax>



</div>
        </Container>

      
</>);

};

export default A003demographics;




