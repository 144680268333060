import React,{ useState, useEffect,Component }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1stickyNoIndFML from "./CmReportTableVS1stickyNoIndFML";
import CmReportTableVS1stickyHRfml from "./CmReportTableVS1stickyHRfml";
import CmReportTableVS1StickyNoIndWL from "./CmReportTableVS1StickyNoIndWL";
// import CmReportTableVS1stickyHRfml from "./CmReportTableVS1stickyHRfml";

// CmReportTableVS1stickyRKMN

import ReportsListSidebarSCA from "./ReportsListSidebarSCA";


import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";




const SCwaitlistA = (props) => {
    const [updated, setUpdated] = useState([]);
    const getTitleCs = async (titleC,setter) => { 
           const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("appointments");
           const doc = await dashes.get();
           const docData=doc.data()
           const titC = docData[titleC];
               console.log(titC)
               setter(titC);
          }
   
          useEffect(() => {  
      
           getTitleCs('updated',setUpdated)
   
       }, [props]);


return (<>


 <Helmet>
        <title>Intake Waitlist SCA</title>
        <meta name="Intake Waitlist SCA" content="Intake Waitlist SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        

       

      
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#waitlist">•Intake Waitlist</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#rxchecks">•Prescription Checks</HashLink>
               <br/>
            
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#outreach">•Outreach Patients</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#inactives">•Inactive Patients</HashLink>
               <br/>
         
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "15px",fontSize: "45px"}}>Patient Outreach<span style={{fontSize: "14px",paddingLeft: "20px"}} >updated: {updated}</span></div>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="waitlist" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Intake Waitlist</HashLink>
</Col>
</Row>
</div>
</Container> 


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "30px",maxWidth: "75%"}}>

<Row>
<Col>
</Col>
</Row>   
<Row style={{overflow: "scroll", width: "700px",maxHeight:"400px",maxWidth: "75%"}}>
<Col>
<CmReportTableVS1StickyNoIndWL style={{padding: "0px", paddingTop: "0px"   }} fColor="#D3DD88"  thFontSize="11px" tdFontSize="10px" tdPaddingRight="3px" tdPaddingTop="4px"  thPaddingLeft="3px" bgColor="#226277" tableKey={"intakeWL"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="rxchecks" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Prescription Checks</HashLink>
</Col>
</Row>
</div>
</Container> 


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "30px",maxWidth: "75%"}}>

<Row>
<Col>
</Col>
</Row>   
<Row style={{overflow: "scroll", width: "750px",maxHeight:"400px",maxWidth: "75%"}}>
<Col >
<CmReportTableVS1stickyHRfml style={{padding: "0px", paddingTop: "0px"   }} fColor="#226277"  thFontSize="11px" tdFontSize="10px" tdPaddingRight="3px" tdPaddingTop="4px"  thPaddingLeft="3px" bgColor="#D3DD88" tableKey={"rx_to_check"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%",paddingTop: "20px"}}>
<Row>
<Col>
<HashLink id="outreach" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Outreach Patients</HashLink>
</Col>
</Row>
<Col>
<Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "15px"}}>Intake appointment scheduled, cancelled or no-showed, and not completed.</div>
</Row>
</Col>
</div>
</Container> 


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "30px",maxWidth: "75%"}}>

<Row>
<Col>
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "15px"}}>Intake appointment scheduled, but neither occured nor upcoming</div> */}
{/* ,color: "#000000" */}
</Col>
</Row>   
<Row style={{overflowY: "scroll", width: "auto",maxHeight:"400px",maxWidth: "85%"}}>
<Col>
<CmReportTableVS1stickyH style={{padding: "0px", paddingTop: "0px"   }} fColor="#D3DD88"  thFontSize="11px" tdFontSize="10px" tdPaddingRight="3px" tdPaddingTop="4px"  thPaddingLeft="3px" bgColor="#226277" tableKey={"intakeOutreach"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%",paddingTop: "20px"}}>
<Row>
<Col>
<HashLink id="inactives" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Inactive Patients</HashLink>
</Col>
</Row>
</div>
</Container> 


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "60px",maxWidth: "70%"}}>

<Row>
<Col>
</Col>
</Row>   
<Row style={{overflowY: "scroll", maxHeight:"400px",maxWidth: "47%", minWidth: "400px"}}>
<Col>
<CmReportTableVS1StickyNoIndWL style={{padding: "0px", paddingTop: "0px"   }} fColor="#226277"  thFontSize="11px" tdFontSize="10px" tdPaddingRight="6px" tdPaddingTop="4px"  thPaddingLeft="6px" bgColor="#D3DD88" tableKey={"inactives"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>






</>);

};

export default SCwaitlistA;