import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import CmReportTable004Bext from "./CmReportTable004Bext";
import CmReportTable004BextST from "./CmReportTable004BextST";
import PROkeyTableXSext from "./PROkeyTableXSext";
import PROkeyTableXrl from "./PROkeyTableXrl";
import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext"; 

import { HashLink } from "react-router-hash-link";
import {Helmet} from "react-helmet";

 




const Bext004notifications = (props) => {
    // usePageName('Notifications 004-2X');
 return (<>
 <Helmet>
        <title>Notifications 004-2X</title>
        <meta name="Notifications 004-2X" content="Notifications 004-2X" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Notifications, Cohort 2 Extension</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
     <AnotherReportsListSidebar004Bext/>
   
        

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">Push Notifications</div>
            <PROkeyTableXSext  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"push_notifications004Bext"}></PROkeyTableXSext>
                </div>
        </Container>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC">Reminders</div>
            <PROkeyTableXrl  style={{ paddingLeft: "0px", paddingRight: "0px", width:"85%"}} tableKey={"reminders004Bext"}></PROkeyTableXrl>
                </div>
        </Container>


</>);

};

export default Bext004notifications;




