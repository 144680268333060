import React from "react";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import AreportsListSidebar from "../AreportsListSidebar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import BReportTable from "./BReportTable";
import AlargerReportTable from "./AlargerReportTable";

// const query = firestore.collection("analytics/reports/reportPages");
    // const [reportPages, reportPagesLoading, reportPagesError] = useCollection(query, {
        // snapshotListenOptions: {includeMetadataChanges: true},
    // });
// const query = firestore.collection("analytics").doc("kpi_summary")
const FiqrVfablesOfTable = (props) => {
 return (<>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>FIQR Scores</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AreportsListSidebar/>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: most recent vs baseline</p>
            <BReportTable tableKey={"fiqrVbaseline002"}></BReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: weekly changes</p>
            <BReportTable tableKey={"fiqr_changes002"}></BReportTable>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white"}}>
            <div className="article">
            <p className="TitleC">FIQR: weekly scores</p>
            <AlargerReportTable tableKey={"fiqr_scores"}></AlargerReportTable>
                </div>
        </Container>
</>);

};

export default FiqrVfablesOfTable;

