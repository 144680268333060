import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003AxS from "./CmReportTable003AxS";
import CmReportTable003Ax from "./CmReportTable003Ax";
import CmReportTable003Ax2smaller from "./CmReportTable003Ax2smaller";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";

import ScrollUpButton from "react-scroll-up-button";


const A003addpractices = (props) => {
    return (<>
 
 <Helmet>
        <title>Other Therapies and Practices 003A</title>
        <meta name="Other Therapies and Practices 003A" content="Other Therapies and Practices 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Other Therapies and Practices 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
 <AnotherReportsListSidebar003A/> 
 
 

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
<div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#baseline">•skip baseline practices</HashLink>

<br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#therChanges">•skip to changes during therapy</HashLink>
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "10px", fontSize: "30px",lineHeight: "1" }}   >FIQR & Outcome, counts
            <span style={{fontSize: "13px",paddingLeft: "20px", paddingTop: "0px",paddingBottom: "0px"}}>responder: ≤-20% change from baseline</span>
            </div>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px"}} bgColor="#0034C0" tableKey={"FIQR_resp_counts003A"}></CmReportTable003Ax>

            
            </div> 
            </Container>

            {/* <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
                <div className="article">
            <div className="TitleC" style={{paddingBottom: "20px", fontSize: "15px",lineHeight: "1" }}>*in graph percentages:
            <br/> top: of responses
            <br/> bottom: of participants</div>
            </div>
            </Container> */}
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px",lineHeight: "1" }}   >Counts By Workflow, Completion Status
            <div style={{fontSize: "15px",paddingBottom: "10px"}} >includes completers only; done_complete & done_incomplete
            <span style={{fontSize: "13px",paddingLeft: "20px", paddingTop: "0px",paddingBottom: "0px"}}>(done_incomplete: did not complete part1)</span>
            </div>
            </div>
            
        
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px"}} report={"ACT003A_otherPrac"} />

                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px",lineHeight: "1" }}   >Counts By Workflow, FIQR Status
            <span style={{fontSize: "15px",paddingLeft:"15px",paddingBottom: "0px"}} >includes completers only</span></div>
        
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px"}} report={"ACT003A_otherPrac_fiqrALL"} />


                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px",lineHeight: "1" }}   >Counts By Workflow, FIQR Status
            <span style={{fontSize: "15px",paddingLeft:"15px",paddingBottom: "0px"}} >includes true completers, done_complete, only</span></div>
        
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px"}} report={"ACT003A_otherPrac_fiqr"} />


                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
        <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>

        <Row>
            <Col>
                <HashLink id="baseline" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
           

            </Col>
            </Row>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px" }}   >Baseline Questions</div>
            <div className="TitleC" style={{paddingBottom: "10px",paddingTop: "0px" }}   >Do you have a regular religious or spiritual practice?</div>

            <CmReportTable003AxS  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#C49CE0" tableKey={"baseli_000124A1"}></CmReportTable003AxS>
    
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#662D91" tableKey={"baseli_000124A0"}></CmReportTable003Ax>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC" style={{paddingBottom: "10px" }}   >Do you have a consistent meditation or mindfulness practice?</div>

            <CmReportTable003AxS  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#F55C48" tableKey={"baseli_000126A1"}></CmReportTable003AxS>
    
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#DF270D" tableKey={"baseli_000126A0"}></CmReportTable003Ax>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC" style={{paddingBottom: "10px" }}   >Do you currently or have you ever had a regular practice of:</div>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#73D5FF" tableKey={"baseli_000127A2"}></CmReportTable003Ax>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#17BAFF" tableKey={"baseli_000127A1"}></CmReportTable003Ax>
                </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC" style={{paddingBottom: "10px" }}   >Have you ever been in:</div>

            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#73ACE6" tableKey={"baseli_000128A2"}></CmReportTable003Ax>
    
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#2475C7" tableKey={"baseli_000128A1"}></CmReportTable003Ax>

                </div>
        </Container>
        <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px"}}>
<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px", lineHeight: "1" }}>
        <Row>
            <Col>
                <HashLink id="therChanges" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
           

            </Col>
            </Row>
            </Container>
            </div>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "40px" ,paddingTop: "0px"}}   >Changes during Therapy, main
<span style={{fontSize: "15px",paddingLeft:"20px"}} >includes completers only;</span><span style={{fontSize: "15px",paddingLeft:"5px"}}>fiqr_responder: ≤-20; fiqr_improver: ≤-10</span></div>
{/* <div className="TitleC"  style={{fontSize: "15px",paddingBottom: "0px"}} >includes completers only;<span style={{fontSize: "15px",paddingLeft:"20px"}}>fiqr_responder: ≤-20; fiqr_improver: ≤-10</span></div> */}


            <div className="TitleC" style={{paddingBottom: "5px",paddingTop: "0px", fontSize: "25px" }}   >each reported change counted</div>
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#9E005D" tableKey={"therapy_changes003A_countMult"}></CmReportTable003Ax>
            <div className="TitleC" style={{paddingBottom: "10px",paddingTop: "0px", fontSize: "25px" }}   >each participant-category only counted 1x</div>
            <CmReportTable003Ax  style={{ paddingLeft: "0px", paddingRight: "0px",}} bgColor="#750046" tableKey={"therapy_changes003A_count1"}></CmReportTable003Ax>
    
        </Container>
 </div>
 </>);

};

export default A003addpractices;
//             <div className="TitleC" style={{paddingBottom: "20px",paddingTop: "0px", fontSize: "15px" }}   >fiqr_responder: ≤-20; fiqr_improver: ≤-10 </div>
