import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import ScrollUpButton from "react-scroll-up-button";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import EproBase005 from  "./eproBase005" ;
import EproBaseGap005 from  "./eproBaseGap005" ;
import { HashLink } from "react-router-hash-link";


const A005baselines = (props) => {
    const [eprosA_updated, setEprosA_updated] = useState([]);

    const ArmData_updateD = async (setter) => { 
        const sco_doc = firestore.collection("analytics").doc("A005").collection("baselineEprosA_updated").doc("baselineEprosA_updated")
        const doc = await sco_doc.get();
        const docData=doc.data()
        const armData_updateD = docData['baselineEprosA_updated'];
        console.log(armData_updateD)
        setter(armData_updateD);
      } 
     

    
      useEffect(() => {
    ArmData_updateD(setEprosA_updated)
    }, [props]);

return (<>
 <Helmet>
        <title>Baselines 005A</title>
        <meta name="Baselines 005A" content="Baselines 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Baselines 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton />
<ReportsListSidebar005A/> 
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "20px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#scoreGaps">•score gap metrics counts</HashLink>
            <br/>
            <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#extraEpros_deets">•extra epro details</HashLink>


  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container>


   
       <Container fluid={true}>
<div className="article" style={{maxWidth:"450px",paddingTop: "20px", paddingBottom: "20px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "10px",fontSize: "20px"}}>data last retrieved: {eprosA_updated}</div>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "4px",fontSize: "45px",lineHeight: "1"}}>Extra Epro Counts</div>
{/* <div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "30px",lineHeight: "1"}} >difference between post-screening and pre-baseline scores</div> */}
<div style={{paddingTop: "20px",fontSize: "30px",paddingBottom: "10px",lineHeight: "1"}} >participants by arm</div>
<div style={{maxWidth:"200px"}}>
<CmReportTable005A2 style={{padding: "0px",paddingBottom: "10px"}}  thFontSize="18px" tdFontSize="15px" bgColor="#A66BD1" tableKey=  {"baselineEprosA_extraEpros_arm"} />
</div>
<div style={{paddingTop: "20px",fontSize: "30px",paddingBottom: "10px",lineHeight: "1"}} >epros by arm & subject status</div>

<CmReportTable005A2 style={{padding: "0px"}}  thFontSize="18px" tdFontSize="15px" bgColor="#9E005D" tableKey=  {"baselineEprosA_extraEpros_status_counts"} />

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px",lineHeight: "1"}}>Scores</div>
<div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "20px",lineHeight: "1"}} > average of post-screening and pre-baseline</div>
<EproBase005 style={{ paddingTop: "0px", paddingBottom: "0px"}}  eproKey={"fiqr"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"bdi2"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"promis_fatigue_conv"} />

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"caq8"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"promis_fatigue_conv"} />

</div>
</Container>  

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"promis_sleep_dist_conv"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"pips"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"fiqrF"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"fiqrI"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"fiqrS"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"pips_avoidance"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"pips_fusion"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"interferes_pain"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"interferes_sleep"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"promis_fatigue_raw"} />

</div>
</Container>  

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBase005 style={{width: "350px"}}  eproKey={"promis_sleep_dist_raw"} />

</div>
</Container>
{/* bdi2, caq8, fiqr, fiqrF, fiqrI, fiqrS, pips, pips_avoidance, pips_fusion, promis_fatigue_conv, promis_fatigue_raw, promis_sleep_dist_conv, promis_sleep_dist_raw */}


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="scoreGaps" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                        <br/>
                        <HashLink id="scoreGaps" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#extraEpros_deets">jump to extra epro details</HashLink>                
            </Col>
            </Row>
            </div>
            </Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px",lineHeight: "1"}}>Score Gaps</div>
<div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "20px",lineHeight: "1"}} >difference between post-screening and pre-baseline scores</div>
<EproBaseGap005 style={{width: "350px"}}  eproKey={"fiqr"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"bdi2"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"promis_fatigue_conv"} />

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"caq8"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"promis_fatigue_conv"} />

</div>
</Container>  

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"promis_sleep_dist_conv"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"pips"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"fiqrF"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"fiqrI"} />

</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"fiqrS"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"pips_avoidance"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"pips_fusion"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"interferes_pain"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"interferes_sleep"} />

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"promis_fatigue_raw"} />

</div>
</Container>  

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px"}}> 
<EproBaseGap005 style={{width: "350px"}}  eproKey={"promis_sleep_dist_raw"} />

</div>
</Container>


<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
          <div className="article" style={{paddingBottom: "0px", paddingLeft: "10px", paddingRight: "0px", lineHeight:"1", paddingTop: "25px"}}>
        <Row>
            <Col>
            <HashLink id="extraEpros_deets" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                        <br/>
                        <HashLink id="extraEpros_deets" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#scoreGaps">jump to score gap metrics</HashLink>                
            </Col>
            </Row>
            </div>
            </Container>
<Container fluid={true}>

          
<div className="article" style={{paddingTop: "20px", paddingBottom: "20px"}}> 

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px",lineHeight: "1"}}>Extra Epro Details</div>
{/* <div style={{paddingTop: "0px",fontSize: "30px",paddingBottom: "30px",lineHeight: "1"}} >difference between post-screening and pre-baseline scores</div> */}
<CmReportTable005A2 style={{padding: "0px"}} thFontSize="14px" tdFontSize="14px" bgColor="#FF6E2C" tableKey=  {"baselineEprosA_indivs_extraEpros"} />

</div>

           
</Container>
{/* CmReportTable005A2 */}

</>);

};

export default A005baselines;


