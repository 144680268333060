import React,{ useState, useEffect,Component }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import CmReportTableVS1 from "./CmReportTableVS1";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import CmReportTableVS1stickyH from "./CmReportTableVS1stickyH";
import CmReportTableVS1sticky from "./CmReportTableVS1sticky";
import CmReportTableVS1stickyFX from "./CmReportTableVS1stickyFX";

import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import GraphTemplateVSA from "./GraphTemplateVSA";

import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";
import GeoApp from "./Texas/GeojApp";
import KYTNgeoApp from "./KYTN/KYTNgeoApp";



const SCdemosA = (props) => {
    const [dem_title, setDem_title] = useState([]);
    const [demos_update, SetDemos_update] = useState([]);

    

    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("demos");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
       }

       useEffect(() => {  
        getTitleCs('dem_title',setDem_title)
        getTitleCs('demos_update', SetDemos_update)



    }, [props]);


return (<>
<link rel="stylesheet" type="text/css" integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==" href={"https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"} />

 <Helmet>
        <title>Demographics SCA</title>
        <meta name="Demographics SCA" content="Demographics SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
<Row>
<Col>
                        <h1>Swing Care</h1>
</Col>
</Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
<Row>
<Col>

<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#gender">•gender</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#age">•age</HashLink>

<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#ethnicity">•ethnicity</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#race1">•race</HashLink>
<br/>


<Row style={{paddingTop: "3px"}}></Row>
               <HashLink id="top" className="TitleDB" style={{ lineHeight: "1",paddingTop: "20px",paddingBottom: "50px", fontSize: "17px", paddingLeft: "15px",color: "#000000" }} to="#geoplus">Patient Geography</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#Texas">•Texas</HashLink>
<br/>
<HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#KY_TN">•Kentucky & Tennessee</HashLink>
<br/>

<div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
</Col>
</Row>
</div>
</Container>
       

      


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",width: "max"}}>

<div className="TitleC" style={{paddingLeft: "0px",paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{dem_title}<span style={{fontSize: "14px",paddingLeft: "20px"}} >updated: {demos_update}</span></div>

</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="gender" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Gender</HashLink>
</Col>
</Row>
</div>
</Container> 
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>

<Row>
<Col>
</Col>
</Row>   
<Row>
<Col>
<CmReportTableVS1 style={{padding: "0px", paddingTop: "20px"}} fColor="#226277"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#D3DD88" tableKey={"demo_gender"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="age" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Age</HashLink>
</Col>
</Row>
</div>
</Container> 


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>

<Row>
<Col>
</Col>
</Row>   
<Row>
<Col>
<CmReportTableVS1noInd style={{padding: "0px", paddingTop: "20px"}} fColor="#D3DD88"  thFontSize="12px" tdFontSize="12px" thPaddingRight="5px"  thPaddingLeft="6px" bgColor="#226277" tableKey={"demo_age"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="ethnicity" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Ethnicity</HashLink>
</Col>
</Row>
</div>
</Container> 
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "5px",maxWidth: "90%"}}>



<Row style={{width:"auto", maxWidth: "450px",paddingBottom: "5px"}} >
<Col>
<CmReportTableVS1stickyFX style={{padding: "0px"   }} fColor="#D3DD88"  thFontSize="12px" tdFontSize="11px" tdPaddingRight="1px" tdPaddingLeft="6px" tdPaddingTop="4px"  thPaddingLeft="2px" thPaddingRight="2px" bgColor="#226277" tableKey={"demo_ethnicityA1"} />
</Col>
</Row>

   
</div>

</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="race1" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Race</HashLink>
</Col>
</Row>
</div>
</Container> 
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "20px",maxWidth: "90%"}}>

<Row >
    
<Col>
</Col>
</Row>   

<Row style={{width:"auto", maxWidth: "450px",paddingBottom: "10px"}} >
<Col>
<CmReportTableVS1stickyFX style={{padding: "0px"   }} fColor="#226277"  thFontSize="12px" tdFontSize="11px" tdPaddingRight="1px" tdPaddingLeft="6px" tdPaddingTop="4px"  thPaddingLeft="2px" thPaddingRight="2px" bgColor="#D3DD88" tableKey={"demo_raceA2"} />
</Col>
</Row>
<Row style={{width:"auto", maxWidth: "450px"}} >
<Col>
<CmReportTableVS1stickyFX style={{padding: "0px"   }} fColor="#D3DD88"  thFontSize="12px" tdFontSize="11px" tdPaddingRight="1px" tdPaddingLeft="6px" tdPaddingTop="4px"  thPaddingLeft="2px" thPaddingRight="2px" bgColor="#226277" tableKey={"demo_raceA1"} />
</Col>
</Row>
<Row>
</Row>
   
</div>

</Container>
<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="geoplus" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">Patient Geography</HashLink>
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px"}}>Patients by County</div>

</div>
</Container> 

<Container fluid={true}>
<Row>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="Texas" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "8px" }} to="#top">Texas</HashLink>
            </Col>
            </Row>
  
          </div>
  </div>
  </Row>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>

<Row style = {{paddingLeft: "20px"}}>
<table style={{padding: "0px", width: "400px",height: "20px"}}>
	<tbody>
		<tr>

<td style={{backgroundColor:"#C6C6C6", width: "10px"}}></td>
<td style={{backgroundColor:"#E0CDEF", width: "10px"}}> </td>
<td style={{backgroundColor:"#D5B1FA", width: "10px"}}></td>
<td style={{backgroundColor:"#A66BD1", width: "10px"}}></td>
<td style={{backgroundColor:"#662D91", width: "10px"}}></td>
<td style={{backgroundColor:"#006FCB", width: "10px"}}></td>
<td style={{backgroundColor:"#0034C0", width: "10px"}}></td>
<td style={{backgroundColor:"#5AC2FF", width: "10px"}}></td>
<td style={{backgroundColor:"#5AA2FF", width: "10px"}}></td>
<td style={{backgroundColor:"#B80079", width: "10px"}}></td>
<td style={{backgroundColor:"#DA36DB", width: "10px"}}></td>
<td style={{backgroundColor:"#FF2F92", width: "10px"}}></td>
<td style={{backgroundColor:"#CB1C49", width: "10px"}}></td>
<td style={{backgroundColor:"#D80000", width: "10px"}}></td>
<td style={{backgroundColor:"#F32E51", width: "10px"}}></td>
<td style={{backgroundColor:"#FF4D00", width: "10px"}}></td>
<td style={{backgroundColor:"#FF7F0E", width: "10px"}}></td>
<td style={{backgroundColor:"#FFA15A", width: "10px"}}></td>
<td style={{backgroundColor:"#FDC000", width: "10px"}}></td>
<td style={{backgroundColor:"#FDCF16", width: "10px"}}></td>


		</tr>
	</tbody>
</table>
</Row>

<GeoApp/>
</div>

</Container>


<Container fluid={true}>
<Row>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", maxWidth: "90%"}}> 
<div style={{paddingBottom: "10px"}}>
        <Row>
            <Col>
                <HashLink id="KY_TN" className="TitleC" style={{ paddingBottom: "0px", fontSize: "25px", paddingLeft: "8px" }} to="#top">Kentucky & Tennessee</HashLink>
            </Col>
            </Row>
  
          </div>
  </div>
  </Row>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "10px",maxWidth: "90%"}}>

<Row style = {{paddingLeft: "20px"}}>
<table style={{padding: "0px", width: "400px",height: "20px"}}>
	<tbody>
		<tr>

<td style={{backgroundColor:"#C6C6C6", width: "10px"}}></td>
<td style={{backgroundColor:"#E0CDEF", width: "10px"}}> </td>
<td style={{backgroundColor:"#D5B1FA", width: "10px"}}></td>
<td style={{backgroundColor:"#A66BD1", width: "10px"}}></td>
<td style={{backgroundColor:"#662D91", width: "10px"}}></td>
<td style={{backgroundColor:"#006FCB", width: "10px"}}></td>
<td style={{backgroundColor:"#0034C0", width: "10px"}}></td>
<td style={{backgroundColor:"#5AC2FF", width: "10px"}}></td>
<td style={{backgroundColor:"#5AA2FF", width: "10px"}}></td>
<td style={{backgroundColor:"#B80079", width: "10px"}}></td>
<td style={{backgroundColor:"#DA36DB", width: "10px"}}></td>
<td style={{backgroundColor:"#FF2F92", width: "10px"}}></td>
<td style={{backgroundColor:"#CB1C49", width: "10px"}}></td>
<td style={{backgroundColor:"#D80000", width: "10px"}}></td>
<td style={{backgroundColor:"#F32E51", width: "10px"}}></td>
<td style={{backgroundColor:"#FF4D00", width: "10px"}}></td>
<td style={{backgroundColor:"#FF7F0E", width: "10px"}}></td>
<td style={{backgroundColor:"#FFA15A", width: "10px"}}></td>
<td style={{backgroundColor:"#FDC000", width: "10px"}}></td>
<td style={{backgroundColor:"#FDCF16", width: "10px"}}></td>


		</tr>
	</tbody>
</table>
</Row>

<KYTNgeoApp/>
</div>

</Container>



</>);

};

export default SCdemosA;