import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";

    
import CmReportTable003A from "./CmReportTable003A";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import GraphTemplate003Aref from "../m003Areferrers/GraphTemplate003Aref"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref"; 




const A003EXT_PGIC = (props) => {
    

 return (<>
 <Helmet>
        <title>PGIC Ext 003A</title>
        <meta name="App Use 003A" content="PGIC Ext 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>PGIC Extension 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 
{/* pgicM_EXT */}
<Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px"}}>All, by Participant</div>
            <table style={{padding: "0px"}}>
<tr>
</tr>
<tr>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#00B050"}}>█</span>  Very Much Improved</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#70AD47"}}>█</span>  Much Improved</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#C5E0B4"}}>█</span>  Minimally Improved</td>

<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#DCD9D9"}}>█</span>  No Change</td>

<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#F99383"}}>█</span>  Minimally Worse</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#FC6454"}}>█</span>  Much Worse</td>
<td style={{backgroundColor: "#FFFFFF", color: "#636161", borderRight: "double white",paddingRight:"5px",paddingLeft: "4px", fontSize: "10px"}}> <span style={{color: "#FF0000"}}>█</span>  Very Much Worse</td>
</tr>
</table>


            <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicM_EXT"} />
        </div>
        </Container>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px"}}>Extension</div>
            


            <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_EXT_All"} />
        </div>
        </Container>
        
       <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 
            <div className="TitleC" style={{paddingBottom: "5px"}}>All</div>
            


            <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px", paddingRight: "0px", paddingLeft: "0px"}} report={"pgicA_All"} />
        </div>
        </Container>
      

       

</>);

};

export default A003EXT_PGIC;
