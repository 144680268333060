import React from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";

// import CmReportTable004B from "./CmReportTable004B";
// import AsmallerReportTable004B from "./AsmallerReportTable004B";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import ApplicantGroups003A from "./ApplicantGroups003A";
// import AsmallerReportTable004Bweekly from "./AsmallerReportTable004Bweekly";
// import PROkeyTableXSext2 from "./PROkeyTableXSext2";
import CmReportTable003Ax from "./CmReportTable003Ax";
import {Helmet} from "react-helmet";

import GraphTemplate003A from "./GraphTemplate003A"; 

import CmReportTable003Axref from "../m003Areferrers/CmReportTable003Axref";

const A003JournalMets = (props) => {
 return (<>
  <Helmet>
		<title>Journal Metrics 003A</title>
		<meta name="Journal Metrics 003A" content="Journal Metrics 003A" />
	</Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>ACT Journals, Cohort 003A</h1>

                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <AnotherReportsListSidebar003A/>
        
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "160px", paddingRight: "0px"}}>
        <ApplicantGroups003A/>
        <Row style={{paddingBottom:"8px"}}>
        
            <Col xs={10}>
            <div className="TitleC" style={{ paddingLeft: "45px",paddingBottom: "0px",paddingTop: "0px"}} >By Journal Type</div>
            
              <GraphTemplate003A style={{paddingBottom:"28px",paddingTop: "0px"}} report={"ACT003A_journals"} />
            </Col>
        
          </Row>
          
         
         
          </Container>
    
          <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >Overall Journal Completion</div>
            <CmReportTable003Ax bgColor="#17BAFF" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_overall003A"}></CmReportTable003Ax>
          </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>


            <div className="TitleC" style={{paddingLeft: "10px",paddingBottom: "1px"}} >By Journal Type</div>
            <CmReportTable003Ax bgColor="#F32E51" style={{ paddingLeft: "0px", paddingRight: "0px",paddingBottom: "25px"}} tableKey={"journal_metrics_byJournalID003A"}></CmReportTable003Ax>
          </div>
        </Container>
           
            
            <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "65px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
           
      
         
          <div className="TitleC" style={{ paddingLeft: "10px",paddingBottom: "1px",paddingTop: "25px"}} >By Participant</div>
          <CmReportTable003Ax bgColor="#A3049F" style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"journal_metrics_by_subjectID003A"}></CmReportTable003Ax>

          </div>
        </Container>

       
 
 
 

    


</>);

};

export default A003JournalMets;




