import React,{ useState, useEffect } from "react";
import "../surveyAdherence.css";

import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
// import { usePageName } from 'react-page-name';
import {Helmet} from "react-helmet";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../../utils/firebase";
    
import CmReportTable003A from "./CmReportTable003A";
// import CmReportTable004BextST from "./CmReportTable004BextST"; 
// import AnotherReportsListSidebar004Bext from "./AnotherReportsListSidebar004Bext";
import AnotherReportsListSidebar003A from "./AnotherReportsListSidebar003A";
import { HashLink } from "react-router-hash-link";
import GraphTemplate003A from "./GraphTemplate003A";
import ApplicantGroups003A from "./ApplicantGroups003A";
// import getScreening_abandonedData  from "./A003expt";


const A003screening = (props) => {
    // const [abandons, setScreening_abandonedData] = useState([]);
    const [groupAAbandons, setGroupAAbandons] = useState([]);
    const [groupBAbandons, setGroupBAbandons] = useState([]);
    const [groupCAbandons, setGroupCAbandons] = useState([]);
    const [TAbandons, setTAbandons] = useState([]);
    const [loading, setLoading] = useState(false);


    const getScreeningAbandonedData = async (docName, setter) => {
        const query = await firestore.collection("analytics").doc("screenfails003A").collection("screening_abandoned003A").doc(docName).get();
        const abandons = query.data().screening_abandoned;
        setter(abandons);
    
        console.log(abandons)
        return abandons;
    }
    useEffect(() => {
        getScreeningAbandonedData('screening_abandoned_groupA', setGroupAAbandons);
        getScreeningAbandonedData('screening_abandoned_groupB', setGroupBAbandons);
        getScreeningAbandonedData('screening_abandoned_groupC', setGroupCAbandons);
        getScreeningAbandonedData('screening_abandoned', setTAbandons);

    }, [props]);



  
     


   
 return (<>
 <Helmet>
        <title>Screening 003A</title>
        <meta name="Screening 003A" content="Screening 003A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Screening 003A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
 <AnotherReportsListSidebar003A/> 
 <div className="article" style={{paddingTop: "0px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "0px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "10px", fontSize: "15px", paddingLeft: "15px" }} to="#screenfailreasons">•skip to screenfail reasons</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "10px", fontSize: "15px", paddingLeft: "15px" }} to="#eproscores">•skip to epro scores</HashLink>

                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </Container>
       </div>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px",paddingTop: "0px"}}>
        <div className="article" style={{paddingTop: "0px",paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
        <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px"}}>Screening Outcomes</div>
  
  <div className="TitleC" style={{fontSize: "12px",paddingBottom: "7px"}}>screening_pt1: screenfailed before starting any ePROs;&nbsp;&nbsp;&nbsp;screeningPROs: screenfailed because of an ePRO</div>
  <ApplicantGroups003A/>
       
        </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 

            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "21px", paddingTop: "0px"}}>All</div>
           

            
            <table style={{paddingTop: "20px", paddingBottom: "0px"}}>
                <tr>
                <td style={{paddingRight:"40px",width: "105px",height:"25px",fontSize: "11px"}}>screening_abandoned</td>
                    <td style={{paddingLeft:"40px",width: "500px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "11px",textAlign: "center"}} key={TAbandons}>{TAbandons}</td>
                    </tr> 
                </table> 


        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"screening_funnel003AT"} />
        </div>
        </Container>
 <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 

            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "21px", paddingTop: "0px"}}>Group A</div>
           

            
            <table style={{paddingTop: "20px", paddingBottom: "0px"}}>
                <tr>
                <td style={{paddingRight:"40px",width: "105px",height:"25px",fontSize: "11px"}}>screening_abandoned</td>
                    <td style={{paddingLeft:"40px",width: "500px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "11px",textAlign: "center"}} key={groupAAbandons}>{groupAAbandons}</td>
                    </tr> 
                </table> 


        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"screening_funnel003A_groupA"} />
        </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 

            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "21px", paddingTop: "0px"}}>Group B</div>
           
            <table style={{paddingTop: "20px", paddingBottom: "0px"}}>
                <tr>
                <td style={{paddingRight:"40px",width: "105px",height:"25px",fontSize: "11px"}}>screening_abandoned</td>
                    <td style={{paddingLeft:"40px",width: "500px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "11px",textAlign: "center"}} key={groupBAbandons}>{groupBAbandons}</td>
                    </tr> 
                </table> 


        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"screening_funnel003A_groupB"} />
        </div>
        </Container>

        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px"}}> 

            <div className="TitleC" style={{paddingBottom: "0px", fontSize: "21px", paddingTop: "0px"}}>Group C</div>
           
            <table style={{paddingTop: "20px", paddingBottom: "0px"}}>
                <tr>
                <td style={{paddingRight:"40px",width: "105px",height:"25px",fontSize: "11px"}}>screening_abandoned</td>
                    <td style={{paddingLeft:"40px",width: "500px",height:"25px", backgroundColor:"#FF0000",color: "#FFFFFF",fontSize: "11px",textAlign: "center"}} key={groupCAbandons}>{groupCAbandons}</td>
                    </tr> 
                </table> 


        <GraphTemplate003A style={{paddingBottom:"0px",paddingTop: "0px"}} report={"screening_funnel003A_groupC"} />
        </div>
        </Container>
        <div className="article" style={{ paddingLeft: "10px", paddingRight: "0px",paddingBottom: "5px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
        <Row>
            <Col>

                <HashLink id="screenfailreasons" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
                <br/>
               <HashLink  className="TitleDB" style={{ paddingBottom: "10px", fontSize: "15px", paddingLeft: "0px" }} to="#eproscores">skip to epro scores</HashLink>

            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
 
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
           
            <div className="TitleC">Screenfail Reasons</div>
            <CmReportTable003A  style={{ paddingLeft: "0px", paddingRight: "0px"}} tableKey={"screenfails003AV1"}></CmReportTable003A>
                </div>
        </Container>
        <div className="article" style={{paddingLeft: "10px", paddingRight: "0px",paddingBottom: "5px"}}>

<Container fluid={true} style={{ paddingBottom: "0px", paddingTop: "5px" }}>
        <Row>
            <Col>

                <HashLink id="eproscores" className="TitleDB" style={{ paddingBottom: "0px", fontSize: "14px", paddingLeft: "0px" }} to="#top">jump to top</HashLink>
            </Col>
            </Row>
            </Container>
            </div>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC">Screening PRO Score Summaries</div>
            <div className="TitleDB" style={{fontSize: "12px", paddingLeft: "17px",paddingBottom: "15px",paddingTop: "5px"}} >includes all people who completed screening</div>
            <CmReportTable003A tableKey={"PROsumms_003A"}></CmReportTable003A>
                </div>
        </Container>
        <Container fluid={true} style={{backgroundColor: "white", paddingLeft: "40px", paddingRight: "0px"}}>
            <div className="article" style={{paddingBottom: "30px", paddingLeft: "10px", paddingRight: "0px"}}>
            
            <div className="TitleC">Successful Screening PRO Score Summaries</div>
            <div className="TitleDB" style={{fontSize: "12px", paddingLeft: "17px",paddingBottom: "15px",paddingTop: "5px"}} >includes only people who completed & passed screening</div>
            <CmReportTable003A tableKey={"PROsummsPassScreen_003A"}></CmReportTable003A>
                </div>
        </Container>
        
        {/* <span className="TitleDB" style={{fontSize: "12px", paddingLeft: "20px"}} >includes only people who completed & passed screening</span> */}
        {/* <span className="TitleDB" style={{fontSize: "12px", paddingLeft: "20px"}} >includes all people who completed screening</span> */}

</>);

};

export default A003screening;




