import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import CmReportTableVS1noIndSpcD2 from "./CmReportTableVS1noIndSpcD2";
import CmReportTableVS1noIndSpcD2F from "./CmReportTableVS1noIndSpcD2F";
import CmReportTableVS1noInd from "./CmReportTableVS1noInd";
import CmReportTableVS1noIndYA from "./CmReportTableVS1noIndYA";
import CmReportTableVS1NoCols from "./CmReportTableVS1NoCols"; 
// CmReportTableVS1noIndYA
import GraphTemplateVSA from "./GraphTemplateVSA";

import ReportsListSidebarSCA from "./ReportsListSidebarSCA";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";


const SCweekdashA = (props) => {
    // const [feedback_update_time, setFeedback_update_time] = useState([]);
    const [wdtitle, setWdtitle] = useState([]);
    const [updated, setUpdated] = useState([]);

    // const [label00, setLabel00] = useState([]);
    // const [label01, setLabel01] = useState([]);
    // const [label02, setLabel02] = useState([]);
    const [label03, setLabel03] = useState([]);

    const [label04, setLabel04] = useState([]);
    const [percent_veryLikely, setPercent_veryLikely] = useState([]);
    const [promoters, setPromoters] = useState([]);
    const [passives, setPassives] = useState([]);
    const [detractors, setDetractors] = useState([]);

    const [fnps, setFnps] = useState([]);
    const [sched_next,setSched_next] = useState([]);

    const [occurred,setOccurred] = useState([]);
    const [cancelled,setCancelled] = useState([]);
    const [no_show,setNo_show] = useState([]);
    const [completed_intake,setCompleted_intake] = useState([]);
    const [total_stanzaRX,setTotalRX] = useState([]);
    const [df03_1,setDf03_1] = useState([]);
    const [df03_2,setDf03_2] = useState([]);
    const [df03_3,setDf03_3] = useState([]);
    const [df03_4,setDf03_4] = useState([]);
    const [df03_5,setDf03_5] = useState([]);
    const [df03_responses,setDf03_responses] = useState([]);


    const [intake_explA, setIntake_explA] = useState([]);
    const [intake_explB, setIntake_explB] = useState([]);



    const getTitleCs = async (titleC,setter) => { 
        const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("wdash");
        const doc = await dashes.get();
        const docData=doc.data()
        const titC = docData[titleC];
            console.log(titC)
            setter(titC);
    }
            const getTitleCCs = async (titleCC,setter) => { 
                const dashes = firestore.collection("analytics").doc("vsA").collection("pages").doc("appointments");
                const doc = await dashes.get();
                const docData=doc.data()
                const titC = docData[titleCC];
                    console.log(titC)
                    setter(titC);


       }

       useEffect(() => {  
        getTitleCs('wdtitle',setWdtitle)
        // getTitleCs('feedback_update_time',setFeedback_update_time)
        getTitleCs('updated',setUpdated)
        // getTitleCs('label00',setLabel00)
        // getTitleCs('label01',setLabel01)
        // getTitleCs('label02',setLabel02)
        getTitleCs('label03',setLabel03)
        getTitleCs('label04',setLabel04)
        getTitleCs('percent_veryLikely',setPercent_veryLikely)
        getTitleCs('promoters',setPromoters)
        getTitleCs('passives',setPassives)
        getTitleCs('detractors',setDetractors)
        getTitleCs('fnps',setFnps)
        getTitleCs('sched_next',setSched_next)
        getTitleCs('occurred',setOccurred)
        getTitleCs('cancelled',setCancelled)
        getTitleCs('no_show',setNo_show)
        getTitleCs('completed_intake',setCompleted_intake)
        getTitleCs('total_stanzaRX',setTotalRX) 
        getTitleCs('df03_1',setDf03_1)
        getTitleCs('df03_2',setDf03_2)
        getTitleCs('df03_3',setDf03_3)
        getTitleCs('df03_4',setDf03_4)
        getTitleCs('df03_5',setDf03_5)
        getTitleCs('df03_responses',setDf03_responses)

        getTitleCCs('intake_explB',setIntake_explB)
        getTitleCCs('intake_explA',setIntake_explA)
    

    }, [props]);
return (<>
 <Helmet>
        <title>Weekly Dash SCA</title>
        <meta name="Weekly Dash SCA" content="Weekly Dash SCA" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Swing Care</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
        <ReportsListSidebarSCA/> 
        
        <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1",width: "max" }}>
<div className="article" style={{paddingLeft: "30px", paddingRight: "0px",paddingBottom: "0px",width: "max"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#q2023W">•2023 Weeks</HashLink>
               <br/>
              
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#atmetrics">•All-Time Metrics</HashLink>
               <br/>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#q2023W_stanza">•2023 Stanza</HashLink>
               <br/> 
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "50px", fontSize: "15px", paddingLeft: "15px" }} to="#allOcc_byWeek">•2023 Occurred by type</HashLink>
               <br/> 

               {/* allOcc_byWeek */}
                   <div style={{ borderTop: "1px solid #941751", paddingTop: "40px",paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"30%"  }}></div>
               </Col>
           </Row>
           </div>
       </Container>
       

      
                   <Container fluid={true}  >
                   <div className="article" style={{ paddingRight: "0px",paddingBottom: "0px"}}>

                   <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "15px",fontSize: "45px",color: "#000000"}}>{wdtitle}<span style={{fontSize: "14px",paddingLeft: "10px"}} >updated: {updated}</span></div>
</div>
                   </Container>

                 
       
       



            {/* paddingTop: "0px", lineHeight: "1" */}


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row style={{padding: "0px",lineHeight: "1"}}>
<Col>
<HashLink id="q2023W" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">2023</HashLink>
</Col>

</Row>



<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "9px",fontSize: "25px"}}>by week</div>
</Col>
</Row>



<Row style={{overflow: "scroll", width: "auto",maxWidth: "85%", height: "auto",maxHeight: "300px"}}>

<Col>
<CmReportTableVS1noIndSpcD2 style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"dashWk2023"} />
</Col>
</Row>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "10px"}}>Proportion_Occurred3 == Occurred/ (No-Show + Cancelled + Occurred) </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "10px"}}>Proportion_Occurred2 == Occurred/ (No-Show + Occurred) </div>

<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "5px",paddingBottom: "0px",fontSize: "25px"}}>totals</div>
</Col>
</Row>
   

<Row style={{overflow: "scroll", width: "auto",maxWidth: "75%", height: "auto",maxHeight: "300px"}}>

<Col>
<CmReportTableVS1noIndSpcD2 style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"dashQT2023"} />
</Col>
</Row>


</div>
</Container>




<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%",paddingTop: "30px"}}>
<Row style={{padding: "0px",lineHeight: "1",paddingBottom: "10px"}}>
<Col>
<HashLink id="atmetrics" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">All-Time Metrics</HashLink>
</Col>

</Row>
{/* <Row style={{padding: "0px",paddingTop: "0px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "25px"}}>All-Time Metrics</div>
</Col>
</Row> */}
{/* <div class="tooltipSCX">Hover over me<span class="tooltipSCX tooltiptext">Tooltip text</span></div> */}
{/* <span class="tooltipSCX">Hover over me<span class="tooltipSCX tooltiptext">Tooltip text</span></span> */}
<Row style={{padding: "0px",paddingTop: "9px"}}>

        <Col style={{maxWidth:"355px"}}>
 <table style={{padding: "0px",maxWidth:"250px"}}>
    <thead>
       {/* <tr>
        <th style={{backgroundColor: "#000000", color: "#FFFFFF", borderRight: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px", width: "300px"}}>metric</th>
<th style={{backgroundColor: "#000000", color: "#FFFFFF", borderRight: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>overall</th>
        </tr> */}
    </thead>
<tbody>
<tr>
</tr>
<tr>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px",width:"80%"}}>completed intakes</td>
<td  style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{completed_intake}</td>
{/* <span class="tooltipSCX" style={{fontSize: "14px"}}> ➕<span class="tooltipSCX tooltiptext">Tooltip text</span></span> */}
</tr>
<tr>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}><Link style={{color: "#73F6D2"}}  to="/VS/SCmetrics#active_patients" target="_blank">➝ active patients</Link></td>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{sched_next}</td>
</tr>
{/*   <Link style={{color: "#73F6D2"}}  to="/VS/SCmetrics#total_appts" target="_blank">+ appointments occurred</Link> */}
<tr>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}><Link style={{color: "#73F6D2"}}  to="/VS/SCmetrics#total_appts" target="_blank">➝ appointments occurred</Link></td>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{occurred}</td>
</tr>

<tr>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>appointments cancelled</td>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{cancelled}</td>
</tr>

<tr>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>appointments no-showed</td>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{no_show}</td>
</tr>
<tr>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}><Link style={{color: "#73F6D2"}}  to="/VS/SCstanza" target="_blank">➝ Stanza prescriptions</Link></td>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{total_stanzaRX}</td>
</tr>
<tr>
<td style={{backgroundColor: "#8C8785", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}><Link style={{color: "#73F6D2"}} to="/VS/SCfeedback" target="_blank">➝ net promoter score</Link></td>
<td style={{backgroundColor: "#570054", color: "#FFFFFF", border: "1px solid white",paddingRight:"5px",paddingLeft: "4px", fontSize: "11px"}}>{fnps}</td>
</tr>

</tbody>
</table>
</Col>
    </Row>

    <Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "30px",paddingBottom: "0px",fontSize: "25px"}}>Patient Duration</div>
</Col>
</Row>

    <Row style={{paddingTop: "0px",paddingBottom: "0px"}}>
    <Col style={{padding:"0px"}}>
    <GraphTemplateVSA style={{padding: "0px",paddingTop: "0px"}} report={"patient_monthsA"} />

</Col>
</Row>
    </div>   
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "30px", paddingBottom: "10px", maxWidth: "90%"}}> 
<Row>
<Col>
<div className="TitleC" style={{padding: "0px",paddingBottom: "5px",fontSize: "20px", maxWidth: "90%"}}>{label04} </div>
</Col>
</Row>
{/* paddingTop: "0px",paddingBottom: "50px",paddingLeft: "0px" */}
<Row style={{padding: "0px",paddingBottom: "10px",paddingLeft: "15px"}}> 
<table style={{padding: "0px",paddingBottom: "10px",maxWidth: "450px"}}>
<tr>
{/* <td  style={{ paddingTop: "30px",backgroundColor:"#FFFFFF",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FFFFFF",borderLeft: "1px solid  #FFFFFF",borderBottom: "1px solid  #FFFFFF",borderRight: "1px solid  #FFFFFF"    }}></td> */}
<td  style={{backgroundColor:"#00B050",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #00B050",borderBottom: "1px solid  #00B050" ,borderRight: "2px solid  #00B050"}}>promoters:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{promoters}</span></td> 
<td  style={{backgroundColor:"#FDCF16",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FDCF16",borderBottom: "1px solid  #FDCF16" ,borderRight: "1px solid  #FDCF16"    }}>passives:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{passives}</span></td>
<td  style={{ backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderBottom: "1px solid #FF8733"   }}></td>
<td  style={{ backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "5px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderBottom: "1px solid #FF8733"    }}>detractors:<span style={{paddingLeft: "8px", fontWeight: "700"}}>{detractors}</span></td>
<td  style={{  backgroundColor:"#FF8733",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FF8733",borderLeft: "1px solid  #FF8733",borderBottom: "1px solid  #FF8733",borderRight: "1px solid  #FF8733"    }}></td>
</tr>
<tr>
{/* <td  style={{ paddingTop: "30px",backgroundColor:"#FFFFFF",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #FFFFFF",borderLeft: "1px solid  #FFFFFF",borderBottom: "1px solid  #FFFFFF",borderRight: "1px solid  #B9B9B9"    }}></td> */}
<td colSpan={4}  style={{color: "#FFFFFF",fontSize: "18px",backgroundColor:"#000000",lineHeight: "2",paddingLeft: "15px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #000000",borderRight: "1px solid  #000000",alignContent: "center",textAlign: "center",borderBottom: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9"    }}>NPS: <span style={{fontWeight: "700"}}>{fnps}</span></td>
<td  style={{  backgroundColor:"#000000",lineHeight: "2",paddingLeft: "3px",paddingRight: "3px",width: "100px", paddingBottom: "4px",border: "1px solid  #000000",borderLeft: "1px solid  #000000",borderBottom: "1px solid  #B9B9B9",borderRight: "1px solid  #B9B9B9"    }}></td>

</tr>
</table>

</Row>
</div>
</Container>

<Container fluid={true}>
<div className="article" style={{paddingTop: "10px", paddingBottom: "10px", maxWidth: "90%"}}> 


<Row style={{padding: "0px",paddingTop: "3px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "5px",fontSize: "20px", maxWidth: "90%"}}>{label03} </div>
</Col>
</Row>
<Row>
<Col   >
	<Table className={"reports_tableX"}  style={{paddingTop: "0px",backgroundColor: "white", maxWidth: "400px"}}>
		<thead >
			<tr    >
            <th className={"twoArm_tableB"} style={{ backgroundColor: "#BCBD22",color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px",border: "1px solid #B9B9B9",borderRight:"1px solid #FFFFFF"  }} >5</th>
				<th className={"twoArm_tableB"} style={{ backgroundColor: "#BCBD22",color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px",border: "1px solid #B9B9B9",borderRight:"1px solid #FFFFFF"   }} >4</th>
				<th className={"twoArm_tableB"} style={{ backgroundColor: "#BCBD22",color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px" ,border: "1px solid #B9B9B9",borderRight:"1px solid #FFFFFF"}} >3</th>
				<th className={"twoArm_tableB"} style={{ backgroundColor: "#BCBD22",color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px",border: "1px solid #B9B9B9",borderRight:"1px solid #FFFFFF" }} >2</th>
				<th className={"twoArm_tableB"} style={{ backgroundColor: "#BCBD22",color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px",border: "1px solid #B9B9B9",borderRight:"1px solid #FFFFFF" }} >1</th>
				<th className={"twoArm_tableB"} style={{backgroundColor: "#BCBD22", color: "#FFF9F3",  fontSize: "11px", paddingLeft: "6px",paddingRight: "5px", paddingBottom: "2px",paddingTop:"2px",border: "1px solid #B9B9B9" }} >responses</th>
			</tr>
		</thead>
		<tbody  className={"twoArm_tableB"}  >
			<tr style={{paddingTop:"1px"}}>
            <td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_5}</td>
				<td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_4}</td>
				<td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_3}</td>
				<td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_2}</td>
				<td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_1}</td>
				<td  style={{ fontSize: "11px", lineHeight: "1",paddingLeft: "5px",paddingRight: "3px",width: "120px", paddingBottom: "4px",border: "1px solid  #B9B9B9",borderLeft: "1px solid  #B9B9B9",borderBottom: "1px solid  #B9B9B9"    }}>{df03_responses}</td>


			</tr>
		</tbody>
	</Table>
</Col>
</Row>
<Row >
    <Col style={{padding:"0px"}}>
    <GraphTemplateVSA style={{padding: "0px",paddingTop: "10px"}} report={"df03_plotSC"} />

</Col>
</Row>
</div>
</Container>




<Container fluid={true}>
<div className="article" style={{paddingTop: "20px",paddingRight: "0px",paddingBottom: "30px",maxWidth: "90%"}}>
<Row style={{padding: "0px",lineHeight: "1"}}>
<Col>
<HashLink id="q2023W_stanza" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">2023</HashLink>
</Col>

</Row>



<Row style={{padding: "0px",paddingTop: "0px",paddingBottom: "0px"}}>
<Col>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "25px"}}>Stanza, by week</div>
</Col>
</Row>
   
<div className="TitleC" style={{paddingTop: "10px",paddingBottom: "0px",fontSize: "18px"}}>All Patient Rx</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "85%", height: "auto",maxHeight: "300px"}}>

<Col>
<CmReportTableVS1noIndSpcD2F style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px"  thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"dashWk2023_Stanza"} />
</Col>
</Row>

<div className="TitleC" style={{paddingTop: "10px",paddingBottom: "0px",fontSize: "18px"}}>Active Patient Rx</div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "85%", height: "auto",maxHeight: "300px"}}>

<Col>
<CmReportTableVS1noIndSpcD2F style={{padding: "0px"}} fColor="#FFF9F3" thFontSize="11px" tdFontSize="11px"  thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"dashWk2023_Stanza_active"} />
</Col>
</Row>

   




</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingRight: "0px",paddingBottom: "0px",maxWidth: "90%"}}>
<Row>
<Col>
<HashLink id="allOcc_byWeek" className="TitleC" style={{ borderBottom: "1px solid #941751",paddingBottom: "0px", fontSize: "40px",maxWidth: "100%" }} to="#top">2023 Occurred, by type</HashLink>
{/* <div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "20px"}}>active patients only </div> */}

</Col>
</Row>

</div>

</Container>



<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 
<div style={{paddingTop: "10px"}}>
<CmReportTableVS1NoCols style={{padding: "0px"}} fColor="#000000"  tdFontSize="12px"  tableKey={"appt_abbrv_key"} />
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "1px",fontSize: "10px", lineHeight: "1"}}>{intake_explA} </div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "8px",fontSize: "10px", lineHeight: "1"}}>{intake_explB} </div>
</div>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "20px"}}>active patients only </div>

<Row style={{overflow: "scroll", width: "auto",maxWidth: "85%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1noIndYA style={{padding: "0px"}}  thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"appt_occurred_2023Wdeet1ActOn"} />
</Col>
</Row>
</div>
</Container>


<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "20px", maxWidth: "90%"}}> 
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "20px"}}>all patients</div>

   
<Row style={{overflow: "scroll", width: "auto",maxWidth: "85%", height: "auto",maxHeight: "300px"}}>
<Col>
<CmReportTableVS1noIndYA style={{padding: "0px"}}  thFontSize="11px" tdFontSize="11px" thPaddingRight="5px"  thPaddingLeft="6px"  tableKey={"appt_occurred_2023Wdeet1"} />
</Col>
</Row>
</div>
</Container>

</>);

};

export default SCweekdashA;