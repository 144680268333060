import React,{ useState, useEffect }  from "react";
import {firestore} from "../../utils/firebase";
import "../surveyAdherence.css";
// import { useState, useEffect } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import {Col, Container, Jumbotron, Row,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import ReportsListSidebar005A from "./ReportsListSidebar005A";
import GraphTemplate005A from "./GraphTemplate005A";
import CmReportTable005A1 from "./CmReportTable005A1";
import CmReportTable005A2 from "./CmReportTable005A2";
import CmReportTable005A2Ind from "./CmReportTable005A2Ind";

import CmReportTable005A3 from "./CmReportTable005A3";
import CmReportTable005A2S from "./CmReportTable005A2S";
import { HashLink } from "react-router-hash-link";
import ScrollUpButton from "react-scroll-up-button";

const A005VoiceActors = (props) => {




 return (<>
 <Helmet>
        <title>Voice Selections 005A</title>
        <meta name="Voice Selections 005A" content="Voice Selections 005A" />
    </Helmet>
        <AdminNavbar/>
        <Jumbotron>
            <Container fluid={true} style={{paddingBottom: "30px"}}>
                <Row>
                    <Col>
                        <h1>Voice Selections 005A</h1>
                    </Col>
                </Row>
            </Container>
        </Jumbotron>
        <ScrollUpButton/>
<ReportsListSidebar005A/> 

{/* <Container fluid={true} style={{ paddingBottom: "0px", paddingLeft: "10px", paddingTop: "0px", lineHeight: "1" }}>
 <div className="article" style={{paddingBottom: "10px", paddingLeft: "10px", paddingRight: "0px",paddingBottom: "0px"}}>
           <Row>
               <Col>
               <HashLink id="top" className="TitleDB" style={{ paddingBottom: "30px", fontSize: "15px", paddingLeft: "15px" }} to="#bysubjectID">•skip to audio & subjectID</HashLink>
           

  
                   <div style={{ borderTop: "1px solid #941751", paddingBottom: "10px",paddingLeft: "30px", paddingRight: "30px", maxWidth:"20%"  }}></div>
               </Col>
           </Row>
       </div>
       </Container> */}

<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 



<div  className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>Voice Selection by Percent of Audio Played</div>

<GraphTemplate005A style={{padding: "0px"}} report={"voice_percents005A"} />

</div>
</Container>
<Container fluid={true}>
<div className="article" style={{paddingTop: "0px", paddingBottom: "0px", width: "auto"}}> 


<Row style={{paddingTop: "20px"}}>
<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>By Audio</div>

</Row>
<Row>
<CmReportTable005A2Ind  style={{padding: "0px"}} thFontSize="16px" tdFontSize="14px" bgColor="#2475C7" tableKey={"voiceActors005A_1"} />
</Row>
<Row style={{paddingTop: "20px"}}>

<div className="TitleC" style={{paddingTop: "0px",paddingBottom: "0px",fontSize: "45px"}}>By Audio & Participant</div>
</Row>
<Row>
<Col>
<CmReportTable005A2Ind  style={{paddingTop: "0px"}} thFontSize="16px" tdFontSize="14px" bgColor="#9E005D" tableKey={"voiceActors005A_0"} />
</Col>

</Row>
</div>
</Container>



</>);

};

export default A005VoiceActors;

